import { Box } from "../../../node_modules/@mui/material/index";
import TicketField from "../inputs/TicketField";
import FieldReview from "./FieldReview";


const FormField = ({value, setValue, fieldStructure, isEditable, sectionValues, onDownloadClick, contextValues, fullWidth}) => {
    const fieldId = fieldStructure.id;
    const isFieldDeleted = fieldStructure.is_deleted;

    if(isFieldDeleted && (value === undefined)){
        return null;
    }

    if(isEditable){
        let label = fieldStructure.label;
        if(isFieldDeleted){
            label = `${label} (deleted field - cannot edit)`;
            setValue = (x) => {};
        }
        else if(fieldStructure.is_optional){
            label = `${label} (optional)`
        }
        return (
            <TicketField
                key={fieldId}
                fieldStructure={fieldStructure}
                value={value}
                setValue={setValue}

                component={fieldStructure.component}
                valueType={fieldStructure.valueType}
                label={label}
                helperText={fieldStructure.helper_text}
                sectionValues={sectionValues}
                optional={fieldStructure.optional}
                choices={fieldStructure.choice_desc?.choices}
                dependencies={fieldStructure.choice_desc?.dependencies}
                contextValues={contextValues}
                onDownloadClick={onDownloadClick}
                fullWidth={fullWidth}
                // hasBeenVisited={hasBeenVisited}
                // idPrefix={fieldId}
                // sectionKey={sectionKey}
                // fieldKey={fieldId}
                // requires_approved_edit={fields_requiring_approved_edit && fields_requiring_approved_edit.includes(fieldKey)}
                />
        )
    }
    else {
        return (
            // <Box sx={{ border: 2, borderColor: '#000000' }}>
                <FieldReview
                    fieldStructure={fieldStructure}
                    fieldValue={value}
                    sectionValues={setValue}
                    onDownloadClick={onDownloadClick}
                    contextValues={contextValues}
                    />
            // </Box>
        )
    }
}

export default FormField;