import React, { useState } from "react";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Context from "../../Store";
import { FIELD_COMPONENT_TYPES } from "../inputs/FieldProps";
import TicketTextField from "../inputs/TicketTextField";
import TicketSelectField from "../inputs/TicketSelectField";
import AbstractTable from "../tables/AbstractTable";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import { Box, Button, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import { EXTENSIONS, post } from "../../helpers/requests";
import { hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import { ManagedTableStructureWidget } from "./ManagedTableStructureWidget";
import LoadButton from "../generic/LoadButton";
import { MAX_LABEL_LEN } from "../configs/form/field_builder_helper";
import { SX_BOX_SELF_CENTERED } from "../../helpers/common_sx";

const LOCK_ACQUIRE_INTERVAL = 1000 * 60 * 15; //15 minutes

const TableField = ({fieldStructure, value, setValue}) => {
    const {component} = fieldStructure;

    if(component === FIELD_COMPONENT_TYPES.SHORT_TEXT){
        return(
            <TicketTextField
                label={fieldStructure.short_label}
                value={value}
                setValue={setValue}
                fullWidth
                />
        )
    }
    else if(component === FIELD_COMPONENT_TYPES.SELECT){
        return(
            <TicketSelectField
                label={fieldStructure.short_label}
                value={value}
                setValue={setValue}
                choices={fieldStructure.choice_desc.choices}
                fullWidth
                />
        )
    }
    return <div>Err #102</div>
}

const EditableManagedTable = ({table, refetchTables, onCancelEditClick}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);
    const {all_users, all_departments} = staticData;

    const [isObtainingConfigLock, setIsObtainingConfigLock] = useState(false);
    const [hasConfigLock, setHasConfigLock] = useState(false);
    
    const [structure, setStructure] = useState(table.structure);
    const [editedRows, setEditedRows] = React.useState([...table.rows]);
    const [editedTableName, setEditedTableName] = React.useState(table.name);

    const canUserEditConfigs = hasGobalPermission(user, ALL_PERMS.SYSTEM_ACTIONS.EDIT_CONFIG.key);
    // const {id, name, structure, rows, lock_holder_id, lock_expiration_time} = table;
    // const structure = table.structure;

    React.useEffect(() => {
        const reacquireLock = () => {
            post(EXTENSIONS.OBTAIN_TABLE_LOCK, {table_id: table.id})
        }

        const timerId = setInterval(() => {
            reacquireLock();
        }, LOCK_ACQUIRE_INTERVAL);

        return () => {
            //clean up
            clearInterval(timerId);
        }
    }, []);

    const columnNames = structure.fields.map((fieldStructure) => fieldStructure.short_label)

    columnNames.unshift('')

    const onSaveClick = () => {
        const structure_descs = !hasConfigLock ? null : structure.fields.map(f => ({id: f.id, value_type: f.value_type, label: f.label}))
        const body = {table_id: table.id, name: editedTableName, rows: [...editedRows], structure_descs}
        const onSuccess = (resp) => {
            alertSuccess("Changes saved");
            refetchTables();
        }
        const onFailure = (e) => {
            alertError(e.response.data);
        }
        post(EXTENSIONS.EDIT_TABLE, body, onSuccess, onFailure);
    }
    const setCellValue = (row, fieldId, value) => {
        const idx = editedRows.findIndex(r => r === row);
        const newRow = {...row, [fieldId]: value};
        const newRows = [...editedRows];
        newRows[idx] = newRow;
        setEditedRows(newRows);
    }
    
    const onAddRowClick = () => {
        const newRow = getDefaultSectionValues(structure);
        console.log('add row', {})
        const newRows = [...editedRows, newRow];
        setEditedRows(newRows)
    }

    const onRemoveRowClick = (idx) => {
        editedRows.splice(idx, 1)
        setEditedRows([...editedRows])
    }

    const onEditColumnsClick = () => {
        const onSuccess = () => {
            setIsObtainingConfigLock(false)
            setHasConfigLock(true)
        }
        const onFail = (err) => {
            setIsObtainingConfigLock(false)
            alertError(err.response.data);
        }
        post(EXTENSIONS.OBTAIN_CONFIG_LOCK, {}, onSuccess, onFail);
        setIsObtainingConfigLock(true)
    }

    const renderRowCells = (row) => {
        const removeButton = (
            <Tooltip enterDelay={750} title={"Remove row"}>
                <IconButton onClick={() => onRemoveRowClick(editedRows.findIndex(r => r === row))}>
                    <RemoveCircleIcon color="error"/>
                </IconButton>
            </Tooltip>
        )
        const formFields = structure.fields.map((fieldStructure) => {
            const fieldValue = row[fieldStructure.id]
            return (
                <TableField
                    fieldStructure={fieldStructure}
                    value={fieldValue}
                    setValue={(x) => setCellValue(row, fieldStructure.id, x)}
                    />
            )
        })
        return [removeButton, ...formFields]
    }

    return (
        <Box>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginX: 'auto', marginBottom:2}} maxWidth={400}>
                <TicketTextField
                    label='Table name'
                    value={editedTableName}
                    setValue={setEditedTableName}
                    maxLen={MAX_LABEL_LEN}
                    fullWidth={true}
                    />
            </Box>
            <Stack direction={'row'} spacing={2} marginBottom={2} alignItems="center">
                <Button onClick={onCancelEditClick} variant="contained" color="error">
                    Cancel
                </Button>
                <Button onClick={onSaveClick} variant="contained">
                    Save
                </Button>
                {
                    (canUserEditConfigs && !hasConfigLock) ?
                    <LoadButton onClick={onEditColumnsClick} loading={isObtainingConfigLock} disabled={isObtainingConfigLock}>
                        Edit Columns
                    </LoadButton> : null
                }
                {
                    !hasConfigLock ? null :
                        <ManagedTableStructureWidget
                            tableId={table.id}
                            structure={structure}
                            setStructure={setStructure}
                            editedRows={editedRows}
                            setEditedRows={setEditedRows}
                            />
                }
            </Stack>
            <AbstractTable
                rows={editedRows}
                rowToKey={row => editedRows.findIndex(r => r === row)} //not great id to use index
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                />
            <Button onClick={onAddRowClick} variant="contained">
                Add Row
            </Button>
        </Box>
    )
}

export default EditableManagedTable;