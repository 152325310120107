import { ListSubheader, MenuItem, TextField, Typography } from "../../../node_modules/@mui/material/index";

export const FieldSelect = ({structure, value, setValue}) => {
    const choices = []

    structure.sections.filter(s => !s.is_deleted).forEach(sectionStructure => {
        if(sectionStructure.is_deleted) return;

        const sectionId = sectionStructure.id;
        choices.push(<ListSubheader key={`header-${sectionId}`}><Typography variant='h6'>{sectionStructure.metadata.label}</Typography></ListSubheader>)
        
        sectionStructure.fields.filter(f => !f.is_deleted).forEach(fieldStructure => {
            if(fieldStructure.is_deleted) return;

            const value = fieldStructure.id;
            const label = fieldStructure.label;
            choices.push(<MenuItem key={value} value={value}>{label}</MenuItem> )            
        })
    })

    return(
        <TextField
            select
            label={"Add column"}
            onChange={e => setValue(e.target.value)}
            value={value}
            fullWidth={true}
            >
                { choices }
        </TextField> 
    )
}