import React, {useState} from "react";
import { TextField } from "@mui/material";
import { LONG_TEXT_LEN } from "../../helpers/Constants";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";

const getErrorMessage = (value, maxLen) => {
    if(!value) return null;
    if(value.length > maxLen){
        return `Input cannot exceed ${maxLen} characters (currently ${value.length})`
    }
    return null;
}
const TicketParagraphField = (props) => {
    const {value, setValue, idPrefix, label, fullWidth, hasBeenVisited, optional, maxLength, helperText} = props
    const maxChars = maxLength ? maxLength : LONG_TEXT_LEN;

    const [visited, setVisited] = useState(hasBeenVisited)
    const [inputStarted, setInputStarted] = useState(false)
    
    const onChange = (e) => {
        if(!inputStarted) setInputStarted(true)
        // const oldValue = value;
        let newValue = e.target.value
        // if((newValue.length > maxChars) && (newValue.length > oldValue.length)) newValue = oldValue
        setValue(newValue)
    }
    
    const onBlur = (e) => {
        if(!visited) setVisited(true)
    }
    
    const id = idPrefix ? `${idPrefix}-${label}` : label;
    const displayLabel = makeFieldDisplayLabel(label, optional)

    let error = (inputStarted && !value) || (visited && !value) || (value.length > maxChars)
    if(value === "" && optional) error = false
    const errorMsg = getErrorMessage(value, maxChars)

    
    return(
        <TextField
            id={id}
            variant="outlined"
            label={displayLabel}
            value={value}
            onChange={onChange}
            multiline
            maxRows={5}
            minRows={2}
            onBlur={onBlur}
            error={error}
            fullWidth={fullWidth && true}
            autoComplete="off"
            helperText={errorMsg ? errorMsg : helperText}

        />
    )
}

export default TicketParagraphField
