import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { gray_light } from '../../Colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const AbstractTable = ({rows, rowToKey, columnNames, renderRowCells, renderRowExpansion, onRowClick, id, onRowHover, rowHeight, expandedRow, initialRowsPerPage, rowsPerPageOptions}) => {
    const cursorType = onRowClick ? 'pointer' : ''
    const numColumns = columnNames.length;
    const isUsingPagination = !!initialRowsPerPage;

    const [pageIdx, setPageIdx] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageIdx(0);
    };
    const visibleRows = isUsingPagination ? rows.slice(pageIdx * rowsPerPage, pageIdx * rowsPerPage + rowsPerPage,) : rows;
    return (
        <>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size={rowHeight} aria-label="a dense table">
            <TableHead>
                <StyledTableRow>
                    {
                        columnNames.map((columnName, columnIdx) => {
                            return (
                                <StyledTableCell align="left" key={`${columnName}_${columnIdx}`}>
                                    <Typography variant='h6'>
                                        {columnName}
                                    </Typography>
                                </StyledTableCell>
                            )
                        })
                    }
                </StyledTableRow>
            </TableHead>
            <TableBody>
            {visibleRows.map((row) => (
                <React.Fragment key={"frag-"+rowToKey(row)}>

                    <StyledTableRow
                        key={rowToKey(row)}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: cursorType}}
                        onClick={onRowClick && ((e) => onRowClick(row))}
                        onMouseEnter={onRowHover && ((e) => {onRowHover(row)})}
                        >
                        {
                            renderRowCells(row).map((cell, cellIdx) => {
                                return (
                                    <StyledTableCell align="left" key={`${rowToKey(row)}_${columnNames[cellIdx]}_${cellIdx}`}>
                                        <Typography variant='h6'>
                                            {cell}
                                        </Typography>
                                    </StyledTableCell>
                                )
                                
                            })
                        }
                    </StyledTableRow>
                    {
                        renderRowExpansion && (row === expandedRow) &&
                        <tr><td colSpan={numColumns}>
                            {renderRowExpansion(row)}
                        </td></tr>
                    }
                </React.Fragment>
            ))}
            </TableBody>
        </Table>
            {
                (rows.length === 0) &&
                <Typography>
                    Nothing to show
                </Typography>
            }
        </TableContainer>
        {
            isUsingPagination &&
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [rowsPerPage]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={pageIdx}
                onPageChange={(e, newPageNum) => setPageIdx(newPageNum)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
        }
        </>
    );
}

export default AbstractTable;