import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useDataState, useEnsureUpToDateConfigs, useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import React, { useState } from "react";
import OrgView from "./OrgView";
import EmployeesView from "./EmployeesView";
import { isHRISManagedInternally, remove_role_from_department } from "./hris_helpers";
import AddEmployeeView from "./AddEmployeeView";
import { useSearchParams } from "react-router-dom";
import AddRoleView from "./AddRoleView";
import EmployeeView from "./EmployeeView";
import { HRIS_VIEW_EXTERNAL_MANAGE, HRIS_READONLY_SELECTABLE_VIEWS, HRIS_SELECTABLE_VIEWS, HRIS_VIEWS, HRIS_WRITE_VIEWS } from "./hris_constants";
import EditRoleView from "./EditRoleView";
import EditEmployeeView from "./EditEmpView";
import AddDepartmentView from "./AddDepartmentView";
import EditDepartmentView from "./EditDepartmentView";
import { hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import Context from "../../Store";
import CloseableMessage from "../generic/CloseableMessage";
import RolesView from "./RolesView";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";
import LoadingMessage from "../generic/LoadingMessage";
import { HRISStructures } from "./HRISStructures";
import { ManageExternalHRISSettings } from "./ManageExternalHRISSettings";




const HRISPageContent = ({view, setView, company, refetchCompany}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    if(!view) view = HRIS_VIEWS.EMPS; //default landing
    switch(view){
        case HRIS_VIEWS.EMPS:
            return <EmployeesView company={company}/>;
        case HRIS_VIEWS.ORG:
            return <OrgView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.ROLES:
            return <RolesView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.ADD_EMP:
            return (
                <AddEmployeeView
                    company={company}
                    refetchCompany={refetchCompany}
                    />
            )
        case HRIS_VIEWS.ADD_ROLE:
            return (
                <AddRoleView
                    company={company}
                    refetchCompany={refetchCompany}
                    />
            )
        case HRIS_VIEWS.EMP:
            const emp_id = searchParams.get('emp_id')
            const emp = company.employees.find(e => e.id == emp_id);
            return (
                <EmployeeView
                    employee={emp}
                    company={company}
                    refetchCompany={refetchCompany}
                    // onClose={() => setSelectedEmpId(null)}
                    // removeRole={removeRole}
                    />
            )
        case HRIS_VIEWS.EDIT_ROLE:
            return <EditRoleView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.EDIT_EMP:
            return <EditEmployeeView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.ADD_DEPT:
            return <AddDepartmentView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.EDIT_DEPT:
            return <EditDepartmentView company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEWS.CONFIGS:
            return <HRISStructures company={company} refetchCompany={refetchCompany}/>
        case HRIS_VIEW_EXTERNAL_MANAGE:
            return <ManageExternalHRISSettings company={company} refetchCompany={refetchCompany}/>
        default:
            return <div>Page not found</div>
    }
}

const SelectViewPanel = ({view, setView, isInternallyManaged, hasPermToEdit}) => {
    const {user} = React.useContext(Context);
    const displayInternalEditOptions = isInternallyManaged && hasPermToEdit;
    const displayExternalEditOptions = !isInternallyManaged && hasPermToEdit;

    // const selectableViews = hasEditPermission ? HRIS_SELECTABLE_VIEWS : HRIS_READONLY_SELECTABLE_VIEWS;

    return (
        <Stack direction='row' spacing={2} alignItems='center'>
            <ButtonGroup variant="contained" >
                {
                    Object.keys(HRIS_READONLY_SELECTABLE_VIEWS).map(key => {
                        const label = HRIS_READONLY_SELECTABLE_VIEWS[key];
                        const color = (label === view) ? 'success' : 'primary'
                        return(
                            <Button onClick={()=>setView(label)} key={label} color={color}>
                                {label}
                            </Button>
                        )
                    })
                }
            </ButtonGroup>
            {
                !displayInternalEditOptions ? null :
                <ButtonGroup variant="contained" >
                    {
                        Object.keys(HRIS_WRITE_VIEWS).map(key => {
                            const label = HRIS_WRITE_VIEWS[key];
                            const color = (label === view) ? 'success' : 'primary'
                            return(
                                <Button onClick={()=>setView(label)} key={label} color={color}>
                                    {label}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            }
            {
                !displayExternalEditOptions ? null :
                <Button onClick={()=>setView(HRIS_VIEW_EXTERNAL_MANAGE)} variant="contained" color={(view === HRIS_VIEW_EXTERNAL_MANAGE) ? 'success' : 'primary'}>
                    Manage
                </Button>
            }

        </Stack>
    )
}
const CANT_EDIT_MSG = "You only have permission to view the HRIS data, but not edit (e.g. add employees, set roles).";
const HRISPage = () => {
    const {user} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const [company, refetchCompany] = useRefetchData(EXTENSIONS.GET_COMPANY, {});
    const [searchParams, setSearchParams] = useSearchParams()
    const view = searchParams.get('view')
    const setView = (x) => setSearchParams({view: x});

    const awaitedDatas = [configSyncState, company]
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const hasPermToEdit = hasGobalPermission(user, ALL_PERMS.ORG_ACTIONS.EDIT_ORG.key);
    const isInternallyManaged = isHRISManagedInternally(company)

    company.employees.sort((a, b) => a.name.localeCompare(b.name))
    const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;

    return (
        <Box>
            {(isInternallyManaged && !hasPermToEdit) ? <CloseableMessage message={CANT_EDIT_MSG}/> : null}
            <SelectViewPanel view={view} setView={setView} isInternallyManaged={isInternallyManaged} hasPermToEdit={hasPermToEdit}/>
            <Box marginTop={2}>
                <HRISPageContent view={view} setView={setView} company={company} refetchCompany={refetchCompany}/>

            </Box>
        </Box>
    )
}

export default HRISPage;