import { Box } from "@mui/material";
import PermissionsSectionForm from "./PermissionSectionForm";
import React from "react";
import { REQUEST_STATES } from "../../helpers/Constants";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { ALL_PERMS } from "./perm_constants";
import Context from "../../Store";
import LoadButton from "../generic/LoadButton";
import { EXTENSIONS, post } from "../../helpers/requests";


const getInitialValue = (currValue) => {
    const values = {};
    const sectionPerms = makeNestedDeepCopy({...ALL_PERMS.GENERAL_ACTIONS, ...ALL_PERMS.ACCESS_ACTIONS});
    sectionPerms.VIEW_OFFBOARDING_STATUS = ALL_PERMS.ORG_ACTIONS.VIEW_OFFBOARDING_STATUS;
    Object.values(sectionPerms).forEach(p => {
        const permKey = p.key;
        values[permKey] = !!currValue[permKey]
    })

    return {
        initialValues: values,
        expectedPerms: sectionPerms
    }
}

const EditOrgRolesProfileView = ({permissions, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    
    const managerSectionValues = permissions.org_roles_profile.manager_section.permissions;
    const {initialValues, expectedPerms} = getInitialValue(managerSectionValues);
    
    const [values, setValues] = React.useState(initialValues);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const isSubmitting = (submitState === REQUEST_STATES.SENDING);

    const setAllValues = (x) => {
        const newValues = {};
        for(const key of Object.keys(values)){
            newValues[key] = x;
        }        
        setValues(newValues)
    }

    const onSaveClick = () => {
        const body = {manager_permissions: {...values}};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Profile Edited")
            refetchPermissions();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_ORG_ROLES_PERM_PROF, body, onSuccess, onFailure)
    }

    return (
        <Box>
            <PermissionsSectionForm
                title={"Manager Permissions"}
                titleTooltip={"Permissions a user has for Access Instances when they are an in/direct manager of the Employee Accessor (e.g. can a manager request access for their employees)"}
                expectedPerms={expectedPerms}
                values={values}
                setValue={(key, value) => setValues({...values, [key]: value})}
                setAllValues={setAllValues}
                />
            <LoadButton onClick={onSaveClick} loading={isSubmitting} disabled={isSubmitting}>
                Save
            </LoadButton>
        </Box>
    )
}

export default EditOrgRolesProfileView;