import { FIELD_VALUE_TYPES } from "../../inputs/FieldProps"


const makeTableConstraint = (col, val) => {
    return {col: col, val: val}
}
export const getManagedTableSelectFieldChoices = (field, tables, emps_map, depts_map) => {

    const table_id = field.choice_desc.table_id
    const table = tables.find(tbl => tbl.id === table_id);
    
    const choices = get_dynamic_choices(
        table.rows,
        field.choice_desc.column_id,
        field.choice_desc.constraints.map(c => c.col),
        [],
        (x) => {
            return {
                value: x,
                label: get_choice_label_from_table(field, x, emps_map, depts_map)
            }
        }
    )
    return choices;
}

const get_choice_label_from_table = (field_structure, field_value, emps_map, depts_map) => {
    if(field_structure.value_type === FIELD_VALUE_TYPES.EMPLOYEE){
        const empName = emps_map[field_value];
        return empName;
    }
    
    if(field_structure.value_type === FIELD_VALUE_TYPES.DEPARTMENT){
        const deptName = depts_map[field_value]
        return deptName
    }
    
    return field_value.toString()
}
    
const get_dynamic_choices = (rows, col, dependency_cols, constraints, val_to_choice) => {
    //code copied from Backend saturator.py
    if(!dependency_cols || (dependency_cols.length === 0)){
        const vals = get_unique_col_values(rows, col, constraints)
        if(!val_to_choice){
            return vals
        }
        return vals.map(val => val_to_choice(val))
    }
        
    const output = {}
    const dep_col = dependency_cols[0]
    const dep_col_vals = get_unique_col_values(rows, dep_col, constraints)
    for(const dep_col_val of dep_col_vals){
        const newConstraints = [...constraints, makeTableConstraint(dep_col, dep_col_val)]
        output[dep_col_val] = get_dynamic_choices(rows, col, dependency_cols.slice(1), newConstraints, val_to_choice)
    }
    return output
}


const get_unique_col_values = (rows, col, constraints) => {
    const output = []
    for(const row of rows){
        const val = row[col]
        if(!output.includes(val)){
            let pred_satisfied = true
            for(const constraint of constraints){
                if(row[constraint.col] !== constraint.val){
                    pred_satisfied = false
                    break;
                }
            }
            if(pred_satisfied){
                output.push(val)
            }
        }
    }

    return output
}