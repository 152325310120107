import React, { useState } from "react";
import Context from "../../Store";
import { FIELD_VALUE_TYPES } from "../inputs/FieldProps";
import AbstractTable from "../tables/AbstractTable";
import { useSearchParams } from "react-router-dom";
import EditableManagedTable from "./EditableManagedTable";
import { Box, Button } from "@mui/material";
import { EXTENSIONS, post } from "../../helpers/requests";
import { hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import { Stack, Typography } from "../../../node_modules/@mui/material/index";

const getFieldValueDisplayString = (fieldStructure, fieldValue, empsIdToNameObj, departmentsIdToNameObj) => {
    switch (fieldStructure.value_type) {
        case FIELD_VALUE_TYPES.STRING:
            return fieldValue;            
        case FIELD_VALUE_TYPES.EMPLOYEE:
            return empsIdToNameObj[""+fieldValue]
        case FIELD_VALUE_TYPES.DEPARTMENT:
            return departmentsIdToNameObj[""+fieldValue]
        default:
            return "??"
    }
}

const ManagedTable = ({table, onEditClick}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);
    const {all_users, all_departments} = staticData;
    const {id, name, structure, rows, lock_holder_id, lock_expiration_time} = table;
    const hasPermToEdit = hasGobalPermission(user, ALL_PERMS.SYSTEM_ACTIONS.EDIT_TABLES.key);

    const columnNames = structure.fields.map((fieldStructure) => fieldStructure.short_label)

    const renderRowCells = (row) => {
        return structure.fields.map((fieldStructure) => {
            const fieldValue = row[fieldStructure.id]
            return getFieldValueDisplayString(fieldStructure, fieldValue, all_users, all_departments);
        })
    }

    return (
        <Stack>
            <Typography variant='h5' align='center'>{table.name}</Typography>
            {hasPermToEdit && <Box marginBottom={2}><Button onClick={onEditClick} variant="contained">Edit</Button></Box>}
            <AbstractTable
                rows={rows}
                rowToKey={row => rows.findIndex(r => r === row)} //not great id to use index
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                />
        </Stack>

    )
}

const ManagedTableView = ({tables, refetchTables}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);

    const [searchParams, setSearchParams] = useSearchParams();
    const tableId = Number(searchParams.get('table_id'));
    const table = tables.find(t => t.id === tableId);

    const [isInEditMode, setIsInEditMode] = useState(false);

    const onEditClick = () => {
        const body = {table_id: tableId};
        const onSuccess = (resp) => {
            const wasLockAcquired = resp.data === true || resp.data === "True";
            if(wasLockAcquired){
                // refetchTables()
                setIsInEditMode(true);
            }
            else{
                const possibleEditorId = table.lock_holder_id; //"possible" because technically it could have changed since table was fetched.
                const possibleEditorName = staticData.all_users[possibleEditorId];
                alertError(`${possibleEditorName} is currently editing the table. Try again later.`)
            }
        }
        const onFailure = (e) => {
            alertError(e.response.data);
        }
        post(EXTENSIONS.OBTAIN_TABLE_LOCK, body, onSuccess, onFailure)
    }
    
    const onCancelEditClick = () => {
        const body = {table_id: tableId};
        post(EXTENSIONS.RELEASE_TABLE_LOCK, body);
        setIsInEditMode(false);
    }

    if(!isInEditMode) return <ManagedTable table={table} onEditClick={onEditClick}/>
    else return <EditableManagedTable table={table} onCancelEditClick={onCancelEditClick} refetchTables={refetchTables}/>

}
export default ManagedTableView;