import React from "react";
import DepartmentForm, { getDepartmentFormErrorMsg } from "./DepartmentForm";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import { EXTENSIONS, post } from "../../helpers/requests";


const AddDepartmentView = ({company, refetchCompany}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);

    const [department, setDepartment] = React.useState({parent_id: "", dept_type_id: "", name: "", extra_data: {}});
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {
        const errMsg = getDepartmentFormErrorMsg(department);
        if(errMsg){
            alertError(errMsg);
            return;
        }
        const body = {...department};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Department Added")
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.ADD_DEPT, body, onSuccess, onFailure)
    }

    return (
        <DepartmentForm
            company={company}
            department={department}
            setDepartment={setDepartment}
            submitState={submitState}
            onSubmitClick={onSubmitClick}
            />
    )
}

export default AddDepartmentView;