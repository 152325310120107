import { useContext, useState } from "react";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper"
import { Box, Button, CircularProgress, ListSubheader, MenuItem, Stack, TextField, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import TicketSelectField from "../inputs/TicketSelectField";
import TicketTextField from "../inputs/TicketTextField";
import { CHOICE_DESC_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../inputs/FieldProps";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getFieldFromStructureWithId, getFieldStructureFromFormStructure, getSectionWithWithId } from "../../helpers/StaticDataHelper";
import PageTitle from "../generic/PageTitle";
import { MyDivider } from "../generic/MyDivider";
import LoadingMessage from "../generic/LoadingMessage";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context from "../../Store";
import { ConfigLockTimer } from "./ConfigLockTimer";
import LabelValueDisplay from "../generic/LabelValueDisplay";

/*
functionality is currently limitted. this feature will like be removed eventually.
at the moment:
- only static single select fields on the parent can be used for predicate (condition for triggering auto creation)
- only short text string fields can be mapped to / from. all other will be a literal value of null for source value.
*/

const getMACOverview = (memberType) => `\
You may define a protocol for auto-creating a ${memberType.name} when a ticket for a ${memberType.parent_type.name} is first submitted. \
The member will be created as "active", even though it's parent ${memberType.parent_type.name} may be pending. \
This is similar to a member being requested and approved before its parent is fully approved. The member will be deactived if the parent is canceled, rejected or deactivated. \
You may set a condition for when the member auto-creation should occur and how the fields in the member form should be populated.
`

const MAPPING_SOURCE_TYPES = {
    CONSTANT: 'LiteralValue',
    PARENT: 'TicketFieldDescriptor'
}

export const SupportedFieldSelect = ({structure, value, setValue}) => {
    const choices = []

    structure.sections.filter(s => !s.is_deleted).forEach(sectionStructure => {
        if(sectionStructure.is_deleted) return;

        const fields = sectionStructure.fields.filter(f => isMappingSupportedForField(f))
        if(fields.length === 0) return

        const sectionId = sectionStructure.id;
        choices.push(<ListSubheader key={`header-${sectionId}`}><Typography variant='h6'>{sectionStructure.metadata.label}</Typography></ListSubheader>)
        
        fields.filter(f => !f.is_deleted).forEach(fieldStructure => {
            if(fieldStructure.is_deleted) return;

            const value = fieldStructure.id;
            const label = fieldStructure.label;
            choices.push(<MenuItem key={value} value={value}>{label}</MenuItem> )            
        })
    })

    return(
        <TextField
            select
            label={"Parent field with value for this field"}
            onChange={e => setValue(e.target.value)}
            value={value}
            fullWidth={true}
            >
                { choices }
        </TextField> 
    )
}


const makeDefaultSource = () => ({__meta_name: MAPPING_SOURCE_TYPES.CONSTANT, value: "", section_key: "", field_key: ""});

const getDefaultMAC = (structure) => {

    const src_fields = []
    const dest_fields = []

    for(const section of structure.sections){
        if(section.is_deleted) continue;

        for(const field of section.fields){
            if(field.is_deleted) continue;
            src_fields.push(makeDefaultSource())
            dest_fields.push({section_key: section.id, field_key: field.id})    
        }
    }

    return  {
        predicate: {
            __meta_name: 'FormValuePredicate',
            field: {
                section_key: "",
                field_key: ""
            },
            value: ""
        },
        field_mapper: {
            src_fields: src_fields,
            dest_fields: dest_fields
        }
    }
}

const getFormattedMac = (mac, structure) => {
    //add missing mappings and delete outdated ones
    const output = makeNestedDeepCopy(mac);
    
    const dests = output.field_mapper.dest_fields;
    const sources = output.field_mapper.src_fields;

    //remove extras
    for(let i = dests.length - 1; i >= 0; i--){
        const dst = dests[i];
        const field = getFieldStructureFromFormStructure(structure, dst);
        if(!field || field.is_deleted){
            dests.splice(i, 1);
            sources.splice(i, 1);
        }
    }

    //add missing dests
    for(const section of structure.sections){
        for(const field of section.fields){
            if(!section.is_deleted && !field.is_deleted){
                const is_present = dests.some(d => (d.section_key === section.id) && (d.field_key === field.id));
                if(!is_present){
                    dests.push({section_key: section.id, field_key: field.id})
                    sources.push(makeDefaultSource())
                }
            }
        }
    }

    for(const src of sources){
        if(src.value === null) src.value = ""
    }

    return output;
}



const areFieldsCompatible = (f1, f2) => {
    if(f1.value_type !== f2.value_type) return false;
    if(f1.value_type !== FIELD_VALUE_TYPES.STRING) return true;
    if(f1.component === f2.component) return true;

    const multiChoiceComponentTypes = [FIELD_COMPONENT_TYPES.MULTICHECK, FIELD_COMPONENT_TYPES.MULTISELECT];
    if(multiChoiceComponentTypes.includes(f1.component) !== multiChoiceComponentTypes.includes(f2.component)) return false;

    if((f1.component === FIELD_COMPONENT_TYPES.MULTI_TEXT) || (f2.component === FIELD_COMPONENT_TYPES.MULTI_TEXT)) return false;
    return true;

}
const getFieldChoicesThatMatch = (destField, sourceStructure) => {
    const fields = [];
    for(const section of sourceStructure.sections){
        if(section.is_deleted) continue;

        for(const field of section.fields){
            if(field.is_deleted) continue;
            if(areFieldsCompatible(field, destField)) fields.push(field)
        }
    }
    return fields;
}

const isMappingSupportedForField = (field) => {
    return (field.value_type === FIELD_VALUE_TYPES.STRING) && (field.component === FIELD_COMPONENT_TYPES.SHORT_TEXT);
}

const MACFieldMapping = ({memberField, parentStructure, source, setSource}) => {
    const {__meta_name, value, section_key, field_key} = source;

    if(!isMappingSupportedForField(memberField)){
        return (
            <Stack direction='row'>
                <Typography>None </Typography>
                <Tooltip title='This field will be empty on the auto-created member'>
                    <InfoOutlinedIcon color='success' sx={{verticalAlign:"middle"}}/>
                </Tooltip>
            </Stack>
        ) 
    }

    const valueSourceChoices = [
        {value: MAPPING_SOURCE_TYPES.CONSTANT, label: 'Constant Value'},
        {value: MAPPING_SOURCE_TYPES.PARENT, label: "From parent"}
    ]

    const setSourceParentFieldId = (fieldId) => {
        const section = getSectionWithWithId(parentStructure, fieldId)
        setSource({...source, section_key: section.id, field_key: fieldId})
    }

    return (
        <Stack direction='row' spacing={1} width={'100%'}>
            <Box width={'40%'}>
                <TicketSelectField
                    label={"Value source"}
                    value={__meta_name}
                    setValue={(x) => setSource({__meta_name: x, value: "", section_key: "", field_key: ""})}
                    choices={valueSourceChoices}
                    />
            </Box>
            {
                (__meta_name !== MAPPING_SOURCE_TYPES.CONSTANT) ? null :
                <TicketTextField
                    key={'constant'}
                    label={'value'}
                    value={value}
                    setValue={(x) => setSource({...source, value: x})}
                    fullWidth={true}
                    />
            }
            {
                (__meta_name !== MAPPING_SOURCE_TYPES.PARENT) ? null :
                <SupportedFieldSelect
                    key={'parent'}
                    structure={parentStructure}
                    value={field_key}
                    setValue={setSourceParentFieldId}
                    />
            }
        </Stack>
    )
}

const getPredicateFieldChoices = (parentStructure) => {
    const output = [];
    for(const section of parentStructure.sections){
        for(const field of section.fields){
            if(!field.is_deleted && (field.component === FIELD_COMPONENT_TYPES.SELECT) && (field.choice_desc?.__meta_type === CHOICE_DESC_TYPES.STATIC)){
                output.push({value: field.id, label: field.label})
            }
        }
    }
    return output;
}

export const MemberAutoCreationConfig = ({parentType, memberType, onSaved, onCancelClick}) => {
    const {alertError, alertSuccess} = useContext(Context);

    const currentlyHasMac = !!parentType.member_auto_creation;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mac, setMac] = useState(currentlyHasMac? getFormattedMac(parentType.member_auto_creation, memberType.structure) : getDefaultMAC(memberType.structure));


    const predicateFieldChoices = getPredicateFieldChoices(parentType.structure);
    const predicateFieldId = mac.predicate.field.field_key;
    const setPredicateFieldId = (fieldId) => {
        const section = getSectionWithWithId(parentType.structure, fieldId);
        const macCopy = makeNestedDeepCopy(mac)
        macCopy.predicate.field.section_key = section.id;
        macCopy.predicate.field.field_key = fieldId;
        setMac(macCopy);
    }

    const predicateValueChoices = !predicateFieldId ? [] : getFieldFromStructureWithId(parentType.structure, predicateFieldId).choice_desc.choices;
    const predicateValue = mac.predicate.value;
    const setPredicateValue = (predVal) => {
        const macCopy = makeNestedDeepCopy(mac);
        macCopy.predicate.value = predVal;
        setMac(macCopy)
    }


    const setSource = (idx, x) => {
        const macCopy = makeNestedDeepCopy(mac);
        macCopy.field_mapper.src_fields[idx] = x;
        setMac(macCopy);
    }

    const onSaveClick = () => {
        if(!predicateFieldId || !predicateValue){
            alertError("must provide conditions");
            return;
        }
        const macCopy = makeNestedDeepCopy(mac);

        //format - empty strings -> null
        for(const src of macCopy.field_mapper.src_fields){
            if(src.value === "") src.value = null;
        }
        
        //validate
        for(let i = 0; i < macCopy.field_mapper.src_fields.length; i++){
            const dst = macCopy.field_mapper.dest_fields[i]
            const memberField = getFieldStructureFromFormStructure(memberType.structure, dst)
            const src = macCopy.field_mapper.src_fields[i]
            if(isMappingSupportedForField(memberField) && (src.__meta_name === MAPPING_SOURCE_TYPES.PARENT) && !src.field_key){
                alertError(`Missing source value for field: ${memberField.label}`);
                return;
            }
        }
        submitChange(macCopy)
    }

    const submitChange = (newMac) => {
        const onSuccess = () => {
            alertSuccess("Saved");
            setIsSubmitting(false);
            onSaved();
        }
        
        const onFail = (err) => {
            alertError(err.response.data);
            setIsSubmitting(false);
        }
        const body = {member_instance_type_id: memberType.id, new_mac: newMac}
        post(EXTENSIONS.UPDATE_MEMBER_AUTO_CREATION, body, onSuccess, onFail)
        setIsSubmitting(true);
    }
    return (
        <Box>
            <PageTitle title={memberType.name} subtitle={'Auto Creation'}/>
            <Box justifyContent='center' display='flex' marginTop={-4}>
                <ConfigLockTimer/>
            </Box>
            <Typography>{getMACOverview(memberType)}</Typography>
            <LabelValueDisplay label="Member auto creation is currently" value={currentlyHasMac ? "Enabled (Delete to disable)" : "Off (configure and save to enable)"}/>
            <MyDivider/>
            <Box>
                <Typography variant='h6'>Condition for auto-creating the {memberType.name}</Typography>
                <Typography>Auto-create if</Typography>
            </Box>
            <Stack direction='row' spacing={4} alignItems='center' marginTop={2}>
                <TicketSelectField
                    label={'Parent field'}
                    helper_text={`The field on a ${parentType.name} that determines if a member should be auto-created`}
                    value={predicateFieldId}
                    setValue={setPredicateFieldId}
                    choices={predicateFieldChoices}
                    fullWidth={true}
                    />
                <Typography variant='h6'>=</Typography>
                <TicketSelectField
                    label={'Value'}
                    helper_text={`If the selected field of ${parentType.name} has this value at submission, a member will be created`}
                    value={predicateValue}
                    setValue={setPredicateValue}
                    choices={predicateValueChoices}
                    fullWidth={true}
                    />
            </Stack>
            <MyDivider/>
            <Typography variant='h6'>What values should the {memberType.name} form be autopopulated with</Typography>
            <Stack spacing={2} marginTop={2}>
                {
                    memberType.structure.sections.filter(s => !s.is_deleted).map(section => {
                        return (
                            <Box key={section.id}>

                                <Typography>{section.metadata.label}</Typography>
                                <Stack spacing={2} marginY={2} marginLeft={4}>
                                    {
                                        section.fields.filter(f => !f.is_deleted).map(field => {
                                            const idx = mac.field_mapper.dest_fields.findIndex(df => df.field_key === field.id);
                                            const src = mac.field_mapper.src_fields[idx];
                                            return (
                                                <Stack direction='row' spacing={1}  alignItems='center' key={field.id}>
                                                    <Typography width='20%'>{field.label}: </Typography>

                                                    <MACFieldMapping
                                                        memberField={field}
                                                        parentStructure={parentType.structure}
                                                        source={src}
                                                        setSource={(x) => setSource(idx, x)}
                                                        />
                                                </Stack>            
                                            )
                                        })
                                    }
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
            <MyDivider/>
            {
                isSubmitting ? 
                    <Box justifyContent='center' display='flex'><CircularProgress /></Box> :
                    <Stack direction='row' spacing={2} justifyContent='center'>
                        <Button variant='contained' color='primary' onClick={onSaveClick}>
                            Save
                        </Button>
                        <Button variant='contained' color='error' onClick={onCancelClick}>
                            Cancel
                        </Button>
                        
                        {
                            !currentlyHasMac ? null :
                            <Tooltip title={'Never auto create a member'}>
                                <Button variant='contained' color='error' onClick={() => submitChange(null)}>
                                    Delete
                                </Button>
                            </Tooltip>
                        }
                    </Stack>

            }
        </Box>
    )
}