import { Box, Stack, Typography } from "@mui/material"
import PageTitle from "../generic/PageTitle"
import { useParams } from "react-router-dom"
import React, { useEffect } from "react"
import Context from "../../Store"
import { getDefaultValue, getUpdatedFormForNewStructure } from "../../helpers/DefaultFormValueMaker"
import { ACTION_MSG_MAX_LEN, ENTITY_NAME_MAX_LEN, REQUEST_STATES } from "../../helpers/Constants"
import { CONTEXT_DEPENDENCIES, formatFormValues, makeNestedDeepCopy, makeTicketDeepCopy } from "../../helpers/TicketFormHelper"
import { validateForm } from "../../helpers/TicketFormValidationHelper"
import { EXTENSIONS, postTicket } from "../../helpers/requests"
import TicketTextField from "../inputs/TicketTextField"
import { getActiveUserChoices, getEntityName, getEntitySummaryFor } from "../../helpers/StaticDataHelper"
import LoadButton from "../generic/LoadButton"
import EntityNameTextField, { getExistingEntityErrorMessage } from "./EntityNameTextField"
import { hasGobalPermission } from "../permissions/perm_helper"
import { ALL_PERMS } from "../permissions/perm_constants"
import MultiSectionForm from "../form/MultiSectionForm"
import MySingleSelectFIeld from "../non_form_fields/MySingleSelectField"
import TicketParagraphField from "../inputs/TicketParagraphField"
import { SX_BOX_SIMPLE } from "../../helpers/common_sx"
import { useEnsureUpToDateConfigs } from "../../helpers/CustomHooks"
import LoadingMessage from "../generic/LoadingMessage"


const RequestPage = () => {
    const {alertSuccess, alertError, staticData, user} = React.useContext(Context);
    const {entity_types, entity_summaries} = staticData;
    const configSyncState = useEnsureUpToDateConfigs();
    
    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const parent_id = params.entity_type_instance_id ? Number(params.entity_type_instance_id) : null;
    
    const subtitle =
        parent_id ? 
        "For: " + getEntityName(staticData, parent_id) :
        undefined;
        
    const entityType = entity_types.find(et => et.id === entity_type_id);
    const entityInstanceType = parent_id ? entityType.member_instance_type : entityType.root_instance_type;
    const structure = entityInstanceType.structure;
    
    const [name, setName] = React.useState("");
    const [formData, setFormData] = React.useState({});
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [message, setMessage] = React.useState("");

    useEffect(() => {
        if(configSyncState === true){
            setFormData(getDefaultValue(structure))
        }
    }, [configSyncState])
    
    if(configSyncState === false) return <div>Failed to load</div>
    if(configSyncState === null) return <LoadingMessage/>
    
    const contextValues = {
        [CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: name
    }
    const entityTypeName = entityType.name;
    let title = `Request a new ${entityTypeName}`;
    if(parent_id) title += ' Member'

    const canOverride = hasGobalPermission(user, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_ADD.key);

    const onSubmitClick = (isOverride) => {
        // console.log('sub: ', {structure, formData})
        if(!name){
            alertError('Must provide a name for the entity');
            return;
        }
        if(name.length > ENTITY_NAME_MAX_LEN){
            alertError(`Entity name cannot exceed ${ENTITY_NAME_MAX_LEN} characters`);
            return;
        }
        const existingEntitySummary = getEntitySummaryFor(staticData, entity_type_id, parent_id, name);
        if(existingEntitySummary && existingEntitySummary.is_active){
            alertError(getExistingEntityErrorMessage(staticData, entity_type_id, parent_id));
            return;
        }
        if(message && (message.length > ACTION_MSG_MAX_LEN)){
            alertError("Note / message for is too long.")
            return;
        }
        let formDataCopy = makeTicketDeepCopy(formData)
        formatFormValues(formDataCopy, structure)
        
        const errors = validateForm(formDataCopy, structure, true, contextValues)
        const hasErrors = (errors.length !== 0)// && false
        
        let meta = null;
        if(existingEntitySummary){
            meta = {
                instance_type: "Entity",
                request_type: "Reopen",
                entity_type_id: null,
                parent_id: null,
                name: null,
                entity_instance_id: existingEntitySummary.id
            }
        }
        else{
            meta = {
                instance_type: "Entity",
                request_type: "Add",
                entity_type_id: entity_type_id,
                parent_id: parent_id,
                name: ""+name,
                entity_instance_id: null
            }
        }
        const body = {meta: meta, values: formDataCopy, is_override: isOverride, msg: message}
        
        // alertError('request - not checking errors')
        if(hasErrors){
            let msg = "Please fix the following errors\n";
            msg += errors.join("\n")
            alertError(msg, null)
        }
        else{
            const onSuccess = (response) => {
                alertSuccess("Successfully processed your request");
                setSubmitState(REQUEST_STATES.SUCCEEDED)
            }
            const onFail = (e) => {
                alertError(e.response.data);
                setSubmitState(REQUEST_STATES.FAILED)
            }
            postTicket(EXTENSIONS.SUBMIT_TICKET, body, structure, onSuccess, onFail)
            setSubmitState(REQUEST_STATES.SENDING)
        }
    }
    // console.log('request structure', structure)
    return (
        <div key={`request-page-div-${entity_type_id}-${parent_id}`}>
            <PageTitle title={title} subtitle={subtitle}/>
            <Box margin={'auto'} maxWidth={700}>
            {/* <TicketTextField
                label={`${entityTypeName} name`}
                value={name}
                setValue={setName}
                fullWidth={true}
                /> */}
            <EntityNameTextField
                text={name}
                setText={setName}
                entityTypeId={entity_type_id}
                parentId={parent_id}
                /> 
            {/* <TicketForm
                structure={structure}
                formData={formData}
                setFormData={setFormData}
                isInitialSubmission={true}
                /> */}
            <MultiSectionForm
                structure={structure}
                formData={formData}
                setFormData={setFormData}
                isInitialSubmission={true}
                contextValues={contextValues}
                />
            
            <Box sx={SX_BOX_SIMPLE}>
                <TicketParagraphField
                    value={message}
                    setValue={setMessage}
                    maxLength={ACTION_MSG_MAX_LEN}
                    label="Notes / message"
                    fullWidth
                    />
                <Stack direction={'row'} spacing={2} marginX={'auto'} marginTop={1} justifyContent={'center'}>
                    <LoadButton
                        variant="contained"
                        onClick={() => onSubmitClick(false)}
                        size="large"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        >
                        Submit
                    </LoadButton>
                    {
                        !canOverride ? null :
                        <LoadButton
                            variant="contained"
                            onClick={() => onSubmitClick(true)}
                            size="large"
                            loading={submitState === REQUEST_STATES.SENDING}
                            disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                            tooltip={`This will bypass any approval approval processes, and the ${entityTypeName} will be immediately active and approved`}
                            color={'error'}
                            >
                            Override
                        </LoadButton>
                    }
                </Stack>
            </Box>
            </Box>
        </div>
    )
}

export default RequestPage;
