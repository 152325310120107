import React, { useContext, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { FIELD_VALUE_TYPES } from "./FieldProps";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Context from "../../Store";
import { EXTRA_LONG_TEXT_LEN, MULTI_TEXT_DELIM } from "../../helpers/Constants";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";

const MAX_LEN = EXTRA_LONG_TEXT_LEN


const TicketMultiTextField = (props) => {
    const {value, setValue, idPrefix, label, helperText, valueType, hasBeenVisited, optional} = props
    const [visitedSinceLastAddition, setVisitedSinceLastAddition] = useState(hasBeenVisited)
    // const [inputStarted, setInputStarted] = useState(false)
    // const {alertError} = useContext(Context)

    //not using multivalue convert since "" => [""]
    const valueAsArray = (typeof value === 'string') ? value.split(MULTI_TEXT_DELIM) : value 
    
    const updateValue = () => {
        const valuesAsString = valueAsArray.join(MULTI_TEXT_DELIM)
        setValue(valuesAsString)
    }
    const handleChange = (idx, val) => {
        const valuesArrayCopy = [...valueAsArray]
        valuesArrayCopy[idx] = val

        if(valuesArrayCopy.join(MULTI_TEXT_DELIM).length > MAX_LEN) console.log(`texts can not exceed a total of ${MAX_LEN} characters`) //alertError(`texts can not exceed a total of ${MAX_LEN} characters`)
        else valueAsArray[idx] = val

        if(!visitedSinceLastAddition) setVisitedSinceLastAddition(true)
        updateValue()
    }
 
    const id = idPrefix + "-" +label
    // const onBlur = (e) => { if(!visited) setVisited(true) }
    // let error = (inputStarted && (value === "")) || (visited && !value) || (value.length > MAX_LEN)

    const addInput = () => {
        valueAsArray.push('')
        setVisitedSinceLastAddition(false)
        updateValue()
    }

    const removeInput = (idx) => {
        valueAsArray.splice(idx, 1)
        updateValue()
    }

    const n = valueAsArray.length
    const canAddTextField = (n === 0) || (valueAsArray[n - 1] !== "")
    const displayLabel = makeFieldDisplayLabel(label, optional)

    const errors = valueAsArray.map((s, idx) => {
        if(idx !== n - 1) return (s === "")
        else return ((s === "") && visitedSinceLastAddition && !(n <= 1 && optional))
    });
    const error = errors.includes(true)
    const border = error ? 2 : 1
    const borderColor = error ? 'error.main' : 'primary.main'
    return(
        <Box id={id} sx={{border: border, borderColor: borderColor, paddingX:3, paddingY:1, borderRadius:2}}>
            <Typography variant="h6" fontSize={16} marginY={1}>{displayLabel}</Typography>
            <Typography variant="p">{helperText}</Typography>
            {
                valueAsArray.map((s, idx) => {
                    // const error = (s === "")// && ((idx !== valueAsArray.length - 1) || visitedSinceLastAddition)
                    // const error = (idx !== n - 1) ? (s === "") :
                    //     ((s === "") && visitedSinceLastAddition && !(n <= 1 && optional))
                    return (
                        <Stack direction={"row"} key={`${id}-${idx}`} marginY={3}>
                            <IconButton onClick={(e) => removeInput(idx)}><RemoveCircleIcon color="error"/></IconButton>
                            <TextField
                                id={`${id}-${idx}`}
                                variant="outlined"
                                value={valueAsArray[idx]}
                                onChange={e => handleChange(idx, e.target.value)}
                                fullWidth
                                autoComplete="off"
                                error={errors[idx]}
                                // helperText={helperText}
                                onBlur={() => { if(!visitedSinceLastAddition) setVisitedSinceLastAddition(true)}}
                                />
                        </Stack>
                    )
                })
            }
            <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                <IconButton onClick={(e) => addInput()} disabled={!canAddTextField}>
                    <AddCircleIcon fontSize="large" color={canAddTextField ? "primary" : undefined}/>
                </IconButton>
            </Box>
        </Box>
    )
}

export default TicketMultiTextField
