import { useContext, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index"
import { EXTENSIONS, post } from "../../../helpers/requests"
import Context from "../../../Store"
import LoadingMessage from "../../generic/LoadingMessage"
import { ExternalHRISIntegrationMultiPanel } from "./ExternalHRISIntegrationMultiPanel"


export const HRISIntegrationConfigPage = () => {
    const {alertSuccess, alertError} = useContext(Context)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isExternalSelected, setIsExternalSelected] = useState(false)

    const onManuallyClick = () => {
        //set to manual and close
        const onSuccess = (resp) => {
            alertSuccess("Success")
            setIsSubmitting(false)
        }

        const onFail = (err) => {
            setIsSubmitting(false)
            alertError(err.response.data)
        }

        post(EXTENSIONS.SET_HRIS_TO_INTERNAL, {}, onSuccess, onFail)
        setIsSubmitting(true)
    }

    const onIntegrateClick = () => {
        setIsExternalSelected(true)
    }


    if(isExternalSelected){
        return (
            <ExternalHRISIntegrationMultiPanel/>
        )
    }
    return (
        <Box >
            <Typography variant='h6'>User base Management</Typography>
            <Typography>How do you want your user base to be managed?</Typography>
            {
                isSubmitting ? <LoadingMessage/> :
                <Box display='flex' m={2}>
                    <Stack spacing={2} justifyContent='center'>
                        <Button variant='contained' onClick={onManuallyClick}>
                            Manually
                        </Button>
                        <Button variant='contained' onClick={onIntegrateClick}>
                            Integrated with my HRIS
                        </Button>
                    </Stack>
                </Box>
            }
        </Box>
    )
}





