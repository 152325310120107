import { useParams, useSearchParams } from 'react-router-dom';
import PageTitle from "../generic/PageTitle";
import { useEnsureUpToDateConfigs, useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, downloadEntityFileField, post, postTicket } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import EntityInstanceActionPanel from '../entity/EntityInstanceActionPanel';
import Context from '../../Store';
import React, { useContext, useState } from 'react';
import EntityView from '../entity/EntityView';
import { cleanForm, formatFormValues, makeTicketDeepCopy } from '../../helpers/TicketFormHelper';
import { COMMON_REQUEST_TYPES, ENTITY_NAME_MAX_LEN, ENTITY_REQUEST_TYPES, REQUEST_STATES } from '../../helpers/Constants';
import { Box, Button, Stack, TextField } from '@mui/material';
import LoadButton from '../generic/LoadButton';
import LabelValueDisplay from '../generic/LabelValueDisplay';
import { hasGobalPermission, hasInstancePermissions } from '../permissions/perm_helper';
import { ALL_PERMS } from '../permissions/perm_constants';
import MultiSectionForm from '../form/MultiSectionForm';
import { getEntityOrUserName, getFieldStructureFromFormStructureWithIds } from '../../helpers/StaticDataHelper';
import { fieldValueString } from '../../helpers/FieldDisplayFormatters';
import { IconButton, Tooltip, Typography } from '../../../node_modules/@mui/material/index';
import EditIcon from '@mui/icons-material/Edit';
import TicketTextField from '../inputs/TicketTextField';
import { FIELD_VALUE_TYPES } from '../inputs/FieldProps';
import { FailedToLoadMessage } from '../generic/FailedToLoadMessage';


const EditEntity = ({entity, entityType, refetchEntity}) => {
    const {alertSuccess, alertError, user, staticData} = React.useContext(Context);
    const instanceType = !entity.parent ? entityType.root_instance_type : entityType.member_instance_type;
    const {structure} = instanceType;

    const [formData, setFormData] = React.useState(cleanForm(makeTicketDeepCopy(entity.state.form_values), structure));
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [message, setMessage] = React.useState("")

    const canEdit = (entity.is_active) && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.REQ_EDIT.key, entity, instanceType);//TODO: also check permissions
    const canOverrideEdit = (entity.is_active) && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_EDIT.key, entity, instanceType);//TODO: also check permissions

    const onSubmitClick = (isOveride) => {
        const body = {
            values: formatFormValues(makeTicketDeepCopy(formData), structure), 
            instance_desc: {instance_type: "Entity", entity_instance_id: entity.id}, 
            is_override: isOveride,
            msg: message
        }
        const onSuccess = (resp) => {
            alertSuccess("Edited");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchEntity();
        }
        const onFailure = (e) => {
            setSubmitState(REQUEST_STATES.FAILED);
            alertError(e.response.data)
        }
        postTicket(EXTENSIONS.EDIT_INSTANCE, body, structure, onSuccess, onFailure);
        setSubmitState(REQUEST_STATES.SENDING)
    }
    const downloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadEntityFileField(entity.id, null, sectionId, fieldId, null);
        }
    }
    return (
        <Box>
            <MultiSectionForm
                structure={structure}
                formData={formData}
                setFormData={setFormData}
                isInitialSubmission={false}
                editableSectionIds={"*"}
                getSectionDownloadFileField={downloadFileGetter}
                />
            {/* <Box justifyContent={'center'} alignContent={'center'} display={'flex'} margin={2}> */}
            <Stack direction={'row'} spacing={2}>
                {
                    canEdit &&
                    <LoadButton
                        variant="contained"
                        onClick={() => onSubmitClick(false)}
                        size="large"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        >
                        Edit
                    </LoadButton>
                }
                {
                    canOverrideEdit &&
                    <LoadButton
                        variant="contained"
                        onClick={() => onSubmitClick(true)}
                        size="large"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        tooltip={"This will bypass any approval approval processes for fields that require approved edit and apply the changes immediately"}
                        color={'error'}
                        >
                        Override Edit
                    </LoadButton>
                }
            </Stack>
        </Box>
    )
}

const RemoveEntity = ({entity, entityType, refetchEntity}) => {
    const {alertSuccess, alertError, user, staticData} = React.useContext(Context);
    const [message, setMessage] = useState("");
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    const isRoot = !entity.parent;
    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type


    const removeEntity = (isOverride) => {
        if(!message){
            alertError('provide a message');
            return;
        }
        const meta = {
            instance_type: "Entity",
            request_type: ENTITY_REQUEST_TYPES.REMOVE,
            entity_type_id: null,
            parent_id: null,
            name: null,
            entity_instance_id: entity.id
        }
        const body = {meta: meta, values: {}, is_override: isOverride, msg: ""}

        const onSuccess = (resp) => {
            const notifMsg = isOverride ? `Removed` : `Request submitted`
            alertSuccess(notifMsg);
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            refetchEntity();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        const emptyStructure = {sections: []}
        postTicket(EXTENSIONS.SUBMIT_TICKET, body, emptyStructure, onSuccess, onFailure)
        setSubmitState(REQUEST_STATES.SENDING);
    }
    const loading = (submitState === REQUEST_STATES.SENDING);
    const canRequestRemoval = entity.is_active && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.REQ_REMOVAL.key, entity, instanceType);
    const canOverrideRemoval = entity.is_active && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_REMOVAL.key, entity, instanceType);

    return (
        <Box>
            {/* <EntityView entity={entity} entityType={entityType} refetchEntity={refetchEntity}/> */}
            <Box>
                <TextField
                    placeholder="Why you want to remove this..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    minRows={2}
                    maxRows={5}
                    fullWidth
                    />
                    <Box marginTop={2}>
                        <Stack direction={'row'} spacing={2}>
                            {
                                canRequestRemoval &&
                                <LoadButton variant="contained" onClick={()=>removeEntity(false)} loading={loading} disabled={loading}>
                                    Request Removal
                                </LoadButton>
                            }
                            {
                                canOverrideRemoval &&
                                <LoadButton variant="contained" onClick={()=>removeEntity(true)} loading={loading} disabled={loading} color={'error'}>
                                    Override Remove
                                </LoadButton>
                            }
                        </Stack>
                    </Box>                        
            </Box>
        </Box>
    )
}
const VIEW_STATES = {
    VIEW: 'view',
    EDIT: 'edit',
    REMOVE: 'remove'
}

const EntityName = ({entity, refetchEntity, entityType}) => {
    const {staticData, user, alertError, alertSuccess} = React.useContext(Context)
    const [inEditMode, setInEditMode] = useState(false);
    const [newName, setNewName] = useState(entity.name);
    const [isLoading, setIsLoading] = useState(false);

    const canEdit = hasGobalPermission(user, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_EDIT.key);

    const onSaveClick = () => {
        if(!newName){
            alertError('Name cannot be empty');
            return;
        }
        if(newName.length > ENTITY_NAME_MAX_LEN){
            alertError('name is too long.');
            return;
        }
        const onSuccess = () => {
            alertSuccess("Name updated");
            setIsLoading(false);
            setInEditMode(false);
            refetchEntity()
        }
        const onFailure = (e) => {
            setIsLoading(false);
            alertError(e.response.data)
        }

        const body = {entity_id: entity.id, entity_type_id: entityType.id, new_name: newName}
        post(EXTENSIONS.EDIT_ENTITY_NAME, body, onSuccess, onFailure)
    }
    if(!inEditMode){
        return (
            <Box>
                {entity.name}
                {
                    !canEdit ? null :
                    <Tooltip title={"Edit name"}>
                        <IconButton onClick={() => setInEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        )
    }
    else{
        return (
            // <Box justifyContent='center'>
            <Stack direction='row' spacing={2} justifyContent='center'>
                <TicketTextField
                    value={newName}
                    setValue={setNewName}
                    label={'New name'}
                    valueType={FIELD_VALUE_TYPES.STRING}
                    maxLen={ENTITY_NAME_MAX_LEN}
                    />
                <Box>
                    <LoadButton
                        loading={isLoading}
                        disabled={isLoading || newName === entity.name}
                        variant='contained'
                        onClick={onSaveClick}
                        >
                            Save
                    </LoadButton>
                </Box>
                <Box>
                    <Button variant='contained' onClick={()=>setInEditMode(false)} color={'error'} disabled={isLoading}>
                        Cancel
                    </Button>
                </Box>
            </Stack>
            // </Box>
        )
    }
}
const ViewEntityPage = () => {
    const {staticData, user} = React.useContext(Context)
    const configSyncState = useEnsureUpToDateConfigs();

    const params = useParams();
    const instance_id = Number(params.entity_type_instance_id);
    const entity_type_id = Number(params.entity_type_id);

    const [viewState, setViewState] = useState(VIEW_STATES.VIEW);
    
    const body = {instance_desc: {instance_type: "Entity", entity_instance_id: instance_id}};
    const [entity, refetchEntity] = useRefetchData(EXTENSIONS.GET_INSTANCE, body);

    const awaitedDatas = [configSyncState, entity];
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;
    
    const entityType = staticData.entity_types.find(et => et.id === entity_type_id);

    const isRoot = !entity.parent;
    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type

    const pendingRequestTypes = entity.tickets.reduce((acc, curr) => {
        if(curr.status === "Pending"){
            if(curr.request_type === COMMON_REQUEST_TYPES.EDIT_REQUEST){
                const sectionId = Object.keys(curr.form_values)[0];
                const fieldId = Object.keys(curr.form_values[sectionId])[0];
                const fieldStructure = getFieldStructureFromFormStructureWithIds(instanceType.structure, sectionId, fieldId);
                const fieldLabel = fieldStructure ? fieldStructure.short_label : '';
                const currValue = fieldValueString(entity.state.form_values[sectionId][fieldId], fieldStructure, staticData);
                const pendingValue = fieldValueString(curr.form_values[sectionId][fieldId], fieldStructure, staticData);
                acc.push(`Edit ${fieldLabel}: ${currValue} → ${pendingValue}`)
            }
            else{
                acc.push(curr.request_type)
            }
        }
        return acc;
    }, [])
    if(pendingRequestTypes.length === 0) pendingRequestTypes.push('None')

    const canEdit = (entity.is_active) && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.REQ_EDIT.key, entity, instanceType);//TODO: also check permissions
    const canRemove = (entity.is_active) && hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.REQ_REMOVAL.key, entity, instanceType);//TODO: also check permissions

    const entityName = getEntityOrUserName(entity, instanceType, staticData.all_users)

    return (
        <div key={`ViewEntityPage-${instance_id}`}>
            <PageTitle
                title={<EntityName entity={entity} refetchEntity={refetchEntity} entityType={entityType}/>}
                subtitle={entity.parent?.name}
                />
            <EntityInstanceActionPanel entityType={entityType} entity={entity}/>
            <Stack spacing={2} marginY={2}>
                <Stack spacing={-1}>
                    <LabelValueDisplay label={'Status'} value={entity.is_active ? 'Active' : 'Inactive'}/>
                    {
                        (pendingRequestTypes.length === 1) ?
                        <LabelValueDisplay label={'Pending request types'} value={pendingRequestTypes[0]}/> :
                        <>
                            <LabelValueDisplay label={'Pending request types'} value={''}/>
                            <Stack sx={{paddingX:4, paddingTop: 1}}>
                                {
                                    pendingRequestTypes.map((s, idx) => <Typography key={`${idx}-${s}`}>{s}</Typography>)
                                }
                            </Stack>
                        </>

                    }
                </Stack>
                {
                    (viewState === VIEW_STATES.VIEW) ?
                    <Stack direction={'row'} alignContent={'center'} spacing={2}>
                        {
                            canEdit &&
                            <Button onClick={() => setViewState(VIEW_STATES.EDIT)} variant='contained'>
                                Edit
                            </Button>
                        }
                        {
                            canRemove &&
                            <Button onClick={() => setViewState(VIEW_STATES.REMOVE)} variant='contained'>
                                Remove
                            </Button>
                        }
                    </Stack>
                    :
                    <Box>
                        <Button onClick={() => setViewState(VIEW_STATES.VIEW)} variant='contained'>
                            Back
                        </Button>
                    </Box>

                }
            </Stack>
            {
                (viewState === VIEW_STATES.VIEW) && <EntityView entity={entity} entityType={entityType} refetchEntity={refetchEntity}/>
            }
            {
                (viewState === VIEW_STATES.EDIT) && <EditEntity entity={entity} entityType={entityType} refetchEntity={refetchEntity}/>
            }
            {
                (viewState === VIEW_STATES.REMOVE) && <RemoveEntity entity={entity} entityType={entityType} refetchEntity={refetchEntity}/>

            }
        </div>
    )
}

export default ViewEntityPage;
