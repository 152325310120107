import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat } from "./hris_helpers";
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import { EXTENSIONS, post } from "../../helpers/requests";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import RoleForm from "./RoleForm";

const TEMP_TAG_CHOICES = ["Accounting", "HR Generalist", "IT", "Legal"].map(t => { return {value: t, label: t}});


const AddRoleView = ({company, refetchCompany}) => {
    // const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    
    const roleStructure = company.employee_role_extra_data_structure;

    const [newRole, setNewRole] = useState({emp_id: "", department_id: "", is_manager: "", extra_data: getDefaultSectionValues(roleStructure)})
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {//emp_id, department_id, tags, is_manager, extra_data
        // const body = {...newRole, extra_data: extraData};
        const body = {...newRole, extra_data: newRole.extra_data};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Role Added")
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.ADD_ROLE, body, onSuccess, onFailure)
    }

    const empChoices = company.employees.filter(e => e.status === 'Active').map(e => {return {value: e.id, label: e.name}})
    const deptChoices = getDepartmentsFlat(company.root_department).map(d => {return {value: d.id, label: `${d.name} (${d.type.name})`}})

    return (
        <RoleForm
            role={newRole} 
            setRole={setNewRole}
            empChoices={empChoices}
            deptChoices={deptChoices} 
            roleStructure={roleStructure} 
            submitState={submitState} 
            onSubmitClick={onSubmitClick}    
            />
    )
}

export default AddRoleView;