import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat, get_emp_dept_role } from "./hris_helpers";
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import TicketSelectField from "../inputs/TicketSelectField";
import { EXTENSIONS, post } from "../../helpers/requests";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import RoleForm from "./RoleForm";
import { useSearchParams } from "react-router-dom";


const EditRoleView = ({company, refetchCompany}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams()
    

    const roleStructure = company.employee_role_extra_data_structure;

    const roleId = Number(searchParams.get('role_id'))
    const empDeptRole = get_emp_dept_role(company.root_department, roleId)

    const roleInitialState = {
        id: roleId,
        is_manager: empDeptRole.is_manager,
        extra_data: {...empDeptRole.role.extra_data}
    } 

    const [role, setRole] = useState(roleInitialState);
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {//emp_id, department_id, tags, is_manager, extra_data
        // const body = {...newRole, extra_data: extraData};
        const body = {...role, emp_role_id: role.id, extra_data: {...role.extra_data}};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Role Edited")
            refetchCompany();
            //TODO: navigate backwards?
        }
        const onFailure = (e) => {
            setSubmitState(REQUEST_STATES.FAILED)
            alertError(e.response.data)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_ROLE, body, onSuccess, onFailure)
    }

    // const empChoices = company.employees.filter(e => e.status === 'Active').map(e => {return {value: e.id, label: e.name}})
    // const deptChoices = getDepartmentsFlat(company.root_department).map(d => {return {value: d.id, label: `${d.name} (${d.type.name})`}})

    return (
        <RoleForm
            role={role} 
            setRole={setRole}
            // empChoices={empChoices}
            // deptChoices={deptChoices} 
            roleStructure={roleStructure} 
            submitState={submitState} 
            onSubmitClick={onSubmitClick}    
            />
    )
}

export default EditRoleView;