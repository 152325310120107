import { Typography } from "@mui/material";
import React from "react";
import {FIELD_COMPONENT_TYPES } from "./FieldProps";
import TicketSelectField from "./TicketSelectField";
import TicketTextField from "./TicketTextField";
import YesNoSelectField from "./YesNoSelectField";
import TicketParagraphField from "./TicketParagraphField";
import TicketCheckbox from "./TicketCheckbox";
import TicketDatePickerField from "./TicketDatePickerField";
import TicketFilePickerField from "./TicketFilePickerField";
import TicketMultiSelectField from "./TicketMultiSelectField";
import TicketMultiCheckField from "./TicketMultiCheckField";
import TicketMultiTextField from "./TicketMultiTextField";


// value, setValue, key, idPrefix, component, valueType, choices, label, helperText, sectionKey, fieldKey
const TicketField = (props) => {
    //potential field props
    // const {value, setValue, idPrefix, label, choices} = props
    const {component} = props
    const shownValue = ([null, undefined].includes(props.value)) ? "" : props.value
    // const alteredProps = {}
    let updatedProps = {...props, value: shownValue}

    if(component === FIELD_COMPONENT_TYPES.SHORT_TEXT){
        return <TicketTextField {...updatedProps}/>
    }
    if(component === FIELD_COMPONENT_TYPES.LONG_TEXT){
        return <TicketParagraphField {...updatedProps}/>
    }
    if(component === FIELD_COMPONENT_TYPES.MULTI_TEXT){
        return <TicketMultiTextField {...updatedProps}/>
    }

    if(component === FIELD_COMPONENT_TYPES.SELECT){
        const {choices, dependencies} = props.fieldStructure.choice_desc;
        updatedProps = {...updatedProps, choices, dependencies}
        return <TicketSelectField {...updatedProps} />
    }
    if(component === FIELD_COMPONENT_TYPES.MULTISELECT){
        const {choices, dependencies} = props.fieldStructure.choice_desc
        updatedProps = {...updatedProps, choices, dependencies}
        return <TicketMultiSelectField {...updatedProps}/>
    }
    if(component === FIELD_COMPONENT_TYPES.MULTICHECK){
        const {choices, dependencies} = props.fieldStructure.choice_desc
        updatedProps = {...updatedProps, choices, dependencies}
        return <TicketMultiCheckField {...updatedProps}/>
    }

    if(component === FIELD_COMPONENT_TYPES.YES_NO_SELECT){
        return <YesNoSelectField {...updatedProps}/>
    }

    if(component === FIELD_COMPONENT_TYPES.CHECKBOX){
        return <TicketCheckbox {...updatedProps}/>
    }

    if(component === FIELD_COMPONENT_TYPES.DATEPICKER){
        return <TicketDatePickerField {...updatedProps}/>
    }
    
    if(component === FIELD_COMPONENT_TYPES.FILEPICKER){
        return <TicketFilePickerField {...updatedProps}/>
    }

    console.log(`unknown component: ${component}... props:`, props );
    return <Typography>Unidentified Field!!!</Typography>
}

export default TicketField
