import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { MULTI_SELECT_DELIM } from '../../helpers/Constants';
import { multiValueToArray } from '../../helpers/TicketFormHelper';
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";
import { SX_BOX_SIMPLE } from '../../helpers/common_sx';
import FormHelperText from '@mui/material/FormHelperText';


const SingleCheckBox = ({label, checked, choiceId, onChange, error}) => {
    const color = error ? 'error' : 'primary'
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={choiceId}
                    id={choiceId}
                    checked={checked}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color={color}
                    />
            }
            label={label}
            labelPlacement={"end"}
            />
    )
}

const TicketMultiCheckField = (props) => {
    const {value, setValue, idPrefix, label, choices, optional, helperText} = props
    const fieldId = idPrefix + "-" +label

    const valueAsArray = multiValueToArray(value)
    const handleChange = (choiceValue) =>{
        if(valueAsArray.includes(choiceValue)){//removal of value
            const newValue = valueAsArray.filter((x) => x !== choiceValue)
            setValue(newValue)
        }
        else{//addition of value
            const newValue = [...valueAsArray, choiceValue]
            setValue(newValue)
        }
    }

    const displayLabel = makeFieldDisplayLabel(label, optional)
    const invalidValues = valueAsArray.filter(val => !choices.some(choice => (choice.value === val)))
    return(
        <Box sx={SX_BOX_SIMPLE}>

        <FormGroup>
            <Typography>{displayLabel}</Typography>
            <Typography variant='p' fontSize={14}>{helperText}</Typography>
            {/* {!helperText ? null : <FormHelperText>{helperText}</FormHelperText>} */}

            <Stack marginLeft={3}>
            {choices.map((choice) => {
                const choiceId = `${fieldId}-${choice.value}`
                return (
                    <SingleCheckBox
                        label={choice.label}
                        checked={valueAsArray.includes(choice.value)}
                        choiceId={choiceId}
                        onChange={(e) => handleChange(choice.value)}
                        key={choiceId}
                        error={false}
                    />
                )
            })}
            {invalidValues.map((val) => {
                const choiceId = `${fieldId}-${val}`
                return (
                    <SingleCheckBox
                        label={`(INVALID) - ${val}`}
                        checked={true}
                        choiceId={choiceId}
                        onChange={(e) => handleChange(val)}
                        key={choiceId}
                        error={true}
                    />
                )
            })}
            </Stack>
        </FormGroup>
        </Box>

    )
}

export default TicketMultiCheckField