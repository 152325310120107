import { useContext, useState } from "react"
import { Stack } from "../../../node_modules/@mui/material/index"
import ConfirmActionDialog from "../generic/ConfirmActionDialog"
import TicketSelectField from "../inputs/TicketSelectField"
import { CHOICE_DESC_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../inputs/FieldProps"
import TicketTextField from "../inputs/TicketTextField"
import { getActiveDepartmentChoices, getActiveUserChoices } from "../../helpers/StaticDataHelper"
import Context from "../../Store"
import { makeDefaultFieldStructure } from "../configs/form/FieldStructureConfig"

const getDefaultValueChoices = (valueType, staticData) => {
    if(valueType === FIELD_VALUE_TYPES.EMPLOYEE)
        return getActiveUserChoices(staticData);
    if(valueType === FIELD_VALUE_TYPES.DEPARTMENT)
        return getActiveDepartmentChoices(staticData);
    return []
}

const PanelBody = ({label, setLabel, valueType, setValueType, defaultValue, setDefaultValue, requireDefaultValue}) => {
    const {staticData} = useContext(Context);

    const valueTypeChoices = [
        {value: FIELD_VALUE_TYPES.STRING, label: "Free text"},
        {value: FIELD_VALUE_TYPES.EMPLOYEE, label: "Employee"},
        {value: FIELD_VALUE_TYPES.DEPARTMENT, label: "Department"},
    ]

    const defaultValueChoices = getDefaultValueChoices(valueType, staticData)

    const onValueTypeSelected = (x) => {
        setValueType(x);
        setDefaultValue("");
    }
    return (
        <Stack spacing={2} minWidth={400}>
            <TicketTextField
                label={'Column label'}
                value={label}
                setValue={setLabel}
                />
            <TicketSelectField
                label={'Value type'}
                value={valueType}
                setValue={onValueTypeSelected}
                choices={valueTypeChoices}
                />
            {
                !requireDefaultValue ? null :
                (
                    (valueType === FIELD_VALUE_TYPES.STRING) ?
                    <TicketTextField
                        label={'Default value'}
                        value={defaultValue}
                        setValue={setDefaultValue}
                        helper_text={'All rows will be autofilled with this value. You can edit them after.'}
                        /> :
                    <TicketSelectField
                        label={'Default value'}
                        value={defaultValue}
                        setValue={setDefaultValue}
                        helper_text={'All rows will be autofilled with this value. You can edit them after.'}
                        choices={defaultValueChoices}
                        />
                )
            }
        </Stack>
    )
}

export const AddColumnPanel = ({close, apply, requireDefaultValue}) => {
    const {alertError} = useContext(Context);

    const [label, setLabel] = useState("");
    const [valueType, setValueType] = useState(FIELD_VALUE_TYPES.STRING);
    const [defaultValue, setDefaultValue] = useState("");

    const onConfirmClick = () => {
        if(!valueType){
            alertError("provide a value type for the new field");
            return;
        }
        if(requireDefaultValue && !defaultValue){
            alertError("provide a default value for the new field");
            return;
        }
        apply(label, valueType, defaultValue);
        close();
    }
    return (
        <ConfirmActionDialog
            title={"New column"}
            body={
                <PanelBody
                    label={label}
                    setLabel={setLabel}
                    valueType={valueType}
                    setValueType={setValueType}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                    requireDefaultValue={requireDefaultValue}
                    />
            }
            handleCancel={close}
            handleConfirm={onConfirmClick}
            cancelText={'Cancel'}
            confirmText={'Done'}
            loading={false}
            disabled={false}
            // fullWidth={}
            // maxWidth={}
            />
    )
}

export const valueTypeToFieldStructure = (staticData, label, valueType) => {
    const field = makeDefaultFieldStructure();
    // field.id = fieldId;
    field.label = label;
    field.short_label = label;
    field.value_type = valueType;
    
    if(valueType === FIELD_VALUE_TYPES.EMPLOYEE){
        field.component = FIELD_COMPONENT_TYPES.SELECT;
        field.choice_desc = { 
            choices: getDefaultValueChoices(valueType, staticData), 
            dependencies: [], 
            __meta_type: CHOICE_DESC_TYPES.COMPANY,// 'CompanySelectChoicesDescriptor', 
            function_id: 'all_emps' 
        }
    }
    else if(valueType === FIELD_VALUE_TYPES.DEPARTMENT){
        field.component = FIELD_COMPONENT_TYPES.SELECT;
        field.choice_desc = { 
            choices: getDefaultValueChoices(valueType, staticData), 
            dependencies: [], 
            __meta_type: CHOICE_DESC_TYPES.COMPANY,// 'CompanySelectChoicesDescriptor', 
            function_id: 'all_departments' 
        }
    }
    return field;
}

