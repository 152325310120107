import React, { useState } from "react"
import { Box, Stack, Typography } from "../../../node_modules/@mui/material/index"
import TicketTextField from "../inputs/TicketTextField"
import LoadButton from "../generic/LoadButton"
import { EXTENSIONS, post } from "../../helpers/requests"
import Context from "../../Store"
import { MAX_LABEL_LEN } from "../configs/form/field_builder_helper"
import { SX_BOX_SIMPLE } from "../../helpers/common_sx"

export const CreateManagedTableWidget = ({onCreated}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);

    const [tableName, setTableName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onCreateClick = () => {
        if(!tableName){
            alertError('Provide a name for the table');
            return;
        }

        const onSuccess = (resp) => {
            setIsSubmitting(false);
            console.log('create table reponse', resp);
            alertSuccess("Created");
            onCreated(resp.data);
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data);
        }
        post(EXTENSIONS.CREATE_MANAGED_TABLE, {table_name: tableName}, onSuccess, onFail);
        setIsSubmitting(true);
    }
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Stack sx={{...SX_BOX_SIMPLE, p: 2}} spacing={2} alignItems='center' width={400} justifySelf='center'>
                <Typography variant='h6'>Create a new table</Typography>
                {/* <Stack direction='row' alignItems='center' spacing={2}> */}
                    {/* <Box minWidth={350}> */}
                        <TicketTextField
                            label={'Table name'}
                            value={tableName}
                            setValue={setTableName}
                            maxLen={MAX_LABEL_LEN}
                            fullWidth={true}
                            />
                    {/* </Box> */}
                                    
                {/* </Stack> */}
                    <LoadButton onClick={onCreateClick} loading={isSubmitting} disabled={isSubmitting}>
                        Create
                    </LoadButton>
            </Stack>
        </Box>
    )
}