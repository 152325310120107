import { Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index"
import TicketCheckbox from "../../inputs/TicketCheckbox"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const CheckBoxWithHelpTooltip = ({label, value, setValue, tooltip}) => {
    const enterDelay = 500;
    return (
        <Stack direction='row' spacing={-1} alignItems='center'>
            <TicketCheckbox
                label={label}
                value={value}
                setValue={setValue}
                />
            <Tooltip
                enterDelay={enterDelay}
                enterNextDelay={enterDelay}
                title={
                    <Typography variant={'h6'} fontSize={16}>
                        {tooltip}
                    </Typography>
                }
            >
                <HelpOutlineIcon color='primary' fontSize='small'/>
            </Tooltip>
        </Stack>
    )
}