import { Box, Button, Stack, Typography } from "@mui/material"
import { useContext, useState } from "react"
import Context from "../../Store"
import { Icon, IconButton, Tooltip } from "../../../node_modules/@mui/material/index"
import EditIcon from '@mui/icons-material/Edit';
import TicketTextField from "../inputs/TicketTextField"
import SaveIcon from '@mui/icons-material/Save';
import { INSTANCE_TYPES, MAX_ENTITY_TYPE_NAME_LEN } from "../../helpers/Constants";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { EXTENSIONS, post } from "../../helpers/requests";

/**
 * Just displays the name text, unless it is a root entity type. Then is allows for editting the name
 */
export const InstanceTypeNameSubtitle = ({instanceType, instanceTypeDescriptor, onNameSaved}) => {
    const {alertError} = useContext(Context);

    const [isBeingEditted, setIsBeingEditted] = useState(false);
    const [edittedName, setEdittedName] = useState(instanceType.name);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isEntityInstanceType = (instanceTypeDescriptor.instance_type === INSTANCE_TYPES.ENTITY)
    const isRootEntityInstanceType = isEntityInstanceType && !instanceType.parent_type;


    if(!isRootEntityInstanceType) return instanceType.name;
    if(!isBeingEditted){
        return (
            <Stack direction='row' alignItems='center' justifyContent='center'>
                <Icon/>
                {instanceType.name}
                <Tooltip title='edit name' placement="right">
                    <IconButton onClick={() => setIsBeingEditted(true)}>
                        <EditIcon color='primary'/>
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    const onSaveClick = () => {
        if(!edittedName || (edittedName.length > MAX_ENTITY_TYPE_NAME_LEN)){
            alertError(`name must be between 1 and ${MAX_ENTITY_TYPE_NAME_LEN} characters`);
            return;
        }
        if(edittedName === instanceType.name){
            return;//unchanged
        }
        const onSuccess = () => {
            setIsSubmitting(false);
            setIsBeingEditted(false);
            onNameSaved();
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }
        const body = {root_type_id: instanceType.id, new_name: edittedName}
        post(EXTENSIONS.RENAME_ENTITY_INSTANCE_TYPE, body, onSuccess, onFail);
        setIsSubmitting(true);
    }
    
    const onCancelClick = () => {
        setEdittedName(instanceType.name);
        setIsBeingEditted(false);
    }

    return (
        <Stack direction='row' spacing={1}>
            <TicketTextField
                label='New name'
                value={edittedName}
                setValue={setEdittedName}
                maxLen={MAX_ENTITY_TYPE_NAME_LEN}
                fullWidth={true}
                />
            <IconButton onClick={onCancelClick} disabled={isSubmitting}>
                <CancelOutlinedIcon color='error'/>
            </IconButton>
            <IconButton onClick={onSaveClick} disabled={isSubmitting}>
                <SaveIcon color='primary'/>
            </IconButton>
        </Stack>
    )


}