import { AppBar, Box, Tab, Tabs } from "@mui/material"
import { EXTENSIONS } from "../../helpers/requests";
import PageTitle from "../generic/PageTitle";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import React, { useState } from "react";
import ActionsTable from "../tables/ActionsTable";
import LoadingMessage from "../generic/LoadingMessage";
import Context from "../../Store";
import { doesEntityHaveMemberFlow } from "../../helpers/CommonUtil";


const CompletedTasksPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();
    const [entityTabIdx, setEntityTabIdx] = useState(0)
    const [innerTabIdx, setInnerTabIdx] = useState(0)
    const data = useData(EXTENSIONS.SW_MY_ACTION_HISTORY, {}, null, null)

    const awaitedDatas = [configSyncState, data]
    if(awaitedDatas.includes(null)) return <LoadingMessage/>
    if(awaitedDatas.includes(false)) return null

    const {actions} = data
    const entityNames = Object.keys(actions)
    const currEntityTypeName = entityNames[entityTabIdx] 

    const title = "Tasks You Completed";
    const currEntityTypeHasMemberFlow = doesEntityHaveMemberFlow(staticData, currEntityTypeName);
    const actionsToDisplay = (innerTabIdx === 0) ? actions[currEntityTypeName].main_flow_actions : actions[currEntityTypeName].member_flow_actions;

    const handleEntityTabClick = (tabIdx) => {
        setEntityTabIdx(tabIdx);
        if(!doesEntityHaveMemberFlow(staticData, entityNames[tabIdx])) setInnerTabIdx(0);
    }
    return (
        <div key={`CompletedTasksPage-div`}>
            <PageTitle title={title}/>
            {/* <ActionsReview url_ext={EXTENSIONS.SW_MY_ACTION_HISTORY}/> */}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
                    <AppBar position="static">
                        <Tabs value={entityTabIdx} onChange={(e, val) => handleEntityTabClick(val)} indicatorColor="secondary" textColor="inherit">
                            {
                                entityNames.map((entityName, idx) => {
                                    return <Tab label={entityName} id={entityName} key={entityName}/>
                                })
                            }
                        </Tabs>
                    </AppBar>
                    {
                        currEntityTypeHasMemberFlow &&
                        <AppBar position="static" sx={{marginTop:0.25}}>
                            <Tabs value={innerTabIdx} onChange={(e, val) => setInnerTabIdx(val)} indicatorColor="secondary" textColor="inherit">
                                <Tab label={"Tickets"} id={"Tickets"} key={"Tickets"}/>
                                <Tab label={"Members"} id={"Members"} key={"Members"}/>
                            </Tabs>
                        </AppBar>
                    }
                </Box>
                <Box marginTop={2} sx={{ width: '95%',justifyContent: "center", alignItems: "center", marginX: "auto"}} key={`tab-${entityTabIdx}`}>
                    <ActionsTable entityName={currEntityTypeName} actions={actionsToDisplay} key={`${currEntityTypeName}-${innerTabIdx}`}/>
                </Box>
            </Box>
        </div>
    )
}

export default CompletedTasksPage;
