import { useContext, useState } from "react"
import ConfirmActionDialog from "../generic/ConfirmActionDialog"
import { EXTENSIONS, post } from "../../helpers/requests"
import Context from "../../Store";



export const RemoveTypeConfirmationDialog = ({instanceTypeDescriptor, close, onRemoved }) => {
    const {alertError, alertSuccess} = useContext(Context);

    const [isSubmitting, setIsSubmitting] = useState(false)
    const bodyText = `
This removal is permanent. You will need to recreate the form and approval processes from scratch if you need this later on.
Note that this cannot be deleted if other types depend on it.
`

    const onConfirmClick = (instanceType) => {
        const onSuccess = () => {
            setIsSubmitting(false);
            alertSuccess("Removed");
            onRemoved();
            close()
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data);
            close();
        }

        const body = {instance_type_desc: instanceTypeDescriptor};
        post(EXTENSIONS.REMOVE_INACTIVE_INSTANCE_TYPE, body, onSuccess, onFail)
        setIsSubmitting(true);
    }

    return (
        <ConfirmActionDialog
            title={`Confirm removing`}
            body={bodyText}
            handleCancel={close}
            handleConfirm={onConfirmClick}
            cancelText={'Cancel'}
            confirmText={'Yes, Remove'}
            loading={isSubmitting}
            disabled={isSubmitting}
            fullWidth={true}
            // maxWidth={}
            />
    )
}