import React from "react";
import { Box, Divider, Stack, Typography } from "../../../node_modules/@mui/material/index";
import { useRefetchData } from "../../helpers/CustomHooks"
import { EXTENSIONS } from "../../helpers/requests"
import LoadingMessage from "../generic/LoadingMessage";
import Context from "../../Store";
import { getEntityInstanceTypeById, getFieldStructureFromFormStructure } from "../../helpers/StaticDataHelper";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import { isAccessPendingRemoval } from "../../helpers/access_helper";

const INDENT_MARGIN = 2;

const MyDivider = () => {
    return <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000", marginLeft:(-1 * INDENT_MARGIN)}}/>
}

const SectionTitle = ({title}) => {
    return <Typography>{title}</Typography>
}

const isEmptyListAccumulator = (acc) => {
    const keys = Object.keys(acc);
    for(const key of keys){
        if(acc[key].length > 0) return false;
    }
    return true;
}

const doAnyBlockersExist = (blockers) => {
    const {managed_tables_with_emp_as_value, pending_roles_acc, active_roles_acc, pending_accesses, active_accesses} = blockers;
    return (
        (managed_tables_with_emp_as_value.length > 0) ||
        [pending_roles_acc, active_roles_acc, pending_accesses, active_accesses].some(acc => !isEmptyListAccumulator(acc))
    )
}

const RolesSection = ({title, roles_acc}) => {
    const {user, staticData} = React.useContext(Context);
    if(isEmptyListAccumulator(roles_acc)) return null;

    return (
        <Box marginLeft={INDENT_MARGIN}>
            <MyDivider/>
            <SectionTitle title={title}/>
            {
                Object.keys(roles_acc).map(instanceTypeIdStr => {
                    const instanceTypeId = Number(instanceTypeIdStr);
                    const instanceType = getEntityInstanceTypeById(staticData, instanceTypeId);
                    const roles = roles_acc[instanceTypeIdStr];
                    if(roles.length === 0) return null;
                    return (
                        <Box key={instanceTypeId} marginLeft={INDENT_MARGIN}>
                            <Typography>{instanceType.name}</Typography>
                            <Stack key={instanceTypeId} marginLeft={INDENT_MARGIN}>
                            {
                                roles.map((role, roleIdx) => {
                                    const {entity_instance, ticket_id, field_desc} = role;
                                    const fieldStructure = getFieldStructureFromFormStructure(instanceType.structure, field_desc);
                                    const key = fieldStructure ? `${entity_instance.id}-${fieldStructure.id}` : `no_field_${roleIdx}`
                                    return (
                                        <LabelValueDisplay label={entity_instance.name} value={fieldStructure.short_label} key={key}/>
                                    )
                                })
                            }
                            </Stack>
                        </Box>
                    )
                })        
            }
        </Box>
    )
}

const AccessSection = ({title, access_acc}) => {
    const {user, staticData} = React.useContext(Context);
    if(isEmptyListAccumulator(access_acc)) return null;

    return (
        <Box marginLeft={INDENT_MARGIN}>
            <MyDivider/>
            <SectionTitle title={title}/>
            {
                Object.keys(access_acc).map(accessTypeIdStr => {
                    const accessTypeId = Number(accessTypeIdStr);
                    const accessType = staticData.access_types.find(at => at.id === accessTypeId);
                    const accesses = access_acc[accessTypeIdStr];
                    if(accesses.length === 0) return null;
                    return (
                        <Box key={accessTypeId} marginLeft={INDENT_MARGIN}>
                            <Typography>{accessType.resource_type.name}</Typography>
                            <Stack key={accessTypeId} marginLeft={INDENT_MARGIN}>
                            {
                                accesses.map(access => {
                                    const suffix = isAccessPendingRemoval(access) ? " (Pending removal)" : "";
                                    return (
                                        <Typography key={`${access.resource.id}-${access.id}`}>{access.resource.name} {suffix}</Typography>
                                    )
                                })
                            }
                            </Stack>
                        </Box>
                    )
                })        
            }
        </Box>
    )
}

const EmpAsEntitiesSection = ({title, entitiesAcc}) => {
    const {user, staticData} = React.useContext(Context);
    if(isEmptyListAccumulator(entitiesAcc)) return null;

    return (
        <Box marginLeft={INDENT_MARGIN}>
            <MyDivider/>
            <SectionTitle title={title}/>
            {
                Object.keys(entitiesAcc).map(instanceTypeIdStr => {
                    const instanceTypeId = Number(instanceTypeIdStr);
                    const instanceType = getEntityInstanceTypeById(staticData, instanceTypeId);
                    const entities = entitiesAcc[instanceTypeIdStr];
                    if(entities.length === 0) return null;
                    return (
                        <Box key={instanceTypeId} marginLeft={INDENT_MARGIN}>
                            <Typography>{instanceType.name}</Typography>
                            <Stack key={instanceTypeId} marginLeft={INDENT_MARGIN}>
                            {
                                entities.map(entity => {
                                    return (
                                        <Typography key={entity.id}>{entity.parent.name}</Typography>
                                    )
                                })
                            }
                            </Stack>
                        </Box>
                    )
                })        
            }
        </Box>
    )
}

const TablesReferencesSection = ({idsAndNames}) => {
    if(idsAndNames.length === 0) return null;
    return (
        <Box marginLeft={INDENT_MARGIN}>
            <MyDivider/>
            <SectionTitle title='Employee is referenced in the following tables'/>
            {
                idsAndNames.map(idAndName => {
                    const {id, name} = idAndName;
                    return <Typography key={id} marginLeft={INDENT_MARGIN}>{name}</Typography>
                })
            }
        </Box>
    )
}

const OffboardingBlockers = ({employeeId}) => {
    const HEADER_TEXT = 'Employee Relevance'

    const {user, staticData} = React.useContext(Context);
    const [blockers, refetchBlockers] = useRefetchData(EXTENSIONS.GET_EMP_OFFBOARDING_BLOCKERS, {offboardee_emp_id: employeeId});

    if(blockers === false) return <div>Failed to load</div>
    if(blockers === null) return <LoadingMessage/>

    const {managed_tables_with_emp_as_value, pending_roles_acc, active_roles_acc, pending_accesses, active_accesses} = blockers;
    const empHasBlockers = doAnyBlockersExist(blockers);

    if(!empHasBlockers){
        return (
            <Box border={1} borderRadius={2}>
                <LabelValueDisplay label={HEADER_TEXT} value={'None'}/>
            </Box>    
        )
    }
    return (
        <Stack border={1} borderRadius={2} spacing={1} paddingLeft={INDENT_MARGIN}>
            <Typography variant='h5' fontSize={20}>{HEADER_TEXT}</Typography>

            <AccessSection title='Pending Access' access_acc={pending_accesses}/>

            <AccessSection title='Active Access' access_acc={active_accesses}/>

            <TablesReferencesSection idsAndNames={managed_tables_with_emp_as_value}/>

            <RolesSection title={'Pending Roles'} roles_acc={pending_roles_acc}/>

            <RolesSection title={'Active Roles'} roles_acc={active_roles_acc}/>

        </Stack>
    )
}

export default OffboardingBlockers;