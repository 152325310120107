import { Box, Stack, TextField } from "@mui/material";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import { datetimeDisplay, fieldValueString } from "../../helpers/FieldDisplayFormatters";
import React, { useContext } from "react";
import LoadButton from "../generic/LoadButton";
import { ACCESS_REQUEST_TYPES, COMMON_REQUEST_TYPES, REQUEST_STATES, UNABORTABLE_REQUEST_TYPES } from "../../helpers/Constants";
import MultiSectionForm from "../form/MultiSectionForm";
import Context from "../../Store";


const TicketView = ({ticket, structure, allUsersMap, editableSections, setFormValues, shouldPrint, onCancelClick, submitState, canUserCancel, getSectionDownloadFileField, contextValues}) => {
    
    const {staticData} = useContext(Context);
    const request_type = ticket.request_type;
    const isPending = (ticket.status === "Pending");

    const submissionAction = ticket.actions.find(a => a.role === "Submitter");
    const [message, setMessage] = React.useState("");
    
    // const ignoreEmptySections = !["Add", "Reopen", "Grant"].includes(request_type);
    // console.log("TicketView", {ticket})
    const isTicketUncancelable = (!isPending || !canUserCancel || !onCancelClick || UNABORTABLE_REQUEST_TYPES.includes(ticket.request_type));

    return (
        <Box>
            {
                isTicketUncancelable ? null :
                <Stack direction={'row'} spacing={1}>
                    {/* <TicketTextField label="" value={message} setValue={setMessage}/> */}
                    <TextField
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="reason for canceling..."
                        size="small"
                        fullWidth
                        autoComplete="off"
                        />
                    <LoadButton
                        variant="contained"
                        onClick={() => onCancelClick(ticket, message)}
                        size="small"
                        loading={submitState === REQUEST_STATES.SENDING}
                        disabled={[REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)}
                        tooltip={`Cancel this request. It will not be able to be reviewed or approved anymore, unless resubmitted.`}
                        color={'error'}
                        >
                        Cancel
                    </LoadButton>
                </Stack>
            }
            <LabelValueDisplay label={'Request type'} value={request_type}/>
            <LabelValueDisplay label={'Status'} value={ticket.status}/>
            <LabelValueDisplay label={'Submitter'} value={submissionAction ? allUsersMap[submissionAction.actor_id] : 'n/a'}/>
            <LabelValueDisplay label={'Submitted'} value={submissionAction ? datetimeDisplay(submissionAction.datetime) : 'n/a'}/>
            {!ticket.stages.length ? null : <LabelValueDisplay label={'Stages'} value={ticket.stages.map(s => s.stage_name).join(", ")}/>}
                {/* // !["Add", "Reopen", "Edit", "Edit Request"].includes(request_type) ? null :  */}
                <MultiSectionForm
                    structure={structure}
                    formData={ticket.form_values}
                    setFormData={setFormValues}
                    editableSectionIds={editableSections}
                    getSectionDownloadFileField={getSectionDownloadFileField}
                    contextValues={contextValues}
                    />

            {/* {(request_type !== "Remove") ? null : null} */}
        </Box>
    )
}

export default TicketView;