import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ALL_PERMS } from "./perm_constants";
import TicketCheckbox from "../inputs/TicketCheckbox";
import React from "react";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import { EXTENSIONS, post } from "../../helpers/requests";
import TicketTextField from "../inputs/TicketTextField";
import PermissionsSectionForm from "./PermissionSectionForm";
import GlobalProfileForm, { MAX_PERM_PROF_NAME_LEN } from "./GlobalProfileForm";

const EditGlobalProfileView = ({permissions, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);

    const [searchParams, setSearchParams] = useSearchParams();
    const profileId = Number(searchParams.get('profile_id'));

    const profileAssignment = permissions.global_assignments.find(a => a.profile.id === profileId);
    const profile = profileAssignment.profile;

    //TODO: useMemo for this
    const initialValues = {...profile.permissions};
    Object.keys(ALL_PERMS).forEach(sectionKey => {
        const sectionPermsObj = ALL_PERMS[sectionKey];
        Object.keys(sectionPermsObj).forEach( key => {
            const permKey = sectionPermsObj[key].key;
            if(initialValues[permKey] === undefined) initialValues[permKey] = false;
        })
    });

    const [sectionValues, setSectionValues] = React.useState(initialValues)
    const [profileName, setProfileName] = React.useState(profile.name)
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const onSaveClick = () => {
        if(!profileName){
            alertError('Profile must have a name');
            return;
        }
        if(profileName.length > MAX_PERM_PROF_NAME_LEN){
            alertError('Profile name is too long');
            return;
        }
        const body = {profile_id: profileId, name: profileName, permissions: {...sectionValues}};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Permission Profile Edited")
            refetchPermissions();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_GLOBAL_PERM_PROF, body, onSuccess, onFailure)
    }

    return (
        <GlobalProfileForm
            profileName={profileName}
            setProfileName={setProfileName}
            sectionValues={sectionValues}
            setSectionValues={setSectionValues}
            submitState={submitState}
            onSaveClick={onSaveClick}
            />
    )
}

export default EditGlobalProfileView;