//Global context example

import React, {Component, useState} from "react";
import { MakeErrorFeedback, MakeSuccessFeedback, MakeWarningFeedback } from "./components/app/Feedback";


export const Context = React.createContext();

export const ContextProvider = ({children}) => {
    const [user, setUser] = useState(getDefaultUserInfo());
    const [staticData, setStaticData] = useState(getInitialStaticData());
    const [feedback, setFeedback] = useState(MakeSuccessFeedback(""))

    const alertSuccess = (msg, hideAfterMs) => {
        msg = msg ? msg : "Success!"
        setFeedback(MakeSuccessFeedback(msg, hideAfterMs))
    }
    const alertError = (msg, hideAfterMs) => {
        msg = msg ? msg : "Something went wrong"
        setFeedback(MakeErrorFeedback(msg, hideAfterMs))
    }
    const alertWarning = (msg, hideAfterMs) => {
        if(!msg) return;
        setFeedback(MakeWarningFeedback(msg, hideAfterMs))
    }

    // const value = { name: [name, setName], color: [color, setColor] }
    const value = {feedback, setFeedback, alertSuccess, alertError, alertWarning, user, setUser, staticData, setStaticData}
    
    return(
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

const getInitialStaticData = () => {
    return {
        config_version: null,
        entity_types: [],
        access_types: [],
        all_users: {},
        active_users: {},
        tags_map: {},
        all_departments: {},
        active_departments: {},
        entity_summaries: []
    }
}

export const getDefaultUserInfo = () => {
    return {
        logged_in: false,
        full_name: '',
        user_id: null,
        managed_emps: [],
        permissions: {
            user_profile: {
                permissions: {}
            },
            entity_role_profiles: [],
            org_roles_profile: {
                manager_section: {
                    permissions: {}
                }
            }
        }
    }
}

export default Context;