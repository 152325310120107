import { hasPendingTicketOfType } from "./CommonUtil";
import { ACCESS_STRUCTURE_IDS, COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, ACCESS_REQUEST_TYPES as _ACCESS_REQUEST_TYPES } from "./Constants";
import { getActiveEmpChoices, getEntitySummariesOfInstanceType, getFieldStructureFromFormStructureWithIds, getInstanceType } from "./StaticDataHelper";
import { CONTEXT_DEPENDENCIES, makeNestedDeepCopy } from "./TicketFormHelper";

export const _EMP_META_LABEL = "EmployeeAccessType";

export const ACCESS_REQUEST_TYPES = _ACCESS_REQUEST_TYPES;

export const isAccessTypeForEmp = (accessType) => {
    return accessType.__meta_name === _EMP_META_LABEL;
}

export const getAccessInstanceContextValues = (accessInstance) => {
    return {
        [CONTEXT_DEPENDENCIES.EMP_ACCESSOR]: accessInstance.accessor.id,
        [CONTEXT_DEPENDENCIES.RESOURCE_ID]: accessInstance.resource.id
    }
}

export const getAccessorTypeLabel = (accessType) => {
    if(isAccessTypeForEmp(accessType)) return "Employee";
    const accessorInstanceType = accessType.accessor_type;
    return accessorInstanceType.name;
// accessType.resource_type_id
}

export const getAccessTypeLabel = (accessType) => {
    const accessorLabel = getAccessorTypeLabel(accessType);
    const resourceType = accessType.resource_type;
    const resourceLabel = resourceType.name; 
    return `${accessorLabel} → ${resourceLabel}`;
}

export const getAccessTypeChoices = (accessTypes) => {
    return accessTypes.map((at) => {
        return {
            value: at.id,
            label: getAccessTypeLabel(at)
        }
    })
}

export const setAllAccessLevelChoices = (accessTypes, levelsPerResource) => {
    console.log(`setAllAccessLevelChoices`, {accessTypes, levelsPerResource})
    const choicesPerResourceId = {};
    const resourceIds = Object.keys(levelsPerResource);
    for(const resourceId of resourceIds){
        const choices = levelsPerResource[resourceId].map(lvl => {
            return {value: lvl.id, label: `${lvl.name}: ${lvl.description}`}
        })
        choicesPerResourceId[resourceId] = choices;
    }

    for(const accessType of accessTypes){
        if(doesAccessTypeHaveAccessLevels(accessType)){
            const sectionStructure = accessType.structure.sections.find(s => s.id === ACCESS_STRUCTURE_IDS.INFO_SECTION_ID)
            const fieldStructure = sectionStructure.fields.find(f => f.id === ACCESS_STRUCTURE_IDS.ACCESS_LVL_FIELD_ID);
            fieldStructure.choice_desc.choices = makeNestedDeepCopy(choicesPerResourceId);
        }
    }
}

export const getAccessStructureCopyWithAccessLevels = (accessType, accessLevels) => {
    //the access level field does not come prepopulated, as the choices could grow very large
    //to anticipate all access levels for all resources, so it is populated when needed
    const accessLevelChoices = accessLevels.map(lvl => {return {value: lvl.id, label: `${lvl.name}: ${lvl.description}`}})
    const output = makeNestedDeepCopy(accessType.structure);
    const field = getFieldStructureFromFormStructureWithIds(output, ACCESS_STRUCTURE_IDS.INFO_SECTION_ID, ACCESS_STRUCTURE_IDS.ACCESS_LVL_FIELD_ID)
    if(field){
        field.choice_desc.choices = accessLevelChoices;
        field.choice_desc.dependencies = [];
    }

    return output;
}

export const doesAccessTypeHaveAccessLevels = (accessType) => {
    const formStructure = accessType.structure;
    const sectionStructure = formStructure.sections.find(s => s.id === ACCESS_STRUCTURE_IDS.INFO_SECTION_ID);
    if(!sectionStructure) return false;
    if(sectionStructure.is_deleted) return false;

    const fieldStructure = sectionStructure.fields.find(f => f.id === ACCESS_STRUCTURE_IDS.ACCESS_LVL_FIELD_ID);
    if(!fieldStructure) return false;
    if(fieldStructure.is_deleted) return false;
    return true;
}

export const getAccessorLabel = (access) => {
    const accessor = access.accessor;
    let output = accessor.name;
    if(accessor.parent) output += ` (${accessor.parent.name})`;
    return output
}

export const isAccessForUser = (user, accessInstance, accessType) => {
    return isAccessTypeForEmp(accessType) && (user.user_id === accessInstance.accessor.id)
}

export const isAccessPendingRemoval = (access) => {
    return (
        hasPendingTicketOfType(access, ACCESS_REQUEST_TYPES.REMOVE)
        || hasPendingTicketOfType(access, ACCESS_REQUEST_TYPES.MAN)
        || (access.is_active && hasPendingTicketOfType(access.resource, ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL))
    )
}

export const getAccessApprovalProcess = (accessType, ticket) => {
    switch (ticket.request_type) {
        case ACCESS_REQUEST_TYPES.GRANT:
            return accessType.addition_process;

        case ACCESS_REQUEST_TYPES.REMOVE:
            return accessType.removal_process;

        case ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL:
            return accessType.mandatory_removal_process;

        case COMMON_REQUEST_TYPES.EDIT_REQUEST:
        case ACCESS_REQUEST_TYPES.LVL_CHANGE:
            const sectionId = Object.keys(ticket.form_values)[0];
            const fieldId = Object.keys(ticket.form_values[sectionId])[0];
            return accessType.edit_processes[sectionId][fieldId];

        default:
            return null;
    }
}
// export const getResourceChoices = (staticData, accessType) => {
//     // console.log('accessType', accessType)
//     const resourceSummaries = getEntitySummariesOfInstanceType(staticData, accessType.resource_type.id);
//     const resourceChoices = resourceSummaries.map(s => {return {value: s.id, label: s.name}});
//     return resourceChoices;
// }

// export const getAccessorChoices = (staticData, accessType) => {
//     if(isAccessTypeForEmp(accessType)){
//         return getActiveEmpChoices(staticData);
//     }
//     else {
//         const accessorParentType = accessType.accessor_type.parent_type;
//         const isRoot = !accessorParentType;
    
//         const accessorSummaries = getEntitySummariesOfInstanceType(staticData, accessType.accessor_type.id);
//         const accessorChoices = accessorSummaries.map(s => {
//             const label = isRoot ? s.name : `${s.parent_name} - ${s.name}`
//             return {value: s.id, label: label}
//         });
//         return accessorChoices;
//     }
// }

// export const getAccessorAndResourceChoices = (staticData, accessType) => {
//     const accessorChoices = getAccessorChoices(staticData, accessType);
//     const resourceChoices = getResourceChoices(staticData, accessType);
//     return [accessorChoices, resourceChoices];
// }