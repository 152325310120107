import { Box, Button, Typography } from "@mui/material"
import axios from "axios";
import { useState } from "react"
import { EXTENSIONS, post } from "../../helpers/requests";

const API_HOST = 'http://localhost:8000/savas';
// const API_HOST = 'http://localhost:8000/savas/logout';

const TokenPing = () => {
    const [token, setToken] = useState(null)
    const [msg, setMsg] = useState("")

    const onGetTokenClick = async () => {
        const response = await fetch(`${API_HOST}/csrf`, {
            credentials: 'include',
        });
        const data = await response.json();
        const _csrfToken = data.csrfToken;
        setToken(_csrfToken)        
    }

    const testRequestAxios = async (method) => {
        // await axios.post(`${API_HOST}/ping`, {'X-CSRFToken': token})
        await axios.post(`${API_HOST}/ping`, {}, { withCredentials: true, headers:{'X-CSRFToken': token} })

    }
    const testRequest = async (method) => {
        const response = await fetch(`${API_HOST}/ping`, {
          method: method,
          headers: (
            method === 'POST'
              ? {'X-CSRFToken': token}
              : {}
          ),
          credentials: 'include',
        });
        const data = await response.json();
        return data.result;
    }

    const onPingClick = () => {
        const onSuccess = (response) => {
            setMsg("Success :)")
        }
        const onFailure = (e) => {
            setMsg("Error :(")
        }
        post(EXTENSIONS.PING, {}, onSuccess, onFailure)
    }

    return (
        <Box>
            {/* <Button onClick={e => onGetTokenClick()}>Get Token</Button> */}
            {/* <Button onClick={e => testRequest('GET')}>Ping Get</Button> */}
            <Button onClick={e => onPingClick()}>Ping POST</Button>
            <Typography>{msg}</Typography>
        </Box>
    )
}

export default TokenPing