import React from 'react';
import ReactFlow from 'reactflow';
import 'reactflow/dist/style.css';
import './index.css' //import css before node components so that they apply styles like the default cursor on hover
import AtomicStageNode from './AtomicStageNode';
// import ParentStageNode from './ParentStageNode';
import GroupingNode from './GroupingNode';
import { approvalProcessToGraph } from './process_graph_maker';


const ZOOM = 1;

const initBgColor = '#ffffff';
// const initBgColor = '#1A192B';
const connectionLineStyle = { stroke: '#fff' };
const defaultViewport = { x: 0, y: 0, zoom: ZOOM };

const nodeTypes = {
  atomicStageNode: AtomicStageNode,
  parentStageNode: GroupingNode
};


export const DenseStageFlow = ({approvalProcess, enabledStageIds}) => {
    const bgColor = initBgColor;
    const [nodes, edges, mainNode] = approvalProcessToGraph(approvalProcess, undefined, false, true);
    nodes.forEach(n => {
        //TODO: eventually replace this logic with something better.
        if(enabledStageIds.includes(n.data.id)) n.data.status = 'enabled';
    })
    mainNode.position = {x: 0, y: 0};

    const isLocked = true;
    return (
        <ReactFlow
        nodes={nodes}
        edges={edges}
        style={{ background: bgColor}}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        defaultViewport={defaultViewport}
        fitView={true}
        proOptions = {{ hideAttribution: true }}
        zoomOnScroll={false}
        zoomOnPinch={false}
        panOnDrag={false}
        edgesUpdatable={!isLocked}
        edgesFocusable={!isLocked}
        nodesDraggable={!isLocked}
        nodesConnectable={!isLocked}
        nodesFocusable={!isLocked}
        elementsSelectable={!isLocked}
        >

        </ReactFlow>
    );
};