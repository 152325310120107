import { Box, Button, ButtonGroup, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APPROVED, PAPER_TRAIL, PENDING, REQUEST, VIEW_ALL } from "../app/LayeredNavPanel";
import { hasInstancePermissions } from "../permissions/perm_helper";
import React from "react";
import Context from "../../Store";
import { ALL_PERMS } from "../permissions/perm_constants";

const EntityInstanceActionPanel = ({entityType, entity}) => {
    const navigate = useNavigate();
    const {staticData, user} = React.useContext(Context);
    const makeMemberActionClickHandler = (action) => () => navigate(`/${action}/entity_type/${entityType.id}/eti/${entity.id}`);
    const isMember = !!entity.parent;
    const displayMemberButtons = !isMember && entityType.member_instance_type;
    const instanceType = isMember ? entityType.member_instance_type : entityType.root_instance_type;
    
    
    // if(!displayedButtons || !displayedButtons.length) return null;
    if(!displayMemberButtons) return null;

    const canRequestMember = hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.REQ_ADD.key, entity, instanceType);
    return (
        <Box>
            <Stack direction={'row'} border={1} borderRadius={2} padding={1} spacing={2}>
                <Typography alignContent={'center'}>Members</Typography>
                <ButtonGroup variant="contained">
                    {canRequestMember && <Button onClick={makeMemberActionClickHandler(REQUEST)}>Add</Button>}
                    <Button onClick={makeMemberActionClickHandler(PENDING)}>Pending</Button>
                    <Button onClick={makeMemberActionClickHandler(APPROVED)}>Approved</Button>
                    {/* <Button onClick={makeClickHandler(PAPER_TRAIL)}>Paper Trail</Button> */}
                    <Button onClick={makeMemberActionClickHandler(VIEW_ALL)}>View All</Button>
                </ButtonGroup>
            </Stack>
        </Box>

    )
}

export default EntityInstanceActionPanel;