import { Box, Button, Stack, Typography } from "@mui/material"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ACCESS_REQUEST_TYPES, COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, INSTANCE_TYPES, MAX_ENTITY_TYPE_NAME_LEN, TICKET_STATUS } from "../../helpers/Constants"
import { MyDivider } from "../generic/MyDivider"
import PageTitle from "../generic/PageTitle"
import { ApprovalProcessBuilder, getApprovalProcess } from "./approval_processes/ApprovalProcessBuilder"
import { INSTANCE_TYPE_ATTRIBUTES, makeConfigUrl } from "./config_util"
import FormConfigPage from "./form/FormConfigPage"
import { getContextFields } from "./form/context_fields"
import { useContext, useState } from "react"
import Context from "../../Store"
import { ConfigLockTimer } from "./ConfigLockTimer"
import { Icon, IconButton, Tooltip } from "../../../node_modules/@mui/material/index"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteEditProcessConfirmationDialog } from "./DeleteEditProcessConfirmationDialogs"
import TicketSelectField from "../inputs/TicketSelectField"
import { FIELD_VALUE_TYPES } from "../inputs/FieldProps"
import { ActivateTypeConfirmationDialog } from "./ActivateTypeConfirmationDialog"
import { RemoveTypeConfirmationDialog } from "./RemoveTypeConfirmationDialog"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { isAccessTypeForEmployee } from "../../helpers/StaticDataHelper"
import { getMandatoryAccessRemovalDescription, getProcessLabel, getResourceAccessRemovalDescription } from "./process_descriptions"
import { InstanceTypeNameSubtitle } from "./InstanceTypeNameSubtitle"
import { TableDisplayConfigWidget } from "./TableDisplayConfigWidget"
import { MemberAutoCreationConfig } from "./MemberAutoCreationConfig"



const getFieldsWithEditProcess = (instanceType) => {
    const editProcessesMap = instanceType.edit_processes
    const output = [];
    const sections = instanceType.structure.sections;
    for(const section of sections){
        const fields = section.fields;
        for(const field of fields){
            if(editProcessesMap[section.id] && editProcessesMap[section.id][field.id]){
                output.push(field)
            }
        }
    }
    return output;
}


const InfoTooltip = ({tooltip}) => {
    return (
        <Tooltip title={<Typography>{tooltip}</Typography>}>
            <InfoOutlinedIcon color='primary'/>
        </Tooltip>
    )
}



export const InstanceTypeConfigPage = ({instanceType, instanceTypeDescriptor, configData, refetchConfigs, userTags, refetchTags}) => {
    const {staticData: {active_users}} = useContext(Context);

    const [isActivationPanelOpen, setIsActivationPanelOpen] = useState(false);
    const [isRemovalPanelOpen, setIsRemovalPanelOpen] = useState(false);
    const [fieldIdOfProcessToDelete, setFieldIdOfProcessToDelete] = useState(null);

    const closePanels = () => {
        setIsActivationPanelOpen(null);
        setIsRemovalPanelOpen(null);
        setFieldIdOfProcessToDelete(null);
    }

    const navigate = useNavigate();
    const params = useParams();
    const processRequestType = params.process_type;
    const editFieldId = params.edit_field_id;
    const {attribute, table_display} = params;

    
    const isEntityInstanceType = (instanceTypeDescriptor.instance_type === INSTANCE_TYPES.ENTITY)
    const isRootEntityInstanceType = isEntityInstanceType && !instanceType.parent_type;
    const isMemberEntityInstanceType = isEntityInstanceType && instanceType.parent_type;

    const onApprovalProcessClick = (processRequestType, editFieldId) => {
        const url = makeConfigUrl(instanceType.id, INSTANCE_TYPE_ATTRIBUTES.PROCESS, processRequestType, editFieldId, null);
        navigate(url);
    }

    const onFormStructureClick = () => {
        const url = makeConfigUrl(instanceType.id, INSTANCE_TYPE_ATTRIBUTES.FORM_STRUCTURE, null, null, null);
        navigate(url);
    }
    
    const onMemberAutoCreateClick = () => {
        const url = makeConfigUrl(instanceType.id, INSTANCE_TYPE_ATTRIBUTES.MEMBER_AUTO_CREATION, null, null, null);
        navigate(url);
    }
    
    const navigateBackToInstanceTypePage = () => {
        const url = makeConfigUrl(instanceType.id, null, null, null, null);
        navigate(url);
    }

    const onSaved = () => {
        refetchConfigs();
        const url = makeConfigUrl(instanceType.id, null, null, null, null);
        navigate(url)
    }
    
    if(processRequestType){
        let process = getApprovalProcess(instanceType, processRequestType, editFieldId);
        if(process === undefined){
            return <div>Page not found (Bad process params)</div>
        }
        return (
            <ApprovalProcessBuilder
                instanceType={instanceType}
                requestType={processRequestType}
                editFieldId={editFieldId}
                userTags={userTags}
                instanceTypeDescriptor={instanceTypeDescriptor}
                onSaved={onSaved}
                refetchUserTags={refetchTags}
                />
        )
    }

    if(attribute === INSTANCE_TYPE_ATTRIBUTES.FORM_STRUCTURE){
        const contextFields = getContextFields(instanceType, active_users);
        return (
            <FormConfigPage
                title={instanceType.name}
                configData={configData}
                initialFormStructure={instanceType.structure}
                canHaveUserRoleFields = {isRootEntityInstanceType}
                hasAccessSection = {!isEntityInstanceType}
                instanceTypeDescriptor={instanceTypeDescriptor}
                contextFields={contextFields}
                onSaved={onSaved}
                />
        )
    }

    if(attribute === INSTANCE_TYPE_ATTRIBUTES.MEMBER_AUTO_CREATION){
        const onMacSaved = () => {refetchConfigs(); navigateBackToInstanceTypePage();}
        return <MemberAutoCreationConfig parentType={instanceType.parent_type} memberType={instanceType} onCancelClick={navigateBackToInstanceTypePage} onSaved={onMacSaved}/>
    }
    const fieldsWithEditProcess = getFieldsWithEditProcess(instanceType);
    const {pending_table_display, approved_table_display} = instanceType;

    const addProcessRequestType = isEntityInstanceType ? ENTITY_REQUEST_TYPES.ADD : ACCESS_REQUEST_TYPES.GRANT;
    const removeProcessRequestType = isEntityInstanceType ? ENTITY_REQUEST_TYPES.REMOVE : ACCESS_REQUEST_TYPES.REMOVE;
    const Subtitle = <InstanceTypeNameSubtitle instanceType={instanceType} instanceTypeDescriptor={instanceTypeDescriptor} onNameSaved={refetchConfigs}/>;

    const isMacEnabled = isMemberEntityInstanceType && !!instanceType.parent_type.member_auto_creation;
    return (
        <Box>
            {
                !fieldIdOfProcessToDelete ? null :
                    <DeleteEditProcessConfirmationDialog
                        instanceTypeDescriptor={instanceTypeDescriptor}
                        fieldId={fieldIdOfProcessToDelete}
                        close={closePanels}
                        onDeleted={onSaved}
                        />
            }
            {
                !isActivationPanelOpen ? null :
                <ActivateTypeConfirmationDialog instanceTypeDescriptor={instanceTypeDescriptor} close={closePanels} onActivated={refetchConfigs}/>
            }
            {
                !isRemovalPanelOpen ? null :
                <RemoveTypeConfirmationDialog instanceTypeDescriptor={instanceTypeDescriptor} close={closePanels} onRemoved={refetchConfigs}/>
            }
            <PageTitle title={`System Configurations`} subtitle={Subtitle}/>

            <Box justifyContent='center' display='flex' marginTop={-4}>
                <ConfigLockTimer/>
            </Box>

            <Stack alignItems="flex-start">
                <Button onClick={onFormStructureClick}>Form Structure</Button>
                <MyDivider/>

                <Box>
                    <Typography>Approval Processes</Typography>
                    <Stack marginLeft={4} alignItems="flex-start">
                        <Stack direction='row' alignItems='center'>
                            <Icon/>
                            <Button onClick={() => onApprovalProcessClick(addProcessRequestType, null)}>
                                {getProcessLabel(instanceType, addProcessRequestType, null)}
                            </Button>
                        </Stack>
                        <Stack direction='row' alignItems='center'>
                            <Icon/>
                            <Button onClick={() => onApprovalProcessClick(removeProcessRequestType, null)}>
                                {getProcessLabel(instanceType, removeProcessRequestType, null)}
                            </Button>
                        </Stack>
                        {
                            !isRootEntityInstanceType ? null :
                            <Stack direction='row' alignItems='center'>
                                <InfoTooltip tooltip={getResourceAccessRemovalDescription(instanceType.name)}/>
                                <Button onClick={() => onApprovalProcessClick(ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL, null)}>
                                    {getProcessLabel(instanceType, ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL, null)}
                                </Button>
                            </Stack>
                        }
                        {
                            isEntityInstanceType ? null :
                            <Stack direction='row' alignItems='center'>
                                <InfoTooltip tooltip={getMandatoryAccessRemovalDescription(instanceType)}/>
                                <Button onClick={() => onApprovalProcessClick(ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL, null)}>
                                    {getProcessLabel(instanceType, ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL, null)}
                                </Button>
                            </Stack>
                        }
                        <Typography>Field Edit Processes for:</Typography>
                        <Stack marginLeft={2}>
                            {
                                fieldsWithEditProcess.map(f => {
                                    return (
                                        <Stack direction='row' alignItems='center' key={f.id}>
                                            <Tooltip title={'Edit'} onClick={() => onApprovalProcessClick(COMMON_REQUEST_TYPES.EDIT_REQUEST, f.id)}>
                                                <IconButton>
                                                    <EditIcon color='primary'/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={'Delete'} onClick={() => setFieldIdOfProcessToDelete(f.id)}>
                                                <IconButton >
                                                    <DeleteForeverIcon color='error'/>
                                                </IconButton>
                                            </Tooltip>
                                            <Typography>{f.label}</Typography>
                                        </Stack>
                                    )
                                })
                            }
                            <AddFieldEditProcessWidget instanceType={instanceType}/>
                        </Stack>


                    </Stack>
                </Box>
                <MyDivider/>
                {
                    !isEntityInstanceType ? null :
                    <>
                        <Box>
                            <Typography>Custom columns in the table for</Typography>
                            <Stack marginLeft={4} alignItems="flex-start">
                                {/* <Button>Pending</Button> */}
                                <TableDisplayConfigWidget instanceType={instanceType} tableType={TICKET_STATUS.PENDING} onSaved={refetchConfigs}/>
                                <TableDisplayConfigWidget instanceType={instanceType} tableType={TICKET_STATUS.APPROVED} onSaved={refetchConfigs}/>
                            </Stack>
                        </Box>
                        <MyDivider/>
                    </>
                }
                {
                    !isMemberEntityInstanceType ? null :
                    <>
                        <Button onClick={onMemberAutoCreateClick}>Member Auto Creation ({isMacEnabled ? "Enabled" : "Disabled"})</Button>
                        <MyDivider/>
                    </>
                }
            </Stack>
            {
                (instanceType.is_active) ? null :
                    <Stack direction='row' spacing={4} alignItems='center' justifyContent='center' marginTop={2}>
                        <Button onClick={() => setIsActivationPanelOpen(true)} variant='contained'>
                            Activate
                        </Button>
                        <Button onClick={() => setIsRemovalPanelOpen(true)} color={'error'} variant='contained'>
                            Remove
                        </Button>
                    </Stack>
            }
        </Box>

    )
}

const AddFieldEditProcessWidget = ({instanceType}) => {
    const navigate = useNavigate();
    const [fieldId, setFieldId] = useState("");

    const choices = [];
    const editProcessesMap = instanceType.edit_processes;
    for(const section of instanceType.structure.sections){
        const fields = section.fields;
        for(const field of fields){
            const doesFieldHaveEditProcess = editProcessesMap[section.id] && editProcessesMap[section.id][field.id];
            const isFieldInactive = section.is_deleted || field.is_deleted;
            const isFileField = (field.value_type === FIELD_VALUE_TYPES.FILE);

            if( !doesFieldHaveEditProcess && !isFieldInactive && !isFileField){
                choices.push({value: field.id, label: field.label})
            }
        }
    }

    const onContinueClick = () => {
        const url = makeConfigUrl(instanceType.id, INSTANCE_TYPE_ATTRIBUTES.PROCESS, COMMON_REQUEST_TYPES.EDIT_REQUEST, fieldId, null);
        navigate(url);
    }

    return (
        <Stack direction='row' spacing={1}>
            <Box minWidth={250}>
                <TicketSelectField
                    label={"Add edit process for"}
                    value={fieldId}
                    setValue={setFieldId}
                    choices={choices}
                    doNotAutoSelect={true}
                    fullWidth={true}
                    />
            </Box>
            <Button variant='contained' disabled={!fieldId} onClick={onContinueClick}>
                Continue
            </Button>
        </Stack>
    )
}