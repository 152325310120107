import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat, get_dept, get_emp_dept_role } from "./hris_helpers";
import React from "react";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context from "../../Store";
import { REQUEST_STATES } from "../../helpers/Constants";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import RoleForm from "./RoleForm";
import { useSearchParams } from "react-router-dom";
import { HRIS_VIEWS } from "./hris_constants";
import DepartmentForm, { getDepartmentFormErrorMsg } from "./DepartmentForm";

const EditDepartmentView = ({company, refetchCompany}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();
    const deptId = Number(searchParams.get('dept_id'));

    const dept = get_dept(company.root_department, deptId);
    const initialDeptState = {id: dept.id, dept_type_id: dept.type.id, name: dept.name, extra_data: dept.extra_data}

    const [deptForm, setDeptForm] = React.useState(initialDeptState);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {
        const errMsg = getDepartmentFormErrorMsg(deptForm);
        if(errMsg){
            alertError(errMsg);
            return;
        }

        const body = {department_id: deptId, name: deptForm.name, extra_data: deptForm.extra_data};
        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Department Edited")
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_DEPT, body, onSuccess, onFailure)
    }
    return (
        <DepartmentForm
            company={company}
            department={deptForm}
            setDepartment={setDeptForm}
            submitState={submitState}
            onSubmitClick={onSubmitClick}
            />
    )
}

export default EditDepartmentView;