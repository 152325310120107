import { REVIEWER_ACTION_TYPES } from "../../helpers/Constants";


export const get_review_action_system_note = (stage, reviewAction, instanceName) => {
    switch (reviewAction) {
        case REVIEWER_ACTION_TYPES.APPROVE:
            return get_approval_note(stage, instanceName)

        case REVIEWER_ACTION_TYPES.REJECT:
            return get_reject_note(stage, instanceName)

        case REVIEWER_ACTION_TYPES.DEFER:
            return get_defer_note(stage, instanceName)
    
        default:
            return ''
    }
} 

const get_approval_note = (stage, instanceName) => {
    let msg = `
    Any changes you made will be applied and
    the request will be forwarded to the next reviewer.    
    `
    
    if(stage.is_resource_access_removal_stage){
        msg += `All access to ${instanceName} will be removed in the system.`
    }
    return msg;
}

const get_reject_note = (stage, instanceName) => {
    return `
        Defering the request will forward the ticket to who you selected for review.
        You will no longer be able to approve or reject this request after confirming.    
    `
}

const get_defer_note = (stage, instanceName) => {
    return `
        Rejecting this request will apply any changes you made
        and the request will be closed to future approvals.
        The ticket will need to be submitted again in order to be approved.    
    `
}