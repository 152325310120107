import * as React from 'react';
import { Box, Button, MenuItem, Paper, Stack, TextField } from "@mui/material"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { canonicalTextToJSX, multiLineStringToJSX } from '../../helpers/FieldDisplayFormatters';
import { REQUEST_STATES } from '../../helpers/Constants';
import LoadButton from '../generic/LoadButton';
import { useState } from 'react';
import { EXTENSIONS, post } from '../../helpers/requests';
import { useContext } from 'react';
import Context from '../../Store';

const MAX_MESSAGE_LENGTH = 1000

const feedbackTypeChoices = [
    {value: 'Bug', label: 'Bug'},
    {value: 'Feature Request', label: 'Feature Request'},
    {value: 'UX Improvement', label: 'Experience Improvement'},
    {value: 'Other', label: 'Other'}
]

export default function FeedbackFormDialog({handleCancel}) {

    const open = true;
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);

    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);
    const [feedbackType, setFeedbackType] = useState("")
    const [message, setMessage] = useState("")
    const {alertSuccess, alertError} = useContext(Context)


    const onMessageChange = (e) => {
        const newValue = e.target.value;
        if(newValue.length <= MAX_MESSAGE_LENGTH){
            setMessage(newValue)
        }
    }

    const onSubmitClick = () => {
        //validate
        if(message === ""){
            alertError("Must provide message details")
            return;
        }

        //Submit
        const body = {feedback_type: feedbackType, message: message}

        const onSuccess = (response) => {
            alertSuccess("Feedback successfully sent. Thank you for your feedback!");
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            // handleCancel()
        }
        const onFail = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        post(EXTENSIONS.PROVIDE_FEEDBACK, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING)
    }

    const isSubmitting = (submitState === REQUEST_STATES.SENDING)
    const btnDisabled = isSubmitting || (message === "") || (feedbackType === "")

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleCancel}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">{"Provide Feedback"}</DialogTitle>
                    <DialogContent dividers={true}>

                        <Stack padding={5} spacing={1}>
                            <TextField
                                id={'feedbackType'}
                                select
                                label={'Type of feedback'}
                                onChange={e => setFeedbackType(e.target.value)}
                                value={feedbackType}
                                fullWidth
                                >
                                    {feedbackTypeChoices.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                            </TextField> 
                            <TextField
                                id={"Message"}
                                variant="outlined"
                                label={"Details"}
                                value={message}
                                onChange={onMessageChange}
                                multiline fullWidth minRows={5} maxRows={12}
                                />
                            <Box justifyContent={"center"} display={"flex"}>
                                <LoadButton variant="contained" size="large" onClick={onSubmitClick} loading={isSubmitting} disabled={btnDisabled}>
                                    Submit
                                </LoadButton>
                            </Box>
                        </Stack>

                    </DialogContent>
            </Dialog>
        </div>
    );
}