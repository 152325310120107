import React, {Fragment, useState} from 'react';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Context from "../../Store";
import { useTheme } from '@mui/material';
import { blue_light, blue_main } from '../../Colors';
import { ALL_PERMS } from '../permissions/perm_constants';
import { makeNestedDeepCopy } from '../../helpers/TicketFormHelper';
import { getEntityInstanceTypeById } from '../../helpers/StaticDataHelper';

export const PENDING = "Pending"
export const REQUEST = "Request"
export const APPROVED = "Approved"
export const PAPER_TRAIL = "Paper Trail"
export const UPLOAD_CSV = "Upload CSV"
export const VIEW_ALL = "View All"
export const VIEW_ALL_MEMBERS = "All Members"
// export const PENDING_EDIT_REQUESTS = "Pending Edits"

export const TASKS = "Tasks"
export const PENDING_TASKS = "Pending Tasks"
export const COMPLETED_TASKS = "Completed Tasks"

export const ADMIN = "Admin"
export const PROBLEMS = "Problems"
export const GSHEET_ISSUES = "GSheet Issues"
export const USERS = "Users"
export const PERMISSIONS = "Permissions"

export const ACCESS = "Access"
export const BULK_IMPORT = "Bulk Import"
export const MANAGE_LEVELS = "Manage Levels"

//new nav
export const HRIS = "HR"
export const ADMIN_NEW = "Admin"
export const PERMISSIONS_NEW = "Permissions"
export const MANAGED_TABLES = "Tables"
export const EMP_TAGS = "Tags"
export const CONFIGS = "Configs"
export const HRIS_INTEGRATION = "HRIS Integration"

const value_label_obj = (value, label) => {
    if(!label) return {value: value, label: value}
    return {value: value, label: label}
}
const value_url_obj = (value, url) => {
    return {value: value, label: value, url}
}

const isChoice = (choices, value) => {
    if(Array.isArray(choices)){
        // base case, just check if the value is a choice
        return choices.some(c => c.value === value);
    }

    const subChoicesLists = Object.values(choices);
    for(const subChoicesList of subChoicesLists){
        //recursively check
        if(isChoice(subChoicesList, value)) return true;
    }
    return false;
}

const printPermDiff = (global, possible) => {
    const added = [];
    const removed = [];

    let allKeys = [...Object.keys(global), ...Object.keys(possible)];
    allKeys = [...new Set(allKeys)];
    for(const permKey of allKeys){
        const hasGlobal = global[permKey];
        const hasPossible = possible[permKey];

        if(hasGlobal && !hasPossible) removed.push(permKey);
        if(!hasGlobal && hasPossible) added.push(permKey);
    }

}
const getPotentialPerms = (userData, staticData) => {
    /*
    gets permissions that the user might have. this includes permissions the users definitely has from their global profile (user_profile)
    and permission they may have from Role Profiles.
    */
    const {user_profile, entity_role_profiles, org_roles_profile} = userData.permissions;
    const {user_id, managed_emps} = userData;

    const output = makeNestedDeepCopy(user_profile.permissions);
    
    //possible perms
    //  entity - submit, paper trail, bulk import, manage access levels
    //  access - view, paper trail, bulk import
    //  other - view offbarding report
    for(const roleProf of entity_role_profiles){
        const {section_key, field_key} = roleProf.role_field_desc;
        const {root_instance_type_id} = roleProf;

        //see if user can potentially hold the role
        const instanceType = getEntityInstanceTypeById(staticData, root_instance_type_id);
        const sectionStructure = instanceType.structure.sections.find(s => s.id === section_key);
        const fieldStructure = sectionStructure.fields.find(f => f.id === field_key);
        const choices = fieldStructure.choice_desc.choices;
        const canUserHoldRole = isChoice(choices, user_id);
        
        //see which permissions can be granted for the role
        if(canUserHoldRole){
            const {root_section, member_section, accessor_root_section, accessor_member_section, resource_root_section, resource_member_section} = roleProf;
            const permSectionsOfInterest = [root_section, accessor_root_section, accessor_member_section, resource_root_section];
            for(const permSection of permSectionsOfInterest){
                const perms = permSection.permissions;
                for (const [permKey, permValue] of Object.entries(perms)) {
                    if(permValue === true){
                        output[permKey] = true;
                    }
                }
            }
        }
    }

    //manager perms
    const isUserAManagaer = managed_emps.length > 0;
    const managerPerms = org_roles_profile.manager_section.permissions;
    if(isUserAManagaer){
        for (const [permKey, permValue] of Object.entries(managerPerms)) {
            if(permValue === true){
                output[permKey] = true;
            }
        }        
    }

    printPermDiff(user_profile.permissions, output)
    return output

}

const getNavOptions = (userData, staticData) => {
    const potentialPerms = userData.potentialPerms;
    const {user_profile, entity_role_profiles, org_roles_profile} = userData.permissions;
    const {SYSTEM_ACTIONS, ORG_ACTIONS, GENERAL_ACTIONS, ENTITY_ACTIONS, ACCESS_ACTIONS} = ALL_PERMS;
    const globalPermissions = user_profile.permissions;
    const permissions = getPotentialPerms(userData, staticData)
    permissions[ALL_PERMS.ENTITY_ACTIONS.REQ_ADD.key] = user_profile.permissions[ALL_PERMS.ENTITY_ACTIONS.REQ_ADD.key]
    
    const navPanelStructure = [];

    //Entities
    const entity_types = staticData.entity_types;
    const entityNavs = entity_types.map((entity_type) => {
        const {name, id} = entity_type;
        const output = {
            parent: value_label_obj(name),
            children:[]
        }
        if(permissions[ENTITY_ACTIONS.REQ_ADD.key]) output.children.push(value_url_obj(REQUEST, `${REQUEST}/entity_type/${id}`));
        output.children.push(value_url_obj(PENDING, `${PENDING}/entity_type/${id}`));
        output.children.push(value_url_obj(APPROVED, `${APPROVED}/entity_type/${id}`));
        output.children.push(value_url_obj(VIEW_ALL, `${VIEW_ALL}/entity_type/${id}`));
        if(entity_type.member_instance_type){
            output.children.push(value_url_obj(VIEW_ALL_MEMBERS, `${VIEW_ALL}/entity_type/${id}/eti/all`));
        }
        // if(permissions[GENERAL_ACTIONS.VIEW_PAPER_TRAILS.key]) output.children.push(value_url_obj(PAPER_TRAIL, `${PAPER_TRAIL}/entity_type/${id}`));
        if(permissions[ENTITY_ACTIONS.BULK_IMPORT.key]) output.children.push(value_url_obj(UPLOAD_CSV, `${UPLOAD_CSV}/entity_type/${id}`));
        return output
    });
    navPanelStructure.push(...entityNavs)

    //Access
    const accessNav = {
        parent: value_label_obj(ACCESS),
        children: []
    }
    accessNav.children.push(value_url_obj(REQUEST, `${ACCESS}/${REQUEST}`));
    if(permissions[ACCESS_ACTIONS.VIEW.key]){
        accessNav.children.push(value_url_obj(PENDING, `${ACCESS}/${PENDING}`));
        accessNav.children.push(value_url_obj(PAPER_TRAIL, `${ACCESS}/${PAPER_TRAIL}`));
    }
    if(permissions[ACCESS_ACTIONS.BULK_IMPORT.key]) accessNav.children.push(value_url_obj(BULK_IMPORT, `${ACCESS}/${BULK_IMPORT}`));
    if(permissions[ENTITY_ACTIONS.MANAGE_ACCESS_LVLS.key]) accessNav.children.push(value_url_obj(MANAGE_LEVELS, `${ACCESS}/${MANAGE_LEVELS}`));
    navPanelStructure.push(accessNav)

    //hris
    const hrisNav = {
        parent: value_label_obj(HRIS),
        children: []
    }
    if(permissions[ORG_ACTIONS.VIEW_ORG.key]) hrisNav.children.push(value_url_obj(HRIS, `${HRIS}`))
    navPanelStructure.push(hrisNav);
    
    //tasks
    const approvalNav = { //everyone has these tabs
        parent: value_label_obj(TASKS),
        children:[
            value_label_obj(PENDING_TASKS),
            // value_label_obj(COMPLETED_TASKS),
        ]
    }
    navPanelStructure.push(approvalNav);

    //Admin
    const adminNav = {
        parent: value_label_obj(ADMIN_NEW, "Admin (new)"),
        children: []
    }
    if(permissions[SYSTEM_ACTIONS.EDIT_CONFIG.key]) adminNav.children.push(value_url_obj(CONFIGS, `${ADMIN_NEW}/${CONFIGS}`))
    if(permissions[SYSTEM_ACTIONS.EDIT_CONFIG.key]) adminNav.children.push(value_url_obj(HRIS_INTEGRATION, `${ADMIN_NEW}/${HRIS_INTEGRATION}`))
    if(permissions[SYSTEM_ACTIONS.VIEW_PERMS.key]) adminNav.children.push(value_url_obj(PERMISSIONS_NEW, `${ADMIN_NEW}/${PERMISSIONS_NEW}`))
    if(permissions[SYSTEM_ACTIONS.VIEW_TABLES.key]) adminNav.children.push(value_url_obj(MANAGED_TABLES, `${ADMIN_NEW}/${MANAGED_TABLES}`))
    if(permissions[ORG_ACTIONS.EDIT_ORG.key]) adminNav.children.push(value_url_obj(EMP_TAGS, `${ADMIN_NEW}/${EMP_TAGS}`))
    navPanelStructure.push(adminNav);

    const output = navPanelStructure.filter(x => x.children.length > 0);
    return output
}


const drawerWidth = 240;
const FONT_SIZE = '18px'
const BACKGROUND_COLOR = blue_main
const SELECTED_COLOR = '#90d0ff'
const UNSELECTED_COLOR = blue_light

const isSameChild = (currentlyOpen, parent, child) => {
    if(!currentlyOpen) return false
    return (currentlyOpen.parent.value === parent.value) && (currentlyOpen.child.value === child.value)
}

const LayeredNavPanel = ({}) => {
    const [expanded, setExpanded] = useState(null)
    const [currentlyOpen, setCurrentlyOpen] = useState(null)
    const {user, staticData} = React.useContext(Context);
    const {logged_in, permissions} = user

    const navigate = useNavigate()

    const handleParentClick = (parentIndex) => {
        if(parentIndex === expanded) setExpanded(null)
        else setExpanded(parentIndex)
    }

    const handleChildClick = (parent, child) => {
        setCurrentlyOpen({parent, child});
        if(child.url){
            navigate(child.url);
        }
        else{
            const url = [TASKS, ADMIN].includes(parent.value) ? `/${parent.value}/${child.value}` : `${child.value}/entity_type/${parent.value}`;
            navigate(url);
        }
    }

    const navOptions = getNavOptions(user, staticData)
    return(
        <Drawer
            variant="permanent"
            sx={{ width: drawerWidth, flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor:BACKGROUND_COLOR },}}
            >
        <Toolbar/>
        <Box sx={{ overflow: 'auto' }}>
            <List>
                {navOptions.map(({parent, children}, index) => (
                    <Fragment key={`nav-${parent.value}`}>
                        <ListItem disablePadding sx={{backgroundColor: (index === expanded ? SELECTED_COLOR : UNSELECTED_COLOR)}}>
                            <ListItemButton onClick={e => handleParentClick(index)}>
                                <ListItemText primary={parent.value} primaryTypographyProps={{fontSize: FONT_SIZE}}/>
                                {/* <Typography variant='h6' fontWeight={'normal'}>{parent.label}</Typography> */}
                            </ListItemButton>
                        </ListItem>
                        <Divider  sx={{ borderBottomWidth: 3 }}/>
                        {
                            (index === expanded) &&
                            <List sx={{marginLeft:5}}>
                                {
                                    children.map((child) => (
                                        <Fragment key={`nav-${parent.value}-${child.value}`}>
                                            <ListItem disablePadding sx={{backgroundColor: (isSameChild(currentlyOpen, parent, child) ? SELECTED_COLOR : UNSELECTED_COLOR)}}>
                                                <ListItemButton onClick={e => handleChildClick(parent, child)}>
                                                    <ListItemText primary={child.value} primaryTypographyProps={{fontSize: FONT_SIZE}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider  sx={{ borderBottomWidth: 2 }}/>
                                        </Fragment>
                                    ))
                                }
                            </List>
                        }
                    </Fragment>
                ))}
            </List>
        </Box>
        </Drawer>
    )
}

export default LayeredNavPanel;