import React, { useState } from "react";
import { Box, Divider, IconButton, Stack, Typography } from "../../../node_modules/@mui/material/index";
import Context from "../../Store";
import { useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import PageTitle from "../generic/PageTitle";
import TicketSelectField from "../inputs/TicketSelectField";
import { getActiveEmpChoices } from "../../helpers/StaticDataHelper";
import SuggestedTextField from "../inputs/SuggestedTextFIeld";
import LoadButton from "../generic/LoadButton";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import { REQUEST_STATES } from "../../helpers/Constants";

const AddTag = ({empTags, refetchEmpTags}) => {
    const {staticData, alertSuccess, alertError} = React.useContext(Context);
    const {all_users, active_users} = staticData;

    const [userId, setUserId] = useState('');
    const [tag, setTag] = useState('');
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    const currTags = Object.keys(empTags);

    const isSending = (submitState === REQUEST_STATES.SENDING);
    const onAddClick = () => {
        if(!userId){
            alertError('must select a user.');
            return;
        }
        if((tag.length <= 0) || (tag.length > 50)){
            alertError('must provide a tag between 1 and 50 characters.');
            return;
        }

        if(empTags[tag] && empTags[tag].includes(userId)){
            alertError('The user already has this tag');
            return;
        }

        const body = {employee_id: userId, tag: tag};
        const onSuccess = (resp) => {
            alertSuccess('Added');
            refetchEmpTags();
            setSubmitState(REQUEST_STATES.NOT_SENT);
        }
            
        const onFailure = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.NOT_SENT);
        }
        
        setSubmitState(REQUEST_STATES.SENDING);
        post(EXTENSIONS.ADD_EMP_TAG, body, onSuccess, onFailure);
    }

    return (
        <Stack direction='row' spacing={2} sx={SX_BOX_SIMPLE}>
            <TicketSelectField
                label={'User'}
                value={userId}
                setValue={setUserId}
                choices={getActiveEmpChoices(staticData)}
                fullWidth={true}
                />
            <SuggestedTextField
                label={"Tag"}
                value={tag}
                setValue={setTag}
                suggestions={currTags}
                />
            <Box alignContent='center'>
                <LoadButton onClick={onAddClick} disabled={isSending} loading={isSending}>
                    Add
                </LoadButton>
            </Box>
        </Stack>
    )
}

const TagsView = ({empTags, refetchEmpTags}) => {
    const {staticData, alertSuccess, alertError} = React.useContext(Context);
    const {all_users} = staticData;

    const currTags = Object.keys(empTags);

    const onDeleteClick = (tag, userId) => {
        const body = {employee_id: userId, tag: tag};
        const onSuccess = (resp) => {
            alertSuccess('Removed');
            refetchEmpTags();
        }
            
        const onFailure = (e) => {
            alertError(e.response.data);
        }
        
        post(EXTENSIONS.REMOVE_EMP_TAG, body, onSuccess, onFailure);        
    }

    return (
        <Stack>
            {
                currTags.map(tag => {
                    const usersWithTag = empTags[tag];

                    return (
                        <Box key={tag} sx={SX_BOX_SIMPLE}>
                            <Typography variant='h5'>{tag}</Typography>
                            <Stack spacing={0} marginLeft={2}>

                            {
                                usersWithTag.map(userId => {
                                    const usersName = all_users[userId];
                                    return (
                                        <Stack key={userId} direction='row' spacing={0}>
                                            <IconButton onClick={() => onDeleteClick(tag, userId)}>
                                                <DeleteIcon color='error'/>
                                            </IconButton>
                                            <Typography alignContent='center'>{usersName}</Typography>
                                        </Stack>
                                    )
                                })
                            }
                            {/* <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/> */}
                            </Stack>
                        </Box>
                    )
                })
            }
        </Stack>
    )
}

const TagsPage = () => {
    const [empTags, refetchEmpTags] = useRefetchData(EXTENSIONS.GET_EMP_TAGS, {});

    if(empTags === false) return <div>Failed to load...</div>;
    else if(empTags === null) return <LoadingMessage/>;

    return (
        <Box>
            <PageTitle title={"Manage Tags"}/>
            <AddTag empTags={empTags} refetchEmpTags={refetchEmpTags}/>
            <Box marginY={2}/>
            <TagsView empTags={empTags} refetchEmpTags={refetchEmpTags}/>
        </Box>
    )
}

export default TagsPage;