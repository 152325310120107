import { useContext, useState } from "react"
import { Stack } from "@mui/material"
import TicketSelectField from "../inputs/TicketSelectField"
import ConfirmActionDialog from "../generic/ConfirmActionDialog";
import { Tooltip } from "@mui/material";
import { EMPLOYEE_ACCESSOR_TYPE_ID, INSTANCE_TYPE_META_NAMES } from "../../helpers/Constants";
import Context from "../../Store";
import { EXTENSIONS, post } from "../../helpers/requests";
import { Typography } from "../../../node_modules/@mui/material/index";
import { isAccessTypeForEmp } from "../../helpers/access_helper";

const _EMPLOYEE_ACCESSOR_CHOICE_VALUE = "emp";//we want to use null, but the dropdown component forbids null as a value


const getErrorMessage = (accessTypes, accessorTypeId, resourceTypeId) => {
    const isNewAccessTypeForEmployee = (accessorTypeId === _EMPLOYEE_ACCESSOR_CHOICE_VALUE) || (accessorTypeId === EMPLOYEE_ACCESSOR_TYPE_ID);

    if(accessorTypeId==='' || resourceTypeId===''){
        return "Select an Accessor and Resource type";
    }

    for(const accessType of accessTypes){
        const doesResourceMatch = (accessType.resource_type.id === resourceTypeId);

        const doesAccessorMatch = isAccessTypeForEmp(accessType) ?
            isNewAccessTypeForEmployee :
            (accessType.accessor_type.id === accessorTypeId);
        
        if(doesResourceMatch && doesAccessorMatch){
            return "This access type already exists";
        }        
    }
    return null;
}

export const CreateAccessTypeDialog = ({entityInstanceTypes, accessTypes, close, onCreated}) => {
    const {alertError, alertSuccess} = useContext(Context);

    const [accessorTypeId, setAccessorTypeId] = useState("");
    const [resourceTypeId, setResourceTypeId] = useState("");
    const [isCreating, setIsCreating] = useState(false);

    const accessorChoices = entityInstanceTypes.map(eit => ({value: eit.id, label: eit.name}));
    accessorChoices.unshift({value: _EMPLOYEE_ACCESSOR_CHOICE_VALUE, label: "Employee"});

    const resourceChoices = entityInstanceTypes.filter(eit => !eit.parent_type).map(eit => ({value: eit.id, label: eit.name}));

    const onCreateClick = () => {
        const errMsg = getErrorMessage(accessTypes, accessorTypeId, resourceTypeId);
        if(errMsg){
            alertError(errMsg);
            return;
        }

        const onSuccess = () => {
            setIsCreating(false);
            alertSuccess('Created');
            onCreated();
            close();
        }
        
        const onFail = (err) => {
            setIsCreating(false);
            alertError(err.response.data);
        }

        setIsCreating(true);
        const actualAccessorTypeId = (accessorTypeId === _EMPLOYEE_ACCESSOR_CHOICE_VALUE) ? EMPLOYEE_ACCESSOR_TYPE_ID : accessorTypeId;
        const body = {accessor_type_id: actualAccessorTypeId, resource_type_id: resourceTypeId}
        post(EXTENSIONS.CREATE_EMPTY_ACCESS_TYPE, body, onSuccess, onFail)
    }

    const Content = (
        <Stack spacing={2}>
            <Typography>
                Note that creating an Access Type will not immediately make it visible for everyone. When you are done configuring the form and approval processes,
                you will need to activate it in order for tickets of this type to be submitted.
            </Typography>
            <TicketSelectField
                label={"Accessor type (What will be granted access)"}
                value={accessorTypeId}
                setValue={setAccessorTypeId}
                choices={accessorChoices}
                fullWidth={true}
                />
            <TicketSelectField
                label={"Resource type (What will be accessed)"}
                value={resourceTypeId}
                setValue={setResourceTypeId}
                choices={resourceChoices}
                fullWidth={true}
                />
        </Stack>
    )

    return (
        <ConfirmActionDialog
            title={"Create Access Type"}
            body={Content}
            handleCancel={close}
            handleConfirm={onCreateClick}
            cancelText={"Cancel"}
            confirmText={'Create'}
            loading={isCreating}
            disabled={isCreating}
            fullWidth={true}
            // maxWidth={}
            />
    )
}