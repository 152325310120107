import { useContext, useState } from "react";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index";
import { SX_BOX_ICON_BTN, SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import TicketSelectField, { getAllUniquePossibleChoices } from "../../inputs/TicketSelectField";
import { COND_RELATION_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../../inputs/FieldProps";
import ConfirmActionDialog from "../../generic/ConfirmActionDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Context from "../../../Store";
import TicketMultiSelectField from "../../inputs/TicketMultiSelectField";
import { MESSAGE_LIST_SEP } from "../../../helpers/Constants";

const _TOOLTIP_DELAY = 500;
const _BUTTON_TOOLTIP = `
Set conditions for not displaying this field
`

const RELATION_CHOICES = [
    {value: COND_RELATION_TYPES.EQUALS, label: '='},
    {value: COND_RELATION_TYPES.NOT_EQUALS, label: '≠'},
    {value: COND_RELATION_TYPES.IN, label: 'In'},
    {value: COND_RELATION_TYPES.NOT_IN, label: 'Not in'},
]

const getHideConditionsErrorMessages = (fieldStructure, sectionStructure) => {
    const {hide_conditions} = fieldStructure;
    //if null - then ok, check the protocol for "no conditions"
    if((hide_conditions === null)) return[];

    const oredClauses = hide_conditions.ored_clauses

    const errs = [];

    if(oredClauses.length === 0){//this shouldnt happen - internal bug
        errs.push("No condition sets were provided.")
    }

    //no empty sections
    if(oredClauses.some(conditionSet => conditionSet.length === 0)){
        errs.push("Remove or fill out the empty condition set(s)")
    }

    //all fields filled out with valid values
    const validDependencyFields = getValidDependencyFields(fieldStructure, sectionStructure);
    for(let sectionIdx = 0; sectionIdx < oredClauses.length; sectionIdx++){
        const conditionSet = oredClauses[sectionIdx];

        for(let constraintIdx = 0; constraintIdx < conditionSet.length; constraintIdx++){
            const cond = conditionSet[constraintIdx];
            const positionStr = `(section #${sectionIdx + 1}, condition #${constraintIdx + 1})`;
            const isRelationToSet = getIsRelationToSet(cond.relation);
            // const {field_id, relation, value} = cond;
            if(!cond.field_id || !cond.relation || (['', [], ['']].includes(cond.value))){
                errs.push(`Some field(s) imcomplete ${positionStr}`)
            }
            else {
                const dependencyField = validDependencyFields.find(f => f.id === cond.field_id);
                const possibleFieldValuesChoices = getConditionFieldChoices(dependencyField)
                if(!dependencyField){
                    errs.push(`invalid field selected ${positionStr}`)
                }
                if(!RELATION_CHOICES.some(c => c.value === cond.relation)){
                    errs.push(`Invalid relation selected ${positionStr}`)
                }

                if(isRelationToSet !== Array.isArray(cond.value)){
                    //this shouldnt happen since the component enforces it.
                    errs.push(`mismatch between selected relation and value type ${positionStr}`);
                }
                else{
                    const depValues = isRelationToSet ? cond.value : [cond.value];
                    for(const v of depValues){
                        if(!possibleFieldValuesChoices.some(c => c.value === v)){
                            errs.push(`Invalid 'Value' selected ${positionStr}`);
                            break;
                        }
                    }
                }
            }
        }
    }


    //no contradictions
    return errs;
}

const HideConditionsField = ({
    fieldStructure, sectionStructure, setHideCondsValue,
    applyChanges, cancel, isPanelOpen, onPanelOpenClick
}) => {
    // const [isOpen, setIsOpen] = useState(false);
    const {staticData, alertError} = useContext(Context);
    const {all_users, all_departments} = staticData;
    
    if(!isPanelOpen){
        return (
            <Box sx={SX_BOX_ICON_BTN}>
                <Tooltip title={_BUTTON_TOOLTIP} enterDelay={_TOOLTIP_DELAY}>
                    <IconButton onClick={onPanelOpenClick}>
                        <VisibilityOffIcon color='primary'/>
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    const tryApplyingChanges = () => {
        const errs = getHideConditionsErrorMessages(fieldStructure, sectionStructure);
        if(errs.length){
            const errMsg = "Please fix the following errors:" + MESSAGE_LIST_SEP + errs.join(MESSAGE_LIST_SEP);
            alertError(errMsg);
            // return false;
        }
        else{
            applyChanges();
            // return true;
        }
    }

    return (
        <ConfirmActionDialog
            title={`Conditions for not displaying field "${fieldStructure.label}"`}
            body={
                <Box minWidth={500} minHeight={100}>
                    <HideConditionsPanel fieldStructure={fieldStructure} sectionStructure={sectionStructure} setHideCondsValue={setHideCondsValue}/>
                </Box>
            }
            handleCancel={cancel}
            handleConfirm={tryApplyingChanges}
            cancelText={'Cancel'}
            confirmText={'Apply'}
            loading={false}
            disabled={false}
            />
    )
}

const getValidDependencyFields = (fieldStructure, sectionStructure) => {
    const validDependencyFields = [];
    for(const f of sectionStructure.fields){
        if(f.id === fieldStructure.id) break;
        //TODO: make sure it is a static select field
        //TODO: allow depending on true/false fields
        if(f.value_type === FIELD_VALUE_TYPES.BOOL){
            validDependencyFields.push(f)
        }
        else if(f.component === FIELD_COMPONENT_TYPES.SELECT){
            // const choices = getAllUniquePossibleChoices(f.choice_desc.choices);
            // validDependencyFields.push({field: f, choices: choices}) 
            validDependencyFields.push(f)
        }

    }
    return validDependencyFields
}

const getIsRelationToSet = (relation) => {
    return [COND_RELATION_TYPES.IN, COND_RELATION_TYPES.NOT_IN].includes(relation);
}

const getConditionFieldChoices = (f) => {
    if(!f) return [];
    if(f.value_type === FIELD_VALUE_TYPES.BOOL) return [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
    return getAllUniquePossibleChoices(f.choice_desc.choices)
}
const HideConditionsPanel = ({fieldStructure, sectionStructure, setHideCondsValue}) => {
    const {hide_conditions} = fieldStructure;

    const oredClauses = (hide_conditions && hide_conditions.ored_clauses) ? hide_conditions.ored_clauses : [];

    const validDependencyFields = getValidDependencyFields(fieldStructure, sectionStructure);

    const onAddOrSectionClick = () => {
        oredClauses.push([]);
        const newHideConds = {ored_clauses: oredClauses};
        setHideCondsValue(newHideConds);
    }
    
    const onRemoveOrSectionClick = (idx) => {
        oredClauses.splice(idx, 1);
        const newHideConds = (oredClauses.length > 0) ? {ored_clauses: oredClauses} : null;
        setHideCondsValue(newHideConds);        
    }

    const onAddCondition = (andedClauses) => {
        const newCond = {field_id: "", relation: COND_RELATION_TYPES.EQUALS, value: ""}
        andedClauses.push(newCond);
        const newHideConds = {ored_clauses: oredClauses};
        setHideCondsValue(newHideConds);
    }

    const onRemoveConditionClick = (andedClauses, idx) => {
        andedClauses.splice(idx, 1);
        const newHideConds = {ored_clauses: oredClauses};
        setHideCondsValue(newHideConds);
    }

    const onRelationSelected = (setIdx, conditionIdx, newRel) => {
        const prevCond = oredClauses[setIdx][conditionIdx];

        const prevRel = prevCond.relation;
        const isForSet = getIsRelationToSet(newRel);
        const wasForSet = getIsRelationToSet(prevRel);

        const prevCondValue = prevCond.value
        let newCondValue = prevCondValue;
        if(isForSet && !wasForSet){
            newCondValue = [prevCondValue];
        }
        else if(!isForSet && wasForSet){
            if(prevCondValue.length === 1){
                newCondValue = prevCondValue[0];
            }
            else{
                newCondValue = '';
            }
        }

        const newCond = {...prevCond, relation: newRel, value: newCondValue}
        oredClauses[setIdx][conditionIdx] = newCond;
        const newHideConds = {ored_clauses: oredClauses};
        setHideCondsValue(newHideConds);
    }
    return (
        <Box>
            <Stack spacing={2}>
            {
                oredClauses.map((andedClauses, idx1) => {

                    return (
                        <Stack key={idx1} sx={{...SX_BOX_SIMPLE, p:2}} spacing={2}>
                            {
                                andedClauses.map((cond, idx2) => {
                                    // const {field_id, relation, value} = cond;
                                    const setCond = (newCond) =>{
                                        oredClauses[idx1][idx2] = newCond;
                                        const newHideConds = {ored_clauses: oredClauses};
                                        setHideCondsValue(newHideConds);
                                    }
                                    const fieldChoices = validDependencyFields.map(f => {return {value: f.id, label: f.label}});
                                    const dependencyField = validDependencyFields.find(f => f.id === cond.field_id);
                                    const valueChoices = getConditionFieldChoices(dependencyField)
                                    const isCondValueASet = getIsRelationToSet(cond.relation);

                                    return (
                                        <Stack direction='row' spacing={2} key={idx2}>
                                            <IconButton onClick={() => onRemoveConditionClick(andedClauses, idx2)}>
                                                <RemoveCircleIcon color={'error'}/>
                                            </IconButton>
                                            <TicketSelectField
                                                label={'Field'}
                                                value={cond.field_id}
                                                setValue={(x) => setCond({...cond, field_id: x})}
                                                choices={fieldChoices}
                                                fullWidth={true}
                                                />
                                            <TicketSelectField
                                                label={'Relation'}
                                                value={cond.relation}
                                                setValue={(x) => onRelationSelected(idx1, idx2, x)}
                                                choices={RELATION_CHOICES}
                                                fullWidth={true}
                                                />
                                            {
                                                isCondValueASet ?
                                                <TicketMultiSelectField
                                                    label={'Values'}
                                                    value={cond.value}
                                                    setValue={(x) => setCond({...cond, value: x})}
                                                    choices={valueChoices}
                                                    fullWidth={true}
                                                    /> :
                                                <TicketSelectField
                                                    label={'Value'}
                                                    value={cond.value}
                                                    setValue={(x) => setCond({...cond, value: x})}
                                                    choices={valueChoices}
                                                    fullWidth={true}
                                                    /> 
                                            }
                                        </Stack>
                                    )
                                })
                            }
                            <Stack direction='row'>
                                <Box alignItems='center' display='flex'>
                                    <Typography variant='subtitle1'>Section #{idx1 + 1}</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title={'Remove this constraints section'} enterDelay={_TOOLTIP_DELAY}>
                                        <IconButton onClick={() => onRemoveOrSectionClick(idx1)}>
                                            <RemoveCircleIcon color={'error'}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    <Tooltip title={'Add condition to this set'} enterDelay={_TOOLTIP_DELAY}>
                                        <IconButton onClick={() => onAddCondition(andedClauses)}>
                                            <AddCircleIcon color={'primary'}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Box sx={{...SX_BOX_ICON_BTN, alignItems: 'center'}}>
                                    <Tooltip title={'If all conditions in this set (outlined box) are true, then the field will NOT be displayed'} enterDelay={_TOOLTIP_DELAY}>
                                        <HelpOutlineIcon color={'primary'}/>
                                    </Tooltip>
                                </Box>
                            </Stack>

                        </Stack>
                        
                    )
                })
            }
            {
                (fieldStructure.hide_conditions !== null) ? null :
                <Typography>
                    The field will always be displayed. Add some conditions for when it should not be.
                </Typography>
            }
                <Box sx={SX_BOX_ICON_BTN}>
                    <Stack direction='row'>
                        <Box sx={SX_BOX_ICON_BTN}>
                            <Tooltip title={'Add new constraints section'} enterDelay={_TOOLTIP_DELAY}>
                                <IconButton onClick={onAddOrSectionClick}>
                                    <AddCircleIcon color={'primary'}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{...SX_BOX_ICON_BTN, alignItems: 'center'}}>
                            <Tooltip title={'If at least 1 of the sections have all its conditions satisfies, Then the field will NOT be displayed'}  enterDelay={_TOOLTIP_DELAY}>
                                <HelpOutlineIcon color={'primary'}/>
                            </Tooltip>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default HideConditionsField;