import {FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../components/inputs/FieldProps";


const LAZYDefaultFieldValue = (fieldStructure) => {
    if(fieldStructure.component === FIELD_COMPONENT_TYPES.CHECKBOX ) return false;
    if((fieldStructure.component === FIELD_COMPONENT_TYPES.SELECT) && (Array.isArray(fieldStructure.choice_desc.choices)) && (fieldStructure.choice_desc.choices.length > 1)){
        return fieldStructure.choice_desc.choices[0].value
    }

    if(fieldStructure.valueType === FIELD_VALUE_TYPES.STRING){
        if([FIELD_COMPONENT_TYPES.SHORT_TEXT, FIELD_COMPONENT_TYPES.LONG_TEXT, FIELD_COMPONENT_TYPES.MULTI_TEXT].includes(fieldStructure.component)){
        // if((fieldStructure.component === FIELD_COMPONENT_TYPES.SHORT_TEXT) || (fieldStructure.component === FIELD_COMPONENT_TYPES.LONG_TEXT)){
            return 'aaa'
        }
    }
    if(([FIELD_COMPONENT_TYPES.MULTISELECT, FIELD_COMPONENT_TYPES.MULTICHECK].includes(fieldStructure.component))){
        return fieldStructure.choice_desc.choices[0].value
    }
    if((fieldStructure.valueType === FIELD_VALUE_TYPES.BOOL)){
        return false
    }
    if((fieldStructure.valueType === FIELD_VALUE_TYPES.FLOAT)){
        return 12
    }
    return ''
}
const defaultFieldValue = (fieldStructure) => {
    // return LAZYDefaultFieldValue(fieldStructure)
    if(fieldStructure.default_value) return fieldStructure.default_value;
    if(fieldStructure.component === FIELD_COMPONENT_TYPES.CHECKBOX ) return false;
    if((fieldStructure.component === FIELD_COMPONENT_TYPES.SELECT) && Array.isArray(fieldStructure.choice_desc.choices) && (fieldStructure.choice_desc.choices.length === 1)){
        return fieldStructure.choice_desc.choices[0].value
    }
    if((fieldStructure.component === FIELD_COMPONENT_TYPES.MULTISELECT)){
        return []
    }
    if((fieldStructure.component === FIELD_COMPONENT_TYPES.MULTICHECK)){
        return []
    }


    return ''
}

export const getUpdatedFormForNewStructure = (structure, formValues) =>{
    if(!formValues) return getDefaultValue(structure);
    const output = {}

    structure.sections.filter(s => !s.is_deleted).forEach(sectionStructure => {
        const sectionId = sectionStructure.id;
        const isSectionInFormValue = (formValues[sectionId] !== undefined);

        if(isSectionInFormValue){
            output[sectionId] = {}
            sectionStructure.fields.filter(f => !f.is_deleted).forEach(fieldStructure => {
                const fieldId = fieldStructure.id;
                const isFieldInSectionValues = (formValues[sectionId][fieldId] !== undefined);
                output[sectionId][fieldId] = isFieldInSectionValues ? formValues[sectionId][fieldId] : defaultFieldValue(fieldStructure)

            })
        }
        else{
            output[sectionStructure.id] = getDefaultSectionValues(sectionStructure);
        }
    })

    return output
}
export const getDefaultValue = (structure) =>{
    // return getTempLazySwVal()
    const output = {}

    structure.sections.forEach(sectionStructure => {
        if(!sectionStructure.is_deleted){
            output[sectionStructure.id] = getDefaultSectionValues(sectionStructure);
        }
    })

    return output
}

const getTempLazySwVal = () => {
    return {
        general: {
            urgency: "Low",
            urgency_explanation: "",
            hosting: [
                "Mango Servers"
            ],
            sensitivity: "Low",
            criticality: "Low"
        },
        budget: {
            owner: 76,
            department: 2,
            team: 31,
            budget_name: "T-Budget",
            manager: 76
        },
        info: {
            description: "a",
            will_integrate: true,
            integration_details: "a",
            accesses_customer_data: true,
            accesses_customer_data_expl: "a",
            accesses_emp_info: true,
            accesses_confidential_info: true,
            admin: 76,
            sw_type: [
                "SaaS"
            ]
        },
        payment_info: {
            can_cost_change_on_useage: true,
            cost_change_explanation: "a",
            payment_type: "Credit Card",
            cost: "",
            payment_period: "Once"
        },
        processed_info: {
            useage_conditions: "",
            processed_parties: [],
            processed_pii: []
        },
        contact_info: {
            company_name: "a",
            contact_name: "a",
            contact_email: "a",
            contact_hq_address: "a",
            contact_website: "a"
        },
        terms: {
            terms_url: "a",
            terms_file: ""
        }
    }
}
export const getDefaultSectionValues = (section_structure) =>{
    const fields = section_structure.fields;
    const section_values = {}

    fields.forEach(f => {
        if(!f.is_deleted){
            section_values[f.id] = defaultFieldValue(f)
        }
    });

    return section_values;
}