import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AbstractTable from "../tables/AbstractTable";
import React from "react";
import Context from "../../Store";
import { truncateString } from "../../helpers/CommonUtil";
import { useSearchParams } from "react-router-dom";
import { PERM_VIEWS } from "./perm_constants";
import { getEntityInstanceTypeById, getFieldStructureFromFormStructure } from "../../helpers/StaticDataHelper";


const GlobalProfileAssignmentsTable = ({assignments, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const {all_users, active_users} = staticData;
    const [searchParams, setSearchParams] = useSearchParams();

    const onEditProfileClick = (profileId) => {
        setSearchParams({view: PERM_VIEWS.EDIT_GLOBAL_PROFILE, profile_id: profileId});
    }

    const onAddGlobalProfileClick = () => {
        setSearchParams({view: PERM_VIEWS.ADD_GLOBAL_PROFILE})
    }

    const columnNames = ['', 'Profile', 'Users']
    const renderRowCells = (a) => {
        return [
            !a.profile.id ? null :
            <Tooltip title={"Edit Profile"} placement="left">
                <IconButton aria-label="edit" onClick={() => onEditProfileClick(a.profile.id)} disabled={false}>
                    <EditIcon color="primary"/>
                </IconButton>
            </Tooltip>,
            a.profile.name,
            truncateString(a.user_ids.map(id => all_users[id]).join(", "), 300, true),
            // <Tooltip title={"Add user to profile"} placement="right">
            //     <IconButton aria-label="edit" onClick={() => onEditProfileClick(a.profile.id)} disabled={false}>
            //         <EditIcon color="primary"/>
            //     </IconButton>
            // </Tooltip>,
        ]
    }
    return (
        <Box>
            <Stack direction={'row'} spacing={2} marginY={1}>
                <Typography alignContent={'center'} variant="h5">Global Profiles</Typography>
                <Button variant="contained" onClick={onAddGlobalProfileClick}>Add</Button>
            </Stack>
            <AbstractTable
                rows={assignments}
                rowToKey={a => a.profile.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                />
        </Box>
    )    
}

const EntityRoleProfilesTable = ({entity_role_profiles, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();

    const rowToKey = (prof) => prof.id;
    const columnNames = ['', 'Entity type', 'Field in form'];

    const onEditClick = (profileId) => {
        setSearchParams({view: PERM_VIEWS.EDIT_ENTITY_ROLE_PROFILE, profile_id: profileId})
    }

    const renderRowCells = (prof) => {
        // const {root_instance_type_id, role_field_desc, root_section, member_section, accessor_root_section, accessor_member_section, resource_root_section, resource_member_section} = prof
        const instanceType = getEntityInstanceTypeById(staticData, prof.root_instance_type_id);
        const roleFieldStructure = getFieldStructureFromFormStructure(instanceType.structure, prof.role_field_desc);
        const roleLabel = roleFieldStructure ? roleFieldStructure.short_label : '';
        return [
            <IconButton aria-label="edit" onClick={() => onEditClick(prof.id)}>
                <EditIcon color="primary"/>
            </IconButton>,
            instanceType.name,
            roleLabel
        ]
    }
    return (
        <Box>
            <Typography alignContent={'center'} variant="h5">Process Role Profiles</Typography>
            <AbstractTable
                rows={entity_role_profiles}
                rowToKey={rowToKey}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                />
        </Box>
    )   
}

const OrgRolesProfileTable = ({org_roles_profile, refetchPermissions}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onEditClick = () => {
        setSearchParams({view: PERM_VIEWS.EDIT_ORG_ROLES_PROFILE})
    }
    return (
        <Box>
            <Typography alignContent={'center'} variant="h5" marginBottom={1}>Org Roles Profile</Typography>
            <Stack direction={'row'} spacing={2}>
                <Button onClick={onEditClick} variant="contained">Edit</Button>
                <Typography alignContent={'center'}>Permissions as manager of employee</Typography>
            </Stack>
        </Box>
    )
}

const ProfilesView = ({permissions, refetchPermissions}) => {
    const {global_assignments, entity_role_profiles, org_roles_profile} = permissions;

    return (
        <Box>
            <Divider sx={{ borderBottomWidth: 2, marginTop:2, marginBottom: 4 }} color={'#000000'}/>
            <GlobalProfileAssignmentsTable assignments={global_assignments} refetchPermissions={refetchPermissions}/>
            <Divider sx={{ borderBottomWidth: 2, marginTop:2, marginBottom: 4 }} color={'#000000'}/>
            <EntityRoleProfilesTable entity_role_profiles={entity_role_profiles} refetchPermissions={refetchPermissions}/>
            <Divider sx={{ borderBottomWidth: 2, marginTop:2, marginBottom: 4 }} color={'#000000'}/>
            <OrgRolesProfileTable org_roles_profile={org_roles_profile} refetchPermissions={refetchPermissions}/>
        </Box>
    )

}

export default ProfilesView;