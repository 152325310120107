import { useContext, useEffect, useRef, useState } from "react";
import Context from "../Store";
import { EXTENSIONS, post } from "./requests";
import { ACCESSOR_TYPES } from "./Constants";
import { setAccessTypesRelatedTypes, sortChoicesInStaticData } from "./StaticDataHelper";

export const useRefetchData = (urlExt, body, onSuccess, onFailure) => {
    const {alertError} = useContext(Context)
    const [data, setData] = useState(null)
    const [toggle, setToggle] = useState(null)

    const bodyStr = JSON.stringify(body)
    useEffect(() => {
        setData(null) //TODO: see about removing this
        const onSucceeded = (response) => {
            setData(response.data);
            if(onSuccess) onSuccess(response)
        }
        const onFailed = (err) => {
            setData(false);
            alertError(err.response.data)
            if(onFailure) onFailure(err)
        }
        post(urlExt, body, onSucceeded, onFailed)
    }, [toggle, urlExt, bodyStr])

    const refetchData = () => setToggle(!toggle)
    return [data, refetchData];
}

export const useDataState = (urlExt, body, onSuccess, onFailure) => {
    // const [data, refetchData] = useRefetchData(urlExt, body, onSuccess, onFailure);
    // return data;
    const {alertError} = useContext(Context)
    const [data, setData] = useState(null)
    const bodyStr = JSON.stringify(body)
    useEffect(() => {
        setData(null)
        const onSucceeded = (response) => {
            setData(response.data);
            if(onSuccess) onSuccess(response)
        }
        const onFailed = (err) => {
            setData(false);
            alertError(err.response.data)
            if(onFailure) onFailure(err)
        }
        post(urlExt, body, onSucceeded, onFailed)
    }, [urlExt, bodyStr])

    return [data, setData]
}

export const useData = (urlExt, body, onSuccess, onFailure) => {
    // const [data, refetchData] = useRefetchData(urlExt, body, onSuccess, onFailure);
    // return data;
    const {alertError} = useContext(Context)
    const [data, setData] = useState(null)
    const bodyStr = JSON.stringify(body)
    useEffect(() => {
        setData(null)
        const onSucceeded = (response) => {
            setData(response.data);
            if(onSuccess) onSuccess(response)
        }
        const onFailed = (err) => {
            setData(false);
            alertError(err.response.data)
            if(onFailure) onFailure(err)
        }
        post(urlExt, body, onSucceeded, onFailed)
    }, [urlExt, bodyStr])

    return data
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
   
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
   
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}

export const useEnsureUpToDateConfigs = () => {
    const {staticData, setStaticData, alertError} = useContext(Context);
    const [syncState, setSyncState] = useState(null);//same convention as useData. null is loading,  false is error, true is success

    useEffect(() => {
        const onSuccess = (resp) => {
            if(!resp || !resp.data || !resp.data.data){
                setSyncState(true)
                return; //data should be null if the server has the same config version as client
            }
            const data = resp.data.data;
            const newStaticData = {...staticData, ...data}
            sortChoicesInStaticData(newStaticData);
            setAccessTypesRelatedTypes(newStaticData);
            setStaticData((prev) => ({...prev, ...newStaticData}));
            setSyncState(true)
        }

        const onFailure = () => {
            setSyncState(false)
        }

        const curr_version = staticData.config_version
        post(EXTENSIONS.GET_CONFIGS_UPDATE, {curr_version: curr_version}, onSuccess, onFailure)
    }, [])

    return syncState;
}