import React from "react";
import Context from "../../Store";
import { Box, Stack, TextField, Divider } from "@mui/material";
import CollapseableContent from "../generic/CollapseableContent";
import { getEntityInstanceTypeById, getEntityTypeWithInstanceTypeId } from "../../helpers/StaticDataHelper";
import MultiSectionForm from "../form/MultiSectionForm";
import { downloadEntityFileField } from "../../helpers/requests";

const AccessRelatedEntitiesStates = ({accessType, accessInstance}) => {
    const {staticData} = React.useContext(Context);
    const accessorEntityInstanceType = getEntityInstanceTypeById(staticData, accessType.accessor_instance_type_id);
    const resourceEntityInstanceType = getEntityInstanceTypeById(staticData, accessType.resource_instance_type_id);

    const downloadFileGetter = (entityId) => {
        return (sectionId) => {
            return (fieldId) => {
                downloadEntityFileField(entityId, null, sectionId, fieldId, null);
                // downloadEntityFileField(entityId, ticketId, sectionId, fieldId, stageId);
            }
        }
    }
    return (
            <Stack direction={'row'}>
            {
                !accessorEntityInstanceType ? null :
                <Box>
                    <CollapseableContent title={`${accessInstance.accessor.name} (Accessor)`} isExpandedByDefault={false}>
                        <MultiSectionForm
                            formData={accessInstance.accessor.state.form_values}
                            structure={accessorEntityInstanceType.structure}
                            getSectionDownloadFileField={downloadFileGetter(accessInstance.accessor.id)}
                            />
                        {
                            !accessInstance.accessor.parent ? null :
                            <CollapseableContent title={`${accessInstance.accessor.parent.name} details`} isExpandedByDefault={false}>
                                <MultiSectionForm
                                    formData={accessInstance.accessor.parent.state.form_values}
                                    structure={accessorEntityInstanceType.parent_type.structure}
                                    getSectionDownloadFileField={downloadFileGetter(accessInstance.accessor.parent.id)}
                                    />
                            </CollapseableContent>
                        }
                    </CollapseableContent>
                </Box>
            }
            <Box>
                <CollapseableContent title={`${accessInstance.resource.name} (Resource)`} isExpandedByDefault={false}>
                    <MultiSectionForm
                        formData={accessInstance.resource.state.form_values}
                        structure={resourceEntityInstanceType.structure}
                        getSectionDownloadFileField={downloadFileGetter(accessInstance.resource.id)}
                        />
                </CollapseableContent>
            </Box>
        </Stack>
    )
}

export default AccessRelatedEntitiesStates;