import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { canonicalTextToJSX, multiLineStringToJSX } from '../../helpers/FieldDisplayFormatters';
import { REQUEST_STATES } from '../../helpers/Constants';
import LoadButton from './LoadButton';

export default function ConfirmActionDialog({title, body, handleCancel, handleConfirm, cancelText, confirmText, loading, disabled, fullWidth, maxWidth}) {

    const open = true;
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);


    return (
        <div>
            <Dialog
                open={true}
                onClose={handleCancel}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
                    <DialogContent dividers={true}>
                        {
                            (typeof body === 'string' || body instanceof String) ?
                                canonicalTextToJSX(body) :
                                // <DialogContentText
                                //     id="scroll-dialog-description"
                                //     ref={descriptionElementRef}
                                //     tabIndex={-1}
                                // >
                                    body
                                // </DialogContentText>
                        }
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} disabled={disabled}>
                        {cancelText}
                    </Button>
                    {
                        (confirmText && handleConfirm) &&
                        <LoadButton onClick={handleConfirm} loading={loading} disabled={disabled}>
                            {confirmText}
                        </LoadButton>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}