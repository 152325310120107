import React, {useState, useEffect, useContext, Fragment} from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { type } from '@testing-library/user-event/dist/type';
import Context from '../../Store';




const MakeFeedback = (message, severity, hideAfterMs) => {
  return {message, severity, hideAfterMs}
}

export const MakeSuccessFeedback = (message, hideAfterMs) => {
  return MakeFeedback(message, "success", hideAfterMs)
}

export const MakeErrorFeedback = (message, hideAfterMs) => {
  return MakeFeedback(message, "error", hideAfterMs)
}

export const MakeWarningFeedback = (message, hideAfterMs) => {
  return MakeFeedback(message, "warning", hideAfterMs)
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getAutoHideDuration = (msg) => {
  const charsPerSecond = 10;
  const msPerSec = 1000;
  const msPerChar = msPerSec / charsPerSecond;
  const expectedTimeToRead = msg.length * msPerChar;
  
  if(expectedTimeToRead < 15000) return 15000
  return expectedTimeToRead
}

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const { feedback } = React.useContext(Context);
  const {message, severity, hideAfterMs} = feedback

  useEffect(() => {
    setOpen(true)
  }, [feedback])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if(!message) return null

  const formattedMessage = message.split("\n").map((s, idx) =>{
    return(
      <Fragment key={`feedback-line-${idx}`}>
        {s}
        <br/>
      </Fragment>
    )
  })

  const autoHideDuration = (hideAfterMs === undefined) ? getAutoHideDuration(message) : hideAfterMs;
  return(
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {formattedMessage}
        </Alert>
      </Snackbar>
  )

}
export default Feedback;
{/* <Alert severity="error">This is an error message!</Alert>
<Alert severity="warning">This is a warning message!</Alert>
<Alert severity="info">This is an information message!</Alert>
<Alert severity="success">This is a success message!</Alert> */}