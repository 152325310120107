

export const doesGraphHaveCycle = (connection, nodes, edges, getOutgoers) => {
    const target = nodes.find((node) => node.id === connection.target);
    const _hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
        if (outgoer.id === connection.source) return true;
        if (_hasCycle(outgoer, visited)) return true;
        }
    };

    if (target.id === connection.source) return false;
    return !_hasCycle(target);
};

