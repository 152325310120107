import { useState } from 'react';
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '../../../../../node_modules/@mui/material/index';
import ConfirmActionDialog from '../../../generic/ConfirmActionDialog';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const INDENT = 4;
const TXT_VARIANT_0 = 'h5'
const TXT_VARIANT_1 = 'h6'
const BULLET = '\u2022';

const MyDivider = () => {
    return <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000", marginY: 2}}/>
}

const GraphInfoPanel = () => {
    const SppIcon = <PlayCircleOutlineIcon color='success' sx={{verticalAlign:"middle"}}/>;
    const EppIcon = <PauseCircleOutlineIcon color='error' sx={{verticalAlign:"middle"}}/>;
    return (
        <Box>
            <Typography variant={TXT_VARIANT_0}>Editing (Important!):</Typography>
            <Box marginLeft={INDENT}>
                <Typography>
                    {BULLET} When saving the updated process, all tickets that are pending in the process will have their current stages
                    updated to reflect the changes.
                </Typography>
                <Box marginLeft={INDENT}>
                    <Typography>
                        {BULLET} A ticket's progress will backtrack to any new stages that have been added, if the ticket's current progress has
                        passed that new stage. This will NOT cause stages that have already been approved to undergo a second review.
                    </Typography>
                    <Typography>
                        {BULLET} A ticket will skip its current stage if it is deleted. Note that a ticket may complete the approval process
                        due to this (if there arent any unapproved stages for the ticket in the new process)
                    </Typography>
                </Box>
                <Typography>
                    {BULLET} Deleting a stage and creating a new identical stage will NOT have the same effect.
                </Typography>
                <Box marginLeft={INDENT}>
                    <Typography>
                        {BULLET} The system will view the new stage as completely unrelated to the deleted stage (view previous section regarding new/deleted stages)
                    </Typography>
                    <Typography>
                        {BULLET} If you regret deleting a stage before saving, then select it from the dropdown above the graph.
                    </Typography>
                </Box>
            </Box>
            <MyDivider/>


            <Typography variant={TXT_VARIANT_0}>Flow:</Typography>
            <Typography marginLeft={INDENT}>
                {BULLET} The approval process will advance from stage to stage/s, begining with the stage/node that has no incoming edges (connections).
                It will end at the stage/node with no outgoing edges. When a request is approved at a stage, the stages that have an edge from the approved
                stage will be activated for review/approval.
            </Typography>
            <Typography marginLeft={INDENT}>
                {BULLET} Note that the direction of the edges matter. You can toggle the edge animation for a clear picture of the
                stage order in the approval process.
            </Typography>
            <MyDivider/>


            <Typography variant={TXT_VARIANT_0}>Node types:</Typography>
            <Box marginLeft={INDENT}>
                <Typography variant={TXT_VARIANT_1}>Stage nodes:</Typography>
                <Typography marginLeft={INDENT}>
                    These are the actual stages in the approval process. Click on one to edit its settings.
                    Stage nodes may have at most 1 incoming and 1 outgoing edge. If you want the completion of a stage
                    to trigger multiple stages, then connect it to a {SppIcon} node.
                </Typography>
                
                    
                <Typography variant={TXT_VARIANT_1}>Start Parallel Process nodes: {SppIcon}</Typography>
                <Typography marginLeft={INDENT}>
                    These signify the begining of a parallel process, where multiple stages will be simultaneously activated.
                    It must have at most 1 incoming incoming edge.
                    It may have have as many outgoing edges as you like, but all nodes stemming from it must reach the same
                    {EppIcon} node eventually.
                </Typography>

                    
                <Typography variant={TXT_VARIANT_1}>End Parallel Process nodes: {EppIcon}</Typography>      
                <Typography marginLeft={INDENT}>
                    These signify the end of a parallel process. The approval flow will not move on from this point until all stages
                    leading into it have been completed. It must have at most 1 outgoing edge.
                    It may have have as many incoming edges as you like, but all nodes leading into it must trace back the same
                    {SppIcon} node.
                </Typography>
            </Box>
            <MyDivider/>

            <Typography variant={TXT_VARIANT_0}>Constraints:</Typography>
            <Box marginLeft={INDENT}>
                <Typography>{BULLET} No cycles in the graph</Typography>
                <Typography>{BULLET} There must be an equal number of {SppIcon} and {EppIcon} </Typography>
                <Typography>{BULLET} There must be exactly 1 node with no incoming edges (the start node)</Typography>
                <Typography>{BULLET} There must be exactly 1 node with no outgoing edges (the end node)</Typography>
                <Typography>{BULLET} All nodes must be connected (directly or indirectly)</Typography>
            </Box>
            <MyDivider/>

            <Typography variant={TXT_VARIANT_0}>Interactivity:</Typography>
            <Box marginLeft={INDENT}>
                <Typography>{BULLET} Click on an edge or node to select it.</Typography>
                <Typography>{BULLET} Hold shift and draw a rectangle to select everything fully contained in a region</Typography>
                <Typography>{BULLET} Hold control and click multiple edges/nodes to select multiple</Typography>
                <Typography>{BULLET} Press backspace to delete whatever is selected</Typography>
                <Typography>
                    {BULLET} Connect an edge from 1 node to another by dragging an edge out from the source node's right handle
                    and releasing it on the target node's left handle
                </Typography>
            </Box>
        </Box>
    )
}
export const GraphHelpButton = () => {

    const [isOpen, setIsOpen] = useState(false);

    if(!isOpen){
    
        return (
            <IconButton onClick={() => setIsOpen(true)} >
                <HelpOutlineIcon color='primary'/>
            </IconButton>
        )
    }

    return (
        <ConfirmActionDialog
            title={'Approval Process Building'}
            body={<GraphInfoPanel/>}
            handleCancel={undefined}
            cancelText={undefined}
            handleConfirm={() => setIsOpen(false)}
            confirmText={'Ok'}
            loading={false}
            disabled={false}
            fullWidth={true}
            maxWidth={'md'}
            />
    )
}