import React from "react";
import Context from "../../Store";
import GlobalProfileForm, { MAX_PERM_PROF_NAME_LEN } from "./GlobalProfileForm";
import { ALL_PERMS } from "./perm_constants";
import { REQUEST_STATES } from "../../helpers/Constants";
import { EXTENSIONS, post } from "../../helpers/requests";
import { Box, Typography } from "@mui/material";



const AddGlobalProfile = ({permissions, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);

    const initialValues = {};
    Object.keys(ALL_PERMS).forEach(sectionKey => {
        const sectionPermsObj = ALL_PERMS[sectionKey];
        Object.keys(sectionPermsObj).forEach( key => {
            const permKey = sectionPermsObj[key].key;
            initialValues[permKey] = false;
        })
    });

    const [sectionValues, setSectionValues] = React.useState(initialValues)
    const [profileName, setProfileName] = React.useState("")
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const onSaveClick = () => {
        if(!profileName){
            alertError('Profile must have a name');
            return;
        }
        if(profileName.length > MAX_PERM_PROF_NAME_LEN){
            alertError('Profile name is too long');
            return;
        }
        const body = {name: profileName, permissions: {...sectionValues}};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Permission Profile Added")
            refetchPermissions();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.ADD_GLOBAL_PERM_PROF, body, onSuccess, onFailure)
    }
    return (
        <Box>
            <Typography variant="h5" textAlign={'center'}>Add a Permission Profile</Typography>
            <GlobalProfileForm
                profileName={profileName}
                setProfileName={setProfileName}
                sectionValues={sectionValues}
                setSectionValues={setSectionValues}
                submitState={submitState}
                onSaveClick={onSaveClick}
                />
        </Box>
    )
}

export default AddGlobalProfile;