import React, { useState } from "react"
import ConfirmActionDialog from "../generic/ConfirmActionDialog"
import Context from "../../Store"
import { EXTENSIONS, post } from "../../helpers/requests"

const DELETE_PROCESS_MESSAGE = `\
Are you sure you want to delete this approval process?
Any pending edits in this process will be applied.
`

export const DeleteEditProcessConfirmationDialog = ({close, instanceTypeDescriptor, fieldId, onDeleted}) => {
    const {alertSuccess, alertError} = React.useContext(Context);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onConfirmClick = () => {
        const onSuccess = () => {
            setIsSubmitting(false);
            alertSuccess("Process deleted");
            onDeleted();
            close();
        }

        const onFail = (e) => {
            setIsSubmitting(false);
            const msg = e.response?.data ? e.response.data : "Something went wrong";
            alertError(msg);
        }

        const body = {instance_type_desc: instanceTypeDescriptor, field_id: fieldId}
        post(EXTENSIONS.REMOVE_EDIT_REQUEST_PROCESS, body, onSuccess, onFail)
        setIsSubmitting(true);
    }
    return (
        <ConfirmActionDialog
            title = {"Delete process?"}
            body = {DELETE_PROCESS_MESSAGE}
            handleCancel = {close}
            handleConfirm = {onConfirmClick}
            cancelText = {"No (keep process)"}
            confirmText = {"Yes, delete process"}
            loading = {isSubmitting}
            disabled = {isSubmitting}
            // fullWidth = {}
            // maxWidth = {}
            />
    ) 
}