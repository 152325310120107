import { useContext, useState } from "react"
import { Box, IconButton, ListSubheader, MenuItem, Stack, TextField, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import Context from "../../Store";
import { getFieldFromStructureWithId } from "../../helpers/StaticDataHelper";
import { TICKET_STATUS } from "../../helpers/Constants";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import { swapItemsAtIndices } from "../../helpers/CommonUtil";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { EXTENSIONS, post } from "../../helpers/requests";
import EditIcon from '@mui/icons-material/Edit';
import { FIELD_VALUE_TYPES } from "../inputs/FieldProps";
import { FieldSelect } from "../non_form_fields/FieldSelect";



const getInitialFields = (instanceType, tableType) => {
    let columnDisplays = [];
    if(tableType === TICKET_STATUS.APPROVED) columnDisplays = instanceType.approved_table_display.table_column_displays;
    else if(tableType === TICKET_STATUS.PENDING) columnDisplays = instanceType.pending_table_display.table_column_displays;
    else console.error(`unexpected table type: ${tableType}`)

    return columnDisplays.map(colDisp => getFieldFromStructureWithId(instanceType.structure, colDisp.field));
}



export const TableDisplayConfigWidget = ({instanceType, tableType, onSaved }) => {
    const [isInEditMode, setIsInEditMode] = useState(false);

    const tableTypeLabel = (tableType === TICKET_STATUS.PENDING) ? "Pending" : "Approved"

    if(!isInEditMode){
        return (
            <Stack direction='row' alignItems='center'>
                <Tooltip title={'add or remove columns'}>
                    <IconButton onClick={()=>setIsInEditMode(true)}>
                        <EditIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Typography>{tableTypeLabel} {instanceType.name} page</Typography>
            </Stack>
        )
    }

    const onTableDisplaySaved = () => {
        setIsInEditMode(false);
        onSaved();
    }

    return (
        <TableDisplayConfigWidgetEditMode
            instanceType={instanceType}
            tableType={tableType}
            onSaved={onTableDisplaySaved}
            onCancelClick={() => setIsInEditMode(false)}
            />
    )
}

const TableDisplayConfigWidgetEditMode = ({instanceType, tableType, onSaved, onCancelClick }) => {
    const {alertError, alertSuccess} = useContext(Context);


    const [fields, setFields] = useState(getInitialFields(instanceType, tableType));
    const [isSubmitting, setIsSubmitting] = useState(false);


    const addField = (fieldId) => {
        const isFieldAlreadyAdded = fields.some(f => f.id === fieldId);
        if(isFieldAlreadyAdded){
            alertError("Field is already selected");
            return;
        }
        const field = getFieldFromStructureWithId(instanceType.structure, fieldId);
        if(field.value_type === FIELD_VALUE_TYPES.FILE){
            alertError("Cannot have file fields as table column");
            return;
        }
        setFields([...fields, field]);
    }

    const removeField = (fieldId) => {
        const newFields = fields.filter(f => f.id !== fieldId);
        setFields(newFields)
    }

    const moveField = (fieldId, isLeft) => {
        const currIdx = fields.findIndex(f => f.id === fieldId);
        const offset = isLeft ? -1 : 1;
        const targetIndex = currIdx + offset;
        if(targetIndex < 0 || targetIndex >= fields.length) return;

        const newFields = [...fields];
        swapItemsAtIndices(newFields, currIdx, targetIndex);
        setFields(newFields)
    }

    const onSaveClick = () => {
        const onSuccess = () => {
            setIsSubmitting(false);
            alertSuccess("Saved");
            onSaved();
        }
        
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }

        const fieldIds = fields.map(f => f.id)
        const body = {entity_instance_type_id: instanceType.id, table_type: tableType, field_ids: fieldIds}
        post(EXTENSIONS.UPDATE_TABLE_DISPLAY, body, onSuccess, onFail);
        setIsSubmitting(true);
    }

    const tableTypeLabel = (tableType === TICKET_STATUS.PENDING) ? "Pending" : "Approved"
    return (
        <Box m={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Box>
                    <Stack direction='row' alignItems='center'>
                        <Tooltip title='cancel'>
                            <IconButton onClick={onCancelClick} disabled={isSubmitting}>
                                <CancelIcon color='error'/>
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={onSaveClick} disabled={isSubmitting}>
                            <SaveIcon color='primary'/>
                        </IconButton>
                        <Typography>{tableTypeLabel} {instanceType.name} page</Typography>
                    </Stack>
                    <Box width={300}>
                        <FieldSelect structure={instanceType.structure} value={""} setValue={addField}/>
                    </Box>
                </Box>
                {
                    fields.map(f => {
                        return (
                            <Box key={f.id} sx={{border: 1, borderRadius: 1}}>
                                <Typography align='center'>{f.label}</Typography>
                                <Stack direction='row'>
                                    <IconButton onClick={() => moveField(f.id, true)}>
                                        <KeyboardArrowLeftOutlinedIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={() => removeField(f.id)}>
                                        <DeleteIcon color='error'/>
                                    </IconButton>
                                    <IconButton onClick={() => moveField(f.id, false)}>
                                        <KeyboardArrowRightOutlinedIcon color='primary'/>
                                    </IconButton>
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}