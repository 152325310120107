import React, { memo } from 'react';
import { Handle, NodeResizer, Position } from 'reactflow';
import { Box, Typography } from '@mui/material';
import CustomHandle from './CustomHandle';

const CONNECTION_LIMIT = 1;

const stateToColor = (isOpen, hasError) => {
    if(isOpen) return "#DCF350";
    if(hasError) return "#ffaaaa";
    return "#cccccc";
}

export default memo(({ data, selected }) => {
    // const {status, name, id, height, width} = data; //status in "enabled", "approved", "rejected", null
    const {stage, isOpen, hasError} = data;
    const {name, id} = stage;
    const color = stateToColor(isOpen, hasError);
    return (
        // <Box bgcolor={color} borderRadius={2} border={1} width={width} height={height} justifyContent={'center'} alignItems={'center'} display={"flex"}>
        <Box bgcolor={color} borderRadius={2} border={1} justifyContent={'center'} alignItems={'center'}>
            <CustomHandle
                type="target"
                id="dst"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                // isConnectableStart={false}
                isConnectable={CONNECTION_LIMIT}
            />
            <Typography m={1} variant='body1' fontSize={16}>{name}</Typography>
            <CustomHandle
                type="source"
                position={Position.Right}
                id="src"
                // isConnectableEnd={false}
                isConnectable={CONNECTION_LIMIT}
            />
        </Box>
    );
});