import { Toolbar, Box } from "@mui/material";
import Login from "./Login";
import React, {useEffect} from "react";
import Header from "./Header";
import LayeredNavPanel from "./LayeredNavPanel";
import { Outlet} from "react-router-dom";
import Feedback from "./Feedback";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context, {getDefaultUserInfo} from "../../Store";
import { setAccessTypesRelatedTypes, sortChoicesInStaticData } from "../../helpers/StaticDataHelper";
import LoadingMessage from "../generic/LoadingMessage";
import { blue_light } from "../../Colors";

const tempRemoveAndMarkFieldsAsDeleted = (staticData) => {
  console.error('removing fields. delete this!')
  const swEt = staticData.entity_types.find(et => et.id === 1);
  const swInstanceType = swEt.root_instance_type;
  const structure = swInstanceType.structure;

  //remove general section
  const generalSectionIdx = structure.sections.findIndex(s => s.id === 'general')
  structure.sections.splice(generalSectionIdx, 1);
  
  //remove budget name field
  const budgetSection = structure.sections.find(s => s.id === 'budget')
  const budgetNameFieldIdx = budgetSection.fields.findIndex(f => f.id === 'budget_name')
  budgetSection.fields.splice(budgetNameFieldIdx, 1)
  
  //mark contact info section as deleted
  const contactInfoSection = structure.sections.find(s => s.id === 'contact_info')
  contactInfoSection.is_deleted = true;
  for(const field of contactInfoSection.fields){
    field.is_deleted = true;
  }

  // mark single field payment period as deleted
  const payInfoSection = structure.sections.find(s => s.id === 'payment_info');
  const payPeriodField = payInfoSection.fields.find(f => f.id === 'payment_period');
  payPeriodField.is_deleted = true;

  //change stage ids around
  for(const stg of swInstanceType.addition_process.stages){
    stg.id += 100000;
    stg.name = `${stg.name}-temp`;
  }
  for(const stg of swInstanceType.removal_process.stages){
    stg.id += 100000;
    stg.name = `${stg.name}-temp`;
  }

  //Access type - change stage ids around
  const accessType = staticData.access_types.find(at => at.id === 101)
  for(const stg of accessType.addition_process.stages){
    stg.id += 100000;
    stg.name = `${stg.name}-temp`;
  }
  for(const stg of accessType.removal_process.stages){
    stg.id += 100000;
    stg.name = `${stg.name}-temp`;
  }
}

const Layout = () => {
  const {alertSuccess, alertError, user, setUser, staticData, setStaticData} = React.useContext(Context);
  // const {logged_in, is_system_admin, is_system_super_admin} = user
  const [isConnecting, setIsConnecting] = React.useState(false);
  const {logged_in} = user

  const onLoggedIn = () => {
    setUser({...user, logged_in: true});
    //fetch and set initial data
    const onFail = (e) => {
      alertError("Failed to fetch data. Some functionality may not work properly. Try refreshing page.");
    }

    const onConfigsFetched = (resp) => {
      const data = resp.data.data;
      const newStaticData = {...staticData, ...data}
      sortChoicesInStaticData(newStaticData);
      setAccessTypesRelatedTypes(newStaticData);
      // tempRemoveAndMarkFieldsAsDeleted(newStaticData)
      setStaticData((prev) => ({...prev, ...newStaticData}))
    }
    post(EXTENSIONS.GET_CONFIGS_UPDATE, {curr_version: null}, onConfigsFetched, onFail)
    

    const onMyUserDataFetched = (resp) => {
      setUser(resp.data.user);
    }
    post(EXTENSIONS.GET_MY_USER_DATA, {}, onMyUserDataFetched, onFail);
    

    const onEntitySummariesFetched = (resp) => {
      const entity_summaries = resp.data.entity_summaries;
      // const newStaticData = {...staticData, entity_summaries: entity_summaries}
      setStaticData((prev) => ({...prev, entity_summaries: entity_summaries}))
    }
    post(EXTENSIONS.GET_ENTITY_SUMMARIES, {}, onEntitySummariesFetched, onFail)
  }

  useEffect(() => {
    // if(logged_in) return
    const body = {}

    const onSuccess = (response) => {
      // setUser(response.data.user);
      // setUser({...user, logged_in: true});
      onLoggedIn();
      // sortChoicesInStaticData(response.data.static_data);
      // setAccessTypesRelatedTypes(response.data.static_data);
      // tempRemoveAndMarkFieldsAsDeleted(response.data.static_data);//TODO: remove this line after testing
      // setStaticData(response.data.static_data)
      setIsConnecting(false);
    }
    const onFailure = (err) => {
      console.log("Failed to connect to network", err)
      setUser(getDefaultUserInfo())
      setStaticData({})
      // alertError("Failed to connect to network. Try again later.")
      setIsConnecting(false);
    }
    setIsConnecting(true);
    post(EXTENSIONS.AM_I_LOGGED_IN, body, onSuccess, onFailure)    
  }, [])

  if(isConnecting) return <LoadScreen/>
  if(!logged_in) return <Login onLoggedIn={onLoggedIn}/>

  const isInitialDataLoaded = (staticData.config_version !== null) && (user.user_id !== null);
  if(!isInitialDataLoaded) return <LoadScreen/>

  const onLogoutClick = () => {
    // setUser(getDefaultUserInfo())
    const body = {}

    const onSuccess = (response) => {
      setUser(getDefaultUserInfo())
      // alertSuccess("Successfully logged out")
    }
    const onFailure = (err) => {
      alertError("Something went wrong while trying to log out")
    }
    post(EXTENSIONS.LOGOUT, body, onSuccess, onFailure)    
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Header user={user} onLogoutClick={onLogoutClick}/>
      <LayeredNavPanel/>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar/>
        <Outlet/>
        <Feedback/>
      </Box>
    </Box>
  ) 
  
}

const LoadScreen = () => {
  return (
    <Box bgcolor={'#42a5f5'} alignItems='center' justifyContent='center' sx={{width: '100vw', height:'100vh', display: 'flex'}}>
      <LoadingMessage/>
    </Box>
  )
}
export default Layout;
