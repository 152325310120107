import React from "react";
import Context from "../../Store";
import AbstractTable from "../tables/AbstractTable";
import { Button } from "@mui/material";
import TicketSelectField from "../inputs/TicketSelectField";
import { REQUEST_STATES } from "../../helpers/Constants";
import { EXTENSIONS, post } from "../../helpers/requests";
import { hasPermToEditPerms } from "./perm_helper";


const getRows = (active_users, global_assignments) => {
    const userIds = Object.keys(active_users).map(userId => Number(userId));
    
    const rows = userIds.map(userId => {
        const assignment = global_assignments.find(a => a.user_ids.includes(userId));
        const profile = (assignment === undefined) ? {} : assignment.profile;
        
        return {
            userId: userId,
            userName: active_users[userId],
            profileId: profile.id,
            profileName: (profile.name === undefined) ? "None" : profile.name
        }
    });
    rows.sort((a, b) => a.userName.localeCompare(b.userName))
    return rows;
}

const UserPermissionsView = ({permissions, refetchPermissions}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);
    const {all_users, active_users} = staticData;
    const canEdit = hasPermToEditPerms(user)

    const [editedAssignment, setEdittedAssignment] = React.useState(null);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const columnNames = ['User', 'Profile', '']
    const rows = getRows(active_users, permissions.global_assignments);

    const profileChoices = permissions.global_assignments.map(a => {
        return {value: a.profile.id, label: a.profile.name}
    })
    
    const onSaveAssignmentClick = () => {
        const body = {id_of_user_being_updated: editedAssignment.userId, profile_id: editedAssignment.profileId};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Permission Profile Assigned")
            refetchPermissions();
        }
        const onFailure = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.UPDATE_USER_PERM_PROF, body, onSuccess, onFailure)
    }

    const renderRowCells = (row) => {
        if(!editedAssignment || editedAssignment.userId !== row.userId){
            return [
                row.userName,
                row.profileName,
                !canEdit ? "" : <Button onClick={() => setEdittedAssignment({userId: row.userId, profileId: row.profileId})}>Edit</Button>
            ]
        }
        return [
            row.userName,
            <TicketSelectField
                label={"Profile"}
                choices={profileChoices}
                value={editedAssignment.profileId ? editedAssignment.profileId : ""}
                setValue={(x) => setEdittedAssignment({...editedAssignment, profileId: x})}
                fullWidth
                />,
            <Button onClick={onSaveAssignmentClick} variant={'contained'}>Save</Button>
        ]
        
    }
    return (
        <AbstractTable
            rows={rows}
            rowToKey={row => row.userId}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            rowHeight={'small'}
            />
    )

}

export default UserPermissionsView;