import React, { useState } from "react";
import { COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, TICKET_STATUS } from "../../helpers/Constants";
import Context from "../../Store";
import { useNavigate, useParams } from "../../../node_modules/react-router-dom/dist/index";
import { getEntityName, getFieldStructureFromFormStructureWithIds, sortEntitiesOfTypeByName } from "../../helpers/StaticDataHelper";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import { DEFAULT_INITIAL_ROWS_PER_PAGE, getCustomColumnNames, getDefaultTableRowsPerPageOptions, getReviewerNamesOfStages, renderCustomRowCells } from "../../helpers/TableHelper";
import { Box, Divider, Stack, Tab, Tabs, Tooltip, Typography } from "../../../node_modules/@mui/material/index";
import { datetimeDisplay, fieldValueString } from "../../helpers/FieldDisplayFormatters";
import { VIEW } from "../../App";
import ProgressGraph from "../graph_stage_progress/ProgressGraph";
import PendingEntitiesTable from "../tables/PendingEntitiesTable";
import AbstractTable from "../tables/AbstractTable";
import PageTitle from "../generic/PageTitle";
import MyTabs from "../generic/MyTabs";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";

const separateEntities = (entities) => {
    entities.sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))
    const output = {
        additions: [],
        removals: [],
        edits: []
    };
    for(const e of entities){
        const tickets = e.tickets;
        const pendingTickets = tickets.filter(t => t.status === TICKET_STATUS.PENDING)
        const additionTicket = pendingTickets.find(t => [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN].includes(t.request_type));
        const removalTicket = pendingTickets.find(t => t.request_type === ENTITY_REQUEST_TYPES.REMOVE);
        const editTickets = pendingTickets.filter(t => t.request_type == COMMON_REQUEST_TYPES.EDIT_REQUEST)
        
        if(additionTicket){
            output.additions.push({entity: e, ticket: additionTicket});
        }
        if(removalTicket){
            output.removals.push({entity: e, ticket: removalTicket});
        }
        for(const t of editTickets){
            output.edits.push({entity: e, ticket: t})
        }
    }

    return output;
}

export const StagesCell = ({stagesReviewers, stagesNames}) => {
    return (
        <div>
            <Tooltip title={stagesReviewers}>
                <Typography variant='h6'>
                    {stagesNames}
                </Typography>
            </Tooltip>
        </div>
    )
}

const getCommonCells = (entity, ticket, staticData, entityInstanceType) => {
    const stagesNames = ticket.stages.map(s => s.stage_name).join(", ");
    const stagesReviewers = getReviewerNamesOfStages(entity, ticket, staticData, entityInstanceType)
    const stagesCell = <StagesCell stagesReviewers={stagesReviewers} stagesNames={stagesNames}/>
    let otherCells = null;
    const submissionAction = ticket.actions.find(a => a.role === "Submitter")
    if(submissionAction){
        const submitterName = submissionAction.actor_id ? staticData.all_users[submissionAction.actor_id] : "System";
        otherCells = [
            stagesCell,
            submitterName,
            datetimeDisplay(submissionAction.datetime, true)
        ]
    }
    else{
        otherCells = [stagesCell, "-", "-"]
    }
    return otherCells;
}

const SectionTitle = ({title}) => {
    return null;
    // return <Typography variant='h5'>{title}</Typography>
}

const AdditionsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    const [hoveredRow, setHoveredRow] = useState(null);

    // const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type;

    const columnDisplays = entityInstanceType.pending_table_display.table_column_displays;
    const columnNames = getCustomColumnNames(entityInstanceType, columnDisplays);
    columnNames.splice(1, 0, 'Stages', 'Requester', 'Sub Date')

    const renderRowCells = ({entity, ticket}) => {
        const formValues = ticket.form_values;

        const cells = renderCustomRowCells(entity, entityInstanceType, columnDisplays, staticData, formValues);
        let otherCells = getCommonCells(entity, ticket, staticData, entityInstanceType);

        cells.splice(1, 0, ...otherCells)
        return cells;
    }

    const approvalProcess = entityInstanceType.addition_process;
    const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];
    return (
        <div>
            <div>
                <SectionTitle title={'Pending Additions'}/>
                <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/>
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                />
        </div>
    )
}

const RemovalsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    const [hoveredRow, setHoveredRow] = useState(null);

    const approvalProcess = entityInstanceType.removal_process;
    const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];
    
    const columnNames = [entityInstanceType.name, 'Stages', 'Requester', 'Sub Date'];

    const renderRowCells = ({entity, ticket}) => {
        let cells = getCommonCells(entity, ticket, staticData, entityInstanceType)
        cells.unshift(entity.name);
        return cells;
    }

    return (
        <Box>
            <div>
                <SectionTitle title={'Pending Removals'}/>
                <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/>
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                />
        </Box>
    )
}

const EditsView = ({datas, entityInstanceType, onRowClick}) => {
    const {staticData} = React.useContext(Context);
    // const [hoveredRow, setHoveredRow] = useState(null);

    // const approvalProcess = entityInstanceType.removal_process;
    // const hoveredTicketStageIds = hoveredRow ? hoveredRow.ticket.stages.map(stageInfo => stageInfo.stage_id) : [];
    const structure = entityInstanceType.structure;

    const columnNames = [entityInstanceType.name, 'Field', 'Current value', 'New value', 'Stages', 'Requester', 'Sub Date'];

    const renderRowCells = ({entity, ticket}) => {
        const sectionId = Object.keys(ticket.form_values)[0];
        const fieldId = Object.keys(ticket.form_values[sectionId])[0];

        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, sectionId, fieldId);
        const fieldLabel = fieldStructure ? fieldStructure.short_label : '';
        const currValue = entity.state.form_values[sectionId][fieldId];
        const newValue = ticket.form_values[sectionId][fieldId];
        let commonCells = getCommonCells(entity, ticket, staticData, entityInstanceType);
        return [
            entity.name,
            fieldLabel,
            fieldValueString(currValue, fieldStructure, staticData),
            fieldValueString(newValue, fieldStructure, staticData),
            ...commonCells
            
        ]
    }

    return (
        <div>
            <div>
                <SectionTitle title={'Pending Edits'}/>
                {/* <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={hoveredTicketStageIds}/> */}
            </div>
            <AbstractTable
                rows={datas}
                rowToKey={(data) => data.ticket.id}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                onRowClick={onRowClick}
                // onRowHover={(r) => setHoveredRow(r)}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(datas)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                />
        </div>
    )
}

const PendingEntityTicketsPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const {entity_types} = staticData;
    const navigate = useNavigate();

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const parent_id = params.entity_type_instance_id ? Number(params.entity_type_instance_id) : null;

    const data = useData(EXTENSIONS.GET_PENDING_ENTITY_TICKETS, {entity_type_id, parent_id});
    
    const awaitedDatas = [configSyncState, data]
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const isMembers = !!parent_id;
    const parentName = getEntityName(staticData, parent_id);
    const entityType = entity_types.find(et => et.id === entity_type_id);
    const entityInstanceType = isMembers ? entityType.member_instance_type : entityType.root_instance_type

    const accumulator = data;
    let entities = accumulator[entityInstanceType.id];
    entities = !isMembers ? entities : entities.filter(e => e.parent.id === parent_id);
    const { additions, removals, edits } = separateEntities(entities);

    const onRowClick = (row) => {
        const {entity, ticket} = row;
        const urlExt = `/${VIEW}/entity_type/${entity_type_id}/eti/${entity.id}`
        navigate(urlExt);
    }

    const tabDatas = [
        {
            title: `Additions (${additions.length})`,
            getContent: () => <AdditionsView datas={additions} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        },
        {
            title: `Removals (${removals.length})`,
            getContent: () => <RemovalsView datas={removals} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        },
        {
            title: `Edits (${edits.length})`,
            getContent: () => <EditsView datas={edits} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
        }
    ]
    return (
        <Box sx={{ width: '100%' }}>
            <PageTitle title={`${entityInstanceType.name}s: Pending Tickets`} subtitle={parentName}/>
            <MyTabs tabDatas={tabDatas} marginYToContent={2}/>
        </Box>
      );
    // return (
    //     <Box>
    //         <PageTitle title={`${entityInstanceType.name}s: Pending Tickets`} subtitle={parentName}/>
    //         <Stack spacing={2}>
    //             <AdditionsView datas={additions} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //             <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
    //             <RemovalsView datas={removals} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //             <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
    //             <EditsView datas={edits} entityInstanceType={entityInstanceType} onRowClick={onRowClick}/>
    //         </Stack>
    //     </Box>
    // )
}

export default PendingEntityTicketsPage;