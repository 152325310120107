import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Tooltip, Typography } from "@mui/material";
import { ALL_PERMS } from "./perm_constants";
import React from "react";
import LoadButton from "../generic/LoadButton";
import { REQUEST_STATES } from "../../helpers/Constants";
import TicketTextField from "../inputs/TicketTextField";
import PermissionsSectionForm from "./PermissionSectionForm";

export const MAX_PERM_PROF_NAME_LEN = 50;

const GlobalProfileForm = ({profileName, setProfileName, sectionValues, setSectionValues, submitState, onSaveClick}) => {

    return (
        <Box>
            <Box marginY={2}>
                <TicketTextField
                    label={"Profile name"}
                    value={profileName}
                    setValue={setProfileName}
                    fullWidth
                    maxLen={MAX_PERM_PROF_NAME_LEN}
                    />
            </Box>
            <Stack direction={'row'}>

                <PermissionsSectionForm
                    title={"System"}
                    expectedPerms={ALL_PERMS.SYSTEM_ACTIONS}
                    values={sectionValues}
                    setValue={(key, value) => setSectionValues({...sectionValues, [key]: value})}
                    />
                <Stack>
                    <PermissionsSectionForm
                        title={"Organization"}
                        expectedPerms={ALL_PERMS.ORG_ACTIONS}
                        values={sectionValues}
                        setValue={(key, value) => setSectionValues({...sectionValues, [key]: value})}
                        />
                    <PermissionsSectionForm
                        title={"General"}
                        expectedPerms={ALL_PERMS.GENERAL_ACTIONS}
                        values={sectionValues}
                        setValue={(key, value) => setSectionValues({...sectionValues, [key]: value})}
                        />
                </Stack>
                <PermissionsSectionForm
                    title={"Entities"}
                    expectedPerms={ALL_PERMS.ENTITY_ACTIONS}
                    values={sectionValues}
                    setValue={(key, value) => setSectionValues({...sectionValues, [key]: value})}
                    />
                <PermissionsSectionForm
                    title={"Access"}
                    expectedPerms={ALL_PERMS.ACCESS_ACTIONS}
                    values={sectionValues}
                    setValue={(key, value) => setSectionValues({...sectionValues, [key]: value})}
                    />
            </Stack>
            <LoadButton onClick={onSaveClick} loading={submitState===REQUEST_STATES.SENDING} disabled={submitState===REQUEST_STATES.SENDING}>
                Save
            </LoadButton>
        </Box>
    )
}

export default GlobalProfileForm;