import React from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { SX_BOX_ICON_BTN, SX_TEXT_VERT_CENTER } from "../../../helpers/common_sx";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const UpDownArrows = ({onClick, tooltip}) => {
    return (
        <Tooltip title={tooltip} enterDelay={500}>
            <Stack spacing={-2}>
                <Box sx={SX_BOX_ICON_BTN}>
                    <IconButton onClick={() => onClick(true)}>
                        <KeyboardArrowUpIcon color='primary'/>
                    </IconButton>
                </Box>
                <Box sx={SX_BOX_ICON_BTN}>
                    <IconButton onClick={() => onClick(false)}>
                        <KeyboardArrowDownIcon color='primary'/>
                    </IconButton>
                </Box>
            </Stack>
        </Tooltip>
    )
}

export const ConfigPanelHeader = ({title, onAddClick, onMoveClick, onDeleteClick, moveTooltip}) => {
    return (
        <Stack direction='row' sx={SX_TEXT_VERT_CENTER}>
            <UpDownArrows onClick={onMoveClick} tooltip={moveTooltip}/>
            <IconButton onClick={onAddClick}>
                <AddCircleIcon color='primary'/>
            </IconButton>
            <Typography align='center' variant='h6'>{title}</Typography>
            <IconButton onClick={onDeleteClick}>
                <DeleteIcon color='error'/>
            </IconButton>
        </Stack>
    )
}