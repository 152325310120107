import { useContext, useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Tooltip, Typography } from "../../../../../node_modules/@mui/material/index";
import TicketSelectField from "../../../inputs/TicketSelectField";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { CLIENT_CHOICE_ID_PREFIX, getFieldsDependentOnStaticChoiceValue, isPreExistingChoiceValue } from "../field_builder_helper";
import { getNextId, swapItemsAtIndices } from "../../../../helpers/CommonUtil";
import Context from "../../../../Store";
import { MESSAGE_LIST_SEP } from "../../../../helpers/Constants";

const NEW_STATIC_CHOICE_PREFIX = 'cx_'
const STATIC_CHOICE_EDIT_SHORT_NOTE = `
Note: Editing a choice will update its references (e.g. all tickets with the choice selected, other fields depending on the choice as a display condition).
Edit the choice if it is a typo fix, added details, etc.
Do not edit as a shortcut to delete & add a choice.
`
const STATIC_CHOICE_EDIT_NOTE_DETAILS = `Note: Editing a choice will update its references`

const getChoiceLabelErrorMessage = (choice, choices) => {
    const duplicateChoice = choices.find(c => (c !== choice) && (c.label === choice.label));
    if(!duplicateChoice){
        return "";
    }
    if(!duplicateChoice.is_deleted){
        return "Choice already exists";
    }
    else{
        let errMsg = "There is a deleted choice with the same label.";
        errMsg += " Use that instead, so that existing forms will recognize the choice as valid";
        return errMsg;
    }
}

export const getErrorMessagesForStaticChoices = (fieldStructure) => {
    const {choice_desc} = fieldStructure;
    const { choices} = choice_desc;

    const errs = [];
    if(choices.length === 0){
        errs.push("No choices were provided");
    }
    if(choices.some(c => c.label === '')){
        errs.push("Must complete or remove the blank fields");
    }

    let hasDup = false;
    for(const c1 of choices){
        for(const c2 of choices){
            if((c1 !== c2) && ((c1.value === c2.value) || (c1.label === c2.label))){
                hasDup = true;
                break;
            }
        }
        if(hasDup) break;
    }
    if(hasDup) errs.push("Cannot have 2 identical choices");
    return errs;
}

export const StaticChoicePanelContent = ({fieldStructure, sectionStructure, setChoiceDesc}) => {
    //deleted choices should be kept at the end of the choices array
    //new paradign - order doesnt matter. dont rely on indices

    const {alertError} = useContext(Context);
    const {choice_desc} = fieldStructure;
    const { choices} = choice_desc;
    const activeChoices = choices.filter(c => !c.is_deleted);
    const deletedChoices = choices.filter(c => c.is_deleted);

    const setChoices = (newChoices) => {
        setChoiceDesc({...choice_desc, choices: newChoices})
    }

    const indexOfChoiceWith = (value) => choices.findIndex(c => c.value === value);

    const setChoice = (value, label) => {
        const idx = indexOfChoiceWith(value)
        const choicesCopy = [...choices];
        choicesCopy[idx].label = label// = {value: label, label: label};
        setChoices(choicesCopy);
    }

    const onRemoveChoiceClick = (value) => {
        const idx = indexOfChoiceWith(value)
        const choice = choices[idx]

        const fieldsDependingOnChoice = getFieldsDependentOnStaticChoiceValue(sectionStructure, fieldStructure.id, value);
        if(fieldsDependingOnChoice.length > 0){
            const errMsg = 'The following fields depend on this choice:' + MESSAGE_LIST_SEP + fieldsDependingOnChoice.map(f => f.label).join(MESSAGE_LIST_SEP);
            alertError(errMsg);
            return;
        }

        choice.is_deleted = true;

        //only stash a prexisting choice
        if(!isPreExistingChoiceValue(value)){
            choices.splice(idx, 1);
        }
        setChoices([...choices])
    }

    const onAddChoiceClick = () => {
        const choiceIdNum = getNextId();
        const choicesCopy = [...choices, {value: `${CLIENT_CHOICE_ID_PREFIX}${choiceIdNum}`, label: ""}];
        setChoices(choicesCopy);
    }

    const moveChoice = (value, isUp) => {
        let idx1 = indexOfChoiceWith(value);
        const dir = isUp ? -1 : 1;
        const n = choices.length;

        let idx2 = (idx1 + dir + n) % n;
        while((idx2 !== idx1) && choices[idx2].is_deleted){
            idx2 = (idx2 + dir + n) % n;
        }

        const choicesCopy = [...choices];
        swapItemsAtIndices(choicesCopy, idx1, idx2);
        setChoices(choicesCopy);
    }

    const addBackDeletedChoice = (choiceValue) => {
        const choicesCopy = [...choices];
        const choice = choices.find(c => c.value === choiceValue);
        choice.is_deleted = false;
        setChoices(choicesCopy); 
    }

    const canAddChoice = (activeChoices.length === 0) || (!activeChoices.some(c => (c.label === "")));

    return (
        <Stack>
            <TicketSelectField
                label={'Add back deleted choice (that has been saved)'}
                choices={deletedChoices.map(c => ({...c, is_deleted: false}))}
                doNotAutoSelect={true}
                value={""}
                setValue={addBackDeletedChoice}
                />

            <Typography marginY={1}>{STATIC_CHOICE_EDIT_SHORT_NOTE}</Typography>
            {
                activeChoices.map((choice) => {
                    const errMsg = getChoiceLabelErrorMessage(choice, choices)
                    return (
                        <Stack key={choice.value} direction='row' spacing={1}>
                            <Stack direction='row' alignItems="center">
                                <IconButton onClick={() => onRemoveChoiceClick(choice.value)}>
                                    <RemoveCircleIcon color='error'/>
                                </IconButton>
                                <Stack spacing={-2}>
                                    <IconButton onClick={() => moveChoice(choice.value, true)}>
                                        <KeyboardArrowUpIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={() => moveChoice(choice.value, false)}>
                                        <KeyboardArrowDownIcon color='primary'/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <TextField
                                key={choice.value}
                                variant="outlined"
                                label={""}
                                value={choice.label}
                                onChange={(e) => setChoice(choice.value, e.target.value)}
                                helperText={errMsg}
                                error={!!errMsg}
                                autoComplete="off"
                                fullWidth={true}
                                multiline
                                // maxRows={5}
                                // minRows={2}
                                />
                        </Stack>
                    )
                })
            }
            <Box justifyContent='center' display='flex'>
                <IconButton onClick={onAddChoiceClick} disabled={!canAddChoice}>
                    <AddCircleIcon color={canAddChoice ? 'primary' : undefined}/>
                </IconButton>
            </Box>
        </Stack>
    )
}

