import React from "react";
import { Box, Stack, Typography } from "../../../node_modules/@mui/material/index";
import { useData } from "../../helpers/CustomHooks"
import { EXTENSIONS } from "../../helpers/requests"
import LoadingMessage from "../generic/LoadingMessage";
import Context from "../../Store";
import { getInstanceType } from "../../helpers/StaticDataHelper";
import AbstractTable from "../tables/AbstractTable";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import PageTitle from "../generic/PageTitle";


const DupNamesSection = ({instanceTypeId, entities}) => {
    const {staticData} = React.useContext(Context);
    const instanceType = getInstanceType(staticData, instanceTypeId);
    if(entities.length === 0) {
        return <LabelValueDisplay label={instanceType.name} value={"No duplicates"}/>
    }
    const isMember = !!instanceType.parent_type;

    const columnNames = ['Name', 'id', 'Is Active'];
    const parentColNames = ['Parent', 'Parent id', 'Is parent active']
    if(isMember) columnNames.push(...parentColNames);

    const renderRowCells = (e) => {
        const output = [
            e.name,
            e.id,
            e.is_active ? "Yes" : 'No',
        ]

        if(isMember){
            output.push(e.parent.name);
            output.push(e.parent.id);
            output.push(e.parent.is_active ? "Yes" : 'No');
        }
        return output;
    }
    return (
        <Box>
            <Typography>{instanceType.name}</Typography>
            <AbstractTable
                rows={entities}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowToKey={(e) => e.id}
                />
        </Box>
    )
}
const DupNamesPage = () => {

    const dupNamesData = useData(EXTENSIONS.GET_DUP_NAMES, {});

    if(dupNamesData === false) return <div>Failed to load</div>;
    if(dupNamesData === null) return <LoadingMessage/>;

    const acc = dupNamesData;
    const instanceTypeIds = Object.keys(acc);

    return (
        <Box>
            <PageTitle title={"Duplicate Names"}/>
            {
                instanceTypeIds.map(instanceTypeId => {

                    return (
                        <DupNamesSection
                            key={instanceTypeId}
                            instanceTypeId={instanceTypeId}
                            entities={acc[instanceTypeId]}
                            />

                    ) 
                })
            }
        </Box>
    )
}

export default DupNamesPage;