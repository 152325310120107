import { Stack, Typography } from "@mui/material";


const LabelValueDisplay = ({label, value, fontSize}) => {
    if(fontSize == undefined) fontSize = 18
    return (
        <Stack direction={'row'} spacing={1}>
            <Typography variant="subtitle2" fontSize={fontSize} alignContent={'center'}>{label}:</Typography>
            <Typography variant="subtitle1" fontSize={fontSize} alignContent={'center'}>{value}</Typography>
        </Stack>
    )
}

export default LabelValueDisplay;