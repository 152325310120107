import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import TicketSelectField from "../inputs/TicketSelectField";
import { EXTENSIONS, post } from "../../helpers/requests";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import LoadButton from "../generic/LoadButton";
import TicketCheckbox from "../inputs/TicketCheckbox";
import TicketTextField from "../inputs/TicketTextField";
import SectionForm from "../form/SectionForm";

const EMP_FIRST_NAME_MAX_LEN = 50;
const EMP_LAST_NAME_MAX_LEN = 50;
const EMP_EMAIL_MAX_LEN = 100;

export const getEmpFormErrorMsg = (empForm) => {
    const {first_name, last_name, email, extra_data} = empForm;
    console.log('emp form', empForm)
    if(!first_name || !last_name || !email) return 'Must provide first name, last name, and email';
    if(first_name.length > EMP_FIRST_NAME_MAX_LEN) return 'First name is too long';
    if(last_name.length > EMP_FIRST_NAME_MAX_LEN) return 'Last name is too long';
    if(email.length > EMP_EMAIL_MAX_LEN) return 'Email is too long';

    return null;
}

const EmpForm = ({emp, setEmp, empStructure, submitState, onSubmitClick}) => {

    const setExtraData = (sectionValues) => {
        setEmp({...emp, extra_data: sectionValues});
    }

    return (
        <Box>
            {/* <Typography justifyContent={'center'}>Add Employee</Typography> */}
            {/* <Stack direction={'row'}>
                <Typography justifyContent={'center'}>Add Employee</Typography>
            </Stack> */}
            <Stack spacing={2} marginY={2}>
                <TicketTextField
                    label={"First name"}
                    value={emp.first_name}
                    setValue={(x) => setEmp({...emp, first_name: x})}
                    fullWidth
                    maxLen={EMP_FIRST_NAME_MAX_LEN}
                    />
                <TicketTextField
                    label={"Last name"}
                    value={emp.last_name}
                    setValue={(x) => setEmp({...emp, last_name: x})}
                    fullWidth
                    maxLen={EMP_LAST_NAME_MAX_LEN}
                    />
                <TicketTextField
                    label={"Email"}
                    value={emp.email}
                    setValue={(x) => setEmp({...emp, email: x})}
                    fullWidth
                    maxLen={EMP_EMAIL_MAX_LEN}
                    />
                <SectionForm
                    sectionStructure={empStructure}
                    sectionValues={emp.extra_data}
                    setSectionValues={setExtraData}
                    isEditable={true}
                    />
            </Stack>
            <LoadButton onClick={onSubmitClick} loading={submitState===REQUEST_STATES.SENDING} disabled={submitState===REQUEST_STATES.SENDING}>
                Submit
            </LoadButton>
        </Box>
    )
}

export default EmpForm