import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

const areAllValuesChecked = (expectedPerms, values) => {
    for(const p of Object.keys(expectedPerms)){
        const key = expectedPerms[p].key;
        const value = !!values[key];
        if(!value) return false;
    }

    return true;
}

const PermissionsSectionForm = ({title, titleTooltip, expectedPerms, values, setValue, setAllValues}) => {
    const areAllChecked = areAllValuesChecked(expectedPerms, values);


    const onSectionCheckboxChange = () => {
        setAllValues(!areAllChecked);
    }
    return (
        <Box border={1} p={2}>
            <FormGroup>
                <Stack direction='row'>
                    {
                        !setAllValues ? null :
                        <Checkbox
                            name={title}
                            id={title}
                            checked={areAllChecked}
                            onChange={onSectionCheckboxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color={'primary'}
                            sx={{p:.25}}
                            />
                    }
                    <Tooltip title={<Typography>{titleTooltip}</Typography>} placement="top">
                        <Typography>{title}</Typography>
                    </Tooltip>
                </Stack>
                <Stack marginLeft={3}>
                {
                    Object.keys(expectedPerms).map(p => {
                        const {key, label, desc} = expectedPerms[p];
                        const value = !!values[key]
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={key}
                                        id={key}
                                        checked={value}
                                        onChange={(e) => setValue(key, !value)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color={'primary'}
                                        sx={{p:.25}}
                                        />
                                }
                                label={
                                    <Tooltip title={<Typography>{desc}</Typography>} key={key} placement="right" enterDelay={600}>
                                        <Typography>{label}</Typography>
                                    </Tooltip>
                                }
                                labelPlacement={"end"}
                                key={key}
                                    />
                        )
                })}
                </Stack>
            </FormGroup>
        </Box>
    )
}

export default PermissionsSectionForm;