

export const PERM_SELECTABLE_VIEWS = {
    USERS: 'users',
    PROFILES: 'profiles',
}
export const PERM_VIEWS = {
    ...PERM_SELECTABLE_VIEWS,
    EDIT_GLOBAL_PROFILE: 'edit_global_profile',
    ADD_GLOBAL_PROFILE: 'add_global_profile',
    EDIT_ENTITY_ROLE_PROFILE: 'edit_entity_role_prof',
    EDIT_ORG_ROLES_PROFILE: 'edit_org_roles_prof',
}
const makeKeyLabelDesc = (key, label, desc) => {
    if (label === undefined) label = key;
    if (desc === undefined) desc = label;
    return {
        key, label, desc
    }
}


const VIEW_PAPER_TRAIL_DESC = `
View all tickets and actions taken. If a user does not have permission to view an access instance, this will not grant permission to view the access or its paper trail
`
const ENTITY_REQ_EDIT_DESC = `
All edits are overrides to some degree. This gives permission to performs edits, where fields that are marked as 'protected edit' will still go through their approval processes.
The fields not marked as 'protected edit'will be immediately edited (override) with this permission.
`
const ENTITY_OVERRIDE_EDIT_DESC = `
Permission to edit an entity without going through an approval process. This includes all fields, even those marked with 'protected edit' can be immediately edited.
This also grants permission to edit an entity's name and pending ticket values.
`
export const ALL_PERMS = {

    SYSTEM_ACTIONS: {
        VIEW_PERMS: makeKeyLabelDesc("s_view_perms", "View permissions"),
        EDIT_PERMS: makeKeyLabelDesc("s_edit_perms", "Edit permissions"),
        VIEW_CONFIG: makeKeyLabelDesc("s_view_configs", "View configurations", "Not applicable yet. View the systems configurations such as form structures and approval processes."),
        EDIT_CONFIG: makeKeyLabelDesc("s_edit_configs", "Edit Configurations", "Not applicable yet. Edit the systems configurations such as form structures and approval processes."),
        VIEW_TABLES: makeKeyLabelDesc("s_view_tables", "View tables", "View the tables managed within the system."),
        EDIT_TABLES: makeKeyLabelDesc("s_edit_tables", "Edit tables", "Manage the tables within the system. Includes creating, editing, renaming, and deleting tables."),
    },
    
    ORG_ACTIONS: {
        VIEW_ORG: makeKeyLabelDesc("o_view_org", "View organization", "View employees, roles, and departments."),
        EDIT_ORG: makeKeyLabelDesc("o_edit_org", "Edit organization", "Add, remove, and edit employees/users, roles, and departments"),
        VIEW_OFFBOARDING_STATUS: makeKeyLabelDesc("o_view_ob_stat", "View employee report", "View a report of an employee's relevance in the system (e.g. offboarding blockers, access, roles)"),
    },
    
    GENERAL_ACTIONS: {
        VIEW_SECRETS: makeKeyLabelDesc("g_view_secrets", "View secrets", "View field values in form that are marked as secret in the form structure/configuration"),
        VIEW_PAPER_TRAILS: makeKeyLabelDesc("g_paper_trail", "View paper trails", VIEW_PAPER_TRAIL_DESC),
    },
    
    ENTITY_ACTIONS: {
        REQ_ADD: makeKeyLabelDesc("e_req_add", "Request adding", "Permission to request a new entity"),
        REQ_REMOVAL: makeKeyLabelDesc("e_req_rem", "Request removal"),
        REQ_EDIT: makeKeyLabelDesc("e_req_edit", "Edit", ENTITY_REQ_EDIT_DESC),
        OVERRIDE_ADD: makeKeyLabelDesc("e_ovrd_add", "Override adding", "Permission to add an entity without going through the approval process"),
        OVERRIDE_REMOVAL: makeKeyLabelDesc("e_ovrd_rem", "Override removal", "Permission to remove an entity without going through the approval process"),
        OVERRIDE_EDIT: makeKeyLabelDesc("e_ovrd_edit", "Override edit", ENTITY_OVERRIDE_EDIT_DESC),
        CANCEL_REQ: makeKeyLabelDesc("e_cancel", "Cancel request", "Permission to cancel a pending request to add, remove, or edit an entity"),
        BULK_IMPORT: makeKeyLabelDesc("e_bulk_import", "Bulk imports", "Permission to bulk import (add) entities with going through an approval process"),
        MANAGE_ACCESS_LVLS: makeKeyLabelDesc("e_mng_lvls", "Manage access levels"),
    },
    
    ACCESS_ACTIONS: {
        VIEW: makeKeyLabelDesc("a_view", "View Access", "Permission to view who has access to what"),
        REQ_GRANT: makeKeyLabelDesc("a_req_grant", "Request grant"),
        REQ_REMOVE: makeKeyLabelDesc("a_req_rem", "Request removal"),
        REQ_EDIT: makeKeyLabelDesc("a_req_edit", "Edit"),
        OVERRIDE_GRANT: makeKeyLabelDesc("a_ovrd_grant", "Override grant"),
        OVERRIDE_REMOVE: makeKeyLabelDesc("a_ovrd_rem", "Override removal"),
        OVERRIDE_EDIT: makeKeyLabelDesc("a_ovrd_edit", "Override edit"),
        CANCEL_REQ: makeKeyLabelDesc("a_cancel", "Cancel requests"),
        BULK_IMPORT: makeKeyLabelDesc("a_bulk_import", "Bulk imports"),
    },
}