import React from "react";
import { Box } from "../../../node_modules/@mui/material/index";
import { getActiveUserChoices } from "../../helpers/StaticDataHelper";
import MultiSectionForm from "../form/MultiSectionForm";
import MySingleSelectFIeld from "../non_form_fields/MySingleSelectField";
import EntityNameTextField from "../pages/EntityNameTextField";
import Context from "../../Store";
import { CONTEXT_DEPENDENCIES } from "../../helpers/TicketFormHelper";


const EntityForm = ({entityType, isRoot, parentChoices, parentId, setParentId, name, setName, formValues, setFormValues, isInitialSubmission, editableSectionIds}) => {
    const {staticData} = React.useContext(Context);

    const entityInstanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;

    const contextValues = {
        [CONTEXT_DEPENDENCIES.EMP_ID_ON_ENTITY]: name
    }
    return (
            <Box margin={'auto'} maxWidth={700}>
            {
                isRoot ? null :
                <MySingleSelectFIeld
                    label={`Root ${entityType.name}`}
                    value={parentId}
                    setValue={setParentId}
                    choices={parentChoices}
                    fullWidth={true}
                    />
            }
            <Box marginY={1}/>
            <EntityNameTextField
                text={name}
                setText={setName}
                entityTypeId={entityType.id}
                parentId={parentId ? parentId : null}
                /> 
            <MultiSectionForm
                structure={entityInstanceType.structure}
                formData={formValues}
                setFormData={setFormValues}
                isInitialSubmission={isInitialSubmission}
                contextValues={contextValues}
                editableSectionIds={editableSectionIds}
                />
            </Box>
    )
}

export default EntityForm;