import { useContext, useState } from "react"
import { Stack } from "@mui/material"
import TicketSelectField from "../inputs/TicketSelectField"
import ConfirmActionDialog from "../generic/ConfirmActionDialog";
import { Tooltip } from "@mui/material";
import Context from "../../Store";
import { EXTENSIONS, post } from "../../helpers/requests";
import YesNoSelectField from "../inputs/YesNoSelectField";
import TicketTextField from "../inputs/TicketTextField";
import { MAX_ENTITY_TYPE_NAME_LEN } from "../../helpers/Constants";
import { Typography } from "../../../node_modules/@mui/material/index";



const getErrorMessage = (entityInstanceTypes, isMemberType, name, parentTypeId) => {
    if(isMemberType === ''){
        return "Must complete the fields";
    }
    if(isMemberType){
        if(!parentTypeId) return "Provide a parent type for this member type";
        if(entityInstanceTypes.some(eit => eit.parent_type && (eit.parent_type.id === parentTypeId))){
            return "A member type already exists for the selected parent type";
        }
    }
    else{
        if(!name || name.length > MAX_ENTITY_TYPE_NAME_LEN){
            return `Name must be between 1 and ${MAX_ENTITY_TYPE_NAME_LEN} characters`
        }
    }
    return null;
}

export const CreateEntityInstanceTypeDialog = ({entityInstanceTypes, close, onCreated}) => {
    const {alertError, alertSuccess} = useContext(Context);
    
    const [isMemberType, setIsMemberType] = useState("");
    const [name, setName] = useState("");
    const [parentTypeId, setParentTypeId] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const parentTypeChoices = entityInstanceTypes.filter(eit => !eit.parent_type).map(eit => ({value: eit.id, label: eit.name}));

    const onCreateClick = () => {
        const errMsg = getErrorMessage(entityInstanceTypes, isMemberType, name, parentTypeId);
        if(errMsg){
            alertError(errMsg);
            return;
        }

        const onSuccess = () => {
            setIsCreating(false);
            alertSuccess('Created');
            onCreated();
            close();
        }
        
        const onFail = (err) => {
            setIsCreating(false);
            alertError(err.response.data);
        }

        setIsCreating(true);
        const body = {
            name: isMemberType ? null : name,
            parent_type_id: isMemberType ? parentTypeId : null
        }
        post(EXTENSIONS.CREATE_EMPTY_ENTITY_INSTNACE_TYPE, body, onSuccess, onFail)
    }

    const Content = (
        <Stack spacing={2}>
            <Typography>
                Note that creating an Entity Type will not immediately make it visible for everyone. When you are done configuring the form and approval processes,
                you will need to activate it in order for tickets of this type to be submitted.
            </Typography>
            <YesNoSelectField
                label={"Is this a member type of another entity (e.g. a vendor's personnel"}
                value={isMemberType}
                setValue={setIsMemberType}
                fullWidth={true}
                />
            {
                (isMemberType === true) &&
                <TicketSelectField
                    label={"Parent type"}
                    value={parentTypeId}
                    setValue={setParentTypeId}
                    choices={parentTypeChoices}
                    fullWidth={true}
                    />
            }
            {
                (isMemberType === false) &&
                <TicketTextField
                    label={"Entity type name (e.g. Software, Vendor)"}
                    value={name}
                    setValue={setName}
                    maxLen={MAX_ENTITY_TYPE_NAME_LEN}
                    fullWidth={true}
                    />
            }
        </Stack>
    )

    return (
        <ConfirmActionDialog
            title={"Create Entity Type"}
            body={Content}
            handleCancel={close}
            handleConfirm={onCreateClick}
            cancelText={"Cancel"}
            confirmText={'Create'}
            loading={isCreating}
            disabled={isCreating}
            fullWidth={true}
            // maxWidth={}
            />
    )
}