import React, { useState } from "react";
import Context from "../../Store";
import TicketSelectField from "../inputs/TicketSelectField";
import { Box, Button, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import { EXTENSIONS, post } from "../../helpers/requests";
import { AddColumnPanel, valueTypeToFieldStructure } from "./AddColumnPanel";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Divider, Typography } from "../../../node_modules/@mui/material/index";
import LoadButton from "../generic/LoadButton";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import { isPreExistingField } from "../configs/form/field_builder_helper";

export const ManagedTableStructureWidget = ({tableId, structure, setStructure, editedRows, setEditedRows}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);

    const [columnIdToRemove, setColumnIdToRemove] = useState('');
    const [isValidatingColumnDeletion, setIsValidatingColumnDeletion] = React.useState(false);
    const [isNewColumnPanelOpen, setIsNewColumnPanelOpen] = useState(false);

    const addNewColumn = (label, valueType, defaultValue) => {
        const newField = valueTypeToFieldStructure(staticData, label, valueType);
        const newStructure = makeNestedDeepCopy(structure);
        newStructure.fields.push(newField);

        const newRows = editedRows.map(r => ({...r, [newField.id]: defaultValue}))
        
        setEditedRows(newRows);
        setStructure(newStructure);
    }

    const onRemoveColumnClick = () => {
        //TODO: need to check if the column is
        const columnId = columnIdToRemove; //side caching in case it changes before response
        if(isValidatingColumnDeletion) return;
        if(!columnId) return;

        setIsValidatingColumnDeletion(true);
        // const field = table.structure.fields.find(f => f.id === columnId);
        const onSuccess = () => {
            setIsValidatingColumnDeletion(false);
            setColumnIdToRemove("")
            const newStructure = {...structure, fields: structure.fields.filter(f => f.id !== columnId)};
            const newRows = editedRows.map(r => {
                const row = {...r};
                delete row[columnId];
                return row;
            })

            setStructure(newStructure);
            setEditedRows(newRows);
        }

        const onFail = (err) => {
            setIsValidatingColumnDeletion(false);
            alertError(err.response.data);
        }
        
        if(isPreExistingField(columnId)){
            post(EXTENSIONS.CAN_DELETE_TABLE_COLUMN, {table_id: tableId, column_field_id: columnId}, onSuccess, onFail)
        }
        else{
            onSuccess()
        }
    }

    return (
        <Stack sx={SX_BOX_SIMPLE} direction='row' spacing={1} alignItems="center">
            { !isNewColumnPanelOpen ? null : <AddColumnPanel close={()=>setIsNewColumnPanelOpen(false)} apply={addNewColumn} requireDefaultValue={!!editedRows.length}/> }
            <Button onClick={()=>setIsNewColumnPanelOpen(true)} variant='contained'>
                Add Column
            </Button>
            <Divider orientation="vertical" flexItem sx={{ bgcolor: "#000000"}}/>
            <Box minWidth={250}>
                <TicketSelectField
                    label="Column to remove"
                    value={columnIdToRemove}
                    setValue={setColumnIdToRemove}
                    choices={structure.fields.map(f => ({value: f.id, label: f.label}))}
                    doNotAutoSelect={true}
                    fullWidth={true}
                    />
            </Box>
                
            <LoadButton
                loading={isValidatingColumnDeletion}
                disabled={isValidatingColumnDeletion || !columnIdToRemove}
                color={'error'}
                onClick={onRemoveColumnClick}
                >
                    Remove COlumn
            </LoadButton>
        </Stack>
    )

}