import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TicketTextField from "../inputs/TicketTextField";
import { MAX_LABEL_LEN } from "../configs/form/field_builder_helper";
import ConfirmActionDialog from "../generic/ConfirmActionDialog";
import React, { useState } from "react";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { UpDownArrows } from "../generic/UpDownArrows";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context from "../../Store";
import { getNextId, swapItemsAtIndices } from "../../helpers/CommonUtil";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import LoadButton from "../generic/LoadButton";
import { MyDivider } from "../generic/MyDivider";

const INDENT = 4
const CLIENT_FIELD_ID_PREFIX = 'fx_';

const MyMenuItem = ({label, onEditClick, onDeleteClick}) => {
    return (
        <Stack direction='row' alignItems='center'>
            <Tooltip title='Edit'>
                <IconButton onClick={onEditClick}>
                    <EditIcon color='primary'/>
                </IconButton>
            </Tooltip>
            <Typography>{label}</Typography>
        </Stack>
    )
}

export const HRISStructures = ({company, refetchCompany}) => {
    // console.log('company', company);

    const [isEmployeeStructureSelected, setIsEmployeeStructureSelected] = useState(false);
    const [isRoleStructureSelected, setIsRoleStructureSelected] = useState(false);
    const [selectedDepartmentTypeId, setSelectedDepartmentTypeId] = useState(null);

    const closeDialogs = () => {
        setIsEmployeeStructureSelected(false);
        setIsRoleStructureSelected(false);
        setSelectedDepartmentTypeId(null)
    }

    if(isEmployeeStructureSelected){
        const idsAndLabels = company.employee_extra_data_structure.fields.map(f => ({id: f.id, label: f.label}))
        return (
            <TextFieldsEditorDialog
                title={"Set Employee Fields"}
                initialIdsAndLabels={idsAndLabels} refetchCompany={refetchCompany}
                close={closeDialogs} postEndPoint={EXTENSIONS.UPDATE_EMPLOYEE_STRUCTURE}/>
        )
    }
    if(isRoleStructureSelected){
        const idsAndLabels = company.employee_role_extra_data_structure.fields.map(f => ({id: f.id, label: f.label}))
        return (
            <TextFieldsEditorDialog
                title={"Set Employee Fields"}
                initialIdsAndLabels={idsAndLabels} refetchCompany={refetchCompany}
                close={closeDialogs} postEndPoint={EXTENSIONS.UPDATE_EMPLOYEE_ROLE_STRUCTURE}/>
        )
    }
    if(selectedDepartmentTypeId){
        return (
            <DepartmentTypeEditorDialog
                departmentType={company.department_types.find(dt => dt.id === selectedDepartmentTypeId)}
                refetchCompany={refetchCompany}
                close={closeDialogs}/>
        ) 
    }

    return (
        <Stack>

            <Typography align='center' variant='h4'>HRIS Configurations</Typography>

            <Typography variant='h6' fontSize={18}>Set available fields for</Typography>
            <Stack alignItems='flex-start' marginLeft={INDENT}>
                <MyMenuItem label={'Employee'} onEditClick={() => setIsEmployeeStructureSelected(true)}/>
                <MyMenuItem label={'Employee Role'} onEditClick={() => setIsRoleStructureSelected(true)}/>
            </Stack>

            <MyDivider/>
            
            <Typography fontSize={18} variant='h6'>Department Types</Typography>
            <Stack alignItems='flex-start' marginLeft={INDENT}>
                {
                    company.department_types.map(deptType => {
                        return(
                            <MyMenuItem key={deptType.id} label={deptType.name} onEditClick={() => setSelectedDepartmentTypeId(deptType.id)}/>
                        )
                    })
                }
                <Box marginY={2} sx={SX_BOX_SIMPLE}>
                    <CreateDepartmentTypeWidget onCreated={refetchCompany}/>
                </Box>
            </Stack>

            <MyDivider/>

        </Stack>
    )
}


const TextFieldsEditor = ({idsAndLabels, setIdsAndLabels}) => {

    const setFieldLabel = (fieldId, newLabel) => {
        const copy = [...idsAndLabels];
        const idAndLabel = copy.find(x => x.id === fieldId);
        idAndLabel.label = newLabel;
        setIdsAndLabels(copy)
    }
    
    const onRemoveFieldClick = (fieldId) => {
        setIdsAndLabels(idsAndLabels.filter(x => x.id !== fieldId))
    }
    
    const onAddFieldClick = () => {
        const copy = [...idsAndLabels];
        const id =  `${CLIENT_FIELD_ID_PREFIX}${getNextId()}`
        copy.push({id: id, label: "New field"})
        setIdsAndLabels(copy)
    }

    const onMoveFieldClick = (fieldId, isUp) => {
        const copy = [...idsAndLabels];
        const currIdx = copy.findIndex(x => x.id === fieldId);
        const N = copy.length;
        const offset = isUp ? -1 : 1;
        const targetIdx = (currIdx + offset + N) % N;
        swapItemsAtIndices(copy, currIdx, targetIdx);
        setIdsAndLabels(copy);
    }

    return (
        <Stack spacing={2}>
            {
                idsAndLabels.map(f => {

                    return (
                        <Stack key={f.id} direction='row' spacing={2}>
                            <UpDownArrows
                                tooltip={'Move field position up or down'}
                                onClick={(isUp) => onMoveFieldClick(f.id, isUp)}
                                />
                            <IconButton onClick={() => onRemoveFieldClick(f.id)}>
                                <DeleteForeverIcon color='error'/>
                            </IconButton>
                            <TicketTextField
                                key={f.id}
                                label={'Field label'}
                                value={f.label}
                                setValue={(x) => setFieldLabel(f.id, x)}
                                fullWidth={true}
                                maxLen={MAX_LABEL_LEN}
                                />
                        </Stack>    
                    )
                })
            }
            <Tooltip title='Add field'>
                <IconButton onClick={onAddFieldClick}>
                    <AddCircleIcon color='primary'/>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

const TextFieldsEditorDialog = ({title, initialIdsAndLabels, refetchCompany, close, postEndPoint}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);

    const [idsAndLabels, setIdsAndLabels] = useState(initialIdsAndLabels);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSaveClick = () => {
        const onSuccess = () => {
            alertSuccess('Updated');
            setIsSubmitting(false);
            refetchCompany();
            close();
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }
        post(postEndPoint, {ids_and_labels: idsAndLabels}, onSuccess, onFail);
        setIsSubmitting(true);
    }

    return (
        <ConfirmActionDialog
            title={title}
            body={<TextFieldsEditor idsAndLabels={idsAndLabels} setIdsAndLabels={setIdsAndLabels}/>}
            handleCancel={close}
            handleConfirm={onSaveClick}
            cancelText={'Cancel'}
            confirmText={'Save'}
            loading={isSubmitting}
            disabled={isSubmitting}
            fullWidth={true}
            // maxWidth={}
            />
    )
}

const DepartmentTypeEditorDialog = ({departmentType, refetchCompany, close}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    
    const initialIdsAndLabels = departmentType.extra_data_structure.fields.map(f => ({id: f.id, label: f.label}))
    const [idsAndLabels, setIdsAndLabels] = useState(initialIdsAndLabels);
    const [name, setName] = useState(departmentType.name);
    const [managerTitle, setManagerTitle] = useState(departmentType.manager_title);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSaveClick = () => {
        const onSuccess = () => {
            alertSuccess('Updated');
            setIsSubmitting(false);
            refetchCompany();
            close();
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }
        const body = {dept_type_id: departmentType.id, name: name, manager_title: managerTitle, ids_and_labels: idsAndLabels}
        post(EXTENSIONS.UPDATE_DEPARTMENT_TYPE, body, onSuccess, onFail);
        setIsSubmitting(true);
    }

    const Content = (
        <Stack spacing={2}>
            <TicketTextField
                label='Department type name'
                value={name}
                setValue={setName}
                fullWidth={true}
                />
            <TicketTextField
                label='Manager title'
                value={managerTitle}
                setValue={setManagerTitle}
                fullWidth={true}
                helper_text={'For example: "Manager", "Captain", "Leader", "Advisor", "Supervisior", etc.'}
                />
            <Box sx={SX_BOX_SIMPLE}>
                <Typography variant='h6'>Fields:</Typography>
                <TextFieldsEditor idsAndLabels={idsAndLabels} setIdsAndLabels={setIdsAndLabels}/>
            </Box>
        </Stack>
    )
    return (
        <ConfirmActionDialog
            title={"Edit department type"}
            body={Content}
            handleCancel={close}
            handleConfirm={onSaveClick}
            cancelText={'Cancel'}
            confirmText={'Save'}
            loading={isSubmitting}
            disabled={isSubmitting}
            fullWidth={true}
            // maxWidth={}
            />
    )
}

const CreateDepartmentTypeWidget = ({onCreated}) => {
    const {alertSuccess, alertError } = React.useContext(Context);

    const [name, setName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onCreateClick = () => {
        if(!name){
            alertError("Provide a name for the department type");
            return;
        }
        const onSuccess = (departmentTypeId) => {
            setIsSubmitting(false);
            alertSuccess('Updated');
            onCreated();
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }
        post(EXTENSIONS.CREATE_DEPARTMENT_TYPE, {dept_type_name: name}, onSuccess, onFail)
        setIsSubmitting(true);
    }
    return (
        <Stack direction='row' spacing={2} width={500} alignItems='center'>
            <TicketTextField
                label='Department type name'
                value={name}
                setValue={setName}
                fullWidth={true}
                maxLen={MAX_LABEL_LEN}
                />
            <LoadButton loading={isSubmitting} disabled={isSubmitting} onClick={onCreateClick}>
                Create
            </LoadButton>
        </Stack>
    )
}