import { ACCESS_STRUCTURE_IDS } from "../../../helpers/Constants";
import { makeNestedDeepCopy } from "../../../helpers/TicketFormHelper";
import { CHOICE_DESC_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../../inputs/FieldProps";

export const MAX_LABEL_LEN = 75;
export const CLIENT_FIELD_ID_PREFIX = 'fx_';
export const CLIENT_SECTION_ID_PREFIX = 'sx_';
export const CLIENT_CHOICE_ID_PREFIX = 'cx_';

export const getValueTypeLabel = (valueType) => {
    switch(valueType){
        case FIELD_VALUE_TYPES.STRING:
            return 'Text or multiple choice';
        case FIELD_VALUE_TYPES.BOOL:
            return 'Binary (Yes / No)';
        case FIELD_VALUE_TYPES.FLOAT:
            return 'Number';
        case FIELD_VALUE_TYPES.FILE:
            return 'File';
        case FIELD_VALUE_TYPES.EMPLOYEE:
            return 'User';
        case FIELD_VALUE_TYPES.DEPARTMENT:
            return 'Department';
        default:
            return valueType
    }
}

export const VALUE_TYPE_CHOICES = [
    FIELD_VALUE_TYPES.STRING,
    FIELD_VALUE_TYPES.BOOL,
    // FIELD_VALUE_TYPES.FLOAT,
    FIELD_VALUE_TYPES.FILE,
    FIELD_VALUE_TYPES.EMPLOYEE,
    FIELD_VALUE_TYPES.DEPARTMENT,
    // {value: 'Multiple Choice', label: 'Multiple Choice'},
].map(valueType => {
    return {
        value: valueType,
        label: getValueTypeLabel(valueType)
    }
})

export const getValueTypeChoices = (fieldId) => {
    if(fieldId === ACCESS_STRUCTURE_IDS.ACCESS_LVL_FIELD_ID){
        return [{value: FIELD_VALUE_TYPES.ACCESS_LEVEL, label: 'Access level'}]
    }

    return VALUE_TYPE_CHOICES;
}

// SELECT
// MULTISELECT
// MULTICHECK
// YES_NO_SELECT
// SHORT_TEXT
// LONG_TEXT
// MULTI_TEXT
// CHECKBOX
// FLOAT
// DATEPICKER
// FILEPICKER

export const COMPONENT_CHOICES_PER_VALUE_TYPE = {
    [FIELD_VALUE_TYPES.FILE]: [
        {value: FIELD_COMPONENT_TYPES.FILEPICKER, label: 'File Picker'}
    ],
    [FIELD_VALUE_TYPES.STRING]: [
        {value: FIELD_COMPONENT_TYPES.SELECT, label: 'Dropdown (select single)'},
        {value: FIELD_COMPONENT_TYPES.MULTISELECT, label: 'Dropdown (select many)'},
        {value: FIELD_COMPONENT_TYPES.MULTICHECK, label: 'Multi-checkbox'},
        {value: FIELD_COMPONENT_TYPES.SHORT_TEXT, label: 'Short text'},
        {value: FIELD_COMPONENT_TYPES.LONG_TEXT, label: 'Paragraph'},
        {value: FIELD_COMPONENT_TYPES.MULTI_TEXT, label: 'Multiple short texts'},
    ],
    [FIELD_VALUE_TYPES.EMPLOYEE]: [
        {value: FIELD_COMPONENT_TYPES.SELECT, label: 'Dropdown (select single)'}
    ],
    [FIELD_VALUE_TYPES.DEPARTMENT]: [
        {value: FIELD_COMPONENT_TYPES.SELECT, label: 'Dropdown (select single)'}
    ],
    [FIELD_VALUE_TYPES.BOOL]: [
        {value: FIELD_COMPONENT_TYPES.YES_NO_SELECT, label: 'Yes/No Dropdown'},
        {value: FIELD_COMPONENT_TYPES.CHECKBOX, label: 'Checkbox'}
    ],
    [FIELD_VALUE_TYPES.ACCESS_LEVEL]: [
        {value: FIELD_COMPONENT_TYPES.SELECT, label: 'Dropdown (select single)'} 
    ]
    // [FIELD_VALUE_TYPES.FLOAT]: [
    //     {value: FIELD_COMPONENT_TYPES.SHORT_TEXT, label: 'Number Box'},
    // ]

}

export const doesComponentUseChoices = (component) => {
    return [
        FIELD_COMPONENT_TYPES.SELECT,
        FIELD_COMPONENT_TYPES.MULTISELECT,
        FIELD_COMPONENT_TYPES.MULTICHECK,
    ].includes(component)
}

export const makeDefaultChoiceDesc = () => {
    return {
        __meta_type: "StaticSelectChoicesDescriptor",
        dependencies: [],
        choices: [
            {value: `${CLIENT_CHOICE_ID_PREFIX}_default_choice_1`, label: 'choice 1'},
            {value: `${CLIENT_CHOICE_ID_PREFIX}_default_choice_2`, label: 'choice 2'},
        ]
    }
}

export const isFieldStaticallySingleSelectable = (fieldStructure) => {
    //returns true if the field has preset set of choices. note this may be a bool field, not just dropdown
    const {
        id, value_type, component, label, short_label, helper_text,
        is_optional, is_secret, notify_if_problematic, is_user_role,
        hide_conditions, choice_desc
    } = fieldStructure;
    if(value_type === FIELD_VALUE_TYPES.BOOL){
        return true;
    }
    if(choice_desc && choice_desc.__meta_type === CHOICE_DESC_TYPES.STATIC){
        return true;//TODO: find better way of assesing if the field is a static dropdown (single or multi)
    }
    return false
}
export const getChoicesOfStaticallySelectableField = (fieldStructure) => {
    //returns static choices of a field. note this may be a bool field, not just dropdown
    console.log('TODO: implement!')
}

export const cloneFieldStructure = (fieldStructure) => {
    //TODO: optimize with smart deep copy
    return makeNestedDeepCopy(fieldStructure);
}

export const doesField1DependOnField2 = (f1, f2) => {
    const depMsgs = getDependencyMessages(f1, f2);
    return (depMsgs.length > 0);
}
export const getDependencyMessages = (f1, f2) => {
    //returns list of dependencies that f1 has on f2
    const output = [];
    //hide conditions
    let hasHideCondDependency = false;
    if(f1.hide_conditions){
        for(const condSet of f1.hide_conditions.ored_clauses){
            for(const cond of condSet){
                if(cond.field_id === f2.id){
                    hasHideCondDependency = true;
                }
            }
        }
    }
    if(hasHideCondDependency){
        output.push("Conditions for not displaying field");
    }

    //choice dependency
    if(f1.choice_desc){
        const hasChoiceDep = f1.choice_desc.dependencies.some(depFieldId => depFieldId === f2.id);
        if(hasChoiceDep){
            output.push("Selectable choices for the field")
        }
    }
    return output;
}

export const getFieldsDependentOnStaticChoiceValue = (sectionStructure, fieldIdWithChoice, choiceValue) => {
    return sectionStructure.fields.filter(f => doHideConditionsDependOnFieldChoice(f.hide_conditions, fieldIdWithChoice, choiceValue))
}

export const doHideConditionsDependOnFieldChoice = (hideConditions, fieldIdWithChoice, choiceValue) => {
    if(hideConditions === null) return false;
    for(const anded_clauses of hideConditions.ored_clauses){
        for(const clause of anded_clauses){
            if(clause.field_id === fieldIdWithChoice){
                if(Array.isArray(clause.value) && clause.value.includes(choiceValue)){
                    return true;
                }
                else if(clause.value === choiceValue){
                    return true;
                }
            }
        }
    }
    return false;
}
export const doHideConditionsDependOnField = (hideConditions, fieldId) => {
    if(hideConditions === null) return false;
    for(const anded_clauses of hideConditions.ored_clauses){
        for(const clause of anded_clauses){
            if(clause.field_id === fieldId){
                return true
            }
        }
    }
    return false;
}
export const getDependantFields = (fieldStructure, sectionStructure) => {
    const output = [];
    const fieldId = fieldStructure.id;

    for(const f of sectionStructure.fields){
        //choice dependencies
        if(f.choice_desc && f.choice_desc.dependencies && f.choice_desc.dependencies.includes(fieldId)){
            output.push(f)
        }
        //hide conds
        else if(doHideConditionsDependOnField(f.hide_conditions, fieldId)){
            output.push(f);
        }
    }

    return output
}

export const isPreExistingField = (fieldId) => {
    return !fieldId.startsWith(CLIENT_FIELD_ID_PREFIX);
}

export const isPreExistingSection = (sectionId) => {
    return !sectionId.startsWith(CLIENT_SECTION_ID_PREFIX);
}
export const isPreExistingChoiceValue = (choiceValue) => {
    return !choiceValue.startsWith(CLIENT_CHOICE_ID_PREFIX);
}