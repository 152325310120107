import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const StageStepper = ({steps, currentStep}) => {

    const activeStep = Number.isInteger(currentStep) ? currentStep : steps.indexOf(currentStep)
    return (
        <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
            <Step key={step}>
                <StepLabel>{step}</StepLabel>
            </Step>
            ))}
        </Stepper>
        </Box>
    );
}

export default StageStepper;
