import { useContext, useState } from "react";
import { Box, Button, Drawer, ListSubheader, MenuItem, Stack, TextField, Toolbar, Typography } from "../../../../node_modules/@mui/material/index";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import { FieldStructureForm } from "./FieldStructureConfig";
import { MultiSectionFormWrapper } from "./Wrappers";
import { addFieldBackToActiveStructure, addNewFieldToStructure, addNewSectionToStructure, deleteFieldFromActiveStructure, deleteSectionFromActiveStructure, getSectionIdWithFieldId } from "./form_builder_helper";
import TicketTextField from "../../inputs/TicketTextField";
import { swapItemsAtIndices } from "../../../helpers/CommonUtil";
import { getDependencyMessages, MAX_LABEL_LEN } from "./field_builder_helper";
import Context from "../../../Store";
import { ConfigPanelHeader } from "./ConfigPanelHeader";
import { getDefaultValue } from "../../../helpers/DefaultFormValueMaker";
import { ACCESS_STRUCTURE_IDS } from "../../../helpers/Constants";
import LoadButton from "../../generic/LoadButton";
import { CheckBoxWithHelpTooltip } from "./CheckBoxWithHelpTooltip";
import { getInitialContextValues } from "./context_fields";
import { ConfigLockTimer } from "../ConfigLockTimer";

const HIDE_AT_SUBMISSION_TOOLTIP = `
Check this box if the section is is not meant to be completed by the submitter, but rather by a reviewer later on in the approval process.
`

const FieldDropdown = ({structure, value, onValueSelected}) => {
    const choices = []
    structure.sections.forEach(sectionStructure => {
        const sectionId = sectionStructure.id;
        choices.push(<ListSubheader key={`header-${sectionId}`}><Typography variant='h6'>{sectionStructure.metadata.label}</Typography></ListSubheader>)
        
        sectionStructure.fields.forEach(fieldStructure => {
            const fieldId = fieldStructure.id;
            const value = fieldId
            const label = fieldStructure.short_label;
            choices.push(<MenuItem key={value} value={value}>{label}</MenuItem> )            
        })
    })

    return(
        <Box p={2}>
            <TextField
                select
                label={"Add back field"}
                onChange={e => onValueSelected(e.target.value)}
                value={value}
                fullWidth={true}
                // sx={{maxWidth: "60%"}}
                >
                    { choices }
            </TextField> 
        </Box>
    )
}

const SectionConfigPanel = ({sectionStructure, setSectionStructure, addSection, moveSection, removeSection}) => {
    const setSectionLabel = (x) => setSectionStructure({...sectionStructure, metadata: {...sectionStructure.metadata, label: x}});
    const setSectionHideAtSubmission = (x) => setSectionStructure({...sectionStructure, metadata: {...sectionStructure.metadata, hide_at_submission: x}});
    return (
        <Stack sx={SX_BOX_SIMPLE} spacing={1}>
            <ConfigPanelHeader
                title={'Section'}
                onAddClick={addSection}
                onMoveClick={moveSection}
                onDeleteClick={removeSection}
                moveTooltip={'Move this section up or down in the form'}
                />
            <TicketTextField
                label={"Label"}
                value={sectionStructure.metadata.label}
                setValue={setSectionLabel}
                maxLen={MAX_LABEL_LEN}
                />
            <CheckBoxWithHelpTooltip
                label={"Hide at submission"}
                value={sectionStructure.metadata.hide_at_submission}
                setValue={setSectionHideAtSubmission}
                tooltip={HIDE_AT_SUBMISSION_TOOLTIP}
                />
        </Stack>
    )
}

const ConfigPanel = ({
    formStructure, setFormStructure, contextFields,
    addField, removeField, moveField,
    addSection, removeSection, moveSection, 
    onSaveClick, isSubmitting, 
    selectedSectionId, selectedFieldId,
    configMeta, tables, refetchTables,
    deletedStructure, addBackDeletedField,
    canHaveUserRoleFields
}) => {
    const selectedSectionStructure = formStructure.sections.find(sd => sd.id === selectedSectionId);
    const selectedFieldStructure = selectedSectionStructure ? selectedSectionStructure.fields.find(fd => fd.id === selectedFieldId) : null;

    const setSectionStructure = (sd) => {
        const formStructureCopy = {...formStructure};
        const sdIdx = formStructure.sections.findIndex(s => s.id === selectedSectionId);
        formStructure.sections[sdIdx] = sd;
        setFormStructure(formStructureCopy);
    }

    const setFieldStructure = (fd) => {
        const fdIdx = selectedSectionStructure.fields.findIndex(f => f.id === selectedFieldId);
        selectedSectionStructure.fields[fdIdx] = fd;
        setSectionStructure(selectedSectionStructure);
    }

    const width = 400;//340
    return (
        <Drawer
            variant="permanent"
            anchor='right'
            sx={{ width: width, flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: width, boxSizing: 'border-box', backgroundColor:undefined },}}
            >
                <Toolbar/>
                {
                    (formStructure.sections.length > 0) ? null :
                    <Box sx={{...SX_BOX_SIMPLE, p: 5, justifyContent: 'center', display: 'flex'}}>
                        <Button variant='contained' onClick={addSection}>
                            Add Section
                        </Button>
                    </Box>
                }
                {
                    !selectedFieldStructure ? null :
                    <FieldStructureForm
                        fieldStructure={selectedFieldStructure}
                        setFieldStructure={setFieldStructure}
                        sectionStructure={selectedSectionStructure}
                        configMeta={configMeta}
                        tables={tables}
                        refetchTables={refetchTables}
                        addField={addField}
                        moveField={moveField}
                        removeField={removeField}
                        canHaveUserRoleFields={canHaveUserRoleFields}
                        contextFields={contextFields}
                        />
                }
                {
                    !selectedSectionStructure ? null :
                    <SectionConfigPanel
                        sectionStructure={selectedSectionStructure}
                        setSectionStructure={setSectionStructure}
                        moveSection={moveSection}
                        removeSection={removeSection}
                        addSection={addSection}
                        />
                }
                {
                    (deletedStructure.sections.length === 0) ? null :
                    <FieldDropdown structure={deletedStructure} value={""} onValueSelected={addBackDeletedField}/>
                }
                <Stack direction='row' justifyContent="space-around" alignItems="center" sx={{...SX_BOX_SIMPLE}} >
                    <ConfigLockTimer/>
                    <Box>
                        <LoadButton variant='contained' onClick={onSaveClick} loading={isSubmitting} disabled={isSubmitting}>
                            Save Changes
                        </LoadButton>
                    </Box>
                </Stack>
        </Drawer>
    )
}


const FormBuilder = ({
    configMeta, tables, refetchTables, initialFormStructure, onSaveClick, canHaveUserRoleFields, hasAccessSection, title, isSubmitting, contextFields,
    initialDeletedStructure
}) => {
    const {company_funcs, choices_per_company_func} = configMeta;
    const {alertError} = useContext(Context);

    // const [formStructure, setFormStructure] = useState({sections: []});
    const [formStructure, setFormStructure] = useState(initialFormStructure);
    const [formValues, setFormValues] = useState(getDefaultValue(formStructure));
    const [contextValues, setContextValues] = useState(getInitialContextValues(contextFields));
    const [selectedSectionId, setSelectedSectionId] = useState(null)
    const [selectedFieldId, setSelectedFieldId] = useState(null)
    const [deletedStructure, setDeletedStructure] = useState(initialDeletedStructure);
    
    const update = () => {
        setFormStructure({...formStructure});
        setFormValues({...formValues});
        setDeletedStructure({...deletedStructure});
    }

    const addField = (sectionId) => {
        if(hasAccessSection && (sectionId === ACCESS_STRUCTURE_IDS.INFO_SECTION_ID)){
            alertError('This is a special section and cannot have fields added to it.');
            return;
        }
        const field = addNewFieldToStructure(formStructure, formValues, sectionId);
        
        setSelectedSectionId(sectionId);
        setSelectedFieldId(field.id);
        update();
    }

    const removeField = (sectionId, fieldId) => {
        const sectionStructure = formStructure.sections.find(s => s.id === sectionId);
        const fields = sectionStructure.fields
        const fieldIdx = fields.findIndex(f => f.id === fieldId);
        const field = fields[fieldIdx];

        for(const otherField of fields){
            const dependencyMessages = getDependencyMessages(otherField, field);
            const doesOtherFieldDependOnIt = (dependencyMessages.length > 0);
            if(doesOtherFieldDependOnIt){
                let errMsg = `Cannot remove. ${otherField.label} depends on ${field.label} for:\n- `;
                errMsg += dependencyMessages.join('\n- ');
                alertError(errMsg);
                return 
            }
        }

        deleteFieldFromActiveStructure(formStructure, formValues, deletedStructure, sectionId, fieldId)
        update();
        setSelectedFieldId(null);
        setSelectedSectionId(null);
    }

    const addSection = () => {
        const section = addNewSectionToStructure(formStructure, formValues, true);

        setSelectedSectionId(section.id);
        setSelectedFieldId(section.fields[0].id);
        update();
    }

    const removeSection = (sectionId) => {
        deleteSectionFromActiveStructure(formStructure, formValues, deletedStructure, sectionId);

        setSelectedFieldId(null);
        setSelectedSectionId(null);
        update();
    }

    const moveField = (sectionId, fieldId, isUp) => {
        const sections = formStructure.sections;
        const section = sections.find(s => s.id === sectionId);

        const fields = section.fields;
        const currFieldIdx = fields.findIndex(f => f.id === fieldId);
        const field = fields[currFieldIdx];

        if(isUp && (currFieldIdx === 0)) return;
        if(!isUp && (currFieldIdx === fields.length-1)) return;

        const targetIdx = isUp ? (currFieldIdx - 1) : (currFieldIdx + 1);

        // check that the move does not result in a field depending on a field beneath it
        const swappedField = fields[targetIdx];
        const fieldThatWillBeFirst = isUp ? field : swappedField;
        const fieldThatWillBeSecond = (!isUp) ? field : swappedField;

        const dependencyMessages = getDependencyMessages(fieldThatWillBeFirst, fieldThatWillBeSecond)
        const willCreateForwardDependency = (dependencyMessages.length > 0);
        if(willCreateForwardDependency){
            let errMsg = "Cannot move field. "
            errMsg += "Fields can only depend on fields that appear before them.\n"
            errMsg += `${fieldThatWillBeSecond.label} has the following dependencies on ${fieldThatWillBeFirst.label}:\n- `;
            errMsg += dependencyMessages.join("\n- ")
            alertError(errMsg);
            return;
        }
        
        swapItemsAtIndices(fields, currFieldIdx, targetIdx);
        setFormStructure({...formStructure});
    }

    const moveSection = (sectionId, isUp) => {
        const sections = formStructure.sections;
        const currIdx = sections.findIndex(s => s.id === sectionId);

        if(isUp && (currIdx === 0)) return;
        if(!isUp && (currIdx === sections.length-1)) return;

        const targetIdx = isUp ? (currIdx - 1) : (currIdx + 1);
        swapItemsAtIndices(sections, currIdx, targetIdx);
        setFormStructure({...formStructure});
    }

    const addBackDeletedField = (fieldId) => {
        addFieldBackToActiveStructure(formStructure, formValues, deletedStructure, fieldId);

        setSelectedSectionId(getSectionIdWithFieldId(formStructure, fieldId));
        setSelectedFieldId(fieldId);
        update();
    }
    return (
        <Box>
            <MultiSectionFormWrapper
                structure={formStructure}
                formData={formValues}
                setFormData={setFormValues}
                selectedSectionId={selectedSectionId}
                setSelectedSectionId={setSelectedSectionId}
                selectedFieldId={selectedFieldId}
                setSelectedFieldId={setSelectedFieldId}
                title={title}
                contextFields={contextFields}
                contextValues={contextValues}
                setContextValues={setContextValues}
                />
            <ConfigPanel
                formStructure={formStructure}
                setFormStructure={setFormStructure}
                configMeta={configMeta}
                tables={tables}
                refetchTables={refetchTables}
                onSaveClick={() => onSaveClick(formStructure)}
                canHaveUserRoleFields={canHaveUserRoleFields}
                isSubmitting={isSubmitting}
                contextFields={contextFields}
                
                addField={() => addField(selectedSectionId)}
                removeField={() => removeField(selectedSectionId, selectedFieldId)}
                moveField={(isUp) => moveField(selectedSectionId, selectedFieldId, isUp)}
                addSection={addSection}
                removeSection={() => removeSection(selectedSectionId)}
                moveSection={(isUp) => moveSection(selectedSectionId, isUp)}

                selectedSectionId={selectedSectionId}
                selectedFieldId={selectedFieldId}

                addBackDeletedField={addBackDeletedField}
                deletedStructure={deletedStructure}
                />
        </Box>
    )
}

export default FormBuilder;