import { Stack, Box, Typography, Divider, Tooltip, IconButton } from "../../../../node_modules/@mui/material/index";
import { cleanForm, isFieldRelevent } from "../../../helpers/TicketFormHelper";
import { SX_BOX_ICON_BTN, SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import FormField from "../../form/FormField";
import EditIcon from '@mui/icons-material/Edit';
import CloseableMessage from "../../generic/CloseableMessage";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const HILIGHT_COLOR = "#bbddff"

const NO_SENSATIVE_INFO_MSG = `
Note: Do NOT add fields to this form that are intended for sensative data such as SSN or credit card info.
`

const IRRELEVANT_FIELD_TOOLTIP = `
this field would be hidden in the form since the sections values satisfy the "Do not display if..." conditions that are set for this field.
It is displayed now for configuration purposes, but cannot be interacted with.
`

const HIDDEN_SECTION_TOOLTIP = `
This section will not display at time of submission. It will only display once it reaches a review stage responsible for completing it.
It is displayed now for configuration purposes.
`


const ContextFieldsWrapper = ({contextFields, contextValues, setContextValues}) => {
    if(contextFields.length === 0) return null;

    return (
        <Box sx={SX_BOX_SIMPLE} marginTop={1}>
            <Typography>Context Values (not part of the form, but the form can depend on these)</Typography>
            <Stack direction='row' spacing={1} marginTop={1}>
                {
                    contextFields.map(field => {
                        const fieldId = field.id;
                        return (
                            <FormField
                                key={fieldId}
                                fieldStructure={field}
                                value={contextValues[fieldId]}
                                setValue={(x) => setContextValues({...contextValues, [fieldId]: x})}
                                isEditable={true}
                                sectionValues={{}}
                                contextValues={{}}
                                onDownloadClick={() => {}}
                                fullWidth={true}
                                />
                        )
                    })
                }
            </Stack>
        </Box>
    )
}

const EditFieldButton = () => {
    return (
        <Box sx={SX_BOX_ICON_BTN}>
            <Tooltip title="Edit field configuration" enterDelay={1000}>
                <IconButton>
                    <EditIcon color='primary'/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export const SectionFormWrapper = ({
    sectionStructure, sectionValues, setSectionValues, isEditable, contextValues,
    selectedSectionId, setSelectedSectionId, selectedFieldId, setSelectedFieldId
}) => {
    const downloadFileField = (x) => (y) => (z) => null;

    const sectionSx = 
        isEditable ?
        {m: 2} :
        { border: 2, borderColor: '#000000', m: 2 }

    const spacing = isEditable ? 2 : 0;

    let visibleFieldIdx = 0; //just a counter for alternating field box color

    return (
        <Stack spacing={spacing} sx={sectionSx}>
            {
                sectionStructure.fields.map(fieldStructure => {
                    const fieldId = fieldStructure.id;
                    const fieldValue = sectionValues[fieldId];
                    // if(!showIrreleventFields || [null, undefined, ""].includes(fieldValue)){
                    const is_relevent = isFieldRelevent(fieldStructure, sectionValues)
                    
                    const onFieldClick = () => {
                        setSelectedSectionId(sectionStructure.id);
                        setSelectedFieldId(fieldId)
                    }


                    const isSelected = (fieldId === selectedFieldId);
                    const fieldSx = {
                        backgroundColor: (isSelected) ? HILIGHT_COLOR : "#ffffff",
                        // display: 'flex',
                        // width: '100%',
                        flexGrow: 1,
                        justifyContent: 'center'
                    };
                    
                    const setFieldValue = is_relevent ?
                        (x) => setSectionValues({...sectionValues, [fieldId]: x}) :
                        (x) => {}

                    if(isEditable) {
                        return (
                            <Stack onClick={onFieldClick} key={fieldId} direction={'row'}>
                                {
                                    is_relevent ? null :
                                    <Tooltip title={<Typography>{IRRELEVANT_FIELD_TOOLTIP}</Typography>}>
                                        <VisibilityOffIcon color='primary'/>
                                    </Tooltip>
                                }
                                <Box sx={fieldSx} key={fieldId}>
                                    <FormField
                                        key={fieldId}
                                        fieldStructure={fieldStructure}
                                        value={fieldValue}
                                        setValue={setFieldValue}
                                        isEditable={isEditable}
                                        sectionValues={sectionValues}
                                        contextValues={contextValues}
                                        onDownloadClick={() => downloadFileField(fieldId)}
                                        fullWidth={true}
                                        />
                                </Box>
                                <EditFieldButton/>
                            </Stack>
                        )
                    }
                    else {
                        if(fieldValue === undefined){
                            //explicit check for undefined (as ooposed to null), because then the value does not exist
                            //due to this being in context of an edit/edit request ticket
                            return null;
                        }
                        visibleFieldIdx += 1;
                        const fieldSx = {backgroundColor: (visibleFieldIdx % 2) ? "#90d0ff" : "#ffffff"};
                        return (
                            <Box sx={fieldSx} key={fieldId}>
                                <FormField
                                    key={fieldId}
                                    fieldStructure={fieldStructure}
                                    value={fieldValue}
                                    setValue={(x) => setSectionValues({...sectionValues, [fieldId]: x})}
                                    isEditable={isEditable}
                                    sectionValues={sectionValues}
                                    onDownloadClick={() => downloadFileField(fieldId)}
                                    contextValues={contextValues}
                                    />
                            </Box>
                        )
                    }
                })
            }
        </Stack>
    )
}

export const MultiSectionFormWrapper = ({
    structure, formData, setFormData,
    contextFields, contextValues, setContextValues,
    selectedSectionId, setSelectedSectionId, selectedFieldId, setSelectedFieldId,
    title
}) => {
    // return null;
    // if(editableSectionIds === undefined) editableSectionIds = [];

    //getSectionFieldSetter('a')('b')(value), sets formData.a.b = value, and updates the state
    const getSectionValuesSetter = (sectionId) => {
        return (sectionValues) => {
            // console.log(`seeting section ${sectionId}`, sectionValues)
            let updatedFormData = {...formData}
            updatedFormData[sectionId] = sectionValues;
            updatedFormData = cleanForm(updatedFormData, structure)
            setFormData(updatedFormData)
        }
    }

    // margin={'auto'}
    return(
        <Box width={700}>
            <Typography align='center' variant={'h5'}>
                {title}
            </Typography>
            <CloseableMessage message={NO_SENSATIVE_INFO_MSG}/>
            <ContextFieldsWrapper contextFields={contextFields} contextValues={contextValues} setContextValues={setContextValues}/>
            {
                structure.sections.map((sectionStructure) => {
                    
                    const sectionId = sectionStructure.id;
                    const isEditable = true;
                    const label = sectionStructure.metadata.label;
                    const sectionValues =  formData[sectionId]
                    if(sectionValues === undefined) return null;

                    const isSectionSelected = (sectionStructure.id === selectedSectionId);
                    const sectionSx = isSectionSelected ? {border: 3, borderRadius: 2, borderColor: HILIGHT_COLOR} : {}
                    return(
                        <Box marginY={2} key={sectionId} sx={sectionSx}>
                            <Stack direction='row'>
                                {
                                    !sectionStructure.metadata.hide_at_submission ? null :
                                    <Tooltip title={<Typography>{HIDDEN_SECTION_TOOLTIP}</Typography>}>
                                        <VisibilityOffIcon color='primary'/>
                                    </Tooltip>
                                }
                                <Typography variant="h5">{label}</Typography>
                            </Stack>
                            <Divider sx={{ borderBottomWidth: 2 }}/>
                            <SectionFormWrapper
                                key={sectionId}
                                sectionStructure={sectionStructure}
                                sectionValues={sectionValues}
                                setSectionValues={getSectionValuesSetter ? getSectionValuesSetter(sectionId) : undefined}
                                isEditable={isEditable}
                                downloadFileField={undefined}
                                contextValues={contextValues}
                                selectedSectionId={selectedSectionId}
                                setSelectedSectionId={setSelectedSectionId}
                                selectedFieldId={selectedFieldId}
                                setSelectedFieldId={setSelectedFieldId}
                                />   
                        </Box>
                    )
                })
            }
        </Box>
    )
}