import React from "react";
import { Autocomplete, TextField, Box, Typography } from "@mui/material";
import Context from "../../Store";

export const getExistingEntityErrorMessage = (staticData, entityTypeId, parentId) => {
    const {entity_types, entity_summaries} = staticData;
    const entityType = entity_types.find(et => et.id === entityTypeId);
    const isRoot = !parentId;

    if(isRoot){
        return `There is already an active ${entityType.name} with this name`
    }
    else{
        const parent = entity_summaries.find(es => es.id === parentId);
        return `${parent.name} already has an active member with this name`
    }
}

const EntityNameTextField = ({text, setText, entityTypeId, parentId}) => {
    const {staticData} = React.useContext(Context);
    const [hasError, setHasError] = React.useState(false);

    const optionObjects = React.useMemo(() => {
        let output = [];
        const {entity_types, entity_summaries} = staticData;
        const entityType = staticData.entity_types.find(et => et.id === entityTypeId);
        const instanceType = !parentId ? entityType.root_instance_type : entityType.member_instance_type;

        for(const entitySummary of entity_summaries){
            const {id, entity_type_id, name, is_active, parent_id, parent_name} = entitySummary;

            if(entitySummary.entity_type_id === entityTypeId && entitySummary.parent_id === parentId){
                let name = entitySummary.name;
                const obj = {label: name, id: entitySummary.id, isActive: entitySummary.is_active, originalName: entitySummary.name}
                output.push(obj);
            }
        }

        const uniqueChoices = [];
        output.forEach(c => {
            if(!uniqueChoices.some(x => x.label === c.label)){
                uniqueChoices.push(c);
            }
        })
        return uniqueChoices;
    }, [entityTypeId, parentId])

    const options = React.useMemo(() => {
        const output = optionObjects.map(obj => obj.label);
        return output;
    }, [optionObjects, entityTypeId, parentId])


    const onChange = (event, newValue) => {
        if(newValue === null) newValue = "";
        setText(newValue);
    }

    const onInputChange = (event, newValue) => {
        setText(newValue);
        const obj = optionObjects.find(o => o.label.toLowerCase() === newValue.toLowerCase());
        if(!obj) setHasError(false);
        else setHasError(obj.isActive);

    }

    return (
        <Box>
            <Autocomplete
                freeSolo
                options={options}
                renderInput={(params) => <TextField {...params} label="Name" />}
                value={text}
                onChange={onChange}
                inputValue={text}
                onInputChange={onInputChange}
                autoHighlight={true}
                key={'EntityNameTextField'}
                id={'EntityNameTextField'}
                />
            {
                !hasError ? null :
                <Typography color={'error'}>{getExistingEntityErrorMessage(staticData, entityTypeId, parentId)}</Typography>
            }
        </Box>

    )
}

export default EntityNameTextField;