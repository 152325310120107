import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat, get_emp_dept_role, isHRISManagedInternally } from "./hris_helpers";
import React, { useState } from "react";
import TicketSelectField from "../inputs/TicketSelectField";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context from "../../Store";
import { EMPLOYEE_STATUSES, REQUEST_STATES } from "../../helpers/Constants";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import RoleForm from "./RoleForm";
import { useSearchParams } from "react-router-dom";
import { HRIS_VIEWS } from "./hris_constants";
import SectionForm from "../form/SectionForm";
import OffboardingBlockers from "./OffboardingBlockers";
import { hasEmpPermission, hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";


// const get_department_roles = ()
const EmployeeView = ({employee, company, refetchCompany}) => {
    const {alertSuccess, alertError, staticData, user } = React.useContext(Context);

    const { __meta_type, id, email, first_name, last_name, name, status, extra_data, role_tags } = employee;
    // const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;

    const roles = extract_department_roles_for_emp(company.root_department, employee.id)

    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);
    const [isShowingBlockers, setIsShowingBlockers] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams()

    const empStatus = employee.status;
    // const [isAddingRole, setIsAddingRole] = useState(false);
    
    const removeRole = (roleId) => {
        const body = {emp_role_id: roleId}

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Role removed");
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.REMOVE_ROLE, body, onSuccess, onFailure)
    }

    const onEditClick = (roleId) => {
        setSearchParams({view: HRIS_VIEWS.EDIT_ROLE, role_id: roleId})
        
        const empRole = get_emp_dept_role(company.root_department, roleId);
        const empRoleCopy = makeNestedDeepCopy(empRole);
    }

    const onEditEmployeeClick = () => {
        setSearchParams({view: HRIS_VIEWS.EDIT_EMP, emp_id: employee.id});
    }

    const onOffboardEmployeeClick = () => {
        const body = {employee_id: employee.id}

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            const newStatus = resp.data;
            alertSuccess(`Employee status is now ${newStatus}`);
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.OFFBOARD_EMPLOYEE, body, onSuccess, onFailure)
    }

    const onReactivateEmployeeClick = () => {
        const body = {employee_id: employee.id}

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            alertSuccess(`Employee reactivated`);
            refetchCompany();
        }
        const onFailure = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.REACTIVATE_EMPLOYEE, body, onSuccess, onFailure)
    }

    const isOffboarding = (status === "Offboarding")
    const relevanceButtonText = isOffboarding ? "View Offboarding Blockers" : "View Relevance";
    const canViewRelevance = hasEmpPermission(user, employee.id, ALL_PERMS.ORG_ACTIONS.VIEW_OFFBOARDING_STATUS.key);
    const hasEditPerms = isHRISManagedInternally(company) && hasGobalPermission(user, ALL_PERMS.ORG_ACTIONS.EDIT_ORG.key);

    return (
        <Box>
            <Stack direction={'row'} spacing={4}>
                <Stack>
                    <Typography alignContent={'center'}>Name: {name}</Typography>
                    {!hasEditPerms ? null : <Typography alignContent={'center'}>Status: {status}</Typography>}
                    <Typography>Email: {email}</Typography>
                </Stack>
                {
                    !hasEditPerms ? null :
                    <Stack spacing={1}>
                        <Box>
                            <Button onClick={onEditEmployeeClick} variant="contained">
                                Edit
                            </Button>
                        </Box>
                        <Stack direction={'row'} spacing={1}>
                            {
                                (empStatus === EMPLOYEE_STATUSES.INACTIVE) ? null :
                                <Button onClick={onOffboardEmployeeClick} variant="contained" color="error">
                                    Offboard
                                </Button>
                            }
                            {
                                (empStatus === EMPLOYEE_STATUSES.ACTIVE) ? null :
                                <Button onClick={onReactivateEmployeeClick} variant="contained" color="success">
                                    Reactivate
                                </Button>
                            }
                        </Stack>
                    </Stack>
                }
            </Stack>
            {/* <OffboardingBlockers employeeId={employee.id} /> */}

            <Box marginTop={2}>
                <Typography>Custom Fields:</Typography>
                {/* <SectionView sectionStructure={company.employee_extra_data_structure} value={ employee.extra_data} /> */}
                <SectionForm
                    sectionStructure={company.employee_extra_data_structure}
                    sectionValues={employee.extra_data}
                    />
            </Box>
            
            <Stack direction={'row'} marginTop={2}>
                <Typography>Roles</Typography>
                {/* <Button onClick={() => setIsAddingRole(true)} >Add</Button> */}
            </Stack>
            {
                roles.map(role => {
                    const roleId = role.employee_role.role.id;

                    let dept_type_title = role.department.type.name;
                    if(role.is_manager) dept_type_title += ` ${role.department.type.manager_title}`

                    return (
                        <Stack direction={'row'} key={roleId} border={1} padding={1}>
                            {/* <Typography>Role</Typography> */}
                            <Stack>

                                <Tooltip title={"Edit Role"} placement="left">
                                    <IconButton aria-label="edit" onClick={() => onEditClick(roleId)} disabled={submitState===REQUEST_STATES.SENDING}>
                                        <EditIcon color="primary"/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete Role"} placement="left">
                                    <IconButton aria-label="delete" onClick={() => removeRole(roleId)} disabled={submitState===REQUEST_STATES.SENDING}>
                                        <DeleteIcon color="error"/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Box>
                                <Typography>{dept_type_title}: {role.department.name}</Typography>
                                {/* <Typography>Approver tags: {tags_label}</Typography> */}
                                <Typography marginTop={1}>Custom Fields:</Typography>
                                {/* <SectionView sectionStructure={company.employee_role_extra_data_structure} value={ role.employee_role.role.extra_data} /> */}
                                <SectionForm
                                    sectionStructure={company.employee_role_extra_data_structure}
                                    sectionValues={role.employee_role.role.extra_data}
                                    />
                            </Box>
                        </Stack>
                    )
                })
            }
            <Box marginTop={2}/>
            {
                !canViewRelevance ? null : (
                    isShowingBlockers ?
                    <OffboardingBlockers employeeId={employee.id} key={employee.id}/> :
                    <Button onClick={() => setIsShowingBlockers(true)} variant="contained" color="primary">
                        {relevanceButtonText}
                    </Button>
                )
            }
        </Box>
    )
}

export default EmployeeView;