import { NODE_TYPES } from "./ApprovalProcessFlow";

const getIdToNodeMap = (nodes) => {
    const output = {};
    for(const node of nodes){
        output[node.id] = node;
    }
    return output;
}

const getIncomingoingEdgeMap = (nodes, edgesList) => {
    //returns map (object) where output[nodeId] = nodeIds with edge TO node
    const output = {};
    for(const node of nodes){
        output[node.id] = [];
    }
    for(const e of edgesList){
        const srcId = e.source;
        const destId = e.target;
        output[destId].push(srcId);
    }
    return output;
}
const getOutgoingEdgeMap = (nodes, edgesList) => {
    //returns map (object) where output[nodeId] = nodeIds with edge FROM node
    const output = {};
    for(const node of nodes){
        output[node.id] = [];
    }
    for(const e of edgesList){
        const srcId = e.source;
        const destId = e.target;
        output[srcId].push(destId);
    }
    return output;
}
const getHighLevelErrors = (nodes, edges) => {
    //asumes no cycles since that should be enforced in the UI while building the graph
    const errs = [];
    let err = '';
    const incomingEdgeMap = getIncomingoingEdgeMap(nodes, edges);
    const outgoingEdgeMap = getOutgoingEdgeMap(nodes, edges);
    const idToNodeMap = getIdToNodeMap(nodes);
    // start with the node that has no incoming edges
    const startNodes = nodes.filter(nd => incomingEdgeMap[nd.id].length === 0);
    const endNodes = nodes.filter(nd => outgoingEdgeMap[nd.id].length === 0);

    //check for island nodes - you can have 1 if it is a single stage process
    const islandNodes = nodes.filter(nd => (incomingEdgeMap[nd.id].length === 0) && (outgoingEdgeMap[nd.id].length === 0));
    const numPermittedIslands = (nodes.length === 1) ? 1 : 0;
    if(islandNodes.length > numPermittedIslands){
        err = `there are ${islandNodes.length} that are not connected to anything. delete or connect them.`;
        errs.push(err);
    }

    if(startNodes.length !== 1){
        err = `Must be exactly 1 node with no incoming edges`;
        errs.push(err);
    }

    if(endNodes.length !== 1){
        err = `Must be exactly 1 node with no outgoing edges`
        errs.push(err);
    }

    const startParallelProcessNodes = nodes.filter(nd => nd.type === NODE_TYPES.START_PP);
    const endParallelProcessNodes = nodes.filter(nd => nd.type === NODE_TYPES.END_PP);
    for(const sppNode of startParallelProcessNodes){
        if(outgoingEdgeMap[sppNode.id].length === 0){
            err = "All Start of Parallel Process nodes require at least 1 outgoing edge";
            errs.push(err)
        }
    }
    for(const eppNode of startParallelProcessNodes){
        if(incomingEdgeMap[eppNode.id].length === 0){
            err = "All End of Parallel Process nodes require at least 1 incoming edge";
            errs.push(err)
        }
    }
    if(startParallelProcessNodes.length != endParallelProcessNodes.length){
        err = 'There should be an equal number of "Start" and "End" Parallel process nodes';
        errs.push(err);
    }
    return errs;
}
export const graphToProcess = (nodes, edges) => {
    //TODO: maybe return error objects instead of string for better UI messaging 
    const incomingEdgeMap = getIncomingoingEdgeMap(nodes, edges);
    const outgoingEdgeMap = getOutgoingEdgeMap(nodes, edges);
    const idToNodeMap = getIdToNodeMap(nodes);

    const highLevelErrors = getHighLevelErrors(nodes, edges);
    if(highLevelErrors.length){
        const sep = "    - ";
        const errMsg = sep + highLevelErrors.join("    - ");
        return [null, errMsg];
    }
    // start with the node that has no incoming edges
    const startNodes = nodes.filter(nd => incomingEdgeMap[nd.id].length === 0);
    const startNode = startNodes[0];
    const [process, nextNode, err] = getProcessStartingAtNode(startNode, idToNodeMap, outgoingEdgeMap);
    return [process, err];
    
}

const getProcessStartingAtNode = (startNode, idToNodeMap, outgoingEdgeMap) => {
    //process end when eithr when no outgoin edges or hitting a parallel-process end
    const stages = [];
    // stages.push(startNode.data.stage);
    let nextNode = startNode;
    while(nextNode && nextNode.type !== NODE_TYPES.END_PP){
        const [stage, nodeAfterStage, err] = nodeToStage(nextNode, idToNodeMap, outgoingEdgeMap);
        if(err) return [null, null, err];
        stages.push(stage);
        nextNode = nodeAfterStage;
    }

    const approvalProcess = {
        'name': '',//arbitrary value, not used really
        'stages': stages
    }

    return [approvalProcess, nextNode, null]
}

const nodeToStage = (node, idToNodeMap, outgoingEdgeMap) => {
    //return stage and next node(s)
    if(node.type === NODE_TYPES.ATOMIC){
        const nextNodesIds = outgoingEdgeMap[node.id];//should be just 1 node if valid
        if(nextNodesIds.length > 1){
            const err = `Expected 0 or 1 outgoing edge(s), recieved ${nextNodesIds.length}`;
            return [null, null, err]
        }
        const nextNode = (nextNodesIds.length === 0) ? null : idToNodeMap[nextNodesIds[0]];
        return [node.data.stage, nextNode, null]
    }
    else if(node.type === NODE_TYPES.START_PP){
        return nodeToParallelProcess(node, idToNodeMap, outgoingEdgeMap);
    }
    else{
        const err = `Unexpected node type: ${node.type}`;
        console.error(err, {node});
        return [null, null, err];
    }
}
const nodeToParallelProcess = (node, idToNodeMap, outgoingEdgeMap) => {
    const processes = [];

    let processEndNode = undefined;
    const destNodesIds = outgoingEdgeMap[node.id]
    if(destNodesIds.length === 0){
        const err = "Starts of parallel processes must have outgoing edges";
        return [null, null, err];
    }
    for(const destNodeId of destNodesIds){
        const destNode = idToNodeMap[destNodeId];
        const [process, nextNodeAfterProcess, error] = getProcessStartingAtNode(destNode, idToNodeMap, outgoingEdgeMap);
        if(error) return [null, null, error];

        if(processEndNode === undefined) processEndNode = nextNodeAfterProcess;
        else if(nextNodeAfterProcess !== processEndNode){
            const err = "All parallel processes sharing the same start node must share the same end node";
            return [null, null, err];
        }
        if(processEndNode.type !== NODE_TYPES.END_PP){
            const err = `processes within a parallel process should end with an "End Parallel Process" node `;
            return [null, null, err];
        }
        processes.push(process)
    }

    const nextNodesIds = outgoingEdgeMap[processEndNode.id];
    if(nextNodesIds.length > 1){
        const err = 'End of parallel process can have at most 1 outgoing edge';
        return [null, null, err];
    }

    const nextNode = (nextNodesIds.length === 0) ? null : idToNodeMap[nextNodesIds[0]]
    const parallelProcessesStage = {
        __meta_name: 'ParallelApprovalsStage',
        id: `pp_${node.id}`,
        name: '',
        'approval_processes': processes
    }
    return [parallelProcessesStage, nextNode, null]
}