import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const CollapseableContent = ({children, title, isExpandedByDefault, headerColor, contentColor}) => {
    return (
        <Accordion
            defaultExpanded={isExpandedByDefault}
            variant="outlined"
            sx={{ boxShadow: "none", borderColor:'#000000', borderRadius:2, borderWidth:2, borderTopWidth:1.5, borderBottomWidth:2}}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ bgcolor: headerColor }}
                >
                {title}
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: contentColor }}>
                {children}
            </AccordionDetails>
        </Accordion>
    )

}

export default CollapseableContent;