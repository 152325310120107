import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, Typography } from '@mui/material';
import { Box } from '../../../node_modules/@mui/material/index';
import { SX_BOX_SIMPLE } from '../../helpers/common_sx';

const LoadingMessage = ({msg}) => {
  if(msg === undefined) msg = 'Loading...'
  return (
    // <Stack sx={{display: "flex"}} >
    <Box sx={{display: "flex"}}>

    <Stack sx={{justifyContent: "center", alignItems: "center" }} >
      <CircularProgress />
      <Typography>{msg}</Typography>
    </Stack>
    </Box>
  );
}

export default LoadingMessage;