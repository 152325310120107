import { useContext, useEffect, useState } from "react";
import { useData, useRefetchData } from "../../../helpers/CustomHooks"
import { EXTENSIONS, post } from "../../../helpers/requests"
import LoadingMessage from "../../generic/LoadingMessage";
import FormBuilder from "./FormBuilder";
import Context from "../../../Store";
import { makeNestedDeepCopy } from "../../../helpers/TicketFormHelper";
import { Box } from "../../../../node_modules/@mui/material/index";
import PageTitle from "../../generic/PageTitle";
import { CHOICE_DESC_TYPES } from "../../inputs/FieldProps";
import { MAX_LABEL_LEN } from "./field_builder_helper";
import { MESSAGE_LIST_SEP } from "../../../helpers/Constants";
import { getManagedTableSelectFieldChoices } from "./table_select_helper";

//#region Helpers
const unsaturateStructure = (formStructure) => {
    for(const section of formStructure.sections){
        for(const field of section.fields){
            if(!field.choice_desc) continue;
            const choiceType = field.choice_desc.__meta_type
            if(choiceType !== CHOICE_DESC_TYPES.STATIC){
                field.choice_desc.choices = null;
            }
        }
    }    
}
const removeDeletedChoices = (formStructure) => {
    for(const section of formStructure.sections){
        for(const field of section.fields){
            if(!field.choice_desc) continue;
            const choiceType = field.choice_desc.__meta_type
            if(choiceType === CHOICE_DESC_TYPES.STATIC){
                field.choice_desc.choices = field.choice_desc.choices.filter(c => !c.is_deleted)
            }
        }
    }  
}

const getActiveOnlyStructureCopy = (structure) => {
    structure = makeNestedDeepCopy(structure);
    structure.sections = structure.sections.filter(s => !s.is_deleted);
    for(const section of structure.sections){
        section.fields = section.fields.filter(f => !f.is_deleted)
    }
    return structure;
}

const exampleAccessLevelChoices = [
    {value: 1, label: 'User'}, {value: 2, label: 'Admin'},
    {value: 3, label: 'These are examples. The choices will depend'},
    {value: 5, label: 'on the access levels you have set for the resource.'}
]

const saturateFormStructure = (formStructure, choices_per_company_func, tables, all_users, all_departments) => {
    for(const section of formStructure.sections){
        for(const field of section.fields){
            if(!field.choice_desc) continue;
            const choiceType = field.choice_desc.__meta_type
            if(choiceType === CHOICE_DESC_TYPES.COMPANY){
                field.choice_desc.choices = choices_per_company_func[field.choice_desc.function_id]
            }
            else if(choiceType === CHOICE_DESC_TYPES.TABLE){
                field.choice_desc.choices = getManagedTableSelectFieldChoices(field, tables, all_users, all_departments)
            }
            else if(choiceType === CHOICE_DESC_TYPES.ACCESS_LEVEL){
                field.choice_desc.choices = {'wtvr': exampleAccessLevelChoices}
            }
        }
    }
}


const getFormattedInitialFormStructure = (structure, choices_per_company_func, tables, all_users, all_departments) => {
    const activeOnlyStructure = getActiveOnlyStructureCopy(structure);
    saturateFormStructure(activeOnlyStructure, choices_per_company_func, tables, all_users, all_departments)
    return activeOnlyStructure;
}

const getDeletedStructure = (structure) => {
    structure = makeNestedDeepCopy(structure);
    structure.sections = structure.sections.filter(s => s.is_deleted || s.fields.some(f => f.is_deleted));
    for(const section of structure.sections){
        section.fields = section.fields.filter(f => f.is_deleted)
        section.is_deleted = false;
        section.fields.forEach(f => f.is_deleted = false);
    }
    return structure;
}
//#region Helpers

// const sanityCheck = (formStructure, alertError) => {
//     const msgs = []
//     for(const section of formStructure.sections){
//         for(const field of section.fields){
//             if(field.label.length > MAX_LABEL_LEN){
//                 msgs.push(`label: ${field.label} is ${field.label.length} chars`)
//             }
//             if(field.short_label.length > MAX_LABEL_LEN){
//                 msgs.push(`short_label: ${field.short_label} is ${field.short_label.length} chars`)
//             }
//         }
//     }
//     const msg = 'issues:' + MESSAGE_LIST_SEP + msgs.join(MESSAGE_LIST_SEP);
//     alertError(msg)
// }

// const formattedForEarlyClient = (formStructure)

const FormConfigPage = ({configData, initialFormStructure, instanceTypeDescriptor, onSaved, canHaveUserRoleFields, hasAccessSection, title, contextFields}) => {
    const {alertError, alertSuccess, staticData: {all_users, all_departments}} = useContext(Context);
    
    const [tablesData, refetchTablesData] = useRefetchData(EXTENSIONS.GET_ALL_MANAGED_TABLES, {});
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [formStructure, setFormStructure] = useState(initialFormStructure);

    const {company_funcs, choices_per_company_func} = configData;//configMeta

    // useEffect(() => sanityCheck(formStructure, alertError), [])
    const awaitedDatas = [tablesData];
    if(awaitedDatas.includes(false)) return <div>Failed to Load</div>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const save = (formStructure) => {
        const structureCopy = makeNestedDeepCopy(formStructure);
        unsaturateStructure(structureCopy);
        removeDeletedChoices(structureCopy);

        const body = {
            instance_type_desc: instanceTypeDescriptor,
            new_structure: structureCopy
        }
        const onSuccess = (resp) => {
            alertSuccess("Saved");
            setIsSubmitting(false);
            onSaved();
        }
        const onFailure = (e) => {
            setIsSubmitting(false);
            const msg = (e?.response?.data) ? (e.response.data) : 'something went wrong';
            alertError(msg)
        }
        setIsSubmitting(true);
        post(EXTENSIONS.EDIT_FORM_STRUCTURE, body, onSuccess, onFailure);
    }

    const tables = tablesData.data;
    const initialStructureFormatted = getFormattedInitialFormStructure(initialFormStructure, choices_per_company_func, tables, all_users, all_departments);
    const deletedStructure = getDeletedStructure(initialFormStructure);

    return (
        <Box>
            <FormBuilder
                configMeta={configData}
                tables={tables}
                refetchTables={refetchTablesData}
                initialFormStructure={initialStructureFormatted}
                // formStructure={formStructure}
                // setFormStructure={setFormStructure}
                onSaveClick={save}
                canHaveUserRoleFields={canHaveUserRoleFields}
                hasAccessSection={hasAccessSection}
                title={title}
                isSubmitting={isSubmitting}
                contextFields={contextFields}
                initialDeletedStructure={deletedStructure}
                />
        </Box>
    )
}


export default FormConfigPage;