import { Box, Stack, Typography } from "@mui/material"
import { datetimeDisplay } from "../../helpers/FieldDisplayFormatters"
import React from "react"
import Context from "../../Store"
import LabelValueDisplay from "../generic/LabelValueDisplay"



const ActionCard = ({action, userIdToNameMap}) => {
    // const {actor, role, action, datetime, message} = action;
    return (
        <Box border={2} padding={2} borderTop={0}>
            <LabelValueDisplay label={'Reviewer'} value={userIdToNameMap[action.actor_id]}/>
            <LabelValueDisplay label={'Stage'} value={action.role}/>
            <LabelValueDisplay label={'Action'} value={action.action}/>
            <LabelValueDisplay label={'Date'} value={datetimeDisplay(action.datetime, true)}/>
            <LabelValueDisplay label={'Message'} value={action.message}/>
            {/* <Typography>Reviewer: {userIdToNameMap[action.actor_id]}</Typography>
            <Typography>Stage: {action.role}</Typography>
            <Typography>Action: {action.action}</Typography>
            <Typography>Date: {datetimeDisplay(action.datetime, true)}</Typography>
            <Typography>Message: {action.message}</Typography> */}
        </Box>
    )
}

const ActionsPanel = ({actions}) => {
    const {staticData} = React.useContext(Context)
    return (
        <Stack borderTop={2}>
            {/* <Box border={2} padding={1}>
                <Typography textAlign={"center"}>Past Reviewers</Typography>
            </Box> */}
            {
                actions.map((action, idx) => {
                    return <ActionCard action={action} userIdToNameMap={staticData.all_users} key={idx}/>
                })
            }
        </Stack>
    )
}

export default ActionsPanel;