import axios from "axios";
import { FIELD_VALUE_TYPES } from "../components/inputs/FieldProps";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

const URL_BASE = process.env.REACT_APP_BACKEND_URL_BASE
let _csrfToken = null

export const EXTENSIONS = {
    PING : 'savas/ping',
    CSRF : 'savas/csrf',
    AM_I_LOGGED_IN : 'savas/am_i_logged_in',
    LOGIN : 'savas/login',
    LOGOUT : 'savas/logout',
    GET_ENTITY_SUMMARIES : 'savas/get_entity_summaries',
    GET_CONFIGS_UPDATE : 'savas/get_configs_update',
    GET_MY_USER_DATA : 'savas/get_user_data',

    //future    
    SW_GET_NEXT_PENDING_ON_ME_META : 'savas/get_next_ticket_meta_pending_users_review',
    SW_MY_ACTION_HISTORY: 'savas/sw_my_action_history',
    GET_PAPER_TRAIL: 'savas/get_approval_paper_trail',
    // GET_PROBLEMATIC_TICKETS: 'savas/get_problematic_tickets',
    PROVIDE_FEEDBACK: 'savas/provide_feedback',
    
    //Access
    GET_ACCESS_PAPER_TRAIL: 'savas/get_full_access_paper_trail',
    BULK_IMPORT_ACCESS_INFO: 'savas/bulk_import_access_info',
    GET_ACCESS_RELATED_TO_ENTITY: 'savas/get_access_related_to_entity',
    GET_ACCESSES_WITH_PENDING_TICKETS: 'savas/get_access_with_pending_tickets',
    
    //Access Levels
    GET_ACCESS_LEVELS: 'savas/get_access_levels',
    GET_ACCESS_LEVELS_PER_RESOURCE: 'savas/get_active_access_levels_per_resource',
    UPDATE_ACCESS_LEVELS: 'savas/update_access_levels_set',
    IS_ACCESS_LEVEL_USED: 'savas/is_access_level_used',

    //Entities
    GET_PENDING_ENTITIES : 'savas/get_entities_pending_activeness',
    GET_PENDING_ENTITY_TICKETS: 'savas/get_entities_With_pending_tickets',
    GET_APPROVED_ENTITIES : 'savas/get_active_entity_instances',
    GET_ALL_ENTITIES: 'savas/get_all_entity_instances_of_set',
    GET_ENTITIES_OF_INSTANCE_TYPE: 'savas/get_all_entity_instances_of_instance_type',
    BULK_IMPORT_ENTITIES: 'savas/bulk_import_entities',
    EDIT_ENTITY_NAME: 'savas/edit_entity_name',
    EDIT_ENTITY_TICKET: 'savas/edit_entity_ticket',

    //Instances
    SUBMIT_TICKET : 'savas/submit_ticket',
    GET_INSTANCE : 'savas/get_instance',
    GET_ALL_MY_PENDING_TASKS : 'savas/get_all_my_pending_tasks',
    GET_MY_PENDING_TASK : 'savas/get_my_task',
    REVIEW_TICKET : 'savas/review_ticket',
    EDIT_INSTANCE: 'savas/edit_instance',
    CANCEL_TICKET: 'savas/cancel_ticket',
    GET_FILE_FIELD_URL: 'savas/get_file_url_from_field',
    
    //HRIS
    GET_COMPANY: 'savas/get_company',
    ADD_EMPLOYEE: 'savas/add_employee',
    OFFBOARD_EMPLOYEE: 'savas/offboard_employee',
    REACTIVATE_EMPLOYEE: 'savas/reactivate_employee',
    EDIT_EMPLOYEE: 'savas/edit_employee',
    ADD_ROLE: 'savas/add_role',
    REMOVE_ROLE: 'savas/remove_role',
    EDIT_ROLE: 'savas/edit_role',
    ADD_DEPT: 'savas/add_department',
    REMOVE_DEPT: 'savas/remove_department',
    EDIT_DEPT: 'savas/edit_department',
    UPDATE_EMPLOYEE_STRUCTURE: 'savas/update_emp_structure',
    UPDATE_EMPLOYEE_ROLE_STRUCTURE: 'savas/update_emp_role_structure',
    UPDATE_DEPARTMENT_TYPE: 'savas/update_department_type',
    CREATE_DEPARTMENT_TYPE: 'savas/create_department_type',

    GET_EMP_TAGS: 'savas/get_tag_to_user_ids_map',
    ADD_EMP_TAG: 'savas/add_employee_tag',
    REMOVE_EMP_TAG: 'savas/remove_employee_tag',
    
    //Permissions
    GET_PERMS: 'savas/get_full_permissions_view',
    EDIT_GLOBAL_PERM_PROF: 'savas/update_perm_profile',
    ADD_GLOBAL_PERM_PROF: 'savas/create_perm_profile',
    UPDATE_USER_PERM_PROF: 'savas/update_users_perm_profile',
    EDIT_ENTITY_ROLE_PERM_PROF: 'savas/edit_entity_role_profile',
    EDIT_ORG_ROLES_PERM_PROF: 'savas/edit_org_roles_permission_profile',

    //Managed Tables
    GET_ALL_MANAGED_TABLES: 'savas/get_all_managed_tables',
    OBTAIN_TABLE_LOCK: 'savas/obtain_or_renew_lock_on_table',
    RELEASE_TABLE_LOCK: 'savas/release_lock_on_table',
    EDIT_TABLE: 'savas/update_table',
    CAN_DELETE_TABLE_COLUMN: 'savas/can_delete_table_column',
    CREATE_MANAGED_TABLE: 'savas/create_managed_table',

    //Integrations
    GET_EMP_OFFBOARDING_BLOCKERS: 'savas/get_offboarding_blockers',

    //Configs
    OBTAIN_CONFIG_LOCK: 'savas/obtain_config_lock',
    GET_CONFIG_META: 'savas/get_config_meta',
    EDIT_FORM_STRUCTURE: 'savas/edit_form_structure',
    EDIT_APPROVAL_PROCESS: 'savas/edit_approval_process',
    REMOVE_EDIT_REQUEST_PROCESS: 'savas/remove_edit_request_process',

    CREATE_EMPTY_ENTITY_INSTNACE_TYPE: 'savas/create_empty_entity_instance_type',
    CREATE_EMPTY_ACCESS_TYPE: 'savas/create_empty_access_type',
    ACTIVATE_INSTANCE_TYPE: 'savas/activate_instance_type',
    REMOVE_INACTIVE_INSTANCE_TYPE: 'savas/remove_inactive_instance_type',
    RENAME_ENTITY_INSTANCE_TYPE: 'savas/rename_entity_instance_type',
    UPDATE_TABLE_DISPLAY: 'savas/update_table_display',
    UPDATE_MEMBER_AUTO_CREATION: 'savas/update_member_auto_creation',
    
    //External HRIS
    START_EXTERNAL_HRIS_VAULT_SESSION: 'savas/start_external_hris_vault_session',
    VALIDATE_VAULT_SETUP: 'savas/validate_vault_setup',
    GET_LIVE_EXTERNAL_HRIS_STATE: 'savas/get_live_external_hris_state',
    SET_HRIS_TO_INTERNAL: 'savas/set_hris_to_internal',
    SET_HRIS_TO_EXTERNAL: 'savas/set_hris_to_external',
    UPDATE_HRIS_CACHE: 'savas/update_hris_cache',
    UPDATE_EMAIL_DOMAIN_WHITELIST: 'savas/update_user_email_domain_whitelist',

    //Temp features
    GET_DUP_NAMES: 'savas/get_duplicate_names',
    GET_BAD_MEMBERS: 'savas/get_members_in_bad_state',

}

const setCsrfToken = async () => {
    const url = URL_BASE + EXTENSIONS.CSRF
    const response = await fetch(url, {
        credentials: 'include',
    });
    const data = await response.json();
    _csrfToken = data.csrfToken;
}

export const postTicket = async (urlExtension, body, structure, onSuccess, onFailure) => {
    if(_csrfToken === null) await setCsrfToken()

    const ticket = body.values

    //extract files from tickets
    const files = {}
    structure.sections.forEach(sectionStructure => {
        const sectionId = sectionStructure.id;
        
        sectionStructure.fields.forEach(fieldStructure => {
            const fieldId = fieldStructure.id
            if(fieldStructure.value_type === FIELD_VALUE_TYPES.FILE){
                const file = ticket[sectionId][fieldId]
                const fileKey = `${sectionId}-${fieldId}`
                files[fileKey] = file
            }
        })
    });

    //build form
    const data = new FormData() 
    data.set('body', JSON.stringify(body))
    Object.keys(files).forEach((key) => {
        data.set(key, files[key])
    })

    //post
    const url = process.env.REACT_APP_BACKEND_URL_BASE + urlExtension
    axios.post(url, data,{
        headers: {
            'X-CSRFToken': _csrfToken,
            "Content-Type": "multipart/form-data",
        },
    }).then(onSuccess).catch(onFailure)
}

export const postFileTemp = async (urlExtension, file, filename, onSuccess, onFailure) => {
    if(_csrfToken === null) await setCsrfToken()

    //build form
    const data = new FormData() 
    data.set(filename, file)
    // data.set('body', JSON.stringify(body))

    //post
    const url = process.env.REACT_APP_BACKEND_URL_BASE + urlExtension
    axios.post(url, data,{
        headers: {
            'X-CSRFToken': _csrfToken,
            "Content-Type": "multipart/form-data",
        },
    }).then(onSuccess).catch(onFailure)
}


//standard post
export const post = async (urlExtension, bodyObject, onSuccess, onFailure) => {
    if(_csrfToken === null) await setCsrfToken()

    const url = URL_BASE + urlExtension
    const body = JSON.stringify(bodyObject)
    try{
        const response = await axios.post(url, body, { headers:{'X-CSRFToken': _csrfToken} })
        if(onSuccess) onSuccess(response)
    }
    catch(e){
        console.log("request error:", e)
        if(onFailure) onFailure(e)
    }
}


export const postForm = async (url, formData, onSuccess, onFailure) => {
    if(_csrfToken === null) await setCsrfToken()

    axios.post(url, formData,{
        headers: {
            'X-CSRFToken': _csrfToken,
            "Content-Type": "multipart/form-data",
        },
    }).then(onSuccess).catch(onFailure)
}

export const downloadFileField = (body) => {
    const onSuccess = (resp) => {
        console.log("got file:", resp)
        const fileUrl = resp.data.file_url;
        window.open(fileUrl, '_blank', 'noopener, noreferrer');
    }
    const onFail = (e) => {
        console.log('failed to get/open file. error: ', e);
    }
    post(EXTENSIONS.GET_FILE_FIELD_URL, body, onSuccess, onFail);

}
export const downloadEntityFileField = (entityId, ticketId, sectionId, fieldId, stageId) => {
    const body = {
        instance_desc: {
            instance_type: "Entity",
            entity_instance_id: entityId,
            // is_emp_access: ,
            // accessor_id: ,
            // resource_id: ,
        }, 
        ticket_id: ticketId, 
        section_key: sectionId, 
        field_key: fieldId,
        stage_id: stageId
    }

    downloadFileField(body);
    // const onSuccess = (resp) => {
    //     const fileUrl = resp.data;
    //     window.open(fileUrl, '_blank', 'noopener, noreferrer');
    // }
    // const onFail = (e) => {
    //     console.log('failed to get/open file. error: ', e);
    // }

}

export const downloadAccessFileField = (is_emp_access, accessor_id, resource_id, ticketId, sectionId, fieldId, stageId) => {
    const body = {
        instance_desc: {
            instance_type: "Access",
            // entity_instance_id: entityId,
            is_emp_access: is_emp_access,
            accessor_id: accessor_id,
            resource_id: resource_id,
        },
        ticket_id: ticketId, 
        section_key: sectionId, 
        field_key: fieldId,
        stage_id: stageId
    }

    downloadFileField(body);
    // const onSuccess = (resp) => {
    //     const fileUrl = resp.data;
    //     window.open(fileUrl, '_blank', 'noopener, noreferrer');
    // }
    // const onFail = (e) => {
    //     console.log('failed to get/open file. error: ', e);
    // }

}