import React, { memo } from 'react';
import { Handle, NodeResizer, Position } from 'reactflow';
import { Box, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import CustomHandle from './CustomHandle';

const CONNECTION_LIMIT = 1;

export const StartParallelProcessNode = memo(({ data }) => {
    // const {status, name, id, height, width} = data; //status in "enabled", "approved", "rejected", null
    const color = 'success'
    return (
        <Box justifyContent={'center'} alignItems={'center'} display={"flex"}>
            <CustomHandle
                type="target"
                id="dst"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={CONNECTION_LIMIT}
            />
            <PlayCircleOutlineIcon color={color} fontSize='large'/>
            <Handle
                type="source"
                position={Position.Right}
                id="src"
                isConnectable={true}
            />
        </Box>
    );
});

export const EndParallelProcessNode = memo(({ data }) => {
    // const {status, name, id, height, width} = data; //status in "enabled", "approved", "rejected", null
    const color = 'error'
    return (
        <Box justifyContent={'center'} alignItems={'center'} display={"flex"}>
            <Handle
                type="target"
                id="dst"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={true}
            />
            <PauseCircleOutlineIcon color={color} fontSize='large'/>
            <CustomHandle
                type="source"
                position={Position.Right}
                id="src"
                isConnectable={CONNECTION_LIMIT}
            />
        </Box>
    );
});

export const MarkerNode = memo(({ data }) => {
    //useful for using as a replacement node to measure/guage where something will be placed
    // const {status, name, id, height, width} = data; //status in "enabled", "approved", "rejected", null
    return (
        <Box justifyContent={'center'} alignItems={'center'} display={"flex"}>
            <Handle
                type="target"
                id="dst"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={true}
            />
            <InfoIcon color={'error'}/>
            <Handle
                type="source"
                position={Position.Right}
                id="src"
                isConnectable={true}
            />
        </Box>
    );
});