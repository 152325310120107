import { Typography } from "@mui/material"
import { useParams } from 'react-router-dom';
import PageTitle from "../generic/PageTitle";
import ApproverTicketReviewDirect from "../ApproverTicketReviewDirect";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import React, { useEffect, useState } from "react";
import Context from "../../Store";

const ReviewTicketPage = () => {
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs()

    const params = useParams();
    const entity_type_id = Number(params.entity_type_id);
    const entity_id = Number(params.instance_id);
    const ticket_id = Number(params.ticket_id);
    const stage_id = Number(params.stage_id);

    // const body ={entity_type_name, entity_type_instance_id, entity_type_member_instance_id: member_id, stage_id};
    const body ={
        instance_desc:{
            instance_type: "Entity",
            entity_instance_id: entity_id
        },
        ticket_id,
        stage_id
    };
    const task = useData(EXTENSIONS.GET_MY_PENDING_TASK, body);

    const awaitedDatas = [configSyncState, task];
    if(awaitedDatas.includes(false)) return <div>Failed to Load...</div>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const entityType = staticData.entity_types.find(et => et.id === entity_type_id)

    const {instance, ticket, stage} = task;
    const instanceType = instance.parent ? entityType.member_instance_type : entityType.root_instance_type;
    const instanceName = instance.name;
    const title = `${instanceType.name}: ${instanceName}`;
    const subtitle = `Review stage: ${stage.stage_name}`;

    const keySuffix = `${entity_type_id}-${entity_id}-${ticket_id}-${stage_id}`;
    return (
        <div key={`ReviewTicketPage-${ticket_id}-${stage_id}`}>
            <PageTitle title={title} subtitle={subtitle}/>
            <ApproverTicketReviewDirect
                entityTypeId={entity_type_id}
                task={task}                
                key={`ApproverTicketReviewDirect-${keySuffix}`}
                />
        </div>
    )
            
}

export default ReviewTicketPage;
