import { useContext, useState } from "react"
import { Box, Button, Icon, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index"
import { EXTENSIONS, post } from "../../../helpers/requests"
import Context from "../../../Store"
import LoadingMessage from "../../generic/LoadingMessage"
import { EmailDomainWhitelistPanel } from "./EmailDomainWhitelistPanel"
import { FailedToLoadMessage } from "../../generic/FailedToLoadMessage"
import { HRISIntegrationEmployeeMapper } from "./HRISIntegrationEmployeeMapper"
import { HRISIntegrationDepartmentMapper } from "./HRISIntegrationDepartmentMapper"
import { ExternalHRISSubmissionPanel } from "./ExternalHRISSubmissionPanel"
import { HRISIntegrationPreVaultPanel } from "./HRISIntegrationPreVaultPanel"
import { ExternalHRISVaultPanel } from "./ExternalHRISVaultPanel"
import { getDepartments } from "../../hris/hris_helpers"
// import { getLiveExternalHrisState } from "./dummy_responses.js/live_external_state_response"
import StageStepper from "../../StageStepper"
import { MyDivider } from "../../generic/MyDivider"
import { ConfigLockTimer } from "../ConfigLockTimer"

const makeEmptyDataMap = () => {
    return {
        internal_to_external_emp_id_map: {},
        unmapped_internal_emp_ids: [],
        unmapped_external_emp_ids: [],
        internal_to_external_dept_id_map: {},
        unmapped_internal_dept_ids: [],
        unmapped_external_dept_ids: []
    }
}

const STEP_NAMES = {
    PRE_VAULT: "Pre-Vault",
    VAULT: "Vault Setup",
    EMPLOYEE_MAPPING: "Map Employees",
    DOMAIN_WHITELIST: "Whitelist Domains",
    DEPARTMENT_MAPPING: "Map Departments",
    SUBMISSION: "Submit",
    DONE: "Done"
}

const STEP_ORDER = [
    STEP_NAMES.PRE_VAULT,
    STEP_NAMES.VAULT,
    STEP_NAMES.EMPLOYEE_MAPPING,
    STEP_NAMES.DOMAIN_WHITELIST,
    STEP_NAMES.DEPARTMENT_MAPPING,
    STEP_NAMES.SUBMISSION,
    STEP_NAMES.DONE,
]
// const STEPS = {
//     PRE_VAULT: 0,
//     VAULT: 1,
//     EMPLOYEE_MAPPING: 2,
//     DOMAIN_WHITELIST: 3,
//     DEPARTMENT_MAPPING: 4,
//     SUBMISSION: 5,
//     DONE: 6
// }


export const ExternalHRISIntegrationMultiPanel = ({}) => {
    const [step, setStep] = useState(0);

    return (
        <Box>
            <Stack direction='row' alignItems='center' justifyContent='center'>
                <Icon/>
                <Typography variant='h5' align='center'>HRIS Itegration</Typography>
                <ConfigLockTimer/>
            </Stack>
            <StageStepper currentStep={STEP_ORDER[step]} steps={STEP_ORDER}/>
            <MyDivider/>
            <ExternalHRISIntegrationMultiContent step={step} setStep={setStep}/>
        </Box>
    )
}
export const ExternalHRISIntegrationMultiContent = ({step, setStep}) => {
    const {user, alertError} = useContext(Context);
    const stepName = STEP_ORDER[step]

    const [whitelist, setWhitelist] = useState(null);
    const [dataMap, setDataMap] = useState(makeEmptyDataMap())

    const [externalCompanyDTO, setExternalCompanyDTO] = useState(null);
    const [internalCompany, setInternalCompany] = useState(null);

    const fetchData = (onFetchFailed) => {
        setWhitelist(null);
        setDataMap(makeEmptyDataMap())
        setExternalCompanyDTO(null)
        setInternalCompany(null)

        const onGetCompanySuccess = (response) => {
            const cmpny = response.data
            setInternalCompany(cmpny);
            const initialWhitelist = (cmpny.user_email_domain_whitelist.length === 0) ? [''] : cmpny.user_email_domain_whitelist;
            setWhitelist([...initialWhitelist])
            const internalEmpIds = cmpny.employees.map(e => ""+e.id)
            const internalDeptIds = getDepartments(cmpny.root_department).filter(d => d.id != cmpny.root_department.id).map(d => ""+d.id)
            setDataMap(prev => ({...prev, unmapped_internal_emp_ids: internalEmpIds, unmapped_internal_dept_ids: internalDeptIds}))
        }
        const onGetCompanyFail = (err) => {
            // setInternalCompany(false)
            alertError(err.response.data)
            onFetchFailed()
        }

        post(EXTENSIONS.GET_COMPANY, {}, onGetCompanySuccess, onGetCompanyFail)

        const onGetExternalCompanyDTOSuccess = (response) => {
            const cmpny = response.data
            setExternalCompanyDTO(cmpny);
            const externalEmpIds = cmpny.employee_dtos.map(e => e.external_id)
            const externalDeptIds = cmpny.department_dtos.map(d => d.external_id)
            setDataMap(prev => ({...prev, unmapped_external_emp_ids: externalEmpIds, unmapped_external_dept_ids: externalDeptIds}))
        }
        const onGetExternalCompanyDTOFail = (err) => {
            // setExternalCompanyDTO(false)
            alertError(err.response.data)
            onFetchFailed()
        }

        post(EXTENSIONS.GET_LIVE_EXTERNAL_HRIS_STATE, {}, onGetExternalCompanyDTOSuccess, onGetExternalCompanyDTOFail)
        // onGetExternalCompanyDTOSuccess({data: getLiveExternalHrisState()});
    }

    const advanceStep = () => {
        const currStep = step;//defined inorder to capture value within scope
        const currStepName = STEP_ORDER[step]

        const nextStep = step + 1;
        setStep(nextStep);

        if(currStepName === STEP_NAMES.VAULT){
            const onFetchFailed = () => setStep(currStep);
            fetchData(onFetchFailed);
        }
    }

    const goBackStep = () => {
        setStep(step - 1)
    }
    const awaitedDatas = [internalCompany, externalCompanyDTO]

    switch(stepName){
        case STEP_NAMES.PRE_VAULT:
            return <HRISIntegrationPreVaultPanel advanceStep={advanceStep}/>
        case STEP_NAMES.VAULT:
            return <ExternalHRISVaultPanel advanceStep={advanceStep} goBackStep={goBackStep}/>
        case STEP_NAMES.EMPLOYEE_MAPPING:
            if(awaitedDatas.includes(null)) return <LoadingMessage/>
            return (
                <HRISIntegrationEmployeeMapper
                    internalCompany={internalCompany}
                    externalCompanyDTO={externalCompanyDTO}
                    dataMap={dataMap}
                    setDataMap={setDataMap}
                    advanceStep={advanceStep}
                    goBackStep={goBackStep}
                    />
            )
        case STEP_NAMES.DOMAIN_WHITELIST:
            // if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>
            if(awaitedDatas.includes(null)) return <LoadingMessage/>
            const currUserExternalId = dataMap.internal_to_external_emp_id_map[user.user_id]
            return (
                <EmailDomainWhitelistPanel
                    externalCompanyDTO={externalCompanyDTO}
                    whitelist={whitelist}
                    setWhitelist={setWhitelist}
                    advanceStep={advanceStep}
                    goBackStep={goBackStep}
                    currUserExternalId={currUserExternalId}
                    />
            )
        case STEP_NAMES.DEPARTMENT_MAPPING:
            return (
                <HRISIntegrationDepartmentMapper
                    internalCompany={internalCompany}
                    externalCompanyDTO={externalCompanyDTO}
                    dataMap={dataMap}
                    setDataMap={setDataMap}
                    advanceStep={advanceStep}
                    goBackStep={goBackStep}
                    />
            )
        case STEP_NAMES.SUBMISSION:
            return <ExternalHRISSubmissionPanel whitelist={whitelist} dataMap={dataMap} advanceStep={advanceStep} goBackStep={goBackStep}/>
        case STEP_NAMES.DONE:
            //should route to HRIS view
            return <div>All set up!</div>
    }
}