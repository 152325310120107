import React from "react";
import { Box, Typography } from "../../../node_modules/@mui/material/index";
import { useData } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import Context from "../../Store";
import { getInstanceType } from "../../helpers/StaticDataHelper";
import AbstractTable from "../tables/AbstractTable";
import { ENTITY_REQUEST_TYPES } from "../../helpers/Constants";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import PageTitle from "../generic/PageTitle";

const ProblematicMembersSection = ({instanceTypeId, entities}) => {
    const {staticData} = React.useContext(Context);
    const instanceType = getInstanceType(staticData, instanceTypeId);
    if(entities.length === 0) {
        return <LabelValueDisplay label={instanceType.name} value={"No Problems"}/>
    }
    const columnNames = [
        'Name', 'id', 'Is Self-Active', 'Pending tickets',
        'Parent', 'Parent id', 'Is parent active', 'Parent pending tickets'];
    
    const renderRowCells = (e) => {
        const parent = e.parent;
        // const isParentPendingRemoval = parent.tickets.some(t => t.requust_type === ENTITY_REQUEST_TYPES.REMOVE && t.status === "Pending");
        // const isParentPendingActiveness = parent.tickets.some(t => [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN].includes(t.requust_type) && t.status === "Pending");
        const parentPendingTicketTypes = parent.tickets.filter(t => t.status === 'Pending').map(t => t.request_type).join(", ");
        const pendingTicketTypes = e.tickets.filter(t => t.status === 'Pending').map(t => t.request_type).join(", ");
        const isSelfActiveLabel = !e.state ? 'No state' : (e.state.is_self_active ? "Yes" : 'No')
        const output = [
            e.name,
            e.id,
            isSelfActiveLabel,
            pendingTicketTypes,

            parent.name,
            parent.id,
            parent.is_active ? "Yes" : 'No',
            parentPendingTicketTypes
        ]

        return output;
    }
    return (
        <Box>
            <Typography>{instanceType.name}</Typography>
            <AbstractTable
                rows={entities}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowToKey={(e) => e.id}
                />
        </Box>
    )
}

const ProblematicMembersPage = () => {
    const data = useData(EXTENSIONS.GET_BAD_MEMBERS, {});

    if(data === false) return <div>Failed to load</div>;
    if(data === null) return <LoadingMessage/>;

    console.log('data: ', data);
    const acc = data;
    const instanceTypeIds = Object.keys(acc);
    return (
        <Box>
            <PageTitle title={"Unstable Members"}/>
            {
                instanceTypeIds.map(instanceTypeId => {

                    return (
                        <ProblematicMembersSection
                            key={instanceTypeId}
                            instanceTypeId={instanceTypeId}
                            entities={acc[instanceTypeId]}
                            />

                    ) 
                })
            }
        </Box>
    )
}

export default ProblematicMembersPage;