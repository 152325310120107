import { useContext, useState } from "react"
import { Box, Button, Stack, Typography } from "../../../../node_modules/@mui/material/index"
import LoadButton from "../../generic/LoadButton"
import { EXTENSIONS, post } from "../../../helpers/requests";
import Context from "../../../Store";
import LoadingMessage from "../../generic/LoadingMessage";
import { makeNestedDeepCopy } from "../../../helpers/TicketFormHelper";


export const ExternalHRISSubmissionPanel = ({whitelist, dataMap, advanceStep, goBackStep}) => {
    const {alertSuccess, alertError} = useContext(Context);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const onSubmitClick = () => {

        const onSuccess = (resp) => {
            alertSuccess("Success!")
            setIsSubmitting(false);
            advanceStep();
        }

        const onFail = (err) => {
            alertError(err.response.data);
            setIsSubmitting(false);
        }
        const dataMapCopy = makeNestedDeepCopy(dataMap);
        dataMapCopy.unmapped_internal_emp_ids = dataMapCopy.unmapped_internal_emp_ids.map(idStr => Number(idStr))
        dataMapCopy.unmapped_internal_dept_ids = dataMapCopy.unmapped_internal_dept_ids.map(idStr => Number(idStr))
        const body = {data_map: dataMapCopy, email_domain_whitelist: whitelist};
        post(EXTENSIONS.SET_HRIS_TO_EXTERNAL, body, onSuccess, onFail);
        setIsSubmitting(true);
    }

    return (
        <Stack spacing={2}>
            <Typography variant='h6'>Review and submit</Typography>
            <Typography>
                Click submit when you are ready. You can click back to review your input before submission.
            </Typography>

            {
                isSubmitting ? 
                <LoadingMessage msg='Submitting. This may take a minute.'/> :
                <Stack direction='row' spacing={4}>
                    <Button variant={'contained'} onClick={goBackStep}>
                        Back
                    </Button>

                    <Button variant={'contained'} onClick={onSubmitClick}>
                        Submit
                    </Button>
                </Stack>
            }
        </Stack>
    )
}