import { Stack} from "../../../../../node_modules/@mui/material/index";
import { FIELD_VALUE_TYPES } from "../../../inputs/FieldProps";
import TicketSelectField from "../../../inputs/TicketSelectField";

export const getErrorMessagesForCompanyFunc = (fieldStructure, sectionStructure, configMeta) => {
    const {company_funcs, choices_per_company_func} = configMeta;
    const {choice_desc} = fieldStructure;
    const {dependencies, function_id} = choice_desc;

    const errs = [];
    const selectedFunc = company_funcs.find(f => f.id === function_id);
    if(!selectedFunc){
        errs.push("Must select a function");
        return errs;
    }

    if(fieldStructure.value_type !== selectedFunc.value_type){
        errs.push("The selected function does not match the fields value type");
        return errs;
    }

    if(selectedFunc.dependencies_value_types.length !== dependencies.length){
        errs.push("The function requires a parameter that was not selected.")
        return errs;
    }

    if(selectedFunc.dependencies_value_types.length){
        const dependencyChoices = getCompanyFuncParamChoices(selectedFunc, sectionStructure, fieldStructure);
        const selectedDepId = dependencies[0];
        if(!dependencyChoices.some(c => c.value === selectedDepId)){
            errs.push("Invalid parameter selected");
        }
    }

    return errs;

}

const getCompanyFunctionChoices = (company_funcs) => {
    const functionChoices = {
        [FIELD_VALUE_TYPES.EMPLOYEE]: [],
        [FIELD_VALUE_TYPES.DEPARTMENT]: []
    };
    for(const f of company_funcs){
        const choice = {value: f.id, label: f.label};
        functionChoices[f.value_type].push(choice);
    }
    return functionChoices;
}

const getCompanyFuncParamChoices = (selectedFunc, sectionStructure, fieldStructure, contextFields) => {
    const dependencyChoices = [];
    if(selectedFunc && selectedFunc.dependencies_value_types.length){
        const requiredValueType = selectedFunc.dependencies_value_types[0];
        for(const f of contextFields){
            if(f.value_type === requiredValueType){
                dependencyChoices.push({value: f.id, label: f.label});
            }
        }
        for(const f of sectionStructure.fields){
            if(f.id === fieldStructure.id) break;
            if(f.value_type === requiredValueType){
                dependencyChoices.push({value: f.id, label: f.label});
            }
        }
    }
    return dependencyChoices;
}

export const CompanyFuncChoicePanelContent = ({fieldStructure, sectionStructure, setChoiceDesc, configMeta, tables, refetchTables, contextFields}) => {
    //NOTE: current imple assumes that company funcs have at most 1 parameter. UPdate if this assumption changes.
    //TODO: provide some text to help user know what to do

    // TODO: also "source for choices" dropdown shouldnt include static when value type is "user"
    const {company_funcs, choices_per_company_func} = configMeta;

    const {choice_desc} = fieldStructure;
    const {__meta_type, dependencies, choices, function_id} = choice_desc;

    const selectedFunc = company_funcs.find(f => f.id === function_id);
    const functionChoices = getCompanyFunctionChoices(company_funcs)
    const dependencyChoices = getCompanyFuncParamChoices(selectedFunc, sectionStructure, fieldStructure, contextFields);

    const setFunctionId = (funcId) => {
        const func = company_funcs.find(f => f.id === funcId);
        const numDependencies = func.dependencies_value_types.length;
        const deps = Array(numDependencies).fill('');
        const newFieldDesc = {
            ...choice_desc,
            function_id: funcId,
            dependencies: deps,
            choices: choices_per_company_func[funcId]
        }
        setChoiceDesc(newFieldDesc);
    }
    
    const setDependency = (fieldId) => {
        setChoiceDesc({...choice_desc, dependencies: [fieldId]});
    }
    return (
        <Stack spacing={2}>
            <TicketSelectField
                label={'Function'}
                value={function_id ? function_id : ''}
                setValue={setFunctionId}
                choices={functionChoices}
                sectionValues={{fieldValueType: fieldStructure.value_type}}
                dependencies={['fieldValueType']}
                fullWidth={true}
                />
            {
                (!function_id || (dependencies.length === 0)) ? null :
                <TicketSelectField
                    label={'Function param / dependency'}
                    value={dependencies[0]}
                    setValue={setDependency}
                    choices={dependencyChoices}
                    fullWidth={true}
                    />
            }
        </Stack>
    )
}