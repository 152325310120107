import { Autocomplete, TextField } from "../../../node_modules/@mui/material/index";


const SuggestedTextField = ({label, value, setValue, suggestions}) => {

    suggestions.sort((a, b) => a.localeCompare(b));

    const onValueChanged = (x) => {
        if(!x) setValue("")
        else setValue(x)
    }
    return (
        <Autocomplete
            freeSolo
            options={suggestions}
            renderInput={(params) => <TextField{...params} label={label} />}
            value={value}
            onChange={(event, newValue) => onValueChanged(newValue)}
            inputValue={value}
            onInputChange={(event, newInputValue) => onValueChanged(newInputValue)}
            fullWidth={true}
            />
    )   
}

export default SuggestedTextField