import React, {useState} from "react";
import { TextField} from "@mui/material";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";


const TicketDatePickerField = (props) => {
    const {value, setValue, idPrefix, label, helperText, hasBeenVisited, optional} = props

    const [visited, setVisited] = useState(hasBeenVisited)
    const [inputStarted, setInputStarted] = useState(false)
    
    const onChange = (e) => {
        if(!inputStarted) setInputStarted(true)
        setValue(e.target.value)
    }
    
    const onBlur = (e) => {
        if(!visited) setVisited(true)
    }
    
    const id = idPrefix + "-" + label
    const displayLabel = makeFieldDisplayLabel(label, optional)

    const error = (inputStarted && !value) || (visited && !value)
    if(value === "" && optional) error = false

    return(
        <TextField
            id={id}
            label={displayLabel}
            type="date"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            // className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}

export default TicketDatePickerField
