import React, { useState } from 'react';
import ConfirmActionDialog from "../../../generic/ConfirmActionDialog"
import { Box, Typography } from '../../../../../node_modules/@mui/material/index';
import TicketCheckbox from '../../../inputs/TicketCheckbox';


const MESSAGE = `
If you want to add this stage back before saving your changes, select it from the "Readd deleted stage" dropdown above the flow graph.
Attempting to simply create a new identical stage will result in the stage needing to be reapproved for tickets that have already passed the stage.
This is because when you save your updates, the system will need to adjust the various requests that are pending within this process.
`

export const NodeDeletionWarningDialog = ({close}) => {
    const [doNotRemind, setDoNotRemind] = useState(false);
    return (
        <ConfirmActionDialog
            title={'Deleted stages cannot be replaced'}
            body={
                <Box>
                    <Typography>
                        {MESSAGE}
                    </Typography>
                    <TicketCheckbox
                        label='Do not remind me again'
                        value={doNotRemind}
                        setValue={setDoNotRemind}
                        />
                </Box>
            }
            // handleCancel={() => onCancel(doNotRemind)}
            handleConfirm={() => close(doNotRemind)}
            // cancelText={"Keep stages"}
            confirmText={"Ok"}
            loading={false}
            disabled={false}
            // fullWidth
            // maxWidth
            />
    )
}