import { INSTANCE_TYPE_META_NAMES } from "../../../helpers/Constants";
import { CONTEXT_DEPENDENCIES } from "../../../helpers/TicketFormHelper";
import { CHOICE_DESC_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../../inputs/FieldProps";

const makeResourceField = (instanceType) => {
    const resourceTypeName = instanceType.resource_type.name;
    const resourceField = {
        id: CONTEXT_DEPENDENCIES.RESOURCE_ID,
        is_deleted: false,
        value_type: 'Auto',
        component: FIELD_COMPONENT_TYPES.SELECT,
        label: `${resourceTypeName} (Resource)`,
        short_label: `${resourceTypeName} (Resource)`,
        helper_text: '',
        is_optional: false,
        is_secret: false,
        notify_if_problematic: false, 
        hide_conditions: null,
        choice_desc: {
            __meta_type: CHOICE_DESC_TYPES.STATIC,
            dependencies: [],
            choices: [
                {value: 1, label: 'Resource #1'},
                {value: 2, label: 'Resource #2'},
            ]
        },
        is_user_role: false
    }
    return resourceField;
}

const makeEmpAccessorField = (instanceType, usersIdToNameObj) => {
    const accessorTypeName = "Employee";
    const choices = Object.keys(usersIdToNameObj).map(empId => {
        return {value: Number(empId), label: usersIdToNameObj[empId]}
    })

    const accessorField = {
        id: CONTEXT_DEPENDENCIES.EMP_ACCESSOR,
        is_deleted: false,
        value_type: FIELD_VALUE_TYPES.EMPLOYEE,
        component: FIELD_COMPONENT_TYPES.SELECT,
        label: `${accessorTypeName} (Accesor)`,
        short_label: `${accessorTypeName} (Accesor)`,
        helper_text: '',
        is_optional: false,
        is_secret: false,
        notify_if_problematic: false, 
        hide_conditions: null,
        choice_desc: {
            __meta_type: CHOICE_DESC_TYPES.STATIC,
            dependencies: [],
            choices: choices
        },
        is_user_role: false
    }
    return accessorField;
}

export const getContextFields = (instanceType, usersIdToNameObj) => {
    const typeOfInstanceType = instanceType.__meta_name;
    if(typeOfInstanceType === INSTANCE_TYPE_META_NAMES.ENTITY_INSTANCE_TYPE){
        return [];
    }
    else if(typeOfInstanceType === INSTANCE_TYPE_META_NAMES.ENTITY_ACCESS_TYPE){
        return [makeResourceField(instanceType)];
    }
    else if(typeOfInstanceType === INSTANCE_TYPE_META_NAMES.EMPLOYEE_ACCESS_TYPE){
        return [makeResourceField(instanceType), makeEmpAccessorField(instanceType, usersIdToNameObj)];
    }
    else{
        console.error(`unexpected instance type: ${typeOfInstanceType}`, {instanceType})
        return [];    
    }
}

export const getInitialContextValues = (contextFields) => {
    const output = {};
    for(const field of contextFields){
        output[field.id] = "";
    }
    return output;
}