import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material';
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";



//todo: dont wrap every checkbox in a <FormGroup> component. instead wrap all checkboxes if whole section is checkboxes?
const TicketCheckbox = (props) => {
    const {value, setValue, idPrefix, label, optional} = props
    const id = idPrefix + "-" +label

    const handleChange = (e) =>{
        setValue(!value)
    }
    const displayLabel = makeFieldDisplayLabel(label, optional)

    return(
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                    name={id}
                    id={id}
                    // value="SomeValue"
                    checked={value}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={displayLabel}
                labelPlacement={"end"}                
                />
        </FormGroup>
    )
    return (
        <Checkbox
            id={id}
            label={label}
            checked={value}
            onChange={handleChange}
            // inputProps={{ 'aria-label': 'controlled' }}
            />
    );
}

export default TicketCheckbox