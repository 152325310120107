import { Button } from "@mui/material";
import AbstractTable from "../tables/AbstractTable";
import React, { useState } from "react";
import EmployeeView from "./EmployeeView";
import { extract_emp_department_roles, isHRISManagedInternally} from "./hris_helpers";
import { useSearchParams } from "react-router-dom";
import { HRIS_VIEWS } from "./hris_constants";
import { hasGobalPermission } from "../permissions/perm_helper";
import Context from "../../Store";
import { ALL_PERMS } from "../permissions/perm_constants";
import { Box, Stack } from "../../../node_modules/@mui/material/index";
import TicketMultiSelectField from "../inputs/TicketMultiSelectField";
import { EMPLOYEE_STATUSES } from "../../helpers/Constants";
import { findCount } from "../../helpers/CommonUtil";


const EmployeesList = ({employees, onOpenEmpClick}) => {
    const {user} = React.useContext(Context);
    const shouldShowEmpStatus = hasGobalPermission(user, ALL_PERMS.ORG_ACTIONS.EDIT_ORG.key);

    const columnNames = [
        'Employee',
        shouldShowEmpStatus ? 'Status' : "",
        ''
    ]
    const renderRowCells = (employee) => {
        const { __meta_type, id, email, first_name, last_name, name, status, extra_data, role_tags } = employee
        
        return [
            employee.name,
            shouldShowEmpStatus ? employee.status : "",
            <Button onClick={() => onOpenEmpClick(employee)}>open</Button>
        ]
    }

    return (
        <AbstractTable
            rows={employees}
            rowToKey={emp => emp.id}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            rowHeight={"small"}
            />
    )

}


const EmployeesView = ({company, removeRole}) => {
    const {user} = React.useContext(Context);
    const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    const [searchParams, setSearchParams] = useSearchParams();
    const [statuses, setStatuses] = React.useState([EMPLOYEE_STATUSES.ACTIVE, EMPLOYEE_STATUSES.OFFBOARDING]);

    const onOpenEmpClick = (emp) => {
        setSearchParams({view: HRIS_VIEWS.EMP, emp_id: emp.id})
        // setSelectedEmpId(emp.id);
    }

    const hasEditPerm = hasGobalPermission(user, ALL_PERMS.ORG_ACTIONS.EDIT_ORG.key);
    const filteredEmps = (statuses.length === 0) ? employees : employees.filter(e => statuses.includes(e.status));

    if(hasEditPerm){
        const statusChoices = Object.values(EMPLOYEE_STATUSES).map(status => {
            const count = findCount(employees, (emp) => emp.status === status);
            return {value: status, label: `${status} (${count})`}
        })

        return (
            <Box>
                <TicketMultiSelectField
                    label={'Filter'}
                    value={statuses} 
                    setValue={setStatuses}
                    choices={statusChoices}
                    />
                <EmployeesList employees={filteredEmps} onOpenEmpClick={onOpenEmpClick}/>
            </Box>
        )
    }
    else{
        const empsToDisplay = employees.filter(e => [EMPLOYEE_STATUSES.ACTIVE, EMPLOYEE_STATUSES.OFFBOARDING].includes(e.status))
        return <EmployeesList employees={empsToDisplay} onOpenEmpClick={onOpenEmpClick}/>
    }
}

export default EmployeesView;