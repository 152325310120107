import { useContext, useState } from "react"
import { Box, Button, Stack, Typography } from "../../../node_modules/@mui/material/index"
import { EmailDomainWhitelistWidget } from "../configs/hris_integration/EmailDomainWhitelistPanel"
import { MyDivider } from "../generic/MyDivider"
import { EXTENSIONS, post } from "../../helpers/requests"
import Context from "../../Store"
import LabelValueDisplay from "../generic/LabelValueDisplay"
import { datetimeDisplay } from "../../helpers/FieldDisplayFormatters"
import LoadingMessage from "../generic/LoadingMessage"


export const ManageExternalHRISSettings = ({company, refetchCompany}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    return (
        <Stack>
            <ExternalHRISSyncSection company={company} refetchCompany={refetchCompany} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting}/>
            <MyDivider/>
            <ExternalHRISDomainWhitelistSection company={company} refetchCompany={refetchCompany} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting}/>
            <MyDivider/>
            {
                !isSubmitting ? null :
                <Box justifyContent='center' marginX='auto'>
                    <LoadingMessage msg={'Submitting... This may take a minute.'}/>
                </Box>
            }
        </Stack>
    )
}

const ExternalHRISSyncSection = ({company, refetchCompany, isSubmitting, setIsSubmitting}) => {
    const {alertSuccess, alertError} = useContext(Context);

    const onSyncClick = () => {
        const onSuccess = (resp) => {
            setIsSubmitting(false);
            alertSuccess('Synced userbase')
            refetchCompany()
        }
        const onFailure = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data);
        }
        
        setIsSubmitting(true);
        post(EXTENSIONS.UPDATE_HRIS_CACHE, {}, onSuccess, onFailure)
    }

    const lastSyncTime = company.last_sync_time;
    const lastSyncTimeText = lastSyncTime ? datetimeDisplay(lastSyncTime) : "N/A";
    return (
        <Box>
            <LabelValueDisplay label={'Last sync with your HRIS'} value={lastSyncTimeText}/>
            <Button onClick={onSyncClick} variant='contained' disabled={isSubmitting}>
                Sync
            </Button>
        </Box>
    )
}

const ExternalHRISDomainWhitelistSection = ({company, refetchCompany, isSubmitting, setIsSubmitting}) => {
    const {alertSuccess, alertError} = useContext(Context);
    const [whitelist, setWhitelist] = useState([...company.user_email_domain_whitelist]);

    const onSubmitClick = () => {
        const onSuccess = (resp) => {
            setIsSubmitting(false);
            alertSuccess('Updated domains and resynced userbase')
            refetchCompany()
        }
        const onFailure = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data);
        }

        setIsSubmitting(true);
        const body = {email_domain_whitelist: [...whitelist]};
        post(EXTENSIONS.UPDATE_EMAIL_DOMAIN_WHITELIST, body, onSuccess, onFailure);
    }
    return (
        <Box>
            <EmailDomainWhitelistWidget whitelist={whitelist} setWhitelist={setWhitelist} suggestions={[]}/>
            <Button onClick={onSubmitClick} variant='contained' disabled={isSubmitting}>
                Submit
            </Button>
        </Box>
    )
}