import { Box, Stack } from "../../../node_modules/@mui/material/index";
import { isFieldRelevent } from "../../helpers/TicketFormHelper";
import FormField from "./FormField";


const SectionForm = ({sectionStructure, sectionValues, setSectionValues, isEditable, downloadFileField, contextValues}) => {
    const isSectionDeleted = sectionStructure.is_deleted;

    const sectionSx = 
        isEditable ?
        {m: 2} :
        { border: 2, borderColor: '#000000', m: 2 }

    const spacing = isEditable ? 2 : 0;

    let visibleFieldIdx = 0; //just a counter for alternating field box color

    return (
        <Stack spacing={spacing} sx={sectionSx}>
            {
                sectionStructure.fields.map(fieldStructure => {
                    if(fieldStructure.is_deleted && isEditable) return null;
                    if(!isFieldRelevent(fieldStructure, sectionValues)) return null;

                    const fieldId = fieldStructure.id;
                    const fieldValue = sectionValues[fieldId];

                    if(isEditable) {
                        return (
                            <FormField
                                key={fieldId}
                                fieldStructure={fieldStructure}
                                value={fieldValue}
                                setValue={(x) => setSectionValues({...sectionValues, [fieldId]: x})}
                                isEditable={isEditable}
                                sectionValues={sectionValues}
                                contextValues={contextValues}
                                onDownloadClick={() => downloadFileField(fieldId)}
                                />
                        )
                    }
                    else {
                        if(fieldValue === undefined){
                            //explicit check for undefined (as ooposed to null), because then the value does not exist
                            //due to this being in context of an edit/edit request ticket
                            return null;
                        }
                        visibleFieldIdx += 1;
                        const fieldSx = {backgroundColor: (visibleFieldIdx % 2) ? "#90d0ff" : "#ffffff"};
                        return (
                            <Box sx={fieldSx} key={fieldId}>
                                <FormField
                                    key={fieldId}
                                    fieldStructure={fieldStructure}
                                    value={fieldValue}
                                    setValue={(x) => setSectionValues({...sectionValues, [fieldId]: x})}
                                    isEditable={isEditable}
                                    sectionValues={sectionValues}
                                    onDownloadClick={() => downloadFileField(fieldId)}
                                    contextValues={contextValues}
                                    />
                            </Box>
                        )
                    }
                })
            }
        </Stack>
    )
}

export default SectionForm;