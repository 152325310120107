import { Box, Button, Divider, IconButton, Link, Stack, Typography } from "@mui/material"
import { FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../inputs/FieldProps"
import React, { useState } from "react"
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { MULTI_SELECT_DELIM, MULTI_TEXT_DELIM } from "../../helpers/Constants";
import { fieldValueString, selectValueToLabel } from "../../helpers/FieldDisplayFormatters";
import Context from "../../Store";

const labelFromValue = (fieldStructure, fieldValue, sectionValues, usersMap, departmentsMap) => {
    let output = selectValueToLabel(fieldValue, fieldStructure.choice_desc.choices);

    if(output === fieldValue){
        if(fieldStructure.value_type === FIELD_VALUE_TYPES.EMPLOYEE) output = usersMap[fieldValue] + " (Invalid)"
        if(fieldStructure.value_type === FIELD_VALUE_TYPES.DEPARTMENT) output = departmentsMap[fieldValue] + " (Invalid)"
    }
    return output;
}
//TODO: remove assumption that url texts are only in MULTI_TEXT components
//TODO: use proper responsive truncation of text, rather than shortText hack
const MultiTextReview = ({fieldStructure, fieldValue}) => {
    const MAX_LINK_LEN = 50
    const allTexts = fieldValue ? fieldValue.split(MULTI_TEXT_DELIM) : [''];
    return(
        <Box m={2}>
            <Typography variant="subtitle1" fontSize={18}>{fieldStructure.label}</Typography>
            <Stack direction={"column"}>
            {
                allTexts.map((txt) => {
                    const shortText = (txt.length > MAX_LINK_LEN) ? (txt.substring(0, MAX_LINK_LEN) + "...") : txt
                    if(txt.startsWith("http")){
                        return (
                            <Link href={txt} underline="always" rel="noreferrer" target="_blank" key={txt}>
                                {shortText}
                            </Link>
                        )
                    }
                    else return <Typography variant="subtitle2" fontSize={18} key={txt}>{txt}</Typography>
                })
            }
            </Stack>
        </Box>
    )
}

const OLDFileLink = ({fieldStructure, fieldValue}) => {

    return (
        <Box m={2}>
            <Typography variant="subtitle1" fontSize={18}>{fieldStructure.label}</Typography>
            {
                [null, undefined, ""].includes(fieldValue) ?
                    <Typography variant="subtitle2" fontSize={18}>None</Typography> :
                    <Link href={fieldValue} underline="always" rel="noreferrer" target="_blank" key={fieldValue}>
                        {"Download"}
                    </Link>
            }
        </Box>
    )
}


const FileLink = ({fieldStructure, fieldValue, onClick}) => {

    return (
        <Box m={2}>
            <Typography variant="subtitle1" fontSize={18}>{fieldStructure.label}</Typography>
            {
                [null, undefined, ""].includes(fieldValue) ?
                    <Typography variant="subtitle2" fontSize={18}>None</Typography> :
                    <Button onClick={onClick}>
                        Download
                    </Button>
                    // <Link href={fieldValue} underline="always" rel="noreferrer" target="_blank" key={fieldValue}>
                    //     {"Download"}
                    // </Link>
            }
        </Box>
    )
}

// const FieldReviewOLD = ({fieldStructure, fieldValue, sectionValues}) => {
//     const {staticData} = React.useContext(Context);
//     const {all_users, all_departments} = staticData;
//     let displayValue = fieldValue

//     if(fieldValue === true) displayValue = "Yes"
//     else if(fieldValue === false) displayValue = "No"

//     else if(fieldStructure.component === FIELD_COMPONENT_TYPES.SELECT) displayValue = labelFromValue(fieldStructure, fieldValue, sectionValues, all_users, all_departments)

//     else if([FIELD_COMPONENT_TYPES.MULTISELECT, FIELD_COMPONENT_TYPES.MULTICHECK].includes(fieldStructure.component)){
//         if(typeof displayValue === 'string'){
//             displayValue = displayValue.split(MULTI_SELECT_DELIM)
//         }
//         if(Array.isArray(displayValue)){
//             displayValue = displayValue.map((x) => labelFromValue(fieldStructure, x, sectionValues, all_users, all_departments))
//             displayValue = displayValue.join(", ")
//         }
//     }
//     else if(fieldStructure.component === FIELD_COMPONENT_TYPES.MULTI_TEXT){
//         return <MultiTextReview fieldStructure={fieldStructure} fieldValue={fieldValue}/>
//     }
//     else if(fieldStructure.component === FIELD_COMPONENT_TYPES.FILEPICKER){
//         return <FileLink fieldStructure={fieldStructure} fieldValue={fieldValue}/>
//     }


//     return(
//         <Box m={2}>
//             <Typography variant="subtitle1" fontSize={18}>{fieldStructure.label}</Typography>
//             <Typography variant="subtitle2" fontSize={18}>{displayValue}</Typography>
//         </Box>
//     )
// }

const FieldReview = ({fieldStructure, fieldValue, sectionValues, onDownloadClick, contextValues}) => {
    const {staticData} = React.useContext(Context);
    // const {all_users, all_departments} = staticData;

    if(fieldStructure.component === FIELD_COMPONENT_TYPES.MULTI_TEXT){
        return <MultiTextReview fieldStructure={fieldStructure} fieldValue={fieldValue}/>
    }
    if(fieldStructure.component === FIELD_COMPONENT_TYPES.FILEPICKER){
        return <FileLink fieldStructure={fieldStructure} fieldValue={fieldValue} onClick={onDownloadClick}/>
    }

    const displayValue = fieldValueString(fieldValue, fieldStructure, staticData);

    return(
        <Box m={2}>
            <Typography variant="subtitle1" fontSize={18}>{fieldStructure.label}</Typography>
            <Typography variant="subtitle2" fontSize={18}>{displayValue}</Typography>
        </Box>
    )
}

export default FieldReview