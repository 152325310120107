//TODO: delete file if no references

import { getNextId, swapItemsAtIndices } from "../../../helpers/CommonUtil";
import { CHOICE_DESC_TYPES } from "../../inputs/FieldProps";
import { CLIENT_SECTION_ID_PREFIX, makeDefaultChoiceDesc } from "./field_builder_helper";
import { makeDefaultFieldStructure } from "./FieldStructureConfig";

export const addNewFieldToStructure = (formStructure, formValues, sectionId) => {
    const newFieldStructure = makeDefaultFieldStructure();
    const fieldId = newFieldStructure.id;

    const sectionStructure = formStructure.sections.find(s => s.id === sectionId);
    sectionStructure.fields.push(newFieldStructure);

    if(formValues){
        formValues[sectionId][fieldId] = "";
    }

    return newFieldStructure
}

export const removeFieldFromStructure = (formStructure, formValues, sectionId, fieldId) => {
    const sectionStructure = formStructure.sections.find(s => s.id === sectionId);
    const fields = sectionStructure.fields
    const fieldIdx = fields.findIndex(f => f.id === fieldId);
    const field = fields[fieldIdx];

    sectionStructure.fields.splice(fieldIdx, 1);

    if(formValues){
        delete formValues[sectionId][fieldId];
    }
    if(sectionStructure.fields.length === 0){
        removeSectionFromStructure(formStructure, formValues, sectionId);//todo: add params
    }

    return field;
}

export const addNewSectionToStructure = (formStructure, formValues, shouldAddField) => {
    const sectionIdNum = getNextId();
    const sectionId = `${CLIENT_SECTION_ID_PREFIX}${sectionIdNum}`
    const newSection = {
        id: sectionId,
        fields: [],
        metadata: {
            label: "New Section", 
            hide_at_submission: false
        }
    }
    formStructure.sections.push(newSection);
    if(formValues){
        formValues[sectionId] = {};
    }
    if(shouldAddField){
        addNewFieldToStructure(formStructure, formValues, sectionId);
    }
    return newSection;
}

export const removeSectionFromStructure = (formStructure, formValues, sectionId) => {
    const sectionStructureIdx = formStructure.sections.findIndex(s => s.id === sectionId);
    const section = formStructure.sections[sectionStructureIdx];
    formStructure.sections.splice(sectionStructureIdx, 1);

    if(formValues){
        delete formValues[sectionId];
    }

    return section;
}

export const addFieldToStructure = (formStructure, formValues, sectionId, field) => {
    const fieldId = field.id;

    let sectionStructure = formStructure.sections.find(s => s.id === sectionId);
    if(!formStructure.sections.some(s => s.id === sectionId)){
        sectionStructure = addNewSectionToStructure(formStructure, formValues, false)
        sectionStructure.id = sectionId;
    }

    sectionStructure.fields.push(field);

    if(formValues){
        if(formValues[sectionId] === undefined) formValues[sectionId] = {};
        formValues[sectionId][fieldId] = "";
    }
}

export const addOrMergeSectionIntoStructure = (formStructure, formValues, section, shouldKeepCurrentSectionMetadata) => {
    const sectionId = section.id;

    //if section does not already exist, just add it
    //if section does exist, then merge the fields
    const alreadyHasSection = formStructure.sections.find(s => s.id === sectionId);

    if(!alreadyHasSection){
        formStructure.sections.push(section);
    }
    else{
        const prevSectionIdx = formStructure.sections.findIndex(s => s.id === sectionId);
        const prevSectionFields = formStructure.sections[prevSectionIdx].fields;

        if(!shouldKeepCurrentSectionMetadata){
            formStructure.sections[prevSectionIdx] = section;
        }
        formStructure.sections[prevSectionIdx].fields = [...prevSectionFields, section.fields]
    }

    const currSection = formStructure.sections.find(s => s.id === sectionId)
    if(formValues){
        if(formValues[sectionId] === undefined) formValues[sectionId] = {};

        for(const field of currSection.fields){
            const fieldId = field.id;
            if(formValues[sectionId][fieldId] === undefined) formValues[sectionId][fieldId] = ""
        }
    }
    return currSection;
}

export const getSectionIdWithFieldId = (formStructure, fieldId) => {
    for(const section of formStructure.sections){
        if(section.fields.some(f => f.id === fieldId)){
            return section.id;
        }
    }
    return null;
}

export const addFieldBackToActiveStructure = (activeFormStructure, activeFormValues, deletedStructure, fieldId) => {
    const sectionId = getSectionIdWithFieldId(deletedStructure, fieldId)
    const field = removeFieldFromStructure(deletedStructure, null, sectionId, fieldId)
    cleanFieldForAddingBack(field);

    addFieldToStructure(activeFormStructure, activeFormValues, sectionId, field);
    return field
}

export const deleteFieldFromActiveStructure = (activeFormStructure, activeFormValues, deletedStructure, sectionId, fieldId) => {
    const field = removeFieldFromStructure(activeFormStructure, activeFormValues, sectionId, fieldId);
    addFieldToStructure(deletedStructure, null, sectionId, field);
    return field;
}

export const deleteSectionFromActiveStructure = (activeFormStructure, activeFormValues, deletedStructure, sectionId) => {
    //remove section from active
    //add or merge section into deleted

    const section = removeSectionFromStructure(activeFormStructure, activeFormValues, sectionId);
    addOrMergeSectionIntoStructure(deletedStructure, null, section, false)
}

export const cleanFieldForAddingBack = (field) => {
    //TODO: recieve the structure as param and determine if it needs to be cleaned
    field.hide_conditions = null;
    if(field.choice_desc && field.choice_desc.__meta_type !== CHOICE_DESC_TYPES.STATIC){
        field.choice_desc = makeDefaultChoiceDesc()
    }
}