import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat, get_emp_dept_role } from "./hris_helpers";
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import { EXTENSIONS, post } from "../../helpers/requests";
import Context from "../../Store";
import { REQUEST_STATES } from "../../helpers/Constants";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { useSearchParams } from "react-router-dom";
import { HRIS_VIEWS } from "./hris_constants";
import EmpForm, { getEmpFormErrorMsg } from "./EmpForm";

const EditEmployeeView = ({company, refetchCompany}) => {
    const {alertSuccess, alertError, staticData } = React.useContext(Context);
    const [searchParams, setSearchParams] = useSearchParams();

    const empId = Number(searchParams.get('emp_id'));
    const emp = company.employees.find(e => e.id === empId);
    const initialEmpState = {first_name: emp.first_name, last_name: emp.last_name, email: emp.email, extra_data: emp.extra_data};
    // const [emp, setEmp] = useState({first_name: "", last_name: "", email: "", extra_data: getDefaultSectionValues(empStructure)})
    
    const [empForm, setEmpForm] = React.useState(initialEmpState);
    const [submitState, setSubmitState] = React.useState(REQUEST_STATES.NOT_SENT);

    const onSubmitClick = () => {//emp_id, department_id, tags, is_manager, extra_data
        // const body = {...newRole, extra_data: extraData};
        const errMsg = getEmpFormErrorMsg(emp);
        if(errMsg){
            alertError(errMsg);
            return;
        }
        
        const body = {...empForm, employee_id: empId};

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            alertSuccess("Employee Edited")
            refetchCompany();
            //TODO: navigate to emp?
        }
        const onFailure = (e) => {
            setSubmitState(REQUEST_STATES.FAILED)
            alertError(e.response.data)
        }
        setSubmitState(REQUEST_STATES.SENDING)
        post(EXTENSIONS.EDIT_EMPLOYEE, body, onSuccess, onFailure)
    }
    // if(!emp) return <div>Employee not found</div>


    return (
        <EmpForm
            emp={empForm}
            setEmp={setEmpForm}
            empStructure={company.employee_extra_data_structure}
            submitState={submitState}
            onSubmitClick={onSubmitClick}
            />
    )
}

export default EditEmployeeView;