import PageTitle from "../generic/PageTitle";
import { AppBar, Box, Button, Tab, Tabs } from "@mui/material";
import { exportArrayToCsv } from "../../helpers/csv_downloader";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import ActionsTable from "../tables/ActionsTable";
import { useParams } from "react-router-dom";
import React from "react";
import Context from "../../Store";
import { doesEntityHaveMemberFlow } from "../../helpers/CommonUtil";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";

const exportDenseCsv = (actions, filenamePrefix) => {
    const arr = actions.map((action) => {
        return {...action, ticket: action.ticket.info.name}
    })
    exportArrayToCsv(arr, `${filenamePrefix}_papertrail_dense`)
}
const exportExpandedCsv = (actions, filenamePrefix) => {
    console.log("Not implemented")
    // exportArrayToCsv(arr, `${filenamePrefix}_papertrail_detailed`)
}

const PaperTrailPage = () => {
    const {entity_type_name} = useParams();
    const {staticData} = React.useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();
    const data = useData(EXTENSIONS.GET_PAPER_TRAIL, {entity_type_name});
    
    const [tabIdx, setTabIdx] = React.useState(0);

    const awaitedDatas = [configSyncState, data];
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;
    
    const hasMemberFlow = doesEntityHaveMemberFlow(staticData, entity_type_name);
    const actions = data.actions;
    const title = `${entity_type_name} Paper Trail`;
    const isDisplayingMemberFlow = (tabIdx != 0);
    const filenamePrefix = `${entity_type_name}-${isDisplayingMemberFlow ? "Members" : "Main"}`;
    const actionsToDisplay = isDisplayingMemberFlow ? actions.member_flow_actions : actions.main_flow_actions;

    return (
        <div key={`PaperTrailPage-${entity_type_name}`}>

            <PageTitle title={title}/>

            <Button onClick={e => exportDenseCsv(actionsToDisplay, filenamePrefix)}>
                Export to CSV (Dense)
            </Button>
            <Button onClick={e => exportExpandedCsv(actionsToDisplay, filenamePrefix)}>
                Export to CSV (Detailed)
            </Button>

            <Box sx={{ width: '100%' }}>
                {
                    hasMemberFlow &&
                    <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
                        <AppBar position="static" sx={{marginTop:0.25}}>
                            <Tabs value={tabIdx} onChange={(e, val) => setTabIdx(val)} indicatorColor="secondary" textColor="inherit">
                                <Tab label={"Tickets"} id={"Tickets"} key={"Tickets"}/>
                                <Tab label={"Members"} id={"Members"} key={"Members"}/>
                            </Tabs>
                        </AppBar>
                    </Box>
                }
                <Box marginTop={2} sx={{ width: '95%',justifyContent: "center", alignItems: "center", marginX: "auto"}}>
                    <ActionsTable entityName={entity_type_name} actions={actionsToDisplay} key={`${entity_type_name}-${tabIdx}`}/>
                </Box>
            </Box>

        </div>
    )
}

export default PaperTrailPage;
