const MUL = 1;
const X_SPACING = 20 * MUL;
const Y_SPACING = 5 * MUL;
const TEXT_HEIGHT = 0 * MUL;
const EDGE_WIDTH = 60 * MUL;

const ANIMATE_EDGES = false;//true;



const exampleApprovalProcess ={
    id: 1,
    name: 'Main',
    status: "enabled",
    stages:[
        {id: 2, name: "Manager", status: "approved"},
        {id: 3, name: "Sponsor", status: "approved"},
        {id: 4, name: "Legal", status: "approved"},
        {id: 5, name: "Accounting + HR", status: "enabled", 
            approval_processes:[
                {
                    id: 6,
                    name: "Accounting",
                    status: "enabled",
                    stages:[
                        {id: 7, name: "Tipalti Invite", status: "approved"},
                        {id: 8, name: "Tipalti Complete", status: "enabled"},
                    ]
                },
                {
                    id: 9,
                    name: "HR",
                    status: "enabled",
                    stages:[
                        {id: 10, name: "Data Privacy", status: "enabled"},
                        {id: 11, name: "Background Check", status: null},
                        {id: 55, name: "wtvr", status: "enabled", 
                            approval_processes:[
                                {
                                    id: 56,
                                    name: "Accounting",
                                    status: "enabled",
                                    stages:[
                                        {id: 57, name: "Tipalti Invite", status: null},
                                        {id: 58, name: "Tipalti Complete", status: null},
                                    ]
                                },
                                {
                                    id: 59,
                                    name: "HR",
                                    status: "enabled",
                                    stages:[
                                        {id: 510, name: "Data Privacy", status: null},
                                        {id: 511, name: "Background Check", status: null},
                                        
                                    ]
                                },
                        ]}
                    ]
                },
        ]},
        {id: 12, name: "Complete!", status: null},
    ]
}


export const approvalProcessToGraph = (approvalProcess, parentStageId, isInteractable, hideGroupingNodes) => {
    const nodes = []
    const edges = []

    const processIdStr = ""+approvalProcess.id;
    const processNode = {
        id: processIdStr,
        type: 'parentStageNode',
        data: approvalProcess,
        hidden: hideGroupingNodes,
        draggable: isInteractable,
        connectable: isInteractable,
        selectable: isInteractable  
    };
    nodes.push(processNode);
    let graphHeight = TEXT_HEIGHT;
    let graphWidth = X_SPACING;

    const stages = approvalProcess.stages;
    const directChildStageNodes = [];
    for(let stageIdx=0; stageIdx<stages.length; stageIdx++){
        const stage = stages[stageIdx];
        const [stageNodes, stageEdges, mainStageNode] = stageObjToGraph(stage);
        directChildStageNodes.push(mainStageNode);

        //set parent and position of process graphs
        mainStageNode.parentNode = processIdStr;
        mainStageNode.position = { x: graphWidth, y: TEXT_HEIGHT};

        nodes.push(...stageNodes);
        edges.push(...stageEdges);

        graphHeight = Math.max(graphHeight, mainStageNode.data.height + (2 * Y_SPACING + TEXT_HEIGHT));
        graphWidth += (mainStageNode.data.width + EDGE_WIDTH);

        if(stageIdx > 0){
            const prevId = stages[stageIdx - 1].id;
            const edge = {
                id: `e${prevId}-${stage.id}`,
                source: ""+prevId,
                target: ""+stage.id,
                animated: ANIMATE_EDGES,
                style: { stroke: '#000fff' },
                sourceHandle: 'src',
                targetHandle: 'dst',
                zIndex: 2,
                markerEnd:{
                    type: 'arrow',
                    color: '#000fff'
                }
            }
            edges.push(edge);
        }
        if((stageIdx === 0) && parentStageId){
            const prevId = parentStageId;
            const edge = {
                id: `e${prevId}-${stage.id}`,
                source: ""+prevId,
                target: ""+stage.id,
                animated: ANIMATE_EDGES,
                style: { stroke: '#000fff' },
                sourceHandle: 'enter',
                targetHandle: 'dst',
                zIndex: 2,
                type: "straight",
                markerEnd:{
                    type: 'arrow',
                    color: '#000fff'
                }
            }
            edges.push(edge);            
        }
        if((stageIdx === stages.length - 1) && parentStageId){
            const edge = {
                id: `e${stage.id}-${parentStageId}`,
                source: ""+stage.id,
                target: ""+parentStageId,
                animated: ANIMATE_EDGES,
                style: { stroke: '#000fff' },
                sourceHandle: 'src',
                targetHandle: 'exit',
                zIndex: 2,
                type: "straight",
                markerEnd:{
                    type: 'arrow',
                    color: '#000fff'
                }
            }
            edges.push(edge);            
        }
    }

    const centerY = ((graphHeight - TEXT_HEIGHT) / 2) + TEXT_HEIGHT;
    for(let i = 0; i < directChildStageNodes.length; i++){
        const directChildStageNode = directChildStageNodes[i];
        const y = centerY - (directChildStageNode.data.height / 2);
        directChildStageNode.position.y = y;
    }

    processNode.data.height = graphHeight// + (2 * Y_SPACING);
    processNode.data.width = graphWidth + X_SPACING - EDGE_WIDTH;

    return [nodes, edges, processNode];
}

const parallelStageObjToGraph = (stage, isInteractable, hideGroupingNodes) => {
    const nodes = [];
    const edges = [];

    const stageIdStr = ""+stage.id;
    const stageNode = {
        id: stageIdStr,
        type: 'parentStageNode',
        data: stage,
        draggable: isInteractable,
        connectable: isInteractable,
        selectable: isInteractable,
        // hidden: true
    };
    nodes.push(stageNode);
    let graphHeight = TEXT_HEIGHT + Y_SPACING;
    let graphWidth = 0;
    for(let processIdx=0; processIdx < stage.approval_processes.length; processIdx++){
        const process = stage.approval_processes[processIdx];
        const [processNodes, processEdges, mainProcessNode] = approvalProcessToGraph(process, stageIdStr, isInteractable, hideGroupingNodes);

        //set parent and position of process graphs
        mainProcessNode.parentNode = stageIdStr;
        mainProcessNode.position = { x: X_SPACING, y: graphHeight};

        nodes.push(...processNodes);
        edges.push(...processEdges);

        graphHeight += mainProcessNode.data.height + Y_SPACING;
        graphWidth = Math.max(graphWidth, mainProcessNode.data.width);
    }

    stageNode.data.height = graphHeight;
    stageNode.data.width = graphWidth + (2 * X_SPACING);

    return [nodes, edges, stageNode];
}

const atomicStageObjToGraph = (stage) => {
    const width = 7 * stage.name.length + 20;
    const height = 30;
    const node = {
        id: ""+stage.id,
        type: 'atomicStageNode',
        data: {...stage, width, height},
    }
    const edges = []
    return [[node], edges, node]
}

const stageObjToGraph = (stage, isInteractable, hideGroupingNodes) => {
    const isAtomicStage = (stage.approval_processes === undefined);
    if(isAtomicStage) return atomicStageObjToGraph(stage);
    return parallelStageObjToGraph(stage, isInteractable, hideGroupingNodes)
}