import { ACCESS_REQUEST_TYPES, COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, INSTANCE_TYPE_META_NAMES } from "../../helpers/Constants";
import { isAccessTypeForEmployee } from "../../helpers/StaticDataHelper";



export const getResourceAccessRemovalDescription = (entityTypeName) => `\
This is an approval process that will be automatically initiated by the system when \
a ${entityTypeName} is deactivated and there is outstanding access to that ${entityTypeName}. \
A single process is initiated, regardless of how many things have access to the ${entityTypeName}. \
This is where you would add your business logic for wholesale removal of access to the ${entityTypeName}. \
When initiated, the process is mandatory and cannot be rejected or canceled.
`

export const getMandatoryAccessRemovalDescription = (accessType) => {
    const accessorTypeLabel = isAccessTypeForEmployee(accessType) ? 'Employee' : accessType.accessor_type.name;
    const resourceTypeLabel = accessType.resource_type.name;

    return `\
This is an approval process that is automatically initiated by the system when a ${accessorTypeLabel} \
is deactivated and it still has outstanding access to a ${resourceTypeLabel}. A process is initiated for each \
${resourceTypeLabel} that the ${accessorTypeLabel} has access to. This is where you would add your business logic \
for removing this specific access of the ${accessorTypeLabel} to the ${resourceTypeLabel}. When initiated, the process is mandatory and cannot \
be rejected or canceled.
`
}

const getRequestTypePresentParticiple = (isEntity, requestType) => {
    
    switch(requestType){
        case ENTITY_REQUEST_TYPES.ADD:
        case ENTITY_REQUEST_TYPES.REOPEN:
            return "Adding / Reopening"
        case ENTITY_REQUEST_TYPES.REMOVE:
            return "Removing"
        case ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL:
            return "Fully removing access to"
        case COMMON_REQUEST_TYPES.EDIT_REQUEST:
            return "Editing a field value"
        case ACCESS_REQUEST_TYPES.GRANT:
            return "Granting"
        case ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL:
            return "Mandatory Removing"
    }
}

const fieldIdToLabel = (instanceType, editFieldId) => {
    for(const section of instanceType.structure.sections){
        for(const field of section.fields){
            if(field.id === editFieldId){
                return field.label;
            }
        }
    }
    return "?";
}

export const getProcessLabel = (instanceType, requestType, editFieldId) => {
    const isEntity = (instanceType.__meta_name === INSTANCE_TYPE_META_NAMES.ENTITY_INSTANCE_TYPE);

    // TODO: handle for access type (has no name, just derived label)
    // let output = `Approval Process for tickets of type: ${requestType}`;
    const editFieldLabel = editFieldId ? fieldIdToLabel(instanceType, editFieldId) : null;

    const name = instanceType.name;
    if(isEntity){
        switch(requestType){
            case ENTITY_REQUEST_TYPES.ADD:
            case ENTITY_REQUEST_TYPES.REOPEN:
                return `Adding / Reopening a ${name}`
            case ENTITY_REQUEST_TYPES.REMOVE:
                return `Removing a ${name}`
            case ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL:
                return `Removing all access to a ${name}`
            case COMMON_REQUEST_TYPES.EDIT_REQUEST:
                return `Editing the ${editFieldLabel} field of a ${name}`
            default:
        }
    }
    else{
        switch(requestType){
            case ACCESS_REQUEST_TYPES.GRANT:
                return `Granting access`
            case ACCESS_REQUEST_TYPES.REMOVE:
                return `Removing access`
            case ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL:
                return `Mandatory access removal`            
            case COMMON_REQUEST_TYPES.EDIT_REQUEST:
                return `Editing the ${editFieldLabel} field`
        }
    }
    return `undefined`            

}

export const getProcessPageTitle = (instanceType, requestType, editFieldId) => {
    const isEntity = (instanceType.__meta_name === INSTANCE_TYPE_META_NAMES.ENTITY_INSTANCE_TYPE);

    let output = getProcessLabel(instanceType, requestType, editFieldId);
    if(!isEntity) output += ` (${instanceType.name})` 
    return output;
}