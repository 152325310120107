import { getPendingActivenessTicket } from "../../helpers/CommonUtil";
import { getInstanceType } from "../../helpers/StaticDataHelper";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { ALL_PERMS } from "./perm_constants";

const isAccessInstance = (instance) => {
    return instance.__meta_type === 'AccessInstance';
}

export const hasGobalPermission = (user, actionKey) => {
    const globalPerms = user.permissions.user_profile.permissions;
    const hasPermission = globalPerms[actionKey];
    return hasPermission;
}
export const hasPermToEditPerms = (user) => {
    return hasGobalPermission(user, ALL_PERMS.SYSTEM_ACTIONS.EDIT_PERMS.key);
}

export const hasEmpPermission = (userData, empId, actionKey) => {
    return hasGobalPermission(userData, actionKey) || hasEmpPermissionFromManagerRole(userData, empId, actionKey)

}
export const hasInstancePermissions = (userData, staticData, actionKey, instance, instanceType) => {
    // const {user_profile, entity_role_profiles, org_roles_profile} = userData.permissions;
    const globalPerms = userData.permissions.user_profile.permissions;
    const managerPerms = userData.permissions.org_roles_profile.manager_section.permissions;
    // const entityRoleProfiles = userData.permissions.entity_role_profiles;

    const {managed_emps} = userData;
    // const {entity_types} = staticData;
    
    //Global perms
    if(globalPerms[actionKey]){
        return true;
    }

    if(hasPermissionFromEntityRoles(userData, staticData, actionKey, instance, instanceType)){
        return true;
    }

    //Manager perms
    if(isAccessInstance(instance)){
        if(hasEmpPermissionFromManagerRole(userData, instance.accessor.id, actionKey)){
            return true;
        }
    }

    return false;

    // if(isAccessInstance(instance)){
    //     const empAccessorId = instance.accessor.id;
    //     const isManagerOfAccessor = managed_emps.includes(empAccessorId);
    //     const doesManagerHavePermission = managerPerms[actionKey];
    //     if(isManagerOfAccessor && doesManagerHavePermission){
    //         return true;
    //     }
    // }
}

const hasPermissionFromEntityRoles = (userData, staticData, actionKey, instance, instanceType) => {
    const entityRoleProfiles = userData.permissions.entity_role_profiles;
    const userId = userData.user_id;
    for(const prof of entityRoleProfiles){
        // const {
        //     id, root_instance_type_id, role_field_desc, root_section, member_section,
        //     accessor_root_section, accessor_member_section, resource_root_section, resource_member_section
        // } = prof;
        
        const applicableSections = _get_applicable_sections(userId, instance, instanceType, prof, staticData);
        for(const profSection of applicableSections){
            if(profSection.permissions[actionKey]){
                return true
            }
        }
    }
    return false;
}
const _get_applicable_sections = (user_id, instance, instance_type, entity_role_prof, staticData) => {
    if(isAccessInstance(instance)){
        return _get_applicable_sections_for_access(user_id, instance, instance_type, entity_role_prof, staticData)
    }
    else{
        return _get_applicable_sections_for_entity(user_id, instance, instance_type, entity_role_prof)
    }
}
const _get_applicable_sections_for_entity = (user_id, instance, instance_type, entity_role_prof) => {
    const output = []
    const isRoot = !instance.parent;
    if(isRoot){
        const root_type_id = instance_type.id
        const instance_with_role = instance
        if(_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
            output.push(entity_role_prof.root_section)
        }
    }
    else{
        const root_type_id = instance_type.parent_type.id
        const instance_with_role = instance.parent
        if(_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
            output.push(entity_role_prof.member_section)
        }
    }
    return output
}

const _get_applicable_sections_for_access = (user_id, instance, instance_type, entity_role_prof, staticData) => {
    const output = []
    let root_type_id = null
    let instance_with_role = null
    //# section for resource
    const resource_instance_type = getInstanceType(staticData, instance_type.resource_instance_type_id);
    const isResourceRoot = !resource_instance_type.parent_type;
    if(isResourceRoot){
        root_type_id = resource_instance_type.id
        instance_with_role = instance.resource
        if (_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
            output.push(entity_role_prof.resource_root_section)
        }
    }
    else{
        root_type_id = resource_instance_type.parent_type.id
        instance_with_role = instance.resource.parent
        if(_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
            output.push(entity_role_prof.resource_member_section)
        }
    }
            
    //# section for accessor
    const isEntityAccess = (instance_type.__meta_name === 'EntityAccessType');
    if(isEntityAccess){
        const accessor_instance_type = getInstanceType(staticData, instance_type.accessor_instance_type_id);
        const isAccessorRoot = !accessor_instance_type.parent_type;
        
        if(isAccessorRoot){
            root_type_id = accessor_instance_type.id
            instance_with_role = instance.accessor
            if(_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
                output.push(entity_role_prof.accessor_root_section)
            }
        }
        else{
            root_type_id = accessor_instance_type.parent_type.id;
            instance_with_role = instance.accessor.parent
            if(_is_applicable(entity_role_prof, root_type_id, instance_with_role, user_id)){
                output.push(entity_role_prof.accessor_member_section)           
            }
        }
    }

    return output
}
    
const _is_applicable = (entity_role_prof, root_type_id, instance_with_role, user_id) => {
    const {section_key, field_key} = entity_role_prof.role_field_desc;
    if(entity_role_prof.root_instance_type_id === root_type_id){
        let form_values_with_role = null
        if(instance_with_role.is_active){
            form_values_with_role = instance_with_role.state.form_values
        }
        else{
            const pending_state_ticket = getPendingActivenessTicket(instance_with_role);
            if(!pending_state_ticket){
                return false;
            }
            form_values_with_role = pending_state_ticket.form_values
        }

        const user_id_with_role = form_values_with_role[section_key][field_key]
        const user_holds_role = (user_id_with_role === user_id)
        return user_holds_role
    }
    return false
}

const hasEmpPermissionFromManagerRole = (userData, empId, actionKey) => {
    // console.log('org perm check', {userData, empId, actionKey})
    const managerPerms = userData.permissions.org_roles_profile.manager_section.permissions;
    const {managed_emps} = userData;

    const isManagerOfAccessor = managed_emps.includes(empId);
    const doesManagerHavePermission = managerPerms[actionKey];
    const output = isManagerOfAccessor && doesManagerHavePermission
    return output;
}

export const hasPermissionToViewRelatedAccessOfEntity = (userData, staticData, entity, entityInstanceType) => {
    const actionKey = ALL_PERMS.ACCESS_ACTIONS.VIEW.key;
    if(hasGobalPermission(userData, actionKey)) return { hasPermForAccessor: true, hasPermForResource: true}
    if(!entity.is_active) return { hasPermForAccessor: false, hasPermForResource: false}
    

    // see what roles the user has for the entity/ entity's parent
    // for each role:
    //      see if role grants permission to view access (entity as accessor, as accessor root, as resource)
    const userId = userData.user_id;
    const isEntityRoot = !entity.parent;
    const root = entity.parent ? entity.parent : entity;
    const rootType = entity.parent ? entityInstanceType.parent_type : entityInstanceType;

    // const releventEntityRoleProfiles = [];
    let hasPermForEntityAsResource = false;
    let hasPermForEntityAsAccessor = false;
    for(const prof of userData.permissions.entity_role_profiles){
        // const {
        //     id, root_instance_type_id, role_field_desc, root_section, member_section,
        //     accessor_root_section, accessor_member_section, resource_root_section, resource_member_section
        // } = prof;

        const isProfForInstance = prof.root_instance_type_id === rootType.id;
        if(isProfForInstance){
            const {section_key, field_key} = prof.role_field_desc;
            const user_id_with_role = root.state.form_values[section_key][field_key];
            const doesUserHoldRole = (user_id_with_role === userId);
            if(doesUserHoldRole){
                const {accessor_root_section, accessor_member_section, resource_root_section, resource_member_section} = prof;
                // console.log(`relevent role ${field_key}`, {field_key, actionKey, isEntityRoot, resource_root_section})
                // check if user has full right to view access where this entity is the resource
                if(isEntityRoot){
                    hasPermForEntityAsResource ||= resource_root_section.permissions[actionKey];
                }
                
                if(isEntityRoot){
                    hasPermForEntityAsAccessor ||= accessor_root_section.permissions[actionKey];
                }
                else{
                    hasPermForEntityAsAccessor ||= accessor_member_section.permissions[actionKey];
                }

                // check if user has full right to view access where this entity is the accessor

                // const prof_sections = [accessor_root_section, accessor_member_section, resource_root_section, resource_member_section];
                // for(const profSection in prof_sections){
                //     if(profSection.permissions[actionKey]){
                //         // return true; //TODO: check more granually
                //     }
                // }
            }
        }

    }
    return {
        hasPermForAccessor: hasPermForEntityAsAccessor,
        hasPermForResource: hasPermForEntityAsResource,
    }
}