import React, { useContext, useState } from "react"
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks"
import { EXTENSIONS } from "../../helpers/requests"
import Context from "../../Store"
import LoadingMessage from "../generic/LoadingMessage"
import PageTitle from "../generic/PageTitle"
import AbstractTable from "../tables/AbstractTable"
import { Box, Divider, Stack, Typography } from "../../../node_modules/@mui/material/index"
import { getAccessApprovalProcess, getAccessTypeLabel, getAccessorLabel } from "../../helpers/access_helper"
import { TICKET_STATUS } from "../../helpers/Constants"
import { StagesCell } from "../entity/PendingEntityTicketsPage"
import { getReviewerNamesOfStages } from "../../helpers/TableHelper"
import ProgressGraph from "../graph_stage_progress/ProgressGraph"
import LabelValueDisplay from "../generic/LabelValueDisplay"


const AccessTicketProgress = ({accessType, accessWithTicket}) => {
    if(!accessWithTicket) return null;
    const {access, ticket} = accessWithTicket;

    const approvalProcess = getAccessApprovalProcess(accessType, ticket);
    const stageIds = ticket.stages.map(stageInfo => stageInfo.stage_id);


    return <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={stageIds}/>
    // return (
    //     <Box border={2} borderRadius={1}>

    //         <ProgressGraph approvalProcess={approvalProcess} enabledStageIds={stageIds}/>
    //         <Stack direction='row' spacing={4}>
    //             <LabelValueDisplay label={'Process / Request type'} value={ticket.request_type}/>
    //             <LabelValueDisplay label={'Accessor'} value={access.accessor.name}/>
    //             <LabelValueDisplay label={'Resource'} value={access.resource.name}/>
    //         </Stack>
    //     </Box>
    // )
}

// const splitA
const PendingAccessTicketsDisplay = ({accessType, accessesWithTickets}) => {
    const {staticData} = React.useContext(Context);
    const [hoveredRow, setHoveredRow] = React.useState(null);

    if(accessesWithTickets.length === 0){
        return <Typography>{getAccessTypeLabel(accessType)}: None</Typography>
    }

    const columnNames = ['Accessor', 'Resource', 'Request type', 'Stages']
    
    const renderRowCells = (accessWithTicket) => {
        const {access, ticket} = accessWithTicket;
        //TODO: replace magic string "Pending" with constant
        const stagesNames = ticket.stages.map(s => s.stage_name).join(", ");
        const stagesReviewers = getReviewerNamesOfStages(access, ticket, staticData, accessType)
        const stagesCell = <StagesCell stagesReviewers={stagesReviewers} stagesNames={stagesNames}/>
        return [
            getAccessorLabel(access),
            access.resource.name,
            ticket.request_type,
            stagesCell
        ]
    }

    return (
        <Box>
            <Typography variant='h6'>{getAccessTypeLabel(accessType)}</Typography>
            <AccessTicketProgress accessType={accessType} accessWithTicket={hoveredRow}/>
            <AbstractTable
                rows={accessesWithTickets}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowToKey={(row) => `${row.access.accessor.id}-${row.access.resource.id}-${row.ticket.id}`}
                rowHeight={'small'}
                onRowHover={(row) => setHoveredRow(row)}
                />
        </Box>
    )
}

const sortAccessAcc = (acc) => {
    const accessTypeIds = Object.keys(acc).map(idStr => Number(idStr));

    for(const accessTypeId of accessTypeIds){
        const accesses = acc[accessTypeId];
        accesses.sort((a, b) => {
            const aLabel = `${getAccessorLabel(a)}-${a.resource.name}`;
            const bLabel = `${getAccessorLabel(b)}-${b.resource.name}`;
            return aLabel.localeCompare(bLabel);
        })
    }

}

const splitAccessAccByTickets = (acc) => {
    const accessTypeIds = Object.keys(acc).map(idStr => Number(idStr));

    for(const accessTypeId of accessTypeIds){
        const accesses = acc[accessTypeId];
        const accessesSplit = []
        for(const access of accesses){
            const pendingTickets = access.tickets.filter(t => t.status === TICKET_STATUS.PENDING);
            for(const t of pendingTickets){
                const accessWithTicket = {access: access, ticket: t}
                accessesSplit.push(accessWithTicket);
            }
        }
        acc[accessTypeId] = accessesSplit;
    }
}

const sortAndSplit = (acc) => {
    sortAccessAcc(acc);
    splitAccessAccByTickets(acc);
}

const AccessPendingTicketsPage = () => {
    const {alertError, staticData: {access_types}} = useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const onSuccess = (resp) => sortAndSplit(resp.data)
    const accessesAcc = useData(EXTENSIONS.GET_ACCESSES_WITH_PENDING_TICKETS, {}, onSuccess);

    const awaitedDatas = [configSyncState, accessesAcc]
    if(awaitedDatas.includes(false)) return <div>Failed to load</div>;
    else if(awaitedDatas.includes(null)) return <LoadingMessage/>;


    return (
        <Box>
            <PageTitle title={"Pending Access Requests"}/>
            <Stack spacing={4}>
                {
                    Object.keys(accessesAcc).map(accessTypeIdStr => {
                        const accessType = access_types.find(at => at.id === Number(accessTypeIdStr));
                        if(!accessType){
                            console.log('no type:', {accessTypeIdStr, id: Number(accessTypeIdStr), access_types, accessesAcc, accessType});
                            return null;
                        }
                        return (
                            <React.Fragment key={accessTypeIdStr}>
                                <PendingAccessTicketsDisplay
                                    key={accessTypeIdStr}
                                    accessType={accessType}
                                    accessesWithTickets={accessesAcc[accessTypeIdStr]}
                                    />
                                <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
                            </React.Fragment>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}

export default AccessPendingTicketsPage;