import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material"
import LabelValueDisplay from "../generic/LabelValueDisplay";


const AccessHeader = ({title, subtitle, accessInstance}) => {
    return (
        <Box marginBottom={2}>
            <Typography variant="h5">{title}</Typography>
            {!subtitle ? null : <Typography variant="h6">{subtitle}</Typography>}
            <LabelValueDisplay label={"For"} value={accessInstance.accessor.name}/>
            <LabelValueDisplay label={"To"} value={accessInstance.resource.name}/>
        </Box>
    )
}

export default AccessHeader;