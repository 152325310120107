import { ENTITY_REQUEST_TYPES } from "../../helpers/Constants";
import { ADMIN_NEW, CONFIGS } from "../app/LayeredNavPanel";

export const INSTANCE_TYPE_ATTRIBUTES = {
    FORM_STRUCTURE: 'form_structure',
    PROCESS: 'process',
    TABLE_DISPLAY: 'table_display',
    MEMBER_AUTO_CREATION: 'mac'
}

export const makeConfigUrl = (instance_type_id, attribute, process_type, edit_field_id, table_display) => {
    // const config_ext = `instance_type_id?/:instance_type_id?/attribute?/:attribute?/process?/:process_type?/edit_field_id?/:edit_field_id?/table_display?/:table_display?`
    // let output = '/temp/'
    let output = `/${ADMIN_NEW}/${CONFIGS}/`
    if(!instance_type_id) return output;

    output += `instance_type_id/${instance_type_id}/`;
    if(!attribute) return output;

    output += `attribute/${attribute}/`
    if(attribute === INSTANCE_TYPE_ATTRIBUTES.PROCESS){
        output += `process/${process_type}/`;
        if(edit_field_id) output += `edit_field_id/${edit_field_id}`;
        // return output;
    }
    else if(attribute === INSTANCE_TYPE_ATTRIBUTES.TABLE_DISPLAY){
        output += `table_display/${table_display}`
    }

    return output
}
