import { useContext, useState } from "react"
import { Box, Button, CircularProgress, Stack, Typography } from "../../../../node_modules/@mui/material/index";
import { ACCESS_REQUEST_TYPES, COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, STAGE_TYPE_METAS } from "../../../helpers/Constants";
import { ApprovalProcessFlow, NODE_TYPES, approvalProcessToGraph} from "./graph/ApprovalProcessFlow";
import { makeNestedDeepCopy } from "../../../helpers/TicketFormHelper";
import { graphToProcess } from "./graph/graph_to_process";
import Context from "../../../Store";
import { AtomicStageConfigPanel, getStageConfigErrors } from "./AtomicStageConfigPanel";
import { isAdditionRequestType } from "../../../helpers/CommonUtil";
import { EXTENSIONS, post } from "../../../helpers/requests";
import { ConfigLockTimer } from "../ConfigLockTimer";
import { getProcessPageTitle } from "../process_descriptions";

const BULLET = '\u2022';
const MSG_SEP = `\n    ${BULLET} `

const stageTypeDescription = (stageType) => {
    // const instanceTypeLabel = isAccessType ? "Access" : instanceType.name;
    switch (stageType) {
        case STAGE_TYPE_METAS.ROLE:
            return `Users with a certain tag`
        case STAGE_TYPE_METAS.TICKET:
            return `One the ticket`
        case STAGE_TYPE_METAS.STATE:
            return `On the state.`
        case STAGE_TYPE_METAS.PARENT:
            return `On the parent of this entity`
        case STAGE_TYPE_METAS.RESOURCE:
            return `On the resource`
        case STAGE_TYPE_METAS.ACCESSOR:
            return `On the accessor`
        case STAGE_TYPE_METAS.ACCESSOR_PARENT:
            return `On the parent of the accessor`
        default:
            return stageType;
    }
}



const getTicketStructure = (instanceType, requestType, editFieldId) => {
    if([ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN, ACCESS_REQUEST_TYPES.GRANT].includes(requestType)){
        return instanceType.structure;
    }
    if([COMMON_REQUEST_TYPES.EDIT_REQUEST, ACCESS_REQUEST_TYPES.LVL_CHANGE].includes(requestType)){
        //create single field structure
        const section = instanceType.structure.sections.find(s => s.fields.some(f => f.id === editFieldId));
        const field = section.fields.find(f => f.id === editFieldId);
        return {
            sections: [
                {
                    id: section.id,
                    metadata: {...section.metadata},
                    fields: [field]//field may have dependencies on other fields that are not included. may cause issue later...
                }
            ]
        }
    }

    return {sections: []} //empty structure
}

/**
 * @returns the process or null. returns undefined if there cant exist such a process 
 */
export const getApprovalProcess = (instanceType, requestType, editFieldId) => {
    switch (requestType) {
        case ENTITY_REQUEST_TYPES.ADD:
        case ENTITY_REQUEST_TYPES.REOPEN:
        case ACCESS_REQUEST_TYPES.GRANT:
            return instanceType.addition_process;

        case ENTITY_REQUEST_TYPES.REMOVE:
        case ACCESS_REQUEST_TYPES.REMOVE:
            return instanceType.removal_process;
            
        case ENTITY_REQUEST_TYPES.RESOURCE_ACCESS_REMOVAL:
            return instanceType.resource_access_removal_process;

        case ACCESS_REQUEST_TYPES.MANDATORY_REMOVAL:
            return instanceType.mandatory_removal_process;
            
        case COMMON_REQUEST_TYPES.EDIT_REQUEST:
        case ACCESS_REQUEST_TYPES.LVL_CHANGE:
            const section = instanceType.structure.sections.find(s => s.fields.some(f => f.id === editFieldId));
            if(!section || section.is_deleted) return undefined;
            const field = section.fields.find(f => (f.id === editFieldId) && !f.is_deleted);
            if(!field) return undefined;

            if(instanceType.edit_processes[section.id]?.[editFieldId]){
                return instanceType.edit_processes[section.id][editFieldId];
            }
            return null;

        default:
            console.error('unexpected case', {instanceType, requestType, editFieldId});
            return undefined;

    }

}


const getProcessOrErrorMessage = (instanceType, ticketFormStructure, requestType, allNodes, allEdges) => {
    const [process, err] = graphToProcess(allNodes, allEdges);
    if(err){
        return [process, err];
    }
    else{
        const atomicStages = allNodes.filter(nd => nd.type === NODE_TYPES.ATOMIC).map(nd => nd.data.stage);
        console.log('atomic stages', {atomicStages})
        for(const stg of atomicStages){
            const stageErrorMessages = getStageConfigErrors(stg, instanceType, ticketFormStructure);
            if(stageErrorMessages.length > 0){
                const msg = `${stg.name} has the following errors:${MSG_SEP}` + stageErrorMessages.join(MSG_SEP);
                return [process, msg];
            }
        }
    }
    
    //check for process errors
    if(isAdditionRequestType(requestType)){
        const allAtomicStages = allNodes.filter(nd => nd.type === NODE_TYPES.ATOMIC).map(nd => nd.data.stage);
        const editableSections = [];
        for(const stg of allAtomicStages){
            editableSections.push(...stg.editable_sections)
        }
        const nonSubmittedSections = instanceType.structure.sections.filter(s => s.metadata.hide_at_submission);
        const deadSections = nonSubmittedSections.filter(s => !editableSections.includes(s.id));
        if(deadSections.length){
            let processErrMsg = `The following sections are not completed at submission and not marked editable at any stage: ${MSG_SEP}`;
            processErrMsg += deadSections.map(s => s.metadata.label).join(MSG_SEP);
            return [process, processErrMsg]
        }
    }
    return [process, null];
}

export const ApprovalProcessBuilder = ({instanceType, requestType, editFieldId, userTags, refetchUserTags, instanceTypeDescriptor, onSaved}) => {
    const {alertSuccess, alertError} = useContext(Context);
    const [selectedStageId, setSelectedStageId] = useState(null);

    let initialApprovalProcess = getApprovalProcess(instanceType, requestType, editFieldId);
    if(initialApprovalProcess === null) initialApprovalProcess = {stages:[]}
    const [initialNodes, initialEdges, initialGraphWidth, initialGraphHeight, firstNode, lastNode] = approvalProcessToGraph(initialApprovalProcess);//TODO: memoize
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const ticketFormStructure = getTicketStructure(instanceType, requestType, editFieldId);

    const selectedNode = nodes.find(nd => nd.data && nd.data.stage && (nd.data.stage.id === selectedStageId));

    const setSelectedStage = (newStage) => {
        const nodesCopy = [...nodes];
        const selectedNode = nodesCopy.find(nd => nd.data && nd.data.stage && (nd.data.stage.id === selectedStageId));
        const errorMessages = getStageConfigErrors(newStage, instanceType, ticketFormStructure);
        const hasError = (errorMessages.length > 0);

        selectedNode.data = {...selectedNode.data, stage: {...newStage}, hasError: hasError};
        setNodes(nodesCopy);
    }

    const validateGraph = (allNodes, allEdges) => {
        const [process, errMsg] = getProcessOrErrorMessage(instanceType, ticketFormStructure, requestType, allNodes, allEdges);
        if(errMsg) alertError(errMsg);
        else alertSuccess("Valid");
    }

    const onSaveClick = () => {
        //make copies in case user makes edits while this code runs
        //ideally we should freeze the interactability during this time
        if(nodes.length === 0){
            alertError('Must have at least 1 stage in process');
            return;
        }
        const allNodes = makeNestedDeepCopy(nodes);
        const allEdges = makeNestedDeepCopy(edges);

        const [process, errMsg] = getProcessOrErrorMessage(instanceType, ticketFormStructure, requestType, allNodes, allEdges);
        if(errMsg){
            alertError(errMsg);
            return;
        }
        const body = {
            instance_type_desc: instanceTypeDescriptor,
            request_type: requestType,
            edit_field_id: editFieldId,
            new_approval_process: process
        }
        const onSuccess = (resp) => {
            alertSuccess("Edited!");
            setIsSubmitting(false);
            onSaved();
        }
        const onFailure = (e) => {
            setIsSubmitting(false);
            alertError(e.response.data)
        }
        setIsSubmitting(true);
        post(EXTENSIONS.EDIT_APPROVAL_PROCESS, body, onSuccess, onFailure);
    }
    //TODO: accomodate title for Accesstypes (no name) and make clean informative title
    return (
        <Box>
            <Typography variant="h6" align="center" marginBottom={1}>{getProcessPageTitle(instanceType, requestType, editFieldId)}</Typography>

            <Stack direction='row' width={'100%'} display='flex' spacing={1}>
                <ApprovalProcessFlow
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    validate={validateGraph}
                    setSelectedStageId={setSelectedStageId}
                    />
                {
                    !selectedNode ? null :
                    <AtomicStageConfigPanel
                        instanceType={instanceType}
                        ticketFormStructure={ticketFormStructure}
                        stage={selectedNode.data.stage}
                        setStage={setSelectedStage}
                        hidePanel={() => setSelectedStageId(null)}
                        requestType={requestType}
                        userTags={userTags}
                        refetchUserTags={refetchUserTags}
                        />
                }
            </Stack>
            {
                isSubmitting ? <CircularProgress /> :
                <Stack direction='row' spacing={2} alignItems="center">
                    <Box>
                        <Button onClick={onSaveClick} variant='contained'>
                            Save Changes
                        </Button>
                    </Box>
                    <Box>
                        <Button color='error' variant='contained'>
                            Cancel
                        </Button>
                    </Box>
                    <ConfigLockTimer/>
                </Stack>
            }
        </Box>
    )
}