import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import { cleanForm } from "../../helpers/TicketFormHelper";
import { Divider, Typography } from "../../../node_modules/@mui/material/index";
import SectionForm from "./SectionForm";

const MultiSectionForm = ({structure, formData, setFormData, isInitialSubmission, editableSectionIds, getSectionDownloadFileField, contextValues}) => {
    if(editableSectionIds === undefined) editableSectionIds = [];

    //getSectionFieldSetter('a')('b')(value), sets formData.a.b = value, and updates the state
    const getSectionValuesSetter = (sectionId) => {
        return (sectionValues) => {
            // console.log(`seeting section ${sectionId}`, sectionValues)
            let updatedFormData = {...formData}
            updatedFormData[sectionId] = sectionValues;
            updatedFormData = cleanForm(updatedFormData, structure)
            setFormData(updatedFormData)
        }
    }

    return(
        <Box maxWidth={700} margin={'auto'}>
            {
                structure.sections.map((sectionStructure) => {
                    const isSectionDeleted = sectionStructure.is_deleted;
                    
                    const sectionId = sectionStructure.id;
                    const isEditable = isInitialSubmission || (editableSectionIds === "*") || editableSectionIds.includes(sectionId);
                    const label = sectionStructure.metadata.label;
                    const sectionValues =  formData[sectionId]

                    if(sectionStructure.is_deleted && isEditable) return null;
                    if(sectionValues === undefined) return null;
                    if(isInitialSubmission && sectionStructure.metadata.hide_at_submission) return null;

                    return(
                        <Box marginY={2} key={sectionId}>
                            <Typography variant="h5">{label}</Typography>
                            <Divider sx={{ borderBottomWidth: 2 }}/>
                            <SectionForm
                                key={sectionId}
                                sectionStructure={sectionStructure}
                                sectionValues={sectionValues}
                                setSectionValues={getSectionValuesSetter ? getSectionValuesSetter(sectionId) : undefined}
                                isEditable={isEditable}
                                downloadFileField={getSectionDownloadFileField ? getSectionDownloadFileField(sectionId) : undefined}
                                contextValues={contextValues}
                                />   
                        </Box>
                    )
                })
            }
        </Box>
    )
}

export default MultiSectionForm;