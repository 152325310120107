import React from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SX_BOX_ICON_BTN } from "../../helpers/common_sx";
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import DeleteIcon from '@mui/icons-material/Delete';

export const UpDownArrows = ({onClick, tooltip}) => {
    return (
        <Tooltip title={tooltip} enterDelay={500}>
            <Stack spacing={-2}>
                <Box sx={SX_BOX_ICON_BTN}>
                    <IconButton onClick={() => onClick(true)}>
                        <KeyboardArrowUpIcon color='primary'/>
                    </IconButton>
                </Box>
                <Box sx={SX_BOX_ICON_BTN}>
                    <IconButton onClick={() => onClick(false)}>
                        <KeyboardArrowDownIcon color='primary'/>
                    </IconButton>
                </Box>
            </Stack>
        </Tooltip>
    )
}