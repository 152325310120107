import { Box, Button, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index"
import { blue_light } from "../../../Colors";


const SECTION_TITLE_VARIANT = 'h6'
const SECTION_DESCRIPTION_VARIANT = 'p'

export const STATE_CHANGE_NOTE = `\
IMPORTANT: If you need to add/remove employees/departments during the integration process, you should restart the process after making the changes. \
The final submission may fail if the state has changed. \
`

export const getBgColor = (isHilighted) => isHilighted ? blue_light : '#ffffff';

export const MappingSectionHeader = ({title, description}) => {
    return (
        <Box marginBottom={2}>
            <Typography variant={SECTION_TITLE_VARIANT} align='center'>{title}</Typography>
            <Typography variant={SECTION_DESCRIPTION_VARIANT}>{description}</Typography>
        </Box>
    )
}


export const HRISDataMapperButtonPanel = ({goBackStep, advanceStep, onAutoMapClick, autoMapTooltip}) => {
    return (
        <Stack direction='row' spacing={4} marginY={2}>
            <Button onClick={goBackStep} variant={'contained'}>
                Back
            </Button>
            <Tooltip title={<Typography>{autoMapTooltip}</Typography>}>
                <Button onClick={onAutoMapClick} variant={'contained'}>
                    Auto-Map
                </Button>
            </Tooltip>
            <Button onClick={advanceStep} variant={'contained'}>
                Done
            </Button>
        </Stack>
    )
}