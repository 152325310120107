import { Box, Button, IconButton, Stack, Typography } from "../../../../../node_modules/@mui/material/index";
import TicketSelectField from "../../../inputs/TicketSelectField";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {  getValueTypeLabel } from "../field_builder_helper";

export const getErrorMessagesForTableChoices = (fieldStructure, sectionStructure, tables) => {
    const {choice_desc} = fieldStructure;
    const {__meta_type, dependencies, choices, table_id, column_id, constraints} = choice_desc;

    const errs = [];
    const table = tables.find(tbl => tbl.id === table_id);
    if(!table){
        errs.push("Must select a valid table");
        return errs;
    }
    const tableFields = table.structure.fields;
    //column selected
    if(!tableFields.some(f => f.id === column_id)){
        errs.push("Must select a valid column");
    }
    //constraints are valid
    for(const constraint of constraints){
        if(!constraint.col || !constraint.id_of_field_with_value){
            errs.push("At least 1 constraint has nothing selected for the column or field. Please correct or remove.");
            break;
        }
    }
    for(let i = 0; i < constraints.length; i++){
        const constraint = constraints[i];
        if(!constraint.col || !constraint.id_of_field_with_value){
            continue; //already notified above
        }
        const constraintColumn = tableFields.find(f => f.id === constraint.col);
        if(!constraintColumn){
            errs.push("At least 1 constraint has an invalid column selected");
            break;
        }
        const constraintField = sectionStructure.fields.find(f => f.id === constraint.id_of_field_with_value)
        if(!constraintField){
            errs.push("At least 1 constraint has an invalid field selected");
            break;
        }
        if(constraintColumn.value_type !== constraintField.value_type){
            const colName = constraintColumn.label;
            const colType = getValueTypeLabel(constraintColumn.value_type);
            const fieldName = constraintField.label;
            const fieldType = getValueTypeLabel(constraintField.value_type);
            errs.push(`Constraint #${i+1} value type mismatch between the column "${colName}" (type: ${colType}) and field "${fieldName}" (type: ${fieldType})`);
            break;
        }
    }
    //constraints do not have duplicates
    let foundDup = false;
    for(const c1 of constraints){
        for(const c2 of constraints){
            if((c1 !== c2) && (c1.col == c2.col) && (c1.id_of_field_with_value == c2.id_of_field_with_value)){
                errs.push("Found duplicate constraint");
                foundDup = true;
                break;
            }
        }
        if(foundDup) break;
    }
    return errs;
}

export const TableChoicePanelContent = ({fieldStructure, sectionStructure, setChoiceDesc, configMeta, tables, refetchTables}) => {
    const {choice_desc} = fieldStructure;
    const {__meta_type, dependencies, choices, table_id, column_id, constraints} = choice_desc;
    
    const tableIdChoices = tables.map(tbl => {
        return {value: tbl.id, label: tbl.name};
    })

    const columnIdChoices = {};
    for(const tbl of tables){
        const choicesForTable = [];
        columnIdChoices[tbl.id] = choicesForTable;
        for(const f of tbl.structure.fields){
            if(f.value_type === fieldStructure.value_type){
                const choice = {value: f.id, label: f.label};
                choicesForTable.push(choice)
            }
        }
    }

    const setTableId = (tableId) => {
        setChoiceDesc({...choice_desc, table_id: tableId});
    }
    const setColumnId = (columnId) => {
        setChoiceDesc({...choice_desc, column_id: columnId});
    }
    const setConstraints = (newConstraints) => {
        setChoiceDesc({...choice_desc, constraints: newConstraints, choices});
    }

    return (
        <Stack spacing={2}>
            <TicketSelectField
                label={'Table'}
                value={table_id ? table_id : ''}
                setValue={setTableId}
                choices={tableIdChoices}
                fullWidth={true}
                />
            {
                !table_id ? null :
                <TicketSelectField
                    label={'Column that this fields value will come from'}
                    value={column_id ? column_id : ''}
                    setValue={setColumnId}
                    choices={columnIdChoices}
                    sectionValues={{tableId: table_id}}
                    dependencies={['tableId']}
                    fullWidth={true}
                    helperText={'only columns with the same "Value Type" as the field you are configuring are displayed'}//TODO: actually support displaying helperTExts
                    />
            }
            {
                !table_id ? null :
                <TableConstraints
                    fieldStructure={fieldStructure}
                    sectionStructure={sectionStructure}
                    table={tables.find(tbl => tbl.id === table_id)}
                    setConstraints={setConstraints}
                    />
            }
        </Stack>
    )
}

const TableConstraints = ({fieldStructure, sectionStructure, table, setConstraints}) => {
    //TODO: make sure that column and field have the same value_type!
    //TODO: update the actual choices on save
    const {choice_desc} = fieldStructure;
    const constraints = choice_desc.constraints ? choice_desc.constraints : []

    const columnIdChoices = table.structure.fields.map(f => {
        const choice = {value: f.id, label: f.label};
        return choice;
    })

    const fieldIdChoices = []
    for(const f of sectionStructure.fields){
        if(f.id === fieldStructure.id) break;
        // if(f.value_type === requiredValueType){
        //     dependencyChoices.push();
        // }
        const choice = {value: f.id, label: f.label};
        fieldIdChoices.push(choice);
    }

    const setConstraintColumnId = (idx, colId) => {
        const newConstraints = [...constraints];
        newConstraints[idx].col = colId;
        setConstraints(newConstraints);
    }
    const setConstraintFieldId = (idx, fieldId) => {
        const newConstraints = [...constraints];
        newConstraints[idx].id_of_field_with_value = fieldId;
        setConstraints(newConstraints);
    }

    const addConstraint = () => {
        const newTableConstraint = {col: '', id_of_field_with_value: ''};
        const newConstraints = [...constraints, newTableConstraint];
        setConstraints(newConstraints);        
    }
    
    const removeConstraint = (idx) => {
        const newConstraints = [...constraints];
        newConstraints.splice(idx, 1);
        setConstraints(newConstraints);        
    }
    return (
        <Box>
            <Typography variant='h6'>Constraints (optional)</Typography>
            <Typography>
                Optionally define constraints for valid choices that the dropdown field can have.
                Choices will be considered valid if they are from a row where
                the value in 'Column' is equal to the user provided value for 'Field' (in the form).
                A choice is valid if ALL the constraints are satisfied.
            </Typography>
            <Stack marginTop={2} spacing={2}>
            {
                constraints.map((constraint, idx) => {
                    const {col, id_of_field_with_value} = constraint;
                    return (
                        <Stack direction='row' spacing={2} key={idx}>
                            <IconButton onClick={() => removeConstraint(idx)}>
                                <RemoveCircleIcon color='error'/>
                            </IconButton>
                            <TicketSelectField
                                label={'Column'}
                                value={col}
                                setValue={(x) => setConstraintColumnId(idx, x)}
                                choices={columnIdChoices}
                                fullWidth={true}
                                />
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography>=</Typography>

                            </Box>
                            <TicketSelectField
                                label={'Field'}
                                value={id_of_field_with_value}
                                setValue={(x) => setConstraintFieldId(idx, x)}
                                choices={fieldIdChoices}
                                fullWidth={true}
                                />
                        </Stack>
                    )
                })
            }
            </Stack>
            <Box justifyContent='center'>
                <IconButton onClick={addConstraint}>
                    <AddCircleIcon color='primary'/>
                </IconButton>
            </Box>
        </Box>

    )
}