import { Box, CircularProgress, Stack } from "@mui/material";
import AccessLevelsSetManager from "../AccessLevelsSetManager";
import React, { useEffect, useState } from "react";
import Context from "../../Store";
import { EXTENSIONS, post } from "../../helpers/requests";
import PageTitle from "../generic/PageTitle";
import TicketSelectField from "../inputs/TicketSelectField";
import { objToChoicesFlat } from "../../helpers/CommonUtil";
import { getActiveEntityChoices, getAllEntityChoices, getEntityTypeWithInstanceTypeId } from "../../helpers/StaticDataHelper";
import { hasInstancePermissions } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import LoadingMessage from "../generic/LoadingMessage";
import { doesAccessTypeHaveAccessLevels } from "../../helpers/access_helper";

const formatAccessLevelsByOrder = (lvls) => {
    let maxOrder = 0;
    lvls.forEach(lvl => {
        if(lvl.order > maxOrder) maxOrder = lvl.order;
    });

    const output = Array.from(Array(maxOrder + 1), () => []);
    lvls.forEach(lvl => {
        const order = lvl.order;
        output[order].push({...lvl});
    })

    return output
}
const getAccessableEntityTypeChoices = (staticData) => {
    const {access_types} = staticData;
    let instanceTypeIds = access_types.filter(at => doesAccessTypeHaveAccessLevels(at)).map(at => at.resource_instance_type_id);
    instanceTypeIds = [...new Set(instanceTypeIds)];
    const entityTypes = instanceTypeIds.map(instanceTypeId => getEntityTypeWithInstanceTypeId(staticData, instanceTypeId));
    const output = entityTypes.map(et => {return {value: et.id, label: et.name}});
    return output;
}
const AccessLevelsManagementPage = () => {
    const {staticData, alertError, user} = React.useContext(Context);
    const {access_types} = staticData;
    const accessableEntityChoices = getAccessableEntityTypeChoices(staticData);

    const [entityTypeId, setEntityTypeId] = useState("");
    const [resourceId, setResourceId] = useState("");
    const [accessLevelsSets, setAccessLevelsSets] = useState("");
    const [hasEditPermission, setHasEditPermission] = useState(false);

    const resourceChoices = React.useMemo(() => {
        if(!entityTypeId) return [];
        const instanceTypeId = staticData.entity_types.find(et => et.id == entityTypeId).root_instance_type.id;
        const choices = getAllEntityChoices(staticData, instanceTypeId, null);
        return choices;
    }, [entityTypeId]);

    const fetchAccessLevels = () => {
        if(resourceId === "") return;
        const selectedEntityTypeId = entityTypeId; //assigning in case user changes selection dring fetch
        setAccessLevelsSets(null);

        const onSuccess = (resp) => {
            const {access_levels, instance} = resp.data;
            const lvls = formatAccessLevelsByOrder(access_levels);
            const entityType = staticData.entity_types.find(et => et.id === selectedEntityTypeId);

            const hasPermission = hasInstancePermissions(user, staticData, ALL_PERMS.ENTITY_ACTIONS.MANAGE_ACCESS_LVLS.key, instance, entityType.root_instance_type);
            if(!hasPermission) alertError(`you do not have permission to manage access levels for this resource`);
            setAccessLevelsSets(lvls);
            setHasEditPermission(hasPermission);
        }
        const onFail = (e) => {
            alertError("Failed to fetch access levels");
            setAccessLevelsSets(false);
        }
        const body = {resource_id: resourceId, with_instance: true}
        post(EXTENSIONS.GET_ACCESS_LEVELS, body, onSuccess, onFail);
    }
    useEffect(() => {
        fetchAccessLevels()
    }, [resourceId]);

    if(accessLevelsSets === null) return <LoadingMessage/>
    if(accessLevelsSets === false) return <div>Failed to load...</div>

    const resourceTypeName = !entityTypeId ? "resource" : staticData.entity_types.find(et => et.id === entityTypeId).name;
    return (
        <Box>
            <PageTitle title={"Manage Access Levels"}/>
            <Stack spacing={2}>
                <TicketSelectField
                    label = {`Resource type`}
                    value={entityTypeId}
                    setValue={setEntityTypeId}
                    choices={accessableEntityChoices}
                    fullWidth
                    />
                <TicketSelectField
                    label = {`Select ${resourceTypeName}`}
                    value={resourceId}
                    setValue={setResourceId}
                    choices={resourceChoices}
                    fullWidth
                    />
            </Stack>
            {
                resourceId && accessLevelsSets &&
                <AccessLevelsSetManager
                    resourceId={resourceId}
                    initialLevelsSets={accessLevelsSets}
                    key={resourceId}
                    isEditable={hasEditPermission}
                    refetchAccessLevels={fetchAccessLevels}
                    />
            }
        </Box>
    )
}

export default AccessLevelsManagementPage;