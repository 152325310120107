import { FIELD_VALUE_TYPES, COND_RELATION_TYPES, FIELD_COMPONENT_TYPES } from "../components/inputs/FieldProps";
import { MULTI_SELECT_DELIM } from "./Constants";

export const is_in_relation = (a, rel, b) => {
    switch(rel){
        case COND_RELATION_TYPES.EQUALS: return a === b;
        case COND_RELATION_TYPES.NOT_EQUALS: return a !== b;
        case COND_RELATION_TYPES.IN: return b.includes(a)
        case COND_RELATION_TYPES.NOT_IN: return !b.includes(a)
    }
    return true
}

export const isFieldRelevent = (fieldStructure, sectionValues) => {
    if(!fieldStructure.hide_conditions) return true;

    const ored_clauses = fieldStructure.hide_conditions.ored_clauses

    for(let i = 0; i < ored_clauses.length; i++){
        const anded_clauses = ored_clauses[i];
        let allCondsSatisfied = true
        for(let j = 0; j < anded_clauses.length; j++){
            const clause = anded_clauses[j];
            const {field_id, relation, value} = clause
            const dependencyValue = sectionValues[field_id]
            allCondsSatisfied = allCondsSatisfied && is_in_relation(dependencyValue, relation, value);
        }
        if(allCondsSatisfied) return false
    }

    return true;
}

/**nullifies irrelevant fields */
export const cleanForm = (formValues, formStructure) => {
    formStructure.sections.forEach(sectionStructure => {
        if(sectionStructure.is_deleted){
            return
        }
        const sectionId = sectionStructure.id;
        const sectionValues = formValues[sectionId];
        
        sectionStructure.fields.forEach(fieldStructure => {
            if(fieldStructure.is_deleted){
                return;
            }
            const is_relevent = isFieldRelevent(fieldStructure, sectionValues)
            if(!is_relevent){
                formValues[sectionId][fieldStructure.id] = null;//""//implicit_value
            } 
        })
    });
    return formValues;
}

/**
 * nullifies empty strings, 
 * strigifies arrays, 
 * convert float strings to floats
 * return the same ref to formValues
*/
export const formatFormValues = (formValues, formStructure) => {

    formStructure.sections.forEach(sectionStructure => {
        if(sectionStructure.is_deleted) return;

        const sectionId = sectionStructure.id;
        const sectionValues = formValues[sectionId];
        if(sectionValues === undefined) return;
        
        sectionStructure.fields.forEach(fieldStructure => {
            if(fieldStructure.is_deleted) return;

            const fieldId = fieldStructure.id;
            const fieldValue = sectionValues[fieldId];
            if(fieldValue === undefined) return;

            const {component, value_type} = fieldStructure;

            if(fieldValue === "") sectionValues[fieldId] = null;
            else if([FIELD_COMPONENT_TYPES.MULTISELECT, FIELD_COMPONENT_TYPES.MULTICHECK].includes(component)){
                if(Array.isArray(fieldValue)) sectionValues[fieldId] = fieldValue.join(MULTI_SELECT_DELIM)
            }
    
            const is_float_field = (value_type === FIELD_VALUE_TYPES.FLOAT);
            if(is_float_field && (fieldValue !== null)){
                sectionValues[fieldId] = parseFloat(fieldValue)
            }               
        })
    });

    return formValues;
}

export const makeTicketDeepCopy = (ticket) => {
    const output = {}
    Object.keys(ticket).forEach(sectionId => {
        output[sectionId] = {};
        Object.keys(ticket[sectionId]).forEach(fieldId => {
            output[sectionId][fieldId] = ticket[sectionId][fieldId]
        })
    })
    return output; 
}

export const makeNestedDeepCopy = (obj) => {
    // if(!obj) return obj;
    return JSON.parse(JSON.stringify(obj))
}

export const multiValueToArray = (value) => {
    if((value === null) || (value === undefined)) return []

    if(typeof value === 'string'){
        if(value === "") return []
        return value.split(MULTI_SELECT_DELIM)
    }
    else return value
}

export const makeFieldDisplayLabel = (label, optional) => {
    return optional ? `${label} (optional)` : label
}

/**sort choices in place and returns the the same reference to the 'choices' parameter */
export const sortChoicesByLabel = (choices) => {
    if(Array.isArray(choices)) choices.sort((a, b) => a.label.localeCompare(b.label));
    else Object.values(choices).forEach(subChoices => sortChoicesByLabel(subChoices));

    return choices;
}

export const CONTEXT_DEPENDENCIES ={
    EMP_ID_ON_ENTITY: "emp_id_on_entity",
    EMP_ACCESSOR: "emp_accessor_id",
    RESOURCE_ID: "resource_id",
    // ALL = [EMP_ACCESSOR, RESOURCE_ID]
}
