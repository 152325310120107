import { ACCESSOR_TYPES, COMMON_REQUEST_TYPES, ENTITY_REQUEST_TYPES, SUBMITTER_ROLE, TICKET_STATUS } from "./Constants";
import { fieldValueString } from "./FieldDisplayFormatters";
import { getFieldStructureFromFormStructureWithIds, getInstanceType } from "./StaticDataHelper";

import { cleanForm, makeNestedDeepCopy } from "./TicketFormHelper";
import { ACCESS_REQUEST_TYPES, getAccessorTypeLabel } from "./access_helper";

export const doesEntityHaveMemberFlow = (staticData, entityTypeName) => {
    return !!staticData.entity_types.find(et => et.name === entityTypeName).member_flow;
}

// ACCESS

export const getAccessType = (access_types, ent_or_emp, emp_email, accessor_type_name, accessor_eti_id, accessor_member_id, res_type_name, res_eti_id, res_member_id) => {
    return access_types.find(a => {
        //{access_type, accessor_entity_type_name, is_accessor_member, resource_entity_type_name, is_resource_member, structure}
        if(a.access_type !== ent_or_emp) return false;
        if(ent_or_emp === ACCESSOR_TYPES.EMPLOYEE){
            if(!emp_email){
                console.log(`Missing - emp_email: ${emp_email}`);
                return false;                
            }
        }
        else if(ent_or_emp === ACCESSOR_TYPES.ENTITY){
            if(a.accessor_entity_type_name !== accessor_type_name) return false;
            if(!accessor_eti_id){
                console.log(`Missing - accessor_eti_id: ${accessor_eti_id}`);
                return false;
            }
            if(!!a.is_accessor_member !== !!accessor_member_id){
                console.log(`Mismatch - accessor_member_id: ${accessor_member_id}, a.is_accessor_member: ${a.is_accessor_member}`);
                return false;
            }
        }
        else {
            return false;
        }

        if(a.resource_entity_type_name !== res_type_name) return false;
        if(!res_eti_id){
            console.log(`Missing res_eti_id: ${res_eti_id}`);
            return false;
        } 

        if(!!a.is_resource_member !== !!res_member_id){
            console.log(`Mismatch - res_member_id: ${res_member_id}, a.is_resource_member: ${a.is_resource_member}`);
            return false;
        }
        return true;
    })
}


export const objToChoicesFlatStable = (obj) => {
    return Object.keys(obj).map((key) => {
        return {value: key, label: obj[key]};
    })
}
export const objToChoicesFlat = (obj, sorted) => {
    const output = Object.entries(obj).map(([key, value]) => {
        return {value: key, label: value};
    })
    if(sorted){
        const compareChoices = (a, b) => a.label.localeCompare(b.label);
        output.sort(compareChoices);
    }
    return output;
}

export const getPendingActivenessTicket = (instance) => {
    return instance.tickets.find(t => {
        return (t.status === TICKET_STATUS.PENDING) && [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN, ACCESS_REQUEST_TYPES.GRANT].includes(t.request_type);
    })
}

export const swapItemsAtIndices = (arr, idx1, idx2) => {
    const temp = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = temp;
}

export const hasDuplicates = (arr) => {
    return new Set(arr).size !== arr.length;
}
let nextId = 1;
export const getNextId = () => {
    //TODO: dont rely on this function, just for testing.
    //unique ids should axquired more properly
    return nextId++;
}
// export const getAccessStructureCopyWithAccessLevels = (structure, accessLevels, requestType, empRequesteeEmail, managerChoicesPerEmp) => {
//     if(!accessLevels) accessLevels = [];
//     const copy = makeNestedDeepCopy(structure);

//     //TODO: remove this choice injection and fix up logic
//     if(structure.approvals && structure.approvals.manager && empRequesteeEmail && managerChoicesPerEmp){
//         // const empRequesteeEmail = ticket.metadata.employee_email;
//         const managerChoices = managerChoicesPerEmp[empRequesteeEmail];
//         copy.approvals.manager.choices = managerChoices;
//     }

//     //TODO: handle case where no null is passed for access levels and request type is 'Remove'.
//     copy.info.access_level_id.choices = accessLevels.map(lvl => {
//         const label = `${lvl.name} - ${truncateString(lvl.description, 75, true)}`;        
//         return {value: lvl.id, label: label};
//     });
//     if(requestType === ACCESS_REQUEST_TYPES.GRANT) return copy;

//     const output = {};
//     output.metadata = copy.metadata;
//     output.info = copy.info;
//     delete output.info.username;
//     if(requestType === ACCESS_REQUEST_TYPES.REMOVE && (output.info.access_level_id)){
//         delete output.info.access_level_id;
//     }
//     return output;
// }

export const getAccessTypeTabLabel = (staticData, accessType) => {
    const accessorLabel = getAccessorTypeLabel(accessType);
    const resourceLabel = getInstanceType(staticData, accessType.resource_instance_type_id);

    return `${accessorLabel} → ${resourceLabel}`;
}

export const truncateString = (s, len, withEllipsis) => {
    if(s === undefined) return "undefined";
    if(s === null) return "null";
    if(s.length <= len) return s;
    const suffix = withEllipsis ? "..." : "";
    return s.substring(0, len) + suffix;
}


export const getSubmissionActionFromTicket = (ticket) => {
    return ticket.actions.find(a => a.role === SUBMITTER_ROLE);
}

export const uniqueifyBy = (arr, key) => {
    var seen = {};
    return arr.filter(function(item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
}

export const labelFromValueAndChoices = (value, choices, defaultLabel ) => {
    const choice = choices.find(choice => choice.value === value);
    if(choice) return choice.label;
    return defaultLabel;
}

export const hasPendingTicketOfType = (instance, requestType) => {
    return instance.tickets.some(t => (t.status === TICKET_STATUS.PENDING) && (t.request_type === requestType));
}

export const findCount = (arr, f) => {
    let output = 0;
    for(const item of arr){
        if(f(item)){
            output += 1;
        }
    }
    return output;
}

export const getPendingEditSummary = (staticData, structure, instance, ticket) => {
    if(ticket.status !== TICKET_STATUS.PENDING){
        return null;
    }
    const request_type = ticket.request_type;
    const editRequestTypes = [COMMON_REQUEST_TYPES.EDIT_REQUEST, ACCESS_REQUEST_TYPES.LVL_CHANGE, ACCESS_REQUEST_TYPES.EDIT_REQUEST];
    if(editRequestTypes.includes(request_type)){
        const sectionId = Object.keys(ticket.form_values)[0];
        const fieldId = Object.keys(ticket.form_values[sectionId])[0];
        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, sectionId, fieldId);
        const fieldLabel = fieldStructure ? fieldStructure.short_label : '';
        const currValue = fieldValueString(instance.state.form_values[sectionId][fieldId], fieldStructure, staticData);
        const pendingValue = fieldValueString(ticket.form_values[sectionId][fieldId], fieldStructure, staticData);
        const s = `${request_type} (${fieldLabel}): ${currValue} → ${pendingValue}`;
        return s
    }
    else {
        return null;
    }
}

export const isAdditionRequestType = (requestType) => {
    return [ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN, ACCESS_REQUEST_TYPES.GRANT].includes(requestType);
}
