import React, {useState} from "react";
import {TextField, MenuItem} from "@mui/material";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";



const choices = [
    {label: "Yes", value: true},
    {label: "No", value: false}
]

const YesNoSelectField = (props) => {
    const {value, setValue, idPrefix, label, helperText, hasBeenVisited, optional, fullWidth} = props;

    const [visited, setVisited] = useState(hasBeenVisited)
    
    const onChange = (e) => {
        setValue(e.target.value)
    }
    
    const onBlur = (e) => {
        if(!visited) setVisited(true)
    }
    
    const id = idPrefix + "-" +label
    const displayLabel = makeFieldDisplayLabel(label, optional)

    let error = (visited && (value === "" || value === null || value === undefined))
    if(value === "" && optional) error = false


    return(
        <TextField id={id} select
            label={displayLabel}
            helperText={helperText}
            value={value}
            onBlur={onBlur}
            error={error}
            onChange={onChange}
            fullWidth={fullWidth}
            >
                {choices.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
        </TextField> 
    )
}

export default YesNoSelectField
