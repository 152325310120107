import React, {useState} from "react";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "../../../node_modules/@mui/material/index";


const getExtension = (fileName) => {
    const parts = fileName.split(".")
    if(parts.length < 2) return ""
    return "." + parts[parts.length - 1]
}
const TicketFilePickerField = (props) => {
    const {value, setValue, idPrefix, label, helperText, sectionKey, fieldKey, optional, fieldStructure, onDownloadClick} = props
    // console.log('file props:', props)
    const [counter, setCounter] = useState(0);//this is just to guarantee providing a unique key to input element, to force rerenders
    // const [text, setText] = useState(value ? value : "None")

    const isFileRemote = Number.isInteger(value);
    const text = value ? value.name : "None"

    const onChange = (e) => {
        const file = e.target.files[0]
        const fileExt = getExtension(file.name)
        const renamedFile = new File([file], file.name)
        // const renamedFile = new File([file], `${sectionKey}-${fieldKey}${fileExt}`)
        // setText(file.name)
        setValue(renamedFile)
        setCounter(counter + 1);
    }
    
    const clearSelection = () => {
        setValue('');
        setCounter(counter + 1);
        // setText('None')
    }

    const id = idPrefix + "-" + label
    const labelRefId = `raised-button-file-${fieldStructure.id}`
    const fileName = value ? value.name : ""
    const displayLabel = makeFieldDisplayLabel(label, optional)

    return(
        <Box border={1} borderRadius={2} padding={2}>
            <Typography>{displayLabel}</Typography>
            <Typography variant='p'>{helperText}</Typography>
            <Stack direction={"row"} marginTop={2}  alignItems={"center"}>
                <input
                    // accept="image/*"
                    style={{ display: 'none' }}
                    id={labelRefId}
                    multiple
                    type="file"
                    onChange={onChange}
                    key={`${counter}-${text}`}
                    />
                <label htmlFor={labelRefId}>
                    <Button variant="contained" component="span">
                        Select
                    </Button>
                </label>
                {
                    !value ? null :
                    <IconButton onClick={clearSelection}>
                        <ClearIcon/>
                    </IconButton>
                }
                {/* <Typography marginX={3} marginY={.5}>{text}</Typography> */}
                {
                    isFileRemote ?
                    <Button onClick={onDownloadClick}>Download</Button> :
                    <Typography alignItems={'center'} marginX={1}>{text}</Typography>
                }
                
            </Stack>
        </Box>
        
    )
    // return(
    //         <input id={id} type="file" className="form-control" name="upload_file" onChange={onChange}/>
    // )
}

export default TicketFilePickerField
