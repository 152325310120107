import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { extract_department_roles_for_emp, getDepartments as getDepartmentsFlat } from "./hris_helpers";
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import TicketSelectField from "../inputs/TicketSelectField";
import { EXTENSIONS, post } from "../../helpers/requests";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import LoadButton from "../generic/LoadButton";
import YesNoSelectField from "../inputs/YesNoSelectField";
import TicketTextField from "../inputs/TicketTextField";
import TicketMultiSelectField from "../inputs/TicketMultiSelectField";
import SectionForm from "../form/SectionForm";

// const TEMP_TAG_CHOICES = ["Accounting", "HR Generalist", "IT", "Legal"].map(t => { return {value: t, label: t}});

const RoleForm = ({role, setRole, empChoices, deptChoices, roleStructure, submitState, onSubmitClick}) => {

    const setExtraData = (sectionValues) => {
        setRole({...role, extra_data: sectionValues})
    }

    return (
        <Box>
            <Stack spacing={2} marginY={2}>
                {
                    role.id ? null : <>
                    <TicketSelectField
                        label={"Employee"}
                        value={role.emp_id}
                        setValue={(x) => {setRole({...role, emp_id: x})}}
                        choices={empChoices}
                        dependencies={[]}
                        sectionValues={role}
                        fullWidth
                        />
                    <TicketSelectField
                        label={"Department"}
                        value={role.department_id}
                        setValue={(x) => {setRole({...role, department_id: x})}}
                        choices={deptChoices}
                        dependencies={[]}
                        sectionValues={role}
                        fullWidth
                        />
                    </>
                }
                <YesNoSelectField
                    label={"Is this a manager role?"}
                    value={role.is_manager}
                    setValue={(x) => {setRole({...role, is_manager: x})}}
                    fullWidth
                    />
                {/* <TicketMultiSelectField
                    label={"Approver tags"}
                    helperText={"Select any tags relevent for the employee to approve requests in this role."}
                    value={role.tags}
                    setValue={(x) => {setRole({...role, tags: x})}}
                    choices={TEMP_TAG_CHOICES}
                    dependencies={[]}
                    sectionValues={{}}
                    fullWidth
                    /> */}
                <SectionForm
                    sectionStructure={roleStructure}
                    sectionValues={role.extra_data}
                    setSectionValues={setExtraData}
                    isEditable={true}
                    />
            </Stack>

            <LoadButton onClick={onSubmitClick} loading={submitState===REQUEST_STATES.SENDING} disabled={submitState===REQUEST_STATES.SENDING}>
                Submit
            </LoadButton>
        </Box>
    )
}

export default RoleForm;