import React, { Fragment, useState } from "react";
import {AppBar, Stack, Box, Button, ButtonGroup, Grid, Toolbar, Typography, IconButton, Divider, Icon} from '@mui/material';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TicketTextField from "./inputs/TicketTextField";
import TicketParagraphField from "./inputs/TicketParagraphField";
import TicketSelectField from "./inputs/TicketSelectField";
import Context from "../Store";
import { EXTENSIONS, post } from "../helpers/requests";
import { LONG_TEXT_LEN, REQUEST_STATES, SHORT_TEXT_LEN } from "../helpers/Constants";
import LoadButton from "./generic/LoadButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SquareIcon from '@mui/icons-material/Square';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { makeNestedDeepCopy } from "../helpers/TicketFormHelper";

const EDIT_BOX_MIN_WIDTH = 300;
const ICON_SIZE = "medium";

//#region Helper Functions

const riskIdToColor = (risk_id) => {
    if(risk_id === 1) return "#00ff00";
    if(risk_id === 2) return "orange";
    if(risk_id === 3) return "#ff4444";
    return "#cccccc";
}

const riskChoices = [
    {value: 1, label: "Low", description: "Basic User level access/no access to PII (under US law) e.g. adult name and email are NOT PII"},
    {value: 2, label: "Medium", description: "Admin Level/Access to student PII/student data/corporate trade secrets"},
    {value: 3, label: "High", description: "Super Admin/Access to protected PII (Social Security numbers, health information)"},
]

const makeEmptyLevel = () => {
    return {id: null, name: "New Level", order: "", risk: "", description: ""};
}

const isLevelDataValid = (lvl) => {
    return lvl.name && lvl.risk && lvl.description;
}
const getIndicesOfLevel = (lvl, allLevels) => {
    for(let idx1=0; idx1 < allLevels.length; idx1++){
        const row = allLevels[idx1];
        for(let idx2=0; idx2 < row.length; idx2++){
            if(row[idx2] === lvl) return [idx1, idx2];    
        }
    }

    return null;
}

const formatLevelsForRequest = (lvls) => {
    const output = [];
    lvls.forEach((lvlsAtOrder, orderIdx) => {
        lvlsAtOrder.forEach(lvl => {
            output.push({...lvl, order: orderIdx});
        })
    })
    return output;
}

const getErrorsFromFormattedLevels = (lvls) => {
    const errors = [];
    lvls.forEach((lvl, idx) => {
        const {id, name, order, risk, description} = lvl;
        if(!name) errors.push(`Access Level missing a name. ${idx}`);
        if(name.length > SHORT_TEXT_LEN) errors.push(`'${name}' Access Level: name is too long. ${idx}`);
        if(!risk) errors.push(`'${name}' Access Level: missing Risk Level. ${idx}`);
        if(!description) errors.push(`'${name}' Access Level: missing description. ${idx}`);
        if(description.length > LONG_TEXT_LEN) errors.push(`'${name}' Access Level: description is too long. ${idx}`);
    });
    return errors;
}

//#endregion  Helper Functions
const RiskLegend = () => {

    return (
        <Stack sx={{display: 'inline-block'}} border={2} borderRadius={2} padding={1}>
            <Typography>Risk Level Color Legend</Typography>
            {
                [1, 2, 3].map(riskId => {
                    const risk = riskChoices.find(c => c.value === riskId);
                    return (
                        <Stack direction={"row"} key={riskId}>
                            <SquareIcon sx={{color: riskIdToColor(riskId)}} />
                            <Typography>{risk.label} - {risk.description}</Typography> 
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}

const AccessLevelEditBox = ({data, update}) => {
    const {id, name, order, risk, description} = data;
    const borderColor = "#000000";
    const border = 2;
    return (
        <Box>
        <Box
            padding={2} minWidth={EDIT_BOX_MIN_WIDTH}
            bgcolor={riskIdToColor(risk)} borderColor={borderColor} borderRadius={2} border={border}>
                <Stack spacing={2}>
                        <Typography justifyContent={"center"} display={"flex"}>Selected Node</Typography>
                    <TicketTextField
                        value={name}
                        setValue={(x) => {data.name = x; update();}}
                        label="Name"/>
                    <TicketSelectField
                        value={risk}
                        setValue={(x) => {data.risk = x; update();}}
                        label="Risk"
                        choices={riskChoices}/>
                    <TicketParagraphField
                        value={description}
                        setValue={(x) => {data.description = x; update();}}
                        label="Description"/>
                </Stack>
        </Box>
        </Box>
    )
}

const AccessLevelBox = ({data, update, onClick, isSelected}) => {
    const {id, name, order, risk, description} = data;
    // const borderColor = isSelected ? "#0000ff" : "#000000";
    // const borderColor = "#0000ff";
    const border = isSelected ? 4 : 2;
    const fontWeight = isSelected ? 'bold' : undefined;
    // sx={{borderColor: "error.main"}}
    return (
        <Box
            onClick={onClick}
            padding={1} m={1}
            bgcolor={riskIdToColor(risk)} borderRadius={2} border={border}>
                <Typography fontWeight={fontWeight}>{data.name}</Typography>
        </Box>
    )
}


const LevelsGrid = ({levelsSet, selectedLevel, setSelectedLevel, update}) => {
    // const boxSx = {border: 2, borderRadius: 2, borderColor: "#000000"}
    // border={2} borderRadius={2} borderColor={"#000000"}
    return (
        <Box>
        <Box
            minWidth={350}
            // sx={{display: 'inline-block'}}
            border={2} borderRadius={2} borderColor={"#000000"}>
            <Stack direction={"column-reverse"}>
                <Typography m={2}>Least Access</Typography>
                <Divider sx={{ borderBottomWidth: 2, width:'100%', color: "#000000"}}/>
                {
                    levelsSet.map((levelsAtOrder, idx1) => {
                        const rowBgColor = levelsAtOrder.includes(selectedLevel) ? "#f0f0f0" : "#ffffff";
                        return (
                            <Fragment key={idx1}>
                                {
                                    (idx1 !== 0) &&
                                    <Divider sx={{ borderBottomWidth: 2, width:'100%', color: "#000000"}}/>
                                }
                                <Stack direction={"row"} minHeight={20} bgcolor={rowBgColor}>
                                    <Box alignItems={"center"} display={"flex"} marginX={2}>
                                        <Typography>Order {idx1 + 1})</Typography>
                                    </Box>
                                    {
                                        levelsAtOrder.map((lvl, idx2) => {
                                            return (
                                                <AccessLevelBox
                                                    key={`${lvl.id}-${idx2}`}
                                                    data={lvl}
                                                    update={update}
                                                    onClick={() => setSelectedLevel(lvl)}
                                                    isSelected={selectedLevel===lvl}
                                                    />
                                            )
                                        })   
                                    }
                                </Stack>
                            </Fragment>
                        )       
                    })
                }
                <Divider sx={{ borderBottomWidth: 2, width:'100%', color: "#000000"}}/>
                <Typography m={2}>Greatest Access</Typography>
            </Stack>
        </Box>
        </Box>
    )
}

const LevelGridControlsButtonsBox = ({title, onArrowClick, onAddClick, onDeleteClick, disableAllButtons, doesSelectionExist, isSubmitting}) => {
    const btnSx = {
        color: "primary",
        fontSize: ICON_SIZE,
        variant:"contained"
    }
    // const btnVariant = "contained";

    return (
        <Stack alignItems={"center"} display={"flex"} border={2} borderRadius={2}>
            <Typography marginTop={1}>{title}</Typography>
            <Stack direction={"row"} maxWidth={250}>
                <Stack alignItems={"center"} display={"flex"}>
                    <Box alignItems={"center"} display={"flex"}>
                        <IconButton onClick={onAddClick} disabled={disableAllButtons}>
                            <AddCircleIcon color="primary" fontSize={ICON_SIZE}/>
                        </IconButton>
                    </Box>
                    {
                        onDeleteClick &&
                        <Box alignItems={"center"} display={"flex"}>
                            <IconButton onClick={onDeleteClick} disabled={disableAllButtons}>
                                <RemoveCircleIcon color="error" fontSize={ICON_SIZE}/>
                            </IconButton>
                        </Box>
                    }
                </Stack>
                <Stack alignItems={"center"} display={"flex"}>
                    <Box alignItems={"center"} display={"flex"}>
                        <IconButton onClick={() => onArrowClick(true)} disabled={!doesSelectionExist || disableAllButtons}>
                            <ArrowUpwardIcon {...btnSx}/>
                        </IconButton>
                    </Box>
                    <Box alignItems={"center"} display={"flex"}>
                        <IconButton onClick={() => onArrowClick(false)} disabled={!doesSelectionExist || disableAllButtons}>
                            <ArrowDownwardIcon {...btnSx}/>
                        </IconButton>
                    </Box>
                </Stack>
            </Stack>
        </Stack>

    )
}

const LevelsGridControls = ({resourceId, levelsSet, updateLevelsSet, undoAllChanges, selectedLevel, setSelectedLevel, context, refetchAccessLevels}) => {
    const {alertSuccess, alertError} = context;
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);

    //#region Node click handlers
    const onAddNodeClick = () => {
        const newNode = makeEmptyLevel();
        levelsSet[0].push(newNode);
        updateLevelsSet();
        setSelectedLevel(newNode)
    }

    const onDeleteNodeClick = () => {
        if(!selectedLevel){
            alertError("Select an access level to remove");
            return;
        }

        const removeSelectedNode = () => {
            const [idx1, idx2] = getIndicesOfLevel(selectedLevel, levelsSet);
            const row = levelsSet[idx1];
            row.splice(idx2, 1);
            setSelectedLevel(null);
            
            if(row.length === 0){
                levelsSet.splice(idx1, 1);
            }
            updateLevelsSet();
        }

        if(selectedLevel.id === null){
            removeSelectedNode();
            return;
        }

        const onSuccess = (resp) => {
            const isInUse = resp.data.is_in_use;
            if(isInUse){
                alertError("Cannot remove. This access level is in use.")
            }
            else{
                removeSelectedNode();
            }
        }
        const onFail = (err) => {
            alertError("failed to verify if node can be deleted.");
        }
        const body = {access_level_id: selectedLevel.id};
        post(EXTENSIONS.IS_ACCESS_LEVEL_USED, body, onSuccess, onFail);
    }

    const onMoveNodeClick = (isUp) => {
        if(selectedLevel === null){
            alertError("Select a node in order to move it.")
        }
        if(!levelsSet || !levelsSet.length) return;
    
        const indices = getIndicesOfLevel(selectedLevel, levelsSet);
        if(!indices) return;
    
        const [idx1, idx2] = indices;
        const currRow = levelsSet[idx1];
        const lvl = currRow[idx2];
        const height = levelsSet.length;
        levelsSet[idx1].splice(idx2, 1);
        const newOrder = (isUp ? (idx1 + 1) : (idx1 + height - 1)) % height;
        levelsSet[newOrder].push(lvl);
    
        if(currRow.length === 0){
            levelsSet.splice(idx1, 1);
        }
        updateLevelsSet();
    }
    //#endregion Node click handlers

    //#region Row click handlers
    const onAddRowClick = () => {
        levelsSet.push([]);
        updateLevelsSet();
    }

    const onDeleteRowClick = undefined;

    const onMoveRowClick = (isUp) => {
        if(!selectedLevel){
            alertError("Select a node within a row to move that row.");
            return;
        }
        const indices = getIndicesOfLevel(selectedLevel, levelsSet);
        const [selectedRowIdx, positionIdx] = indices;
        const numRows = levelsSet.length;
        if((isUp && (selectedRowIdx === numRows - 1)) || (!isUp && (selectedRowIdx === 0))) return;

        const selectedRow = levelsSet[selectedRowIdx];
        const newRowIdx = (isUp ? (selectedRowIdx + 1) : (selectedRowIdx + numRows - 1)) % numRows;

        //swap rows
        levelsSet[selectedRowIdx] = levelsSet[newRowIdx];
        levelsSet[newRowIdx] = selectedRow;
        updateLevelsSet();
    }
    //#endregion
    
    const onSaveClick = () => {
        const levelsFormatted = formatLevelsForRequest(levelsSet);
        const errors = getErrorsFromFormattedLevels(levelsFormatted);
        if(errors.length > 0){
            alertError(errors.join("\n"));
            return;
        }

        const onSuccess = (resp) => {
            setSubmitState(REQUEST_STATES.SUCCEEDED);
            alertSuccess("Saved");
            refetchAccessLevels()
        }

        const onFail = (err) => {
            setSubmitState(REQUEST_STATES.FAILED);
            const msg = (err && err.response && err.response.data) ? err.response.data : "Failed to save";
            alertError(msg);
        }

        const body = {resource_id: resourceId, access_levels: levelsFormatted}
        post(EXTENSIONS.UPDATE_ACCESS_LEVELS, body, onSuccess, onFail);
        setSubmitState(REQUEST_STATES.SENDING);
    }


    const isSubmitting = (submitState === REQUEST_STATES.SENDING);
    const disableAllButtons = isSubmitting || (submitState === REQUEST_STATES.SUCCEEDED);
    return (
        <Box>
        <Box border={2} borderRadius={2} height={"auto"}>
            <Stack direction={"row"} spacing={1} m={1}>
                <LevelGridControlsButtonsBox
                    title={"Row"}
                    onArrowClick={onMoveRowClick}
                    onAddClick={onAddRowClick}
                    onDeleteClick={onDeleteRowClick}
                    disableAllButtons={disableAllButtons}
                    doesSelectionExist={!!selectedLevel}
                    isSubmitting={isSubmitting}
                    />
                <LevelGridControlsButtonsBox
                    title={"Node"}
                    onArrowClick={onMoveNodeClick}
                    onAddClick={onAddNodeClick}
                    onDeleteClick={onDeleteNodeClick}
                    disableAllButtons={disableAllButtons}
                    doesSelectionExist={!!selectedLevel}
                    isSubmitting={isSubmitting}
                    />
            </Stack>
            <Stack direction={"column"} spacing={2} m={2}>
                <LoadButton onClick={onSaveClick} variant={"contained"} loading={isSubmitting} disabled={disableAllButtons}>
                    Save
                </LoadButton>  
                <LoadButton onClick={undoAllChanges} variant={"contained"} loading={isSubmitting} disabled={disableAllButtons}>
                    Undo All
                </LoadButton>  
            </Stack>
        </Box>
        </Box>
    )
}


const AccessLevelsSetManager = ({resourceId, initialLevelsSets, isEditable, refetchAccessLevels}) => {
    
    const context = React.useContext(Context);
    const copyOfInitialLevelSets = React.useMemo(() => makeNestedDeepCopy(initialLevelsSets), [resourceId]);
    const [levelsSets, setLevelsSets] = useState(copyOfInitialLevelSets);
    const [selectedLevel, setSelectedLevel] = useState(null);
    
    const updateLevelsSet = () => {setLevelsSets([...levelsSets]);}
    const undoAllChanges = () => {
        setSelectedLevel(null);
        setLevelsSets(makeNestedDeepCopy(initialLevelsSets));
    }

    return (
        <Box p={2}>
            <RiskLegend/>
            <Stack direction={"row"} spacing={2} marginTop={2}>
                {
                    !isEditable ? null :
                    <LevelsGridControls
                        resourceId={resourceId}
                        levelsSet={levelsSets}
                        undoAllChanges={undoAllChanges}
                        updateLevelsSet={updateLevelsSet}
                        selectedLevel={selectedLevel}
                        setSelectedLevel={setSelectedLevel}
                        context={context}
                        refetchAccessLevels={refetchAccessLevels}
                        />
                }
                <LevelsGrid
                    levelsSet={levelsSets}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    update={updateLevelsSet}/>
                {
                    selectedLevel &&
                    <AccessLevelEditBox
                        data={selectedLevel}
                        update={updateLevelsSet}/>
                }
            </Stack>
        </Box>
    )
    // return (
    //     <Box p={2}>
    //         <Stack direction={"row"} spacing={2}>
    //             <Stack spacing={2}>
    //                 <RiskLegend/>
    //                 <LevelsGridControls
    //                     resourceId={resourceId}
    //                     levelsSet={levelsSets}
    //                     undoAllChanges={undoAllChanges}
    //                     updateLevelsSet={updateLevelsSet}
    //                     selectedLevel={selectedLevel}
    //                     setSelectedLevel={setSelectedLevel}
    //                     context={context}
    //                     />
    //             </Stack>
    //             <LevelsGrid
    //                 levelsSet={levelsSets}
    //                 selectedLevel={selectedLevel}
    //                 setSelectedLevel={setSelectedLevel}
    //                 update={updateLevelsSet}/>
    //             {
    //                 selectedLevel &&
    //                 <AccessLevelEditBox
    //                     data={selectedLevel}
    //                     update={updateLevelsSet}/>
    //             }
    //         </Stack>
    //     </Box>
    // )
};

export default AccessLevelsSetManager;