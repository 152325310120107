


export const extract_emp_department_roles = (dept) => {
    const { id, type, name, manager, extra_data, children, employee_roles } = dept;
    
    const output = employee_roles.map(emp_role => {
        return {
            employee_role: emp_role,
            department: dept,
            is_manager: false
        }
    })
    
    if(manager){
        const manager_role = {
            employee_role: manager,
            department: dept,
            is_manager: true
        }
        
        output.push(manager_role)
    }

    children.forEach(d => {
        const child_roles = extract_emp_department_roles(d);
        output.push(...child_roles)
    });
    return output;
    
}

export const extract_department_roles_for_emp = (dept, emp_id) => {
    const all_roles = extract_emp_department_roles(dept)
    const output = all_roles.filter(r => r.employee_role.employee.id === emp_id)
    return output
}

/**
 * 
 * @param {*} dept 
 * @returns flat list of all the subdepartments of dept, including dept. sorted alphabetically by name.
 */
export const getDepartments = (dept) => {
    const output = [dept];

    dept.children.forEach(d => {
        const subDepts = getDepartments(d);
        output.push(...subDepts);
    })

    output.sort((a, b) => a.name.localeCompare(b.name))
    return output;
}

/**
removes role with id from the department or any of its subdepartments. returns true if
found and removed the role.
*/
export const remove_role_from_department = (dept, role_id) => {
    // wont work, the return statement within foreach block just return from inner funcs
    // const { id, type, name, manager, extra_data, children, employee_roles } = dept;

    // if(dept.manager.role.id === role_id){
    //     dept.manager = null;
    //     return true;
    // }
    // let idx = dept.employee_roles.findIndex(emp_role => emp_role.role.id === role_id)
    // if(idx != -1){
    //     dept.employee_roles.splice(idx, 1);
    //     return true;
    // }

    // dept.children.forEach(d => {
    //     const found_and_removed = remove_role_from_department(d, role_id);
    //     if(found_and_removed) return true;
    // })

    // return false;
}

export const get_emp_dept_role = (dept, role_id) => {
    // const { id, type, name, manager, extra_data, children, employee_roles } = dept;

    if(dept.manager && dept.manager.role.id === role_id){
        return {
            is_manager: true,
            employee: dept.manager.employee,
            department: dept,
            role: dept.manager.role
        };
    }
    const empRole = dept.employee_roles.find(emp_role => emp_role.role.id === role_id);
    if(empRole){
        return {
            is_manager: false,
            employee: empRole.employee,
            department: dept,
            role: empRole.role,
        };
    }
    for(let i=0; i < dept.children.length; i++){
        const d = dept.children[i];
        const output = get_emp_dept_role(d, role_id);
        if(output) return output;
    }

    return null;
}

/**
 * 
 * @param {*} dept - the root department to recursively search within
 * @param {*} dept_id - the id of the department to return
 * @returns {*} the department with id dept_id in the children of dept (or dept itself)
 */
export const get_dept = (dept, dept_id) => {
    if(dept.id === dept_id) return dept;

    for(let i=0; i < dept.children.length; i++){
        const d = dept.children[i];
        const output = get_dept(d, dept_id);
        if(output) return output;
    }

    return null;
}

export const isHRISManagedInternally = (company) => {
    return !company.is_externally_managed;
}