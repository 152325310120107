import React, { useState } from "react";
import { TextField } from "@mui/material";
import { FIELD_VALUE_TYPES } from "./FieldProps";
import { makeFieldDisplayLabel } from "../../helpers/TicketFormHelper";
import { SHORT_TEXT_LEN } from "../../helpers/Constants";

// const MAX_LEN = SHORT_TEXT_LEN

//only numbers and up to 1 decimal point. no leading zeros
const isValidPositiveFloatPrefix = (s) => {
    if(s === "") return true;
    if(s === "0") return true;
    if((s.length > 1) && (s[0] === "0") && (s[1] !== ".")) return false

    let foundDecimal = false
    for (var i = 0; i < s.length; i++) {
        const c = s[i]
        if(c === "."){
            if(foundDecimal) return false;
            foundDecimal = true;
        }
        else if((c > "9") || (c < "0")) return false;
    }
    return true;
}

const getErrorMessage = (value, maxLen, isNumber) => {
    if(isNumber) return null;
    if(!value) return null;
    if(value.length > maxLen){
        return `Input cannot exceed ${maxLen} characters (currently ${value.length})`
    }
    return null;
}
const TicketTextField = (props) => {
    const {value, setValue, idPrefix, label, helperText, valueType, hasBeenVisited, optional, fullWidth} = props;
    if(value === undefined) console.log(`undefine value for field ${label}`)
    let maxLen = props.maxLen;
    
    const [visited, setVisited] = useState(hasBeenVisited)
    const [inputStarted, setInputStarted] = useState(false)
    
    if(!maxLen) maxLen = SHORT_TEXT_LEN
    const isNumberField = (valueType === FIELD_VALUE_TYPES.FLOAT);
    const onChange = (e) => {
        if(!inputStarted) setInputStarted(true);
        let newValue = e.target.value;
        const newLen = newValue.length;
        const oldValue = value;

        // if((newLen > MAX_LEN) && (newLen > oldValue.length)) newValue = oldValue;
        // else
        if(isNumberField && (newLen > 0) && !isValidPositiveFloatPrefix(newValue)){
            newValue = oldValue;
        }
        // if(valueType === FIELD_VALUE_TYPES.FLOAT){
        //     if(newValue !== "")newValue = parseFloat(newValue)
        // }
        setValue(newValue)
    }
    
    const onBlur = (e) => {
        if(!visited) setVisited(true)
    }
    
    const id = idPrefix ? `${idPrefix}-${label}` : label;
    
    let error = (inputStarted && (value === "")) || (visited && !value) || (value.length > maxLen)
    if(valueType === FIELD_VALUE_TYPES.FLOAT){
        error = error || !isValidPositiveFloatPrefix(value)
    }
    if(value === "" && optional) error = false
    
    const displayLabel = makeFieldDisplayLabel(label, optional)
    const errorMsg = getErrorMessage(value, maxLen, isNumberField)
    return(
        <TextField
            id={id}
            variant="outlined"
            label={displayLabel}
            value={value}
            onChange={onChange}
            helperText={errorMsg ? errorMsg : helperText}
            onBlur={onBlur}
            error={error}
            autoComplete="off"
            fullWidth={fullWidth && true}
        />
    )
}

export default TicketTextField
