import { useContext, useState } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blue_light } from "../../../Colors";
import { getDepartments as getDepartmentsFlat } from "../../hris/hris_helpers";
import { getBgColor, HRISDataMapperButtonPanel, MappingSectionHeader, STATE_CHANGE_NOTE } from "./HRISDataMapperHelper";
import Context from "../../../Store";
import { VerticalDivider } from "../../generic/Dividers";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import { BULLET_CHAR } from "../../../helpers/Constants";

const AUTOMAP_TOOLTIP = `\
Auto-map the departments based off uniquely common name. You should verify the mapping after clicking, as the mapping may not be correct.
`

const DepartmentMapperInstructions = () => {
    return (
        <Box sx={SX_BOX_SIMPLE}>
            <Typography>
                {BULLET_CHAR} Similar to the previos step, map the departments in your HRIS to those tracked in the system.
            </Typography>
            <Typography>
                {BULLET_CHAR} This helps maintain consistency. For example, If you have a department field in a form or managed table, 
                the system will consider the department selected for the field as invalid if it is not mapped to department in your HRIS. 
            </Typography>
            <Typography>
                {BULLET_CHAR} {STATE_CHANGE_NOTE}
            </Typography>
        </Box>        
    )
}
const ExternalDeptDTOItem = ({deptDTO, onClick, isHilighted}) => {
    return (
        <Box onClick={onClick} bgcolor={getBgColor(isHilighted)}>
            <Typography>{deptDTO.name}</Typography>
        </Box>
    )
}

const InternalDeptItem = ({dept, onClick, isHilighted}) => {
    return (
        <Box onClick={onClick}  bgcolor={getBgColor(isHilighted)}>
            <Typography>{dept.name}</Typography>
        </Box>
    )
}

const sortedInternalDeptIds = (deptIds, idToDeptObj) => {
    return [...deptIds].sort((a, b) => idToDeptObj[a].name.localeCompare(idToDeptObj[b].name))
}

const sortedExternalDeptIds = (extDeptIds, idToEmpDeptObj) => {
    return [...extDeptIds].sort((a, b) => {
        const aName = `${idToEmpDeptObj[a].first_name} ${idToEmpDeptObj[a].last_name}`;
        const bName = `${idToEmpDeptObj[b].first_name} ${idToEmpDeptObj[b].last_name}`;
        return aName.localeCompare(bName)
    })
}

export const HRISIntegrationDepartmentMapper = ({internalCompany, externalCompanyDTO, dataMap, setDataMap, advanceStep, goBackStep}) => {
    const {alertError} = useContext(Context);

    const {internal_to_external_dept_id_map, unmapped_internal_dept_ids, unmapped_external_dept_ids} = dataMap;
    const {department_dtos} = externalCompanyDTO;

    const [selectedInternalDeptId, setSelectedInternalDeptId] = useState(null);
    const [selectedExternalDeptId, setSelectedExternalDeptId] = useState(null);

    const internalRootDept = internalCompany.root_department;
    const internalNonRootDepartments = getDepartmentsFlat(internalRootDept).filter(d => d.id !== internalRootDept.id)

    const externalIdToDeptDTO = {}
    for(const deptDTO of department_dtos) externalIdToDeptDTO[deptDTO.external_id] = deptDTO;

    const internalIdToDept = {}
    for(const dept of internalNonRootDepartments) internalIdToDept[dept.id] = dept;

    const onUnmappedExternalDeptDTOClick = (externalId) => {
        if(!selectedInternalDeptId) setSelectedExternalDeptId(externalId);
        else createSingleMapping(selectedInternalDeptId, externalId)
    }
    const onUnmappedInternalDeptDTOClick = (internalId) => {
        if(!selectedExternalDeptId) setSelectedInternalDeptId(internalId);
        else createSingleMapping(internalId, selectedExternalDeptId)
    }

    const createSingleMapping = (internalDeptId, externalDeptId) => {
        internal_to_external_dept_id_map[internalDeptId] = externalDeptId;
        const newUnmappedInternalIds = unmapped_internal_dept_ids.filter(id => id !== internalDeptId)
        const newUnmappedExternalIds = unmapped_external_dept_ids.filter(id => id !== externalDeptId)
        setDataMap({...dataMap, unmapped_internal_dept_ids: newUnmappedInternalIds, unmapped_external_dept_ids: newUnmappedExternalIds})
        setSelectedInternalDeptId(null);
        setSelectedExternalDeptId(null);
    }
    const onRemoveMappingClick = (internalId) => {
        const externalId = internal_to_external_dept_id_map[internalId];
        delete internal_to_external_dept_id_map[internalId];
        unmapped_internal_dept_ids.push(internalId);
        unmapped_external_dept_ids.push(externalId);
        setDataMap({...dataMap})
    }

    const onAutoMapClick = () => {
        const unmappedInternal = [];
        const unmappedExternal = [];
        const mapping = {};

        for(const internalDept of internalNonRootDepartments){
            const deptName = internalDept.name;
            const isNameUniqueInternally = !internalNonRootDepartments.some(d => (d.id !== internalDept.id) && (d.name === deptName));
            const externalDeptDTOsWithName = department_dtos.filter(d => d.name === internalDept.name);
            if(isNameUniqueInternally && (externalDeptDTOsWithName.length === 1)){
                mapping[internalDept.id] = externalDeptDTOsWithName[0].external_id;
            }
            else{
                unmappedInternal.push(internalDept.id);
            }
        }

        const mappedExternalIds = Object.values(mapping);
        for(const deptDTO of department_dtos){
            if(!mappedExternalIds.includes(deptDTO.external_id)){
                unmappedExternal.push(deptDTO.external_id)
            }
        }

        const newDataMap = {
            ...dataMap,
            internal_to_external_dept_id_map: mapping,
            unmapped_internal_dept_ids: unmappedInternal,
            unmapped_external_dept_ids: unmappedExternal
        }
        setDataMap(newDataMap);
    }

    return (
        <Box>
            <Typography variant='h6'>Map Departments</Typography>
            <DepartmentMapperInstructions/>

            <HRISDataMapperButtonPanel goBackStep={goBackStep} advanceStep={advanceStep} onAutoMapClick={onAutoMapClick} autoMapTooltip={AUTOMAP_TOOLTIP}/>

            <Stack direction='row' spacing={2} sx={SX_BOX_SIMPLE}>
                <Stack width='25%'>
                    <MappingSectionHeader
                        title={'Unmapped Departments in your HRIS'}
                        description={'If you do not map them to a tracked user, then AxoTrax will create a new department to track.'}
                        />
                    {
                        sortedExternalDeptIds(unmapped_external_dept_ids, externalIdToDeptDTO).map(externalId => {
                            const deptDTO = externalIdToDeptDTO[externalId];
                            return (
                                <ExternalDeptDTOItem
                                    key={externalId} deptDTO={deptDTO} onClick={() => onUnmappedExternalDeptDTOClick(externalId)}
                                    isHilighted={selectedExternalDeptId === externalId}/>
                            )
                        })
                    }
                </Stack>

                <VerticalDivider/>

                <Stack width='25%'>
                    <MappingSectionHeader
                        title={'Unmapped departments tracked in AxoTrax'}
                        description={'If you do not map these departments to one in your HRIS, they will be deleted'}
                        />
                    {
                        sortedInternalDeptIds(unmapped_internal_dept_ids, internalIdToDept).map(internalId => {
                            const dept = internalIdToDept[internalId];
                            return (
                                <InternalDeptItem
                                    key={internalId} dept={dept} onClick={() => onUnmappedInternalDeptDTOClick(internalId)}
                                    isHilighted={selectedInternalDeptId === internalId}/>
                            )
                        })
                    }
                </Stack>

                <VerticalDivider/>

                <Stack width='50%'>
                    <MappingSectionHeader
                        title={'Mappings'}
                        />
                    {
                        sortedInternalDeptIds(Object.keys(internal_to_external_dept_id_map), internalIdToDept).map(internalId => {
                            const externalId = internal_to_external_dept_id_map[internalId];
                            const dept = internalIdToDept[internalId];
                            const deptDTO = externalIdToDeptDTO[externalId];

                            return (
                                <Stack key={internalId} direction='row'  spacing={2} alignItems='center'>
                                    <Button onClick={() => onRemoveMappingClick(internalId)}>
                                        Unmap
                                    </Button>
                                    <ExternalDeptDTOItem deptDTO={deptDTO}/>
                                    <Typography>→</Typography>
                                    <InternalDeptItem dept={dept}/>
                                </Stack>
                            )
                        })
                    }
                </Stack>
            </Stack>
        </Box>
    )
}

