import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import React, { useState } from "react";
import { getDefaultSectionValues } from "../../helpers/DefaultFormValueMaker";
import TicketSelectField from "../inputs/TicketSelectField";
import { REQUEST_STATES } from "../../helpers/Constants";
import Context from "../../Store";
import LoadButton from "../generic/LoadButton";
import TicketTextField from "../inputs/TicketTextField";
import { getDepartments } from "./hris_helpers";
import SectionForm from "../form/SectionForm";

const MAX_DEPT_NAME_LEN = 100;

export const getDepartmentFormErrorMsg = (deptForm) => {
    const deptName = deptForm.name;
    if(!deptName){
        return "Department must have a name";
    }
    if(deptName.length > MAX_DEPT_NAME_LEN){
        return "Department name is too long";
    }

    return null;
}

const DepartmentForm = ({company, department, setDepartment, submitState, onSubmitClick}) => {
    
    const isEdit = !!department.id;

    const setExtraData = (sectionValues) => {
        setDepartment({...department, extra_data: sectionValues});
    }

    const isTypeSelected = (department.dept_type_id !== "");

    const deptTypeChoice = company.department_types.map(dt => {return {value: dt.id, label: dt.name}})
    const allDepts = getDepartments(company.root_department);
    const parentChoices = allDepts.map(d => {return {value: d.id, label: `${d.name} (${d.type.name})`}})

    const setDepartmentType = (deptTypeId) => {
        if(deptTypeId === department.dept_type_id) return;
        
        const deptType = company.department_types.find(dt => dt.id === deptTypeId);
        const structure = deptType.extra_data_structure;
        const extraData = getDefaultSectionValues(structure)
        setDepartment({...department, dept_type_id: deptTypeId, extra_data: extraData});
    }

    return (
        <Box>
            <Stack spacing={2} marginY={2}>
                {
                    isEdit ? null :
                    <TicketSelectField
                        label={"Department type"}
                        value={department.dept_type_id}
                        setValue={setDepartmentType}
                        choices={deptTypeChoice}
                        fullWidth
                        />
                }
                <TicketTextField
                    label={"Department name"}
                    value={department.name}
                    setValue={(x) => setDepartment({...department, name: x})}
                    fullWidth
                    maxLen={MAX_DEPT_NAME_LEN}
                    />
                {
                    isEdit ? null :
                    <TicketSelectField
                        label={"Which department is this a subdepartment of?"}
                        value={department.parent_id}
                        setValue={(x) => setDepartment({...department, parent_id: x})}
                        choices={parentChoices}
                        fullWidth
                        />
                }
                {
                    !isTypeSelected ? null :
                    <SectionForm
                        sectionStructure={company.department_types.find(dt => dt.id === department.dept_type_id).extra_data_structure}
                        sectionValues={department.extra_data}
                        setSectionValues={setExtraData}
                        isEditable={true}
                        />
                }
            </Stack>
            <LoadButton onClick={onSubmitClick} loading={submitState===REQUEST_STATES.SENDING} disabled={submitState===REQUEST_STATES.SENDING}>
                Submit
            </LoadButton>
        </Box>
    )
}

export default DepartmentForm;