import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { makeFieldDisplayLabel, multiValueToArray } from '../../helpers/TicketFormHelper';
import { useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(item, vals, theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium
  };
}

const TicketMultiSelectField = ({value, setValue, idPrefix, label, choices, helperText, hasBeenVisited, optional}) => {
  const theme = useTheme();
  const [visited, setVisited] = useState(hasBeenVisited)

  const valueAsArray = multiValueToArray(value)

  const handleChange = (event) => {
    const newValue = event.target.value
    if(!visited) setVisited(true)
    setValue(
      // On autofill we get a stringified value.
      typeof newValue === 'string' ? newValue.split(', ') : newValue,
    );
  };

  const handleDelete = (e, idx) => {
    // e.stopPropagation();
    const newVals = [...valueAsArray]
    newVals.splice(idx, 1)
    setValue(newVals)
  }
  const id = idPrefix + "-" + label
  const labelId = "label-" + id

  const invalidSelectedValues = valueAsArray.filter(x => (x !== "") && (choices.findIndex((choice) => choice.value === x) < 0))
  const makeInvalidLabel = (val) => `(INVALID) ${val}`

  const detailsOfValue = (val) => {
    const idx = choices.findIndex((choice) => choice.value === val)
    return (idx >= 0) ? 
      {label: choices[idx].label, color: 'primary'} : 
      {label: makeInvalidLabel(val), color: 'error'}
  }

  const areAllValuesValidChoices = (value === "") || valueAsArray.reduce((acc, val) => acc && choices.some(choice => choice.value === val), true)
  const displayLabel = makeFieldDisplayLabel(label, optional)

  let error = ((visited && (valueAsArray.length === 0)) || !areAllValuesValidChoices)
  if(valueAsArray.length === 0 && optional) error = false
  // error = true

  return (
    <div>
      {/* <FormControl sx={{ m: 1, width: 300 }}> */}
      <FormControl fullWidth>
        <InputLabel id={labelId}>{displayLabel}</InputLabel>
        <Select
          labelId={labelId}
          helpertext={helperText}
          id={id}
          multiple
          value={valueAsArray}
          onChange={handleChange}
          input={<OutlinedInput id={`multi-select-${id}`} label={displayLabel} />}
          error={error}
          onBlur={() => {if(!visited)setVisited(true)}}
          renderValue={(selected) =>{
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((val, idx) => {
                  const details = detailsOfValue(val)
                  return (
                    <Chip
                      key={val}
                      label={details.label}
                      color={details.color}
                      onDelete={e => handleDelete(e, idx)}
                      onMouseDown={(event) => { event.stopPropagation(); }}
                      />
                  )
              })}
              </Box>
            )}
          }
          MenuProps={MenuProps}
        >
          {invalidSelectedValues.map((x) => (
            <MenuItem
              key={x}
              value={x}
              style={{fontWeight: theme.typography.fontWeightMedium}}
            >
              {makeInvalidLabel(x)}
            </MenuItem>
          ))}
          {choices.map((choice) => (
            <MenuItem
              key={choice.value}
              value={choice.value}
              style={{fontWeight: theme.typography.fontWeightMedium}}
            >
              {choice.label}
            </MenuItem>
          ))}
        </Select>
        {!helperText ? null : <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default TicketMultiSelectField