import React, {useEffect, useState} from "react";
import {TextField, MenuItem} from "@mui/material";
import Context from "../../Store";

const MySingleSelectFIeld = ({value, setValue, label, choices, fullWidth}) => {

    // useEffect(() => {
    //     if(choices.length === 1 && choices[0].value !== value && value === "") setValue(currChoices[0].value);
    // }, [choices, value]);
    
    const onChange = (e) => {
        setValue(e.target.value);
    }
    
    
    return(
        <TextField
            select
            label={label}
            // defaultValue={defaultValue}
            // onBlur={onBlur}
            // error={error}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            >
                {choices.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
        </TextField> 
    )
}

export default MySingleSelectFIeld