import { useSearchParams } from "react-router-dom";
import { useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import ProfilesView from "./ProfilesView";
import { Box, Button, ButtonGroup } from "@mui/material";
import { PERM_SELECTABLE_VIEWS, PERM_VIEWS } from "./perm_constants";
import EditGlobalProfileView from "./EditGlobalProfileView";
import AddGlobalProfile from "./AddGlobalProfile";
import UserPermissionsView from "./UserPermissionsView";
import EditEntityRoleProfileView from "./EditEntityRoleProfileView";
import EditOrgRolesProfileView from "./EditOrgRolesProfileView";
import { hasPermToEditPerms } from "./perm_helper";
import React from "react";
import Context from "../../Store";
import CloseableMessage from "../generic/CloseableMessage";

const SelectViewPanel = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view');
    const setView = (x) => setSearchParams({view: x});
    return (
        <ButtonGroup variant="contained" >
            {
                Object.keys(PERM_SELECTABLE_VIEWS).map(key => {
                    const label = PERM_SELECTABLE_VIEWS[key];
                    const color = (label === view) ? 'success' : 'primary'
                    return(
                        <Button onClick={()=>setView(label)} key={label} color={color}>
                            {label}
                        </Button>
                    )
                })
            }
        </ButtonGroup>
    )
}

const PermissionsViewContent = ({permissions, refetchPermissions}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let view = searchParams.get('view');
    if(!view) view = PERM_VIEWS.USERS; //default landing

    switch (view) {
        case PERM_VIEWS.USERS:
            return <UserPermissionsView permissions={permissions} refetchPermissions={refetchPermissions}/>
        case PERM_VIEWS.PROFILES:
            return <ProfilesView permissions={permissions} refetchPermissions={refetchPermissions}/>
        case PERM_VIEWS.EDIT_GLOBAL_PROFILE:
            return <EditGlobalProfileView permissions={permissions} refetchPermissions={refetchPermissions}/>
        case PERM_VIEWS.ADD_GLOBAL_PROFILE:
            return <AddGlobalProfile permissions={permissions} refetchPermissions={refetchPermissions}/>
        case PERM_VIEWS.EDIT_ENTITY_ROLE_PROFILE:
            return <EditEntityRoleProfileView permissions={permissions} refetchPermissions={refetchPermissions}/>
        case PERM_VIEWS.EDIT_ORG_ROLES_PROFILE:
            return <EditOrgRolesProfileView permissions={permissions} refetchPermissions={refetchPermissions}/>

        default:
            return <div>page not found</div>
    }

}

const getUsersWithoutAssignment = (permissionAssignments, activeUsers) => {
    const output = []
    for (const [userIdStr, usersName] of Object.entries(activeUsers)) {
        const userId = Number(userIdStr);
        let found = false;
        for(const assignment of permissionAssignments){
            if(assignment.user_ids.includes(userId)) found = true;
        }

        if(!found) output.push(userId)
    }
    return output;
}

const CAN_ONLY_VIEW_MSG = "You only have permission to view the systems permissions, but not to edit them."
const PermissionsPage = () => {
    const {user, staticData} = React.useContext(Context);

    const insertNonAssignments = (response) => {
        //adds a dummy perm profile assignment for users that dont have a prfile assigned
        const globalAssignments = response.data.global_assignments;
        const userIdsWithoutProfile = getUsersWithoutAssignment(globalAssignments, staticData.active_users);
        const dummyAssignment = {
            profile: {
                id: null,
                name: "No Profile",
                permissions: {}
            },
            user_ids: userIdsWithoutProfile
        }
        globalAssignments.push(dummyAssignment)
    }

    const [permissions, refetchPermissions] = useRefetchData(EXTENSIONS.GET_PERMS, {}, insertNonAssignments);
    // const [view, setView] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();

    if(permissions === false) return <div>Failed to load.</div>
    if(permissions === null) return <div>Loading...</div>
    const canEdit = hasPermToEditPerms(user)

    // console.log('perms', permissions)
    // console.log('static data', staticData)
    

    return (
        <Box>
            {canEdit ? null : <CloseableMessage message={CAN_ONLY_VIEW_MSG}/>}
            <SelectViewPanel/>
            <Box marginTop={2}>
                <PermissionsViewContent permissions={permissions} refetchPermissions={refetchPermissions}/>
            </Box>
        </Box>
    )
}

export default PermissionsPage;