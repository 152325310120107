import { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Divider, IconButton, Typography } from '../../../../node_modules/@mui/material/index';
import ConfirmActionDialog from '../../generic/ConfirmActionDialog';
import CollapseableContent from '../../generic/CollapseableContent';

const INDENT = 4;
const TXT_VARIANT_0 = 'h5'
const TXT_VARIANT_1 = 'h6'
const BULLET = '\u2022';

const FieldTypePanel = () => {
    return (
        <CollapseableContent title={'Approvers of this field are:'}>
            <Box marginLeft={0}>
                <Typography variant={TXT_VARIANT_1}>Users with a certain tag:</Typography>
                <Typography marginLeft={INDENT}>
                    Users can be marked with tags (e.g. "Legal", "IT", "HR", "Accounting", etc). Selecting this option means that the reviewers of this
                    stage will be anyone with the selected tag. For the stage to pass approval, only 1 user with the tag needs to approve, not all of them.
                </Typography>

                <Typography variant={TXT_VARIANT_1}>On the State:</Typography>
                <Typography marginLeft={INDENT}>
                    Select this if the user that should review the ticket is a field of the entity. Fields that are optionally or conditionally displayed cannot
                    be used for this purpose (or any of the other stages below)
                </Typography>

                <Typography variant={TXT_VARIANT_1}>On the ticket:</Typography>
                <Typography marginLeft={INDENT}>
                    This is similar to the above option ("On the state"), and in some cases, it is the same. The difference here is that the field with the approver 
                    is expected to be on the request/ticket, rather than the current state  of the entity. The main cases where it makes a difference
                    are:
                </Typography>
                <Box marginLeft={2 * INDENT}>
                    <Typography>
                        {BULLET} For an Edit Request. For example, if there is an edit request to change the "Software Admin" field of a software from "John" to "Jack",
                        then "Jack" would be the stage's reviewer if this option is selected, since his name is on the field for the ticket. "John" would be the stage's
                        reviewer if "On the state" is selected, since his name is on the current state of the software.
                    </Typography>                    
                    <Typography>
                        {BULLET} Certain tickets do not have all the fields that the "state" has (e.g. removal requests).
                    </Typography>                    
                    <Typography>
                        {BULLET} The ability for the reviewer to defer (see the "Defer" panel below for details)
                    </Typography>                    
                </Box>

                <Typography variant={TXT_VARIANT_1}>On the parent of this entity:</Typography>
                <Typography marginLeft={INDENT}>
                    In the case of approving a member (e.g. an employee of a vendor), this option will be available. Selecting it means that the
                    reviewer of the stage is marked on the parent entity (e.g. the vendor).
                </Typography>

                <Typography variant={TXT_VARIANT_1}>On the resource:</Typography>
                <Typography marginLeft={INDENT}>
                    In the case of approving access, select this option if the approver appears on a field of the resource entity. For example,
                    if the request is for "John" to gain access to "SomeSoftware", then "SomeSoftware" is the resource (John would be the accessor).
                </Typography>

                <Typography variant={TXT_VARIANT_1}>On the accessor:</Typography>
                <Typography marginLeft={INDENT}>
                    In the case of approving access, select this option if the approver appears on a field of the accessor entity. For example,
                    if the request is for a vendor to gain access to "SomeSoftware", then the vendor is the accessor (SomeSoftware would be the resource).
                </Typography>

                <Typography variant={TXT_VARIANT_1}>On the parent of the accessor:</Typography>
                <Typography marginLeft={INDENT}>
                    In the case of approving access, select this option if the approver appears on a field of the parent of the accessor entity. For example,
                    if the request is for a vendor's employee "John" to gain access to "SomeSoftware", then the John is the accessor and the vendor is the parent.
                    You would select this option if the vendor has the field with the user that reviews this stage
                </Typography>
            </Box>
        </CollapseableContent>
    )
}

const DaysUntilNeglectedPanel = () => {
    return (
        <CollapseableContent title={'Days until neglected:'}>
            <Box marginLeft={0}>
                {/* <Typography variant={TXT_VARIANT_1}>Users with a certain tag:</Typography> */}
                <Typography marginLeft={INDENT}>
                    Provide a positive number. After that many days of a ticket pending at this stage, daily email reminders
                    will be sent to the reviewers at this stage that it is awaiting their review.
                </Typography>
            </Box>
        </CollapseableContent>
    )
}
const EditableSectionsPanel = () => {
    return (
        <CollapseableContent title={'Days until neglected:'}>
            <Box marginLeft={0}>
                <Typography marginLeft={INDENT}>
                    Mark the sections that the reviewer of this stage should be able to edit. If the ticket form has sections that
                    are marked as "hide at submission", then there should be at least 1 stage in the process where that section
                    is marked as editable by the reviewer(s).
                </Typography>
            </Box>
        </CollapseableContent>
    )
}
const DeferabilityPanel = () => {
    return (
        <CollapseableContent title={'Reviewer can defer:'}>
            <Box marginLeft={0}>
                <Typography marginLeft={INDENT}>
                    If you selected that the "Approvers for this stage are on the ticket", then you can mark the stage as deferable.
                    This allows the reviewer to defer the request to someone else that could have been selected instead of them for the field.
                    When the request is defered, it does not yet advance from the stage, but just edits the ticket and who the stage's approver is.
                </Typography>
            </Box>
        </CollapseableContent>
    )
}
const IsAccessRemovalStagePanel = () => {
    return (
        <CollapseableContent title={'Is full access removal stage'}>
            <Box marginLeft={0}>
                <Typography marginLeft={INDENT}>
                    Intended for removal processes of entities that can be accessed resources (e.g. software, USBs, documents). Check this box
                    to let the system know that when this stage is approved, that all access to the entity has been removed. An example of why you 
                    may want this:
                </Typography>
                <Typography marginLeft={INDENT * 2}>
                    For removing a software, the last stage might be that IT confirms that the company has been disconnected from the software.
                    You may want a stage before that, where the Software's admin confirms that all access to the software has been removed. You would
                    check this box for that stage. 
                </Typography>
            </Box>
        </CollapseableContent>
    )
}

const ConfirmationMessagesPanel = () => {
    return (
        <CollapseableContent title={'Confirmation messages:'}>
            <Box marginLeft={0}>
                <Typography marginLeft={INDENT}>
                    These are the messages that the stage reviewer will be presented when acting on a ticket (approving, rejecting, or defering).
                    For example, when the user clicks "Approve", the corresponding message will pop-up and they will be asked to confirm whatever message 
                    you provided, before the approval is submitted.
                </Typography>
            </Box>
        </CollapseableContent>
    )
}
const InfoPanel = () => {
    return (
        <Box>
            {/* <Typography variant={TXT_VARIANT_0}>Approvers of this field are:</Typography> */}
            <FieldTypePanel/>
            <DaysUntilNeglectedPanel/>
            <EditableSectionsPanel/>
            <DeferabilityPanel/>
            <IsAccessRemovalStagePanel/>
            <ConfirmationMessagesPanel/>
        </Box>
    )
}

export const ConfigPanelHelpButton = () => {

    const [isOpen, setIsOpen] = useState(false);

    if(!isOpen){
    
        return (
            <IconButton onClick={() => setIsOpen(true)} >
                <HelpOutlineIcon color='primary'/>
            </IconButton>
        )
    }

    return (
        <ConfirmActionDialog
            title={'Stage Settings'}
            body={<InfoPanel/>}
            handleCancel={undefined}
            cancelText={undefined}
            handleConfirm={() => setIsOpen(false)}
            confirmText={'Ok'}
            loading={false}
            disabled={false}
            fullWidth={true}
            maxWidth={'md'}
            />
    )
}