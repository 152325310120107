import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Stack, IconButton } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useInterval } from "../../helpers/CustomHooks";
import Context from "../../Store";
import { CONFIG_LOCK_HOLD_TIME_MINUTES } from "../../helpers/Constants";
import { EXTENSIONS, post } from "../../helpers/requests";
import ConfirmActionDialog from "../generic/ConfirmActionDialog";
import { Tooltip } from "../../../node_modules/@mui/material/index";

const NUM_MS_IN_MINUTE = (1000 * 60);
const POPUP_MINUTES = 5;

const getPopupBodyText = (minutesRemaining) => {
    return `
Right now, you hold the configuration lock. This means that others cannot edit the systems configurations.
The lock will expire in ${minutesRemaining} minutes.
When time runs out, someone else may start editing the \
systems configurations and you will not be able to save your work.
You can always click the renew lock icon, rather than waiting for this popup.
If you are done configuring and have saved all your work, you may close the tab/window.
`

}

const RenewLockWarningPopup = ({renewLock, minutesRemaining, isRenewing}) => {

    return(
        <ConfirmActionDialog
            title={"Are you still working?"}
            body={getPopupBodyText(minutesRemaining)}
            // cancelText={""}
            // handleCancel={""}
            confirmText={"Yes, renew lock"}
            handleConfirm={renewLock}
            loading={isRenewing}
            disabled={isRenewing}
            // fullWidth={""}
            // maxWidth={""}
            />
    )
}

export const ConfigLockTimer = () => {
    const {alertError} = useContext(Context);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [minutes, setMinutes] = useState(CONFIG_LOCK_HOLD_TIME_MINUTES - 1);
    const [isRenewing, setIsRenewing] = useState(false);

    useEffect(() => {
        const onSuccess = () => {
            setMinutes(CONFIG_LOCK_HOLD_TIME_MINUTES - 1)
        }
        const onFail = () => {
            setMinutes(0);
            alertError("Failed to renew config lock. You may not be able edit configurations. Try manually renewing by clicking the icon");
        }
        post(EXTENSIONS.OBTAIN_CONFIG_LOCK, {}, onSuccess, onFail)
    }, [])

    useInterval(()=>{
        if (minutes !== 0) {
            const newMinutes = minutes - 1;
            setMinutes(newMinutes);
            if(newMinutes === POPUP_MINUTES){
                setIsPopupOpen(true);
            }
        }
    }, NUM_MS_IN_MINUTE);

    const renewLock = () => {
        setIsRenewing(true);

        const onSuccess = () => {
            setMinutes(CONFIG_LOCK_HOLD_TIME_MINUTES - 1)
            setIsPopupOpen(false);
            setIsRenewing(false);
        }

        const onFail = () => {
            setIsPopupOpen(false);
            setIsRenewing(false);
            alertError("Failed to renew config lock");
        }
        post(EXTENSIONS.OBTAIN_CONFIG_LOCK, {}, onSuccess, onFail)
    }

    if(isPopupOpen){
        return <RenewLockWarningPopup renewLock={renewLock} minutesRemaining={minutes} isRenewing={isRenewing}/>
    }
    const tooltipText = (minutes > 0) ? `Renew lock (Expires in ${minutes} minutes)` : `Try to reacquire lock (Expired)`
    return (
        <Tooltip title={<Typography>{tooltipText}</Typography>}>
            <IconButton onClick={renewLock}>
                <LockResetIcon color='primary' fontSize='large' sx={{verticalAlign:"middle"}}/>
            </IconButton>
        </Tooltip>
    )
}