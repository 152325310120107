import { FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../components/inputs/FieldProps";
import { EXTRA_LONG_TEXT_LEN, LONG_TEXT_LEN, MULTI_SELECT_DELIM, MULTI_TEXT_DELIM, SHORT_TEXT_LEN } from "./Constants";
import { isFieldRelevent } from "./TicketFormHelper";

const isSelectFieldValid = (fieldStructure, sectionValues) =>  {
    // console.log('section values', sectionValues)
    const fieldValue = sectionValues[fieldStructure.id];
    const fieldDependencies = fieldStructure.choice_desc.dependencies ? fieldStructure.choice_desc.dependencies : [];
    let validChoices = fieldStructure.choice_desc.choices;

    fieldDependencies.forEach( depField => {
        const depVal = sectionValues[depField];
        validChoices = validChoices[depVal];
    });
    const output = validChoices.some(choice => choice.value === fieldValue);
    
    return output;
}

export const IsFieldValid = (fieldStructure, sectionValues) => {
    try {
        const {component, value_type, is_optional} = fieldStructure;
        const {SELECT, LONG_TEXT, SHORT_TEXT, MULTISELECT, MULTICHECK, MULTI_TEXT} = FIELD_COMPONENT_TYPES;
        const fieldValue = sectionValues[fieldStructure.id];

        // empty strings should be provided as None/null, helps with consistency throughout program flow
        if(fieldValue === "") return false;

        // optional fields MAY be null
        if((is_optional === true) && (fieldValue === null)) return true;

        // irrelevent fields MUST be null
        const is_relevent = isFieldRelevent(fieldStructure, sectionValues)
        if(!is_relevent){
            return (fieldValue === null)
        }
        
        if(fieldValue === null) return false
        if(component === SELECT)
            return isSelectFieldValid(fieldStructure, sectionValues)
        
        //string types - long, short, selected from closed set
        if(value_type === FIELD_VALUE_TYPES.STRING){
            if(component === LONG_TEXT)
                return fieldValue.length <= LONG_TEXT_LEN
            if(component === SHORT_TEXT)
                return fieldValue.length <= SHORT_TEXT_LEN
            
            if([MULTISELECT, MULTICHECK].includes(component)){
                if(fieldValue.length > EXTRA_LONG_TEXT_LEN) return false
                const allVals = fieldValue.split(MULTI_SELECT_DELIM);
                for(let i = 0; i < allVals.length; i++) if(!fieldStructure.choice_desc.choices.some(choice => (choice.value === allVals[i]))) return false;
                return true;
            }
            if(component === MULTI_TEXT){
                const isTooLong = (fieldValue.length > EXTRA_LONG_TEXT_LEN)
                const startsEmpty = (fieldValue[0] === MULTI_TEXT_DELIM)
                const endsEmpty = (fieldValue[fieldValue.length - 1] === MULTI_TEXT_DELIM)
                const middleHasEmpty = (fieldValue.includes(MULTI_TEXT_DELIM + MULTI_TEXT_DELIM))
                return (!isTooLong && !startsEmpty && !endsEmpty && !middleHasEmpty)
            }
        }
        if(value_type === FIELD_VALUE_TYPES.FLOAT){
            //TODO: use better float parsing
            const f = parseFloat(fieldValue)
            return (!isNaN(f) && (f >= 0))
        }
        if(value_type === FIELD_VALUE_TYPES.BOOL){
            return ((fieldValue === true) || (fieldValue === false))
        }
        if(value_type === FIELD_VALUE_TYPES.FILE){
            return true
        }

        return false
                
    } catch (error) {
        return false
    }
}

export const validateForm = (formValues, formStructure, isInitialSubmission, contextValues) => {
    const errors = [];

    formStructure.sections.forEach(sectionStructure => {
        if(sectionStructure.is_deleted){
            return;
        }
        const sectionId = sectionStructure.id;
        const sectionValues = formValues[sectionId];
        const sectionLabel = sectionStructure.metadata.label;
        
        const sectionValuesWithContext = contextValues ? {...sectionValues, ...contextValues} : {...sectionValues};
        sectionStructure.fields.forEach(fieldStructure => {
            if(fieldStructure.is_deleted){
                return;
            }
            const fieldId = fieldStructure.id;
            const fieldValue = sectionValues[fieldId];

            if(isInitialSubmission && sectionStructure.metadata.hide_at_submission){
                if(fieldValue !== null && fieldValue !== "") errors.push(`${sectionLabel} section should be empty`);
                return;
            }
    
            let isValidField = IsFieldValid(fieldStructure, sectionValuesWithContext);
            //add error
            if(!isValidField){
                const fieldLabel = fieldStructure.short_label;
                const error = sectionLabel + " - " + fieldLabel;
                errors.push(error);
            }              
        })
    });

    return errors;
}