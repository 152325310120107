import { Box, Typography } from "@mui/material"


const PageTitle = ({title, subtitle}) => {

    return (
        <Box marginBottom={3}>
            <Typography variant="h3" align="center" marginBottom={1}>{title}</Typography>
            {
                subtitle &&
                <Typography variant="h4" align="center">{subtitle}</Typography>
            }
        </Box>
    )
}

export default PageTitle;
