import { useContext, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index"
import { EXTENSIONS, post } from "../../../helpers/requests"
import Context from "../../../Store"
import LoadingMessage from "../../generic/LoadingMessage"
import { BULLET_CHAR } from "../../../helpers/Constants"
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx"


export const HRISIntegrationPreVaultPanel = ({advanceStep}) => {
    const {alertError} = useContext(Context);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onOpenVaultClick = () => {
        const onSuccess = (resp) => {
            setIsSubmitting(false);
            const sessionUri = resp.data.data.session_uri;
            const newWindow = window.open(sessionUri, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
            advanceStep();
        }
        const onFail = (e) => {
            setIsSubmitting(false);
            alertError(e.response.data);
        }
        post(EXTENSIONS.START_EXTERNAL_HRIS_VAULT_SESSION, {}, onSuccess, onFail)
        setIsSubmitting(true);
    }

    const onSkipClick = () => {
        const onSuccess = (resp) => {
            setIsSubmitting(false);
            advanceStep();
        }
        const onFail = (e) => {
            setIsSubmitting(false);
            alertError(e.response.data);
        }
        post(EXTENSIONS.SET_HRIS_TO_INTERNAL, {}, onSuccess, onFail)
        setIsSubmitting(true);
    }

    return (
        <Box>
            <Typography variant='h6'>Pre-Vault</Typography>
            <Box sx={SX_BOX_SIMPLE}>
                <Typography>
                    {BULLET_CHAR} Open the vault to integrate your HRIS. A new tab will be opened to APIDeck. Make sure to only maintain 1 connected HRIS
                    integration in the vault. When you have completed authorizing the integration, return to this page and click continue.
                </Typography>
                <Typography>
                    {BULLET_CHAR} NOTE: AxoTrax only needs the following "read" permissions from you HRIS: 
                </Typography>
                <Box marginLeft={2}>
                    <Typography>
                        {BULLET_CHAR} Get Employees (id, emails, manager, name, employment status, department_external_id)
                    </Typography>
                    <Typography>
                        {BULLET_CHAR} Get Departments (id, name, parent department - may not apply to your hris)
                    </Typography>
                    <Typography>
                        {BULLET_CHAR} When setting permissions in your HRIS for this integration, do not grant access to sensative information
                        such as SSN. AxoTrax does not use or store data other than what is mentioned above.
                    </Typography>
                </Box>
                <Typography>
                    {BULLET_CHAR} NOTE: Avoid adding/removing/editting employees & departments in your HRIS (and the AxoTrax userbase) while you are performing the integration.
                    If you need to do so for the purpose of this integration, you should restart the process. The final submission may fail if the state has changed.
                </Typography>
                <Typography>
                    {BULLET_CHAR} NOTE: If your userbase is currently integrated with an HRIS and you are changing your integration, 
                    once you begin the vault session (or skip this step), your HRIS management will switch to "Manually Managed" and will not continue syncing with
                    the current integration. Only upon completion of this process, the userbase will again be Externally Managed. This means that if you
                    quit the process in the middle, you will need to reintegrate with your previous HRIS in order to truly "cancel".
                </Typography>
            </Box>
            {
                isSubmitting ?
                    <LoadingMessage msg={'Setting up vault. This might take a minute'}/> :
                    <Box display='flex' m={2}>
                        <Stack spacing={2}>
                            <Box>
                                <Button variant='contained' onClick={onOpenVaultClick}>
                                    Open Vault
                                </Button>
                            </Box>
                            <Box display='flex'  alignItems='center'>
                                <Button variant='contained' onClick={onSkipClick}>
                                    Skip
                                </Button>
                                <Typography marginLeft={1}>
                                    Only select this if you have setup your HRIS in the vault already.
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
            }
        </Box>
    )
}