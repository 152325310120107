import React, {useEffect, useState} from "react";
import {TextField, MenuItem} from "@mui/material";
import { makeFieldDisplayLabel, sortChoicesByLabel } from "../../helpers/TicketFormHelper";
import Context from "../../Store";
import { FIELD_VALUE_TYPES } from "./FieldProps";


const getAllPossibleChoices = (obj) => {
    if(Array.isArray(obj)) return obj;

    const output = []
    Object.values(obj).forEach(x => output.push(...getAllPossibleChoices(x)))
    return output
}

// returns all the unique valued possible choices from a tree of choices
export const getAllUniquePossibleChoices = (allChoices) => {
    const dups = getAllPossibleChoices(allChoices)
    const uniqueChoices = []
    const uniqueVals = [] //Lazy implemenation to check for duplicates. TODO: replace with better logic at some point.
    dups.forEach(x => {
        if(!uniqueVals.includes(x.value)){
            uniqueVals.push(x.value);
            uniqueChoices.push(x)
        }
    })
    // sortChoicesByLabel(uniqueChoices);
    return uniqueChoices;
}

const getCurrChoices = (dependencies, sectionValues, allChoices) => {

    if((dependencies === undefined) || (dependencies === null) || (dependencies.length === 0)) return allChoices

    let output = allChoices;
    for(let i = 0; i < dependencies.length; i++){
        const dependencyField = dependencies[i];
        const dependencyValue = sectionValues[dependencyField]

        if(output[dependencyValue] !== undefined){
            output = output[dependencyValue]
        }
        else{
            //return all possible choices
            return getAllUniquePossibleChoices(output)
        }
    }
    return output;
}
const getInvalidChoiceLabel = (value, fieldStructure, usersMap, departmentsMap) => {
    if(!fieldStructure || !usersMap || !departmentsMap) return value;

    if(fieldStructure.value_type === FIELD_VALUE_TYPES.EMPLOYEE) return usersMap[value];
    if(fieldStructure.value_type === FIELD_VALUE_TYPES.DEPARTMENT) return departmentsMap[value];

    if(!fieldStructure.choice_desc?.choices) return value;

    const allChoices = getAllUniquePossibleChoices(fieldStructure.choice_desc.choices);
    const choice = allChoices.find(c => c.value === value);
    if(choice) return choice.label;

    return value;
}

const TicketSelectField = ({value, setValue, idPrefix, label, choices, helperText, requires_approved_edit, dependencies, sectionValues, hasBeenVisited, optional, fullWidth, hideValues, fieldStructure, contextValues, doNotAutoSelect}) => {
    const {staticData} = React.useContext(Context);
    const {all_users, all_departments} = staticData;

    if(contextValues){
        sectionValues = {...sectionValues, ...contextValues}
    }
    const currChoices = getCurrChoices(dependencies, sectionValues, choices).filter(c => !c.is_deleted);//todo: use UseMemo eventually

    const [visited, setVisited] = useState(hasBeenVisited);

    useEffect(() => {
        if(!doNotAutoSelect && (currChoices.length === 1) && (currChoices[0].value !== value) && (value === "")) setValue(currChoices[0].value);
    }, [currChoices, value]);
    
    const onChange = (e) => {
        setValue(e.target.value);
    }
    
    const onBlur = (e) => {
        if(!visited) setVisited(true);
    }
    
    const id = idPrefix ? `${idPrefix}-${label}` : label;
    const isValueInList = (value === "") || currChoices.map((choice) => choice.value).includes(value)
    // const defaultValue = (choices.length === 1) ? choices[0].value : value
    // if((choices.length === 1) &&)
    const displayChoices = hideValues ? currChoices.filter(x => (x.value === value) || !hideValues.includes(x.value)) : currChoices;
    const displayLabel = makeFieldDisplayLabel(label, optional)

    let error = ((visited && !value) || !isValueInList)
    if(value === "" && optional) error = false
    
    return(
        <TextField id={id} select
            label={displayLabel}
            // defaultValue={defaultValue}
            onBlur={onBlur}
            error={error}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth && true}
            helperText={helperText}
            // disabled={requires_approved_edit}
            >
                {displayChoices.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                {
                    !isValueInList &&
                    <MenuItem key={value} value={value}>
                        {`(INVALID) ${getInvalidChoiceLabel(value, fieldStructure, all_users, all_departments)}`}
                    </MenuItem>
                }
        </TextField> 
    )
}

export default TicketSelectField
