import { CONFIGS } from "../app/LayeredNavPanel"

export const HRIS_READONLY_SELECTABLE_VIEWS = {
    EMPS: 'Employees',
    ORG: 'Org',
    ROLES: 'Roles',
}
export const HRIS_WRITE_VIEWS = {
    ADD_EMP: 'Add Employee',
    ADD_ROLE: 'Add Role',
    ADD_DEPT: 'Add Department',
    CONFIGS: 'Configs',
}
export const HRIS_SELECTABLE_VIEWS = {
    // EMPS: 'Employees',
    // ORG: 'Org',
    // ROLES: 'Roles',
    ...HRIS_READONLY_SELECTABLE_VIEWS,
    ADD_EMP: 'Add Employee',
    ADD_ROLE: 'Add Role',
    ADD_DEPT: 'Add Department',
    CONFIGS: 'Configs',
}
export const HRIS_VIEWS = {
    // EMPS: 'Employees',
    // ORG: 'Org',
    // ADD_EMP: 'Add Employee',
    // ADD_ROLE: 'Add Role',
    ...HRIS_SELECTABLE_VIEWS,
    EMP: 'Employee',
    EDIT_ROLE: 'Edit Role',
    EDIT_EMP: 'Edit Emp',
    EDIT_DEPT: 'Edit Dept',
}

export const HRIS_VIEW_EXTERNAL_MANAGE = 'Manage'