import { useState } from "react"
import { Box, Button, TextField } from "../../../../node_modules/@mui/material/index";
import ConfirmActionDialog from "../../generic/ConfirmActionDialog";
import TicketSelectField from "../../inputs/TicketSelectField";
import { REVIEWER_ACTION_TYPES } from "../../../helpers/Constants";

const getPresentParticipleOfAction = (action) => {
    switch (action) {
        case REVIEWER_ACTION_TYPES.APPROVE:
            return "approving"
        case REVIEWER_ACTION_TYPES.REJECT:
            return "rejecting"
        case REVIEWER_ACTION_TYPES.DEFER:
            return "defering"    
        default:
            return action
    }
}
const getHelperText = (action) => {
    const actioning = getPresentParticipleOfAction(action);
    return `This is the message the task reviewer will confirm before ${actioning} a request at this stage.`
}

const REVIEW_ACTION_CHOICES = [
    {value: REVIEWER_ACTION_TYPES.APPROVE, label: 'Approve (required)'},
    {value: REVIEWER_ACTION_TYPES.REJECT, label: 'Reject'},
    {value: REVIEWER_ACTION_TYPES.DEFER, label: 'Defer'},
]

const ConfirmationMessagesPanelContent = ({confirmationMessages, setConfirmationMessages}) => {
    // const {Approve, Reject, Defer} = confirmationMessages;

    const [selectedAction, setSelectedAction] = useState(REVIEWER_ACTION_TYPES.APPROVE)

    const currMessage = confirmationMessages[selectedAction];

    const onChange = (e) => {
        let newValue = e.target.value
        setConfirmationMessages({...confirmationMessages, [selectedAction]: newValue});
    }

    return (
        <Box>
            <TicketSelectField
                label={'Review Action'}
                value={selectedAction}
                setValue={setSelectedAction}
                choices={REVIEW_ACTION_CHOICES}
                />
            <TextField
                variant="outlined"
                label={'Message'}
                value={currMessage}
                onChange={onChange}
                multiline
                maxRows={25}
                minRows={2}
                fullWidth={true}
                autoComplete="off"
                helperText={getHelperText(selectedAction)}
                placeholder={'By clicking confirm, you agree that...'}
                />            
        </Box>
    )
}

export const ConfirmationMessagesField = ({confirmationMessages, setConfirmationMessages}) => {

    const [isOpen, setIsOpen] = useState(false);
    if(!isOpen){
        return (
            <Button variant='contained' onClick={() => setIsOpen(true)}>
                Confirmation Messages
            </Button>
        )
    }

    return (
        <ConfirmActionDialog
            title={'Set Messages for reviewers'}
            body={
                <ConfirmationMessagesPanelContent
                    confirmationMessages={confirmationMessages}
                    setConfirmationMessages={setConfirmationMessages}
                    />
            }
            handleCancel={undefined}
            handleConfirm={() => setIsOpen(false)}
            cancelText={undefined}
            confirmText={"Done"}
            loading={false}
            disabled={false}
            />
    )
}