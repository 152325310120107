import React, { useCallback, useContext, useMemo, useState } from 'react';
import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
    useReactFlow,
    getOutgoers
} from 'reactflow';
import 'reactflow/dist/style.css';
import AtomicStageNode from './AtomicStageNode';
import { Box, Button, Stack } from '../../../../../node_modules/@mui/material/index';
import { STAGE_TYPE_METAS } from '../../../../helpers/Constants';
import { EndParallelProcessNode, MarkerNode, StartParallelProcessNode } from './ControlNodes';
import Context from '../../../../Store';
import { doesGraphHaveCycle } from './graph_helper';
import { GraphHelpButton } from './GraphHelpButton';
import { SX_BOX_SIMPLE } from '../../../../helpers/common_sx';
import TicketCheckbox from '../../../inputs/TicketCheckbox';
import { NodeDeletionWarningDialog } from './NodeDeletionWarningDialog';
import TicketSelectField from '../../../inputs/TicketSelectField';

const MUL = 0;
const Y_SPACING = 30;
const EDGE_WIDTH = 20; //60 * MUL
const CONTROL_NODE_DIAMETER  = 40;

const ZOOM = 1;
const ANIMATE_EDGES = false;//true;
const IS_INTERACTABLE = true;

const initBgColor = '#ffffff';
// const initBgColor = '#1A192B';
const connectionLineStyle = { stroke: '#000fff' };
const defaultViewport = { x: 0, y: 0, zoom: ZOOM };

const getDefaultEdgeOptions = (isAnimated) => {
    // const defaultEdgeOptions = {
    return {
        animated: isAnimated,//ANIMATE_EDGES
        style: { stroke: '#000fff' },
        zIndex: 2,
        markerEnd:{
            type: 'arrow',
            color: '#000fff'
        }
    }
}

export const NODE_TYPES = {
    ATOMIC: 'atomicStageNode',
    START_PP: 'startParallelProcessNode',
    END_PP: 'endParallelProcessNode',
}

const nodeTypes = {
    [NODE_TYPES.ATOMIC]: AtomicStageNode,
    // parentStageNode: GroupingNode,
    [NODE_TYPES.START_PP]: StartParallelProcessNode,
    [NODE_TYPES.END_PP]: EndParallelProcessNode,
    markerNode: MarkerNode
};

//#region constants
const HEIGHT = 300;

const DEFAULT_CONFIRM_REJECT_MSG = `
Are you sure you want to reject this request?
If you do, any changes you made will be applied
and the request will be closed to future approvals.
The ticket will need to be submitted again in order to be approved.
`

const DEFAULT_CONFIRM_DEFER_MSG = `
Are you sure you want to defer this request?
If you do, the request will be forwarded to who you selected for review.
You will no longer be able to approve or reject this request after confirmation.
`
//#endregion constants

//#region process to graph conversions

export const exampleApprovalProcess ={
    id: 1,
    name: 'Main',
    status: "enabled",
    stages:[
        {id: 2, name: "Manager", status: "approved"},
        {id: 3, name: "Sponsor", status: "approved"},
        {id: 4, name: "Legal", status: "approved"},
        {id: 5, name: "Accounting + HR", status: "enabled", 
            approval_processes:[
                {
                    id: 6,
                    name: "Accounting",
                    status: "enabled",
                    stages:[
                        {id: 7, name: "Tipalti Invite", status: "approved"},
                        {id: 8, name: "Tipalti Complete", status: "enabled"},
                    ]
                },
                {
                    id: 9,
                    name: "HR",
                    status: "enabled",
                    stages:[
                        {id: 10, name: "Data Privacy", status: "enabled"},
                        {id: 11, name: "Background Check", status: null},
                        {id: 55, name: "wtvr", status: "enabled", 
                            approval_processes:[
                                {
                                    id: 56,
                                    name: "Accounting",
                                    status: "enabled",
                                    stages:[
                                        {id: 57, name: "Tipalti Invite", status: null},
                                        {id: 58, name: "Tipalti Complete", status: null},
                                    ]
                                },
                                {
                                    id: 59,
                                    name: "HR",
                                    status: "enabled",
                                    stages:[
                                        {id: 510, name: "Data Privacy", status: null},
                                        {id: 511, name: "Background Check", status: null},
                                        
                                    ]
                                },
                        ]}
                    ]
                },
        ]},
        {id: 12, name: "Complete!", status: null},
    ]
}

export const approvalProcessToGraph = (approvalProcess) => {
    const nodes = []
    const edges = []

    let graphHeight = 0;
    let graphWidth = 0;

    let graphFirstNode = null;
    let graphLastNode = null;

    const stages = approvalProcess.stages;
    const childlenInfos = []
    for(let stageIdx=0; stageIdx<stages.length; stageIdx++){
        const stage = stages[stageIdx];
        const [stageNodes, stageEdges, stageWidth, stageHeight, stageFirstNode, stageLastNode] = stageObjToGraph(stage);
        nodes.push(...stageNodes);
        edges.push(...stageEdges);
        if(stageIdx === 0) graphFirstNode = stageFirstNode;
        if(stageIdx === stages.length - 1) graphLastNode = stageLastNode;

        childlenInfos.push({
            nodes: stageNodes,
            edges: stageEdges,
            width: stageWidth,
            height: stageHeight,
            firstNode: stageFirstNode,
            lastNode: stageLastNode,
        })

        for(const node of stageNodes){
            node.position.x += (graphWidth + (EDGE_WIDTH * stageIdx));
        }

        graphHeight = Math.max(graphHeight, stageHeight)
        graphWidth += (stageWidth + EDGE_WIDTH);

        //internal Edge handling
        if(stageIdx > 0){
            const srcNode = childlenInfos[stageIdx - 1].lastNode;
            const destNode = stageFirstNode;
            const edge = makeEdge(srcNode.id, destNode.id);
            edges.push(edge);
        }
    }

    return [nodes, edges, graphWidth, graphHeight, graphFirstNode, graphLastNode];
}

const parallelStageObjToGraph = (stage) => {
    const nodes = [];
    const edges = [];

    const startParallelProcessNode = makeControlNode(true, 0 ,0);
    nodes.push(startParallelProcessNode);
    const endParallelProcessNode = makeControlNode(false, 0 ,0);
    nodes.push(endParallelProcessNode);
    
    let graphHeight = Y_SPACING;
    let graphWidth = 0;//just something for the control nodes

    const processInfos = []
    for(let processIdx=0; processIdx < stage.approval_processes.length; processIdx++){
        const process = stage.approval_processes[processIdx];
        const [processNodes, processEdges, processWidth, processHeight, processFirstNode, processLastNode] = approvalProcessToGraph(process);
        processInfos.push({nodes: processNodes, edges: processEdges, width: processWidth, height: processHeight})

        nodes.push(...processNodes);
        edges.push(...processEdges);

        graphHeight += processHeight + Y_SPACING;
        graphWidth = Math.max(graphWidth, processWidth);

        //create edge from startParallelProcessNode to each first node
        const startEdge = makeEdge(startParallelProcessNode.id, processFirstNode.id);
        edges.push(startEdge);

        //create edge from each lastNode to endParallelProcessNode
        const endEdge = makeEdge(processLastNode.id, endParallelProcessNode.id);
        edges.push(endEdge);
    }

    graphWidth += (2 * (CONTROL_NODE_DIAMETER + EDGE_WIDTH))
    endParallelProcessNode.position.x += (graphWidth - CONTROL_NODE_DIAMETER);

    let y = -1 * (graphHeight / 4); //should be divided by 2, but for somereason it works with 4 instead
    for(let i = 0; i < processInfos.length; i++){
        // const directChildStageNode = directChildStageNodes[i];
        const { nodes, edges, width, height } = processInfos[i];
        y += (height / 2);
        for(const node of nodes){
            node.position.y += y;
            node.position.x += (CONTROL_NODE_DIAMETER + EDGE_WIDTH);
        }
        y += ((height / 2) + Y_SPACING);
    }

    // nodes.push(makeMarkerNode(0, -1 * (graphHeight / 2)));
    // nodes.push(makeMarkerNode(0, 1 * (graphHeight / 2)));
    return [nodes, edges, graphWidth, graphHeight, startParallelProcessNode, endParallelProcessNode];
}

const atomicStageObjToGraph = (stage) => {
    const width = 7 * stage.name.length + 20;
    const height = 30;
    const node = {
        id: ""+stage.id,
        type: 'atomicStageNode',
        data: {stage: stage, width, height},
        // stage: stage,
        position: {x: 0, y: 0}
    }
    const edges = []
    return [[node], edges, width, height, node, node]
}

const stageObjToGraph = (stage) => {
    const isAtomicStage = (stage.approval_processes === undefined);
    if(isAtomicStage) return atomicStageObjToGraph(stage);
    return parallelStageObjToGraph(stage)
}
//#endregion process to graph conversions

let nextNodeId = 1000; //#TODO: use actual ids

const makeDefaultAtomicStageNode = () => {
    const expectedNameLen = 15;
    const width = 7 * expectedNameLen + 20;
    const height = 30;
    const id = nextNodeId++;

    const stage = {
        id: id,
        __meta_name: STAGE_TYPE_METAS.ROLE,
        name: 'New Stage',
        editable_sections: [],
        confirmation_messages: {
            Approve: "",
            Reject: "",
            Defer: "",
        },
        role: "",
        can_defer: false,
        section_key: "",
        field_key: "",
        days_until_neglected: 7,
        is_resource_access_removal_stage: false
    }
    const node = {
        id: ""+id,
        type: 'atomicStageNode',
        position: { x: 100, y: 100 },
        data: {stage: stage, width, height},
        
    }    
    return node;
}

const makeControlNode = (isParallelProcessStart, x, y) => {
    const nodeType = isParallelProcessStart ? 'startParallelProcessNode' : 'endParallelProcessNode'
    const node = {
        id: ''+nextNodeId++,
        type: nodeType,
        data: {},
        draggable: IS_INTERACTABLE,
        connectable: IS_INTERACTABLE,
        selectable: IS_INTERACTABLE,
        position: {x: x, y: y}
    };
    return node;
}

const makeEdge = (srcId, destId) => {
    const edge = {
        id: `e${srcId}-${destId}`,
        source: ""+srcId,
        target: ""+destId,
        animated: ANIMATE_EDGES,
        style: { stroke: '#000fff' },
        sourceHandle: 'src',
        targetHandle: 'dst',
        zIndex: 2,
        markerEnd:{
            type: 'arrow',
            color: '#000fff'
        }
    }
    return edge;
}

export const ApprovalProcessFlow = ({
    setSelectedStageId, nodes, setNodes, edges, setEdges, validate
}) => {
    const bgColor = initBgColor;
    const {alertError} = useContext(Context);

    // const [nodes, setNodes] = useState(initialNodes);
    const [areEdgesAnimated, setAreEdgesAnimated] = useState(ANIMATE_EDGES);
    const [deletedNodes, setDeletedNodes] = useState([]);
    const [dontRemindAboutNodeDeletion, setDontRemindAboutNodeDeletion] = useState(false);//false, true, or "open"
    const preexistingNodeIds = useMemo(() => nodes.filter(nd => nd.type === NODE_TYPES.ATOMIC).map(nd => nd.id), [])

    // const { getNodes, getEdges } = useReactFlow();
  
    const onNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => applyNodeChanges(changes, nds));
        },[],
    );
    const onEdgesChange = useCallback((changes) => {
        setEdges((eds) => applyEdgeChanges(changes, eds))
    }, [],
    );
  
    const onConnect = useCallback((params) => {
        setEdges((eds) => addEdge(params, eds))
    },[],
    );


    const isValidConnection = (connection) => {
        const hasCycle = !doesGraphHaveCycle(connection, nodes, edges, getOutgoers);
        return !hasCycle;
    };

    const onAddNodeClick = () => {
        const newNode = makeDefaultAtomicStageNode();
        newNode.data.isOpen = true;
        newNode.data.hasError = true;
        const nodesCopy = nodes.map(nd => {return {...nd, data: {...nd.data, isOpen: false}}})
        nodesCopy.push(newNode);
        setNodes(nodesCopy);
        setSelectedStageId(newNode.data.stage.id);
    }
    const onAddParallelProcessClick = () => {
        const startNode = makeControlNode(true, 100, 100);
        const endNode = makeControlNode(false, 100 + 40, 100);
        setNodes([...nodes, startNode, endNode]);
    }
    
    const onNodeClick = (node) => {
        if(node.data && node.data.stage){
            setNodes(nodes.map(nd => {return {...nd, data: {...nd.data, isOpen: nd.id === node.id}}}));

            setSelectedStageId(node.data.stage.id);
        }
    }
    const onValidateClick = () => {
        validate(nodes, edges);
    }

    const onAreEdgesAnimatedChanged = (isChecked) => {
        const newEdges = edges.map(e => {
            return {...e, animated: isChecked}
        })
        setEdges(newEdges);
        setAreEdgesAnimated(isChecked);
    }

    const onNodesDelete = (nds) => {
        const deletedPreexistingNodes = nds.filter(nd => preexistingNodeIds.includes(nd.id))
        const newDeletedNodes = [...deletedNodes, ...deletedPreexistingNodes];

        setDeletedNodes(newDeletedNodes);
        if((deletedPreexistingNodes.length > 0) && (dontRemindAboutNodeDeletion !== true)){
            setDontRemindAboutNodeDeletion("open");
        }
    }

    const readdDeletedNode = (nodeId) => {
        const node = deletedNodes.find(nd => nd.id === nodeId);
        const newNodes = [...nodes, node];
        const newDeletedNodes = deletedNodes.filter(nd => nd.id !== nodeId);
        setNodes(newNodes);
        setDeletedNodes(newDeletedNodes)
    }
    const GRAPH_HEIGHT = 500;
    return (
      <Box justifyContent={'center'} width='100%' height='100%'>
        {(dontRemindAboutNodeDeletion === "open") ? <NodeDeletionWarningDialog close={(x) => setDontRemindAboutNodeDeletion(x)}/> : null}
        <Stack direction='row' spacing={1} alignItems='center'>
            {/* <Stack direction='row' spacing={1}> */}
                <Button variant='contained' onClick={onAddNodeClick}>
                    Add Stage
                </Button>
                <Button variant='contained' onClick={onAddParallelProcessClick}>
                    Add Parallel
                </Button>
                <Button variant='contained' onClick={onValidateClick}>
                    Validate
                </Button>
            {/* </Stack> */}
            <Box sx={SX_BOX_SIMPLE}>
                <TicketCheckbox
                    label='Animate edges'
                    value={areEdgesAnimated}
                    setValue={onAreEdgesAnimatedChanged}
                    />
            </Box>
            <Box display='flex' width={200}>
                <TicketSelectField
                    label='Readd deleted stage'
                    value=''
                    setValue={(nodeId) => readdDeletedNode(nodeId)}
                    choices={deletedNodes.map(nd => {return {value: nd.id, label: nd.data.stage.name}})}
                    doNotAutoSelect={true}
                    fullWidth={true}
                    />
            </Box>
            <GraphHelpButton/>
        </Stack>

          <Box height={GRAPH_HEIGHT} sx={{border: 1, borderRadius: 2, marginY: 1}}>
              <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
                nodeTypes={nodeTypes}
                onNodeClick={(e, nd) => onNodeClick(nd)}
                connectionLineStyle={connectionLineStyle}
                isValidConnection={isValidConnection}
                defaultEdgeOptions={getDefaultEdgeOptions(areEdgesAnimated)}
                onNodesDelete={onNodesDelete}
                >
                  <Background />
                  <Controls />
              </ReactFlow>
          </Box>
      </Box>
    );
}
