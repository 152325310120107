import React from "react";
import { getAccessorTypeLabel, isAccessPendingRemoval, isAccessTypeForEmp } from "../../helpers/access_helper";
import Context from "../../Store";
import { Box, Button, Divider, Stack, Typography } from "../../../node_modules/@mui/material/index";
import { ACCESS_REQUEST_TYPES, TICKET_STATUS } from "../../helpers/Constants";
import { hasPendingTicketOfType } from "../../helpers/CommonUtil";
import { EXTENSIONS, post } from "../../helpers/requests";
import CollapseableContent from "../generic/CollapseableContent";
import LoadingMessage from "../generic/LoadingMessage";
import { hasPermissionToViewRelatedAccessOfEntity } from "../permissions/perm_helper";
import CloseableMessage from "../generic/CloseableMessage";


// X remove access instances that are inactive and not pending anything
// display parent of other entity of access
// display if the access is pending removal or activeness

const isEmptyListAccumulator = (acc) => {
    //NOTE: dup code form OffboardingBLockers
    const keys = Object.keys(acc);
    for(const key of keys){
        if(acc[key].length > 0) return false;
    }
    return true;
}

const seperate = (acc, entity_id, accessTypes) => {
    const entityIsResourceAcc = {};
    const entityIsAccessorAcc = {};

    const accessTypeIds = Object.keys(acc).map(idStr => Number(idStr));
    
    for(const accessTypeId of accessTypeIds){
        const accessType = accessTypes.find(at => at.id === accessTypeId);
        const isEmpAccess = isAccessTypeForEmp(acc);
        entityIsResourceAcc[accessTypeId] = [];
        entityIsAccessorAcc[accessTypeId] = [];

        const accesses = acc[accessTypeId];
        for(const access of accesses){
            const isActiveOrPendingActiveness = access.is_active || hasPendingTicketOfType(access,  ACCESS_REQUEST_TYPES.GRANT);
            if(isActiveOrPendingActiveness){
                if(access.resource.id === entity_id){
                    entityIsResourceAcc[accessTypeId].push(access);
                }
    
                if((access.accessor.id === entity_id) && !isEmpAccess){
                    entityIsAccessorAcc[accessTypeId].push(access);
                }
            }
        }
    }

    return {
        entityIsResourceAcc,
        entityIsAccessorAcc
    }
}

const getAccessStatusLabel = (access) => {
    if(isAccessPendingRemoval(access)){
        return "(Pending removal)";
    }

    const isPendingGrant = hasPendingTicketOfType(access, ACCESS_REQUEST_TYPES.GRANT);
    if(isPendingGrant) return "(Pending grant)";

    return "";
}

const PanelSection = ({isEntityTheResource, accesses, accessType}) => {
    if(accesses.length === 0) return null;

    const title = isEntityTheResource ? getAccessorTypeLabel(accessType) : accessType.resource_type.name;

    const labeledAccesses = accesses.map(access => {
        const other = isEntityTheResource ? access.accessor : access.resource; //could be entity or employee
        let label = other.name;
        if(other.parent) label = `${other.parent.name} - ${other.name}`
        const statusLabel = getAccessStatusLabel(access);
        label = `${label} ${statusLabel}`;
        return {access, label};
    });

    labeledAccesses.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <Box>
            <Typography variant='h6'>{title}</Typography>
            <Stack marginLeft={2}>
                {
                    labeledAccesses.map(labeledAccess => {
                        const {access, label} = labeledAccess;
                        const other = isEntityTheResource ? access.accessor : access.resource; //could be entity or employee

                        return (
                            <Typography key={other.id}>
                                {label}
                            </Typography>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}

const RelatedAccessPanelSide = ({isEntityTheResource, acc, accessTypes, hasFullPermToView}) => {
    const title = isEntityTheResource ? "Is accessed by" : "Has access to";

    const incompleteListMsg = "This list may be missing entries due to your permission level."
    if(isEmptyListAccumulator(acc)){
        return (
            <Box>
                <Typography>{title}: Nothing</Typography>
                {hasFullPermToView ? null : <Typography>{incompleteListMsg}</Typography>}
            </Box>
        )
    }

    const accessTypeIds = Object.keys(acc).map(idStr => Number(idStr));

    return (
        <Box>
            <Typography variant='h6'>{title}:</Typography>
            {hasFullPermToView ? null : <CloseableMessage message={incompleteListMsg}/>}
            <Stack marginLeft={2} spacing={1}>
            {
                accessTypeIds.map(accessTypeId => {
                    const accessType = accessTypes.find(at => at.id === accessTypeId);
                    return (
                        <PanelSection
                            key={accessTypeId}
                            isEntityTheResource={isEntityTheResource}
                            accesses={acc[accessTypeId]}
                            accessType={accessType}
                            />
                    )
                })
            }
            </Stack>
        </Box>
    )
}
const RelatedAccessPanel = ({acc, entity_id, hasPermForAccessor, hasPermForResource}) => {
    const {staticData} = React.useContext(Context);
    const {access_types} = staticData;
    //sepearte by if entity is the accessor or the resource
    const {entityIsResourceAcc, entityIsAccessorAcc} = seperate(acc, entity_id, access_types);
    // return <div>RelatedAccessPanel</div>
    return (
        <Stack direction='row' spacing={2}>
            <RelatedAccessPanelSide isEntityTheResource={true} acc={entityIsResourceAcc} accessTypes={access_types} hasFullPermToView={hasPermForResource}/>
            <Divider flexItem orientation="vertical" sx={{ borderBottomWidth: 1, bgcolor: "#000000"}}/>
            <RelatedAccessPanelSide isEntityTheResource={false} acc={entityIsAccessorAcc} accessTypes={access_types} hasFullPermToView={hasPermForAccessor}/>
        </Stack>
    )

}

const ViewRelatedAccessPanelButton = ({entity, entityInstanceType}) => {
    const {staticData, user} = React.useContext(Context);

    const [relatedAccessData, setRelatedAccessData] = React.useState(null);
    const [wasClicked, setWasClicked] = React.useState(false);

    const isPartOfAccess = staticData.access_types.some(at => {
        const isResource = at.resource_type.id === entityInstanceType.id;
        const isAccessor = at.accessor_type && (at.accessor_type.id === entityInstanceType.id);
        return isResource || isAccessor;
    })

    const {hasPermForAccessor, hasPermForResource} = hasPermissionToViewRelatedAccessOfEntity(user, staticData, entity, entityInstanceType)
    const userHasPermission = hasPermForAccessor || hasPermForResource;

    if(!isPartOfAccess || !userHasPermission) return null;

    const onClick = () => {
        const onSucceeded = (response) => {
            setRelatedAccessData(response.data);
        }
        const onFailed = (err) => {
            setRelatedAccessData(false);
        }
        post(EXTENSIONS.GET_ACCESS_RELATED_TO_ENTITY, {entity_id: entity.id}, onSucceeded, onFailed)        
        setWasClicked(true);
    }

    if(!wasClicked){
        return (
            <Button onClick={onClick} variant={'contained'}>
                View Related Access
            </Button>
        )
    }

    if(relatedAccessData === false) return <div>Failed to load related access...</div>;
    else if(relatedAccessData === null) return <LoadingMessage/>;

    return (
        <CollapseableContent title={"Related Access"} isExpandedByDefault={true}>
            <RelatedAccessPanel
                entity_id={entity.id}
                acc={relatedAccessData}
                hasPermForAccessor={hasPermForAccessor}
                hasPermForResource={hasPermForResource}
                />
        </CollapseableContent>
    )
}

export default ViewRelatedAccessPanelButton;
