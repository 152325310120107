import React, {useEffect, useMemo, useState} from 'react';
import AbstractTable from './AbstractTable';
import { datetimeDisplay } from '../../helpers/FieldDisplayFormatters';
import { MenuItem, Stack, TextField } from '@mui/material';
import { DEFAULT_INITIAL_ROWS_PER_PAGE, getDefaultTableRowsPerPageOptions } from '../../helpers/TableHelper';

const ANY_FIELD = "Any Field"

const rowToKey = (action) => {return `ticket-${action.ticket.metadata.id}-action-${action.role}-${action.datetime}`}

const getColumnNames = (entityName, withGroup) => {
    if(withGroup) return [entityName, 'Request', 'Actor', 'Stage', 'Action', 'Date'];
    return ['Request', 'Actor', 'Stage', 'Action', 'Date'];
}
const renderRowCells = (action) => {
    if(action.group_name) return [action.group_name, action.ticket.info.name, action.actor, action.role, action.action, datetimeDisplay(action.datetime)]
    return [action.ticket.info.name, action.actor, action.role, action.action, datetimeDisplay(action.datetime)]
}

const colToStringValue = (action, col, columnNames) => {
    const colIdx = columnNames.indexOf(col)
    if(colIdx >= 0){
        return renderRowCells(action)[colIdx]
    }
    else return null
}

const filterActions = (actions, searchField, term, columnNames) => {
    const output = []
    for(var i=0; i < actions.length; i++){
        const action = actions[i] 
        if(searchField === ANY_FIELD){
            const values = renderRowCells(action)
            for(var colIdx = 0; colIdx < values.length; colIdx++){
                const value = values[colIdx]
                if(value !== null && value !== undefined){
                    const s = value.toString().toLowerCase()
                    if(s.includes(term)){
                        output.push(action)
                        break
                    }
                }
            }
        }
        else{
            const value = colToStringValue(action, searchField, columnNames)
            if(value !== null && value !== undefined){
                const s = value.toString().toLowerCase()
                if(s.includes(term)){
                    output.push(action)
                }
            }
        }

    }
    return output
}

const FieldFilterSelect = ({value, setValue, columnNames}) => {
    const choices = columnNames.map(col => {
        return (<MenuItem key={col} value={col}>{col}</MenuItem>)
    })

    choices.unshift(<MenuItem key={ANY_FIELD} value={ANY_FIELD}>{ANY_FIELD}</MenuItem>)
    return(
        <TextField
            select
            label={"Filter by"}
            onChange={e => setValue(e.target.value)}
            value={value}
            fullWidth={true}
            sx={{maxWidth: "60%"}}
            >
                { choices }
        </TextField> 
    )
}

const ActionsTable = ({entityName, actions}) => {
    const [searchField, setSearchField] = useState(ANY_FIELD)
    const [searchTerm, setSearchTerm] = useState("")

    const shouldShowGroup = actions.length && actions[0].group_name;
    const columnNames = getColumnNames(entityName, shouldShowGroup);

    const handleSearchTermChange = (e) => {
        const newVal = e.target.value.toLowerCase()
        setSearchTerm(newVal)   
    }

    const filteredActions = useMemo(() => {
        return (searchTerm.length > 0) ? filterActions(actions, searchField, searchTerm, columnNames) : actions
    }, [searchField, searchTerm, actions])

    return (
        <>
            {
                (actions.length > 0) &&
                <Stack direction={"row"} marginY={2} alignContent={"center"} spacing={2}>
                    <TextField value={searchTerm} onChange={handleSearchTermChange} label={"Search term"} autoComplete='off' sx={{maxWidth: "40%"}} fullWidth/>
                    <FieldFilterSelect value={searchField} setValue={setSearchField} columnNames={columnNames}/>
                </Stack>
            }
            <AbstractTable
                rows={filteredActions}
                rowToKey={rowToKey}
                columnNames={columnNames}
                renderRowCells={renderRowCells}
                rowsPerPageOptions={getDefaultTableRowsPerPageOptions(filteredActions)}
                initialRowsPerPage={DEFAULT_INITIAL_ROWS_PER_PAGE}
                rowHeight={"small"}
                />
        </>
    )

}

export default ActionsTable;