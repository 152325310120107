import { getPendingActivenessTicket } from "./CommonUtil";
import { ACCESS_REQUEST_TYPES, ENTITY_REQUEST_TYPES, FALLBACK_FIELD_LABEL, FALLBACK_REVIEWER_ID, FALLBACK_REVIEWER_NAME, STAGE_TYPE_METAS, TICKET_STATUS } from "./Constants";
import { datetimeDisplay, fieldValueString } from "./FieldDisplayFormatters";
import { getEntityOrUserName, getFieldStructureFromFormStructureWithIds, getStageWithIdFromInstanceType } from "./StaticDataHelper";

export const DEFAULT_INITIAL_ROWS_PER_PAGE = 25;
export const getDefaultTableRowsPerPageOptions = (rows) => {
    const numRows = rows.length;
    const output = [5, 10, 25, 50, 100];
    if(!output.includes(numRows)) output.push(numRows);
    return output;
}

export const getCustomColumnNames = (entityInstanceType, columnDisplays) => {
    // const columnDisplays = entityInstanceType[displayTypeKey].table_column_displays;

    const structure = entityInstanceType.structure;
    const columnNames = columnDisplays.map(d => {
        const {section, field} = d;
        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, section, field);
        return fieldStructure ? fieldStructure.short_label : FALLBACK_FIELD_LABEL;
    });

    columnNames.unshift(entityInstanceType.name)

    return columnNames;
}

export const renderCustomRowCells = (entity, entityInstanceType, table_column_displays, staticData, form_values) => {
    const columnDisplays = table_column_displays;//entityInstanceType[displayTypeKey].table_column_displays;

    const structure = entityInstanceType.structure;

    // const renderRowCells = (entity) => {
        // const form_values = getFormValuesFromEntity(entity)
    const cells = columnDisplays.map(d => {
        const {section, field} = d;
        const fieldStructure = getFieldStructureFromFormStructureWithIds(structure, section, field);
        if(!fieldStructure || !form_values?.[d.section]?.[d.field]) return "";
        return fieldValueString(form_values[d.section][d.field], fieldStructure, staticData)
    })

    const name = getEntityOrUserName(entity, entityInstanceType, staticData.all_users)

    cells.unshift(name);
    return cells;
    // };

    // return renderRowCells
}

const getActiveOrPendingInstanceFormValues = (instance) => {
    const pendingActivenessTicket = getPendingActivenessTicket(instance);
    const isPendingActiveness = !!pendingActivenessTicket;

    if(!instance.is_active && isPendingActiveness){
        return pendingActivenessTicket.form_values;
    }
    return instance.state.form_values;
}
const getFormValuesWithReviewer = (fieldStage, instance, ticket) => {
    const {TICKET, STATE, PARENT, RESOURCE, ACCESSOR, ACCESSOR_PARENT} = STAGE_TYPE_METAS;
    switch (fieldStage.__meta_name) {
        case TICKET:
            return ticket.form_values;
        case STATE:
            return getActiveOrPendingInstanceFormValues(instance);
        case PARENT:
            return getActiveOrPendingInstanceFormValues(instance.parent);
        case RESOURCE:
            return getActiveOrPendingInstanceFormValues(instance.resource);
        case ACCESSOR:
            return getActiveOrPendingInstanceFormValues(instance.accessor);
        case ACCESSOR_PARENT:
            return getActiveOrPendingInstanceFormValues(instance.accessor.parent);
        default:
            console.log(`ERROR: unexpected stage meta value: ${fieldStage.__meta_name}`)
            return null;
    }
}
export const getReviewerNamesOfStages = (instance, ticket, staticData, instanceType) => {
    const {tags_map, all_users} = staticData;
    const reviewerIds = new Set();
    const unassignedTags = new Set();

    for(const stageData of ticket.stages){
        const stageId = stageData.stage_id;
        const stageConfig = getStageWithIdFromInstanceType(instanceType, stageId);
        if(!stageConfig){
            reviewerIds.add(FALLBACK_REVIEWER_ID);
        }
        else if(stageConfig.__meta_name === STAGE_TYPE_METAS.ROLE){
            const tag = stageConfig.role;
            const stageReviewerIds = tags_map[tag];
            if((stageReviewerIds === undefined) || (stageReviewerIds.length === 0)){
                unassignedTags.add(tag);
            }
            else{
                stageReviewerIds.forEach(id => reviewerIds.add(id))
            }
        }
        else{
            const formValues = getFormValuesWithReviewer(stageConfig, instance, ticket);
            const stageReviewerId = formValues[stageConfig.section_key][stageConfig.field_key];
            reviewerIds.add(stageReviewerId);
        }
    }

    const names = []
    for(const userId of reviewerIds){
        if(userId === FALLBACK_REVIEWER_ID){
            names.push(FALLBACK_REVIEWER_NAME);
        }
        else{
            const name = all_users[userId];
            names.push(name)
        }
    }
    for(const tag of unassignedTags){
        const label = `A stage has no reviewers since no one has the ${tag} tag assigned to them.`
        names.push(label);
    }

    const output = names.join(", ");
    return output;

}

