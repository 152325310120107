import AbstractTable from "../tables/AbstractTable";

const extractRoles = (department) => {
    // const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    const {id, name, type, manager, employee_roles, children, extra_data} = department;

    const output = [];
    //manager role
    if(manager){
        output.push({department, ...manager});
    }
    //dept roles
    for(const emp_role of employee_roles){
        output.push({department, ...emp_role})
    }
    //sub dept roles
    for(const subDept of children){
        const subDeptRoles = extractRoles(subDept);
        output.push(...subDeptRoles);
    }
    return output
}
const RolesView = ({company, refetchCompany}) => {
    const { root_department, employee_extra_data_structure, employee_role_extra_data_structure, employees, department_types } = company;
    const allRoles = extractRoles(root_department);
    allRoles.sort((a, b) => a.employee.name.localeCompare(b.employee.name));

    const columnNames = [
        'Employee',
        'Department',
        'Department type',
        'Department Manager',
        // 'Parent Department'
    ]

    const renderRowCells = (r) => {
        return [
            r.employee.name,
            r.department.name,
            r.department.type.name,
            r.department.manager ? r.department.manager.employee.name : '-',
        ]
    }
    return (
        <AbstractTable
            rows={allRoles}
            rowToKey={r => r.role.id}
            columnNames={columnNames}
            renderRowCells={renderRowCells}
            rowHeight={'small'}
            />
    )
}

export default RolesView;