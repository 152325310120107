import { Button, CircularProgress, Tooltip } from "@mui/material"

const LoadButton = ({children, loading, disabled, variant, onClick, size, color, sx, tooltip}) => {
    const btnVariant = variant ? variant : "contained";
    
    if(tooltip && !disabled){
        return (
            <Tooltip title={tooltip}>
                <Button disabled={disabled} variant={btnVariant} onClick={onClick} size={size} color={color} sx={sx}>
                    {
                        loading ? <CircularProgress/> : children
                    }
                </Button>
            </Tooltip>
        )        
    }
    return (
        <Button disabled={disabled} variant={btnVariant} onClick={onClick} size={size} color={color} sx={sx}>
            {
                loading ? <CircularProgress/> : children
            }
        </Button>
    )
}

export default LoadButton