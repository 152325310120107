import React, {Fragment, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AppBar, Stack } from '@mui/material';

//tab_data: title, content

const MyTabs = ({tabDatas, marginYToContent}) => {
    const [selectedTabIdx, setSelectedTabIdx] = useState(0);
    const tabContent = tabDatas[selectedTabIdx].getContent();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
                <AppBar position="static">
                    <Tabs value={selectedTabIdx} onChange={(e, val) => setSelectedTabIdx(val)} indicatorColor="secondary" textColor="inherit">
                        {
                            tabDatas.map((tabData, tabIdx) => {
                                const {title} = tabData;
                                const key = `${title}-${tabIdx}`;
                                return <Tab label={title} id={key} key={key}/>
                            })
                        }
                    </Tabs>
                </AppBar>
            </Box>
            <Box>
                <Box paddingTop={.25} key={`tab-${selectedTabIdx}`} marginTop={marginYToContent}>
                    {tabContent}
                </Box>
            </Box>
        </Box>
    );
}

export default MyTabs;