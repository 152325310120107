import { Box } from "@mui/material";
import { useData, useEnsureUpToDateConfigs } from "../../helpers/CustomHooks";
import { EXTENSIONS } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import PageTitle from "../generic/PageTitle";
// import CurrAccessesWithPaperTrailTable from "../access/AccessPaperTrail";
import { useContext } from "react";
import Context from "../../Store";
import CurrAccessesWithPaperTrail from "./AccessPaperTrail";
import MyTabs from "../generic/MyTabs";
import { getAccessTypeTabLabel } from "../../helpers/CommonUtil";
import { ACCESSOR_TYPES } from "../../helpers/Constants";
import { getAccessTypeLabel } from "../../helpers/access_helper";
import { FailedToLoadMessage } from "../generic/FailedToLoadMessage";


export const choicesArrayToObject = (choices) => {
    const output = {};
    choices.forEach(choice => output[choice.value] = choice.label);
    return output;
}
// export const getEntiityNamesObject = (accessType, allEntityNames) => {
//     //assumes allEntityNames was recieved from EXTENSIONS.GET_ALL_ENTITY_INSTANCE_NAMES: 'savas/get_all_entity_instance_names'
    
//     const choices = getChoiceData(accessType, allEntityNames);
//     const output = {}
//     output.resources = choicesArrayToObject(choices.resources);    
//     output.accessors = choicesArrayToObject(choices.accessors);

//     output.accessor_members = {};
//     const accessorKeys = Object.keys(choices.accessor_members);
//     accessorKeys.forEach(accessorKey => {
//         const memberChoicesForAccessor = choices.accessor_members[accessorKey];
//         output.accessor_members[accessorKey] = choicesArrayToObject(memberChoicesForAccessor);
//     })
//     return output;    
// }

const getAccessTypeTabContent = (accessType, accessPaperTrail, allAccessLevels) => {
    const accesses = accessPaperTrail[accessType.id];
    // const entityNames = getEntiityNamesObject(accessType, allEntityNames);
    // sortAccesses(accessType, accesses, entityNames);
    // console.log("content stuff", {accesses, entityNames});
    return (
        <CurrAccessesWithPaperTrail
            accessType={accessType}
            accesses={accesses}
            allAccessLevels={allAccessLevels}
            />                
    ) 
}

const sortAccesses = (accessType, acceses, allEntityNames) => {
    // Resource name, accessor name, accessor member name
    const is_emp_access = (accessType.access_type === ACCESSOR_TYPES.EMPLOYEE);
    const accessorKey = is_emp_access ? 'employee_email' : 'accessor_eti_id';
    const compResource = (a, b) => {
        const aName = allEntityNames.resources[a.metadata.resource_eti_id];
        const bName = allEntityNames.resources[b.metadata.resource_eti_id];
        return aName.localeCompare(bName);
    }
    const compAccessor = (a, b) => {
        let aName = allEntityNames.accessors[a.metadata[accessorKey]];
        if(!aName) aName = a.metadata[accessorKey];

        let bName = allEntityNames.accessors[b.metadata[accessorKey]];
        if(!bName) bName = b.metadata[accessorKey];
        return aName.localeCompare(bName); //error here
    }
    const compAccessorMember = (a, b) => {
        // return 0;
        if(!accessType.is_accessor_member) return 0;
        const aName = allEntityNames.accessor_members[a.metadata[accessorKey]][a.metadata.accessor_member_id];
        const bName = allEntityNames.accessor_members[b.metadata[accessorKey]][b.metadata.accessor_member_id];
        return aName.localeCompare(bName);
    }
    const compAccesses = (a, b) => {
        return compResource(a, b) || compAccessor(a, b) || compAccessorMember(a, b);
    }
    acceses.sort(compAccesses)
}

const AccessPaperTrailPage = () => {
    const {staticData} = useContext(Context);
    const configSyncState = useEnsureUpToDateConfigs();

    const {access_types} = staticData;

    const allAccessLevels = useData(EXTENSIONS.GET_ACCESS_LEVELS_PER_RESOURCE, {});
    const accessPaperTrail = useData(EXTENSIONS.GET_ACCESS_PAPER_TRAIL, {});


    const awaitedDatas = [configSyncState, allAccessLevels, accessPaperTrail];
    // console.log('datas: ', {allAccessLevels, accessPaperTrail})
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    // console.log("stuff", {allEntityNames, accessPaperTrail})
    const tabDatas = access_types.map(accessType =>{
        return {
            title: getAccessTypeLabel(accessType),
            getContent: () => getAccessTypeTabContent(accessType, accessPaperTrail, allAccessLevels)
        }
    })
    return (
        <Box key={"AccessPaperTrailPage"}>
            <PageTitle title={"Access Paper Trail"}/>
            <MyTabs tabDatas={tabDatas}/>
        </Box>
    )
}

const fileToObj = async (filePath) => {
    // const output = require(filePath);
    // return output;

    // let data = JSON.parse(await readFile("filename.json", "utf8"));
    // return data;
}
export default AccessPaperTrailPage;