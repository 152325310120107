import { useContext, useState } from "react"
import ConfirmActionDialog from "../generic/ConfirmActionDialog"
import { EXTENSIONS, post } from "../../helpers/requests"
import Context from "../../Store";



export const ActivateTypeConfirmationDialog = ({instanceTypeDescriptor, close, onActivated, }) => {
    const {alertError, alertSuccess} = useContext(Context);

    const [isSubmitting, setIsSubmitting] = useState(false)
    const bodyText = `
After activating, tickets of this type will be able to be submitted fby users.
You will not be able to remove this type once it is active.
Approval processes must be set prior to activation.
Also, anything that this Entity/Access type depends on must be activated prior to this being activated\
(e.g. parent, resource type, etc)
`

    const onConfirmClick = (instanceType) => {
        const onSuccess = () => {
            setIsSubmitting(false);
            alertSuccess("Activated");
            onActivated();
            close();
        }
        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data)
        }

        const body = {instance_type_desc: instanceTypeDescriptor};
        post(EXTENSIONS.ACTIVATE_INSTANCE_TYPE, body, onSuccess, onFail)
        setIsSubmitting(true);
    }

    return (
        <ConfirmActionDialog
            title={`Confirm activating`}
            body={bodyText}
            handleCancel={close}
            handleConfirm={onConfirmClick}
            cancelText={'Cancel'}
            confirmText={'Activate'}
            loading={isSubmitting}
            disabled={isSubmitting}
            fullWidth={true}
            // maxWidth={}
            />
    )
}