import { Box, Button, Stack, Typography} from '@mui/material';
import React, { useEffect, useState} from 'react';
import { EXTENSIONS, downloadEntityFileField, post } from '../helpers/requests';
import { cleanForm, formatFormValues, makeTicketDeepCopy } from '../helpers/TicketFormHelper';
import ResponseDialog, { DEFER } from './instance/ResponseDialog';
import { ACTION_MSG_MAX_LEN, ENTITY_REQUEST_TYPES, REQUEST_STATES, UNABORTABLE_REQUEST_TYPES } from '../helpers/Constants';
import Context from '../Store';
import EntityView from './entity/EntityView';
import CollapseableContent from './generic/CollapseableContent';
import TicketView from './instance/TicketView';
import { getStageWithId, getStageWithIdFromInstanceType } from '../helpers/StaticDataHelper';
import ActionsPanel from './instance/ActionsPanel';
import { REVIEW } from '../App';
import ConfirmActionDialog from './generic/ConfirmActionDialog';
import { PENDING_TASKS, TASKS } from './app/LayeredNavPanel';
import { useNavigate } from 'react-router-dom';
import { getPendingEditSummary } from '../helpers/CommonUtil';
import LabelValueDisplay from './generic/LabelValueDisplay';

const msgPlaceholderText = `Enter a message for ticket submitter and future reviewers. 
If rejecting, the message will also be sent to any previous approvers`

const ApproverTicketReviewDirect = ({entityTypeId, task}) =>{
    const navigate = useNavigate();
    const {staticData, alertSuccess, alertError} = React.useContext(Context);
    const {all_users} = staticData;

    const {instance, stage} = task;
    const [ticket, setTicket] = useState(task.ticket);

    // const [formData, setFormData] = useState({...ticket.form_values});

    const formData = ticket.form_values;
    const setFormData = (newFormData) => setTicket({...ticket, form_values: newFormData});
    useEffect(() => {
        setFormData({...task.ticket.form_values})
    }, 
    [task]
)
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);
    const [submitResponse, setSubmitResponse] = useState(null);

    const entityType = staticData.entity_types.find(et => et.id === entityTypeId);
    const instanceType = (!instance.parent) ? entityType.root_instance_type : entityType.member_instance_type;
    const {structure} = instanceType;
    const stageConfig = getStageWithIdFromInstanceType(instanceType, stage.stage_id);
    if(!stageConfig){
        return <div>Something went wrong. Try refreshing or try again later.</div>
    }
    const {editable_sections, confirmation_messages} = stageConfig;

    const editableSectionLabels = editable_sections.map(sId => structure.sections.find(s => s.id===sId).metadata.label)
    const canReject = !UNABORTABLE_REQUEST_TYPES.includes(ticket.request_type);
    


    // const {structure, editable_sections, deferable_field, confirm_msgs} = data//ticketData
    // const deferable_field_structure = deferable_field ? structure[deferable_field.section][deferable_field.field] : null

    const onConfirmClick = (action, msg, deferValue) => {
        if(msg && (msg.length > ACTION_MSG_MAX_LEN)){
            alertError("Message is too long.")
            return;
        }
        const formDataCopy = makeTicketDeepCopy(formData);
        if(action === DEFER) formDataCopy[stageConfig.section_key][stageConfig.field_key] = deferValue;

        if([ENTITY_REQUEST_TYPES.ADD, ENTITY_REQUEST_TYPES.REOPEN].includes(ticket.request_type)){
            formatFormValues(formDataCopy, structure);
        }

        const body = {
            instance_desc: {
                instance_type: "Entity",
                entity_instance_id: instance.id
            },
            ticket_id: ticket.id,
            stage_id: stage.stage_id,
            action_type: action,
            msg: msg,
            edited_form: formDataCopy
        }

        const onSuccess = (response) => {
            alertSuccess(response.data.message);
            setSubmitState(REQUEST_STATES.SUCCEEDED)
            setSubmitResponse(response.data)
        }
        const onFail = (e) => {
            alertError(e.response.data)
            setSubmitState(REQUEST_STATES.FAILED)
        }
        post(EXTENSIONS.REVIEW_TICKET, body, onSuccess, onFail)
        setSubmitState(REQUEST_STATES.SENDING)
    }

    const downloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadEntityFileField(instance.id, ticket.id, sectionId, fieldId, stage.stage_id);
        }
    }
   //"Continue Reviewing?" post submission dialog
   if(submitResponse){//submit response
        const dialogTitle = "Continue reviewing tickets?"
        // const {message, stage_id, stage_name} = submitResponse

        // const onNextTicketClick = () => {}
        const body = (
            <Stack spacing={2}>
                {
                    submitResponse.stage_id &&
                    <Button onClick={() => navigate(`/${REVIEW}/entity_type/${entityTypeId}/eti/${instance.id}/ticket/${ticket.id}/stage/${submitResponse.stage_id}`)}>
                        {`Review again for stage: ${submitResponse.stage_name}`}
                    </Button>
                }
                {/* <Button onClick={onNextTicketClick}>
                    Next Ticket
                </Button> */}
                <Button onClick={() => navigate(`/${TASKS}/${PENDING_TASKS}`)}>
                    Back to pending tasks
                </Button>
            </Stack>
        )
        return(
            <ConfirmActionDialog
                title={dialogTitle}
                body={body}
                cancelText={"Close"}
                handleCancel={() => {setSubmitResponse(null)}}
                loading={false}
                disabled={false}
                />
        )        
    }

    const pendingEditSummary = getPendingEditSummary(staticData, structure, instance, ticket);
    return(
        <Box>
            {/* <Stack direction={"row"}> */}
            {
                (editable_sections.length === 0) ? null :
                <Box marginBottom={1} paddingY={1}>
                    <Typography variant='h5'>You can edit the following sections:</Typography>
                    <Typography variant='h6' marginLeft={2}>{editableSectionLabels.join(", ")}</Typography>
                </Box>
            }
            <CollapseableContent isExpandedByDefault={false} title={"Past reviews of this ticket"}>
                <ActionsPanel actions={ticket.actions}/>
            </CollapseableContent>

            <CollapseableContent isExpandedByDefault={true} title={"Ticket being reviewed"}>
                {
                    !pendingEditSummary ? null : 
                    <LabelValueDisplay label={'Summary'} value={pendingEditSummary}/> 
                }
                <TicketView
                    ticket={ticket}
                    structure={structure}
                    allUsersMap={all_users}
                    editableSections={editable_sections}
                    setFormValues={setFormData}
                    shouldPrint={true}
                    getSectionDownloadFileField={downloadFileGetter}
                    />
            </CollapseableContent>

            {/* </Stack> */}
            {
                (instance.tickets.length <= 1) ? null : //hide, redundant if the only ticket is the 1 already shown 
                <EntityView
                    entity={instance}
                    entityType={entityType}
                    />
            }
            {
                (submitState === REQUEST_STATES.SUCCEEDED) ? null :
                <ResponseDialog
                    confirm_msgs={confirmation_messages}
                    submitState={submitState}
                    deferChoices={undefined}
                    onConfirmClick={onConfirmClick}
                    msgPlaceholderText={msgPlaceholderText}
                    confirmTitle={stage.stage_name}
                    canReject={canReject}
                    stage={stageConfig}
                    instanceName={instance.name}
                    />
            }
            <Box marginBottom={20}/> {/* margin so the response dialog doesnt block view*/}
        </Box>

    )
}

export default ApproverTicketReviewDirect