import React from "react";
import Context from "../../Store";
import { makeNestedDeepCopy } from "../../helpers/TicketFormHelper";
import { VIEW } from "../../App";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";



const GlobalSearchBar = () => {
    const navigate = useNavigate()
    const {staticData} = React.useContext(Context);
    
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    
    
    const options = React.useMemo(() => {
        const {all_users, active_users, entity_summaries} = staticData;
        if(!entity_summaries) return [];
        const copy = makeNestedDeepCopy(entity_summaries);
        copy.sort((a, b) => a.name.localeCompare(b.name));
        // console.log('summaries', copy)
        const choices = [];//choice dont include entities that are users
        copy.forEach(c => {
            const {id, entity_type_id, name, is_active, parent_id, parent_name} = c;
            const entityType = staticData.entity_types.find(et => et.id === entity_type_id);
            const isRoot = !parent_id;
            const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;
            const note = isRoot ? entityType.name : `${entityType.name} member of ${parent_name}`;
            const activenessSuffix = is_active ? "" : " [INACTIVE]";
            const label = `${name} (${note})${activenessSuffix}`;
            c.label = label;
            if(instanceType){
                choices.push(c);
            }
        })
        
        const uniqueChoices = [];
        choices.forEach(c => {
            if(!uniqueChoices.some(x => x.label === c.label)){
                uniqueChoices.push(c);
            }
        })
        return uniqueChoices;
    }, [staticData])

    const onOptionSelected = (event, newValue) => {
        setValue(newValue);
        if(newValue === null) return;
        const {id, entity_type_id, name, is_active, parent_id, parent_name} = newValue;
        const urlExt = `/${VIEW}/entity_type/${entity_type_id}/eti/${id}`
        navigate(urlExt);
        setValue(null);//see if this line fixes issue of failed navigation
    }
    return (
        <Autocomplete
            // freeSolo
            value={value}
            onChange={onOptionSelected}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            autoHighlight={true}
            sx={{ width: 300, bgcolor:'white', borderRadius:5, marginY:0, "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {border: 0}}}
            renderInput={(params) => <TextField {...params} size="small" label="" placeholder='Search entities...'/>}
            />
    )    
}

export default GlobalSearchBar;