
import { useParams, useSearchParams } from 'react-router-dom';
import PageTitle from "../generic/PageTitle";
import { useEnsureUpToDateConfigs, useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, downloadEntityFileField, post, postTicket } from "../../helpers/requests";
import LoadingMessage from "../generic/LoadingMessage";
import Context from '../../Store';
import React, { useContext, useState } from 'react';
import { cleanForm, formatFormValues, makeTicketDeepCopy } from '../../helpers/TicketFormHelper';
import { ACTION_MSG_MAX_LEN, REQUEST_STATES } from '../../helpers/Constants';
import { Box, Button, Stack, TextField } from '@mui/material';
import LoadButton from '../generic/LoadButton';
import { hasGobalPermission, hasInstancePermissions } from '../permissions/perm_helper';
import { ALL_PERMS } from '../permissions/perm_constants';
import MultiSectionForm from '../form/MultiSectionForm';
import { getPendingActivenessTicket } from '../../helpers/CommonUtil';
import TicketParagraphField from '../inputs/TicketParagraphField';
import CloseableMessage from '../generic/CloseableMessage';
import { SX_BOX_SIMPLE } from '../../helpers/common_sx';
import { FailedToLoadMessage } from '../generic/FailedToLoadMessage';

const NOTE_TO_USER = `
Note: This is an override edit. No approval processes will be triggered and the edit will
be immediately applied upon submission. If you are editing who the reviewer is of a currently pending stage, the new reviewer
not be notified by the system that the ticket is now pending their review.
`

const EditEntityTicket = ({entity, refetchEntity, entityType}) => {
    const {staticData, user, alertSuccess, alertError} = React.useContext(Context)
    const [submitState, setSubmitState] = useState(REQUEST_STATES.NOT_SENT);
    const isSubmitting = (submitState === REQUEST_STATES.SENDING);

    const isRoot = !entity.parent;
    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type

    const ticket = getPendingActivenessTicket(entity);
    // console.log('pre_edit', makeTicketDeepCopy(ticket.form_values))
    const canEditTicket = hasGobalPermission(user, ALL_PERMS.ENTITY_ACTIONS.OVERRIDE_EDIT.key);
    const [formValues, setFormValues] = useState(ticket.form_values);
    const [msg, setMsg] = useState("");

    if(!ticket){
        return <div>There is no pending ticket for adding or reopening this entity.</div>
    }

    if(!canEditTicket){
        return <div>You do not have sufficient permission to edit this ticket.</div>
    }

    const downloadFileGetter = (sectionId) => {
        return (fieldId) => {
            downloadEntityFileField(entity.id, ticket.id, sectionId, fieldId, null);
        }
    }

    const onSubmitClick = () => {
        if(msg && (msg.length > ACTION_MSG_MAX_LEN)){
            alertError("Message is too long");
        }
        let formDataCopy = makeTicketDeepCopy(formValues)
        formatFormValues(formDataCopy, instanceType.structure)
        // console.log('form', {pre_format: makeTicketDeepCopy(formValues), post_format: makeTicketDeepCopy(formDataCopy)})
        const onSuccess = (resp) => {
            alertSuccess("Edited");
            setSubmitState(REQUEST_STATES.SUCCEEDED);
        }

        const onFailure = (e) => {
            alertError(e.response.data);
            setSubmitState(REQUEST_STATES.FAILED);
        }
        const body = {
            entity_id: entity.id,
            ticket_id: ticket.id,
            edited_values: formDataCopy,
            msg: msg
        }
        post(EXTENSIONS.EDIT_ENTITY_TICKET, body, onSuccess, onFailure);
        setSubmitState(REQUEST_STATES.SENDING);
    }
    
    // value, setValue, idPrefix, label, fullWidth, hasBeenVisited, optional, maxLength, helperText
    return (
        <Box>
            <CloseableMessage message={NOTE_TO_USER}/>
            <MultiSectionForm
                structure={instanceType.structure}
                formData={formValues}
                setFormData={setFormValues}
                isInitialSubmission={false}
                editableSectionIds={'*'}
                getSectionDownloadFileField={downloadFileGetter}
                contextValues={{}}    
                />
            <Box sx={SX_BOX_SIMPLE}>
                <TicketParagraphField
                    value={msg}
                    setValue={setMsg}
                    label={'Note for what you are editing and why'}
                    maxLength={ACTION_MSG_MAX_LEN}
                    fullWidth={true}
                    />
                <Box justifyContent='center' display='flex' marginTop={1}>
                    <LoadButton onClick={onSubmitClick} variant='contained' loading={isSubmitting} disabled={isSubmitting}>
                        Submit
                    </LoadButton>
                </Box>
            </Box>
        </Box>
    )
}

const EditEntityTicketPage = () => {
    const {staticData, user} = React.useContext(Context)
    const configSyncState = useEnsureUpToDateConfigs();

    const params = useParams();
    const instance_id = Number(params.entity_type_instance_id);
    const entity_type_id = Number(params.entity_type_id);

    
    const body = {instance_desc: {instance_type: "Entity", entity_instance_id: instance_id}};
    const [entity, refetchEntity] = useRefetchData(EXTENSIONS.GET_INSTANCE, body);
    
    const awaitedDatas = [configSyncState, entity];
    if(awaitedDatas.includes(false)) return <FailedToLoadMessage/>;
    if(awaitedDatas.includes(null)) return <LoadingMessage/>;

    const entityType = staticData.entity_types.find(et => et.id === entity_type_id);
    
    return (
        <div key={`ViewEntityPage-${instance_id}`}>
            <PageTitle
                title={`Edit: ${entity.name}`}
                subtitle={entity.parent?.name}
                />
            <EditEntityTicket
                entity={entity}
                refetchEntity={refetchEntity}
                entityType={entityType}
                />
        </div>
    )
}

export default EditEntityTicketPage;
