import { useContext, useState } from "react"
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "../../../../node_modules/@mui/material/index"
import SuggestedTextField from "../../inputs/SuggestedTextFIeld"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { UpDownArrows } from "../../generic/UpDownArrows";
import { hasDuplicates, swapItemsAtIndices } from "../../../helpers/CommonUtil";
import Context from "../../../Store";
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import { BULLET_CHAR } from "../../../helpers/Constants";
import { ExternalEmpDTOItem } from "./HRISIntegrationEmployeeMapper";
import CollapseableContent from "../../generic/CollapseableContent";
import LabelValueDisplay from "../../generic/LabelValueDisplay";

const CurrUserLoginNote = ({currUserExternalId, whitelist, externalCompanyDTO}) => {
    const usersNewLogin = getCurrUsersNewLoginEmail(currUserExternalId, whitelist, externalCompanyDTO);
    let note = ''
    if(!usersNewLogin){
        note = 'You do not have a whitelisted email. Whitelist a domain for an email you have in your HRIS.';
    }
    else{
        note = `Your login email will be ${usersNewLogin}. update the whitelisted domains if this is not desired.`
    }
    return (
        <LabelValueDisplay label={'NOTE'} value={note}/>
    )
}

const getCurrUsersNewLoginEmail = (currUserExternalId, whitelist, externalCompanyDTO) => {
    if(!currUserExternalId){
        return null;
    }

    const currUserEmpDTO = externalCompanyDTO.employee_dtos.find(e => e.external_id === currUserExternalId);

    for(const domain of whitelist){
        for(const email of currUserEmpDTO.emails){
            if(email.endsWith(`@${domain}`)){
                return email;
            }
        }
    }
    return null;
}

const getEmailDomain = (email) => {
    try{
        const parts = email.split("@")
        return parts[parts.length - 1]
    }
    catch(e){
        return null;
    }
}

const getAllEmailDomains = (externalEmpDTOs) => {
    const allDomains = []
    for(const empDTO of externalEmpDTOs){
        const empEmails = empDTO.emails;
        for(const email of empEmails){
            const domain = getEmailDomain(email);
            if(domain && !allDomains.includes(domain)){
                allDomains.push(domain)
            }
        }
    }
    allDomains.sort((a, b) => a.localeCompare(b))
    return allDomains;
}

const ExternalEmpsWithoutWhiteListedDomain = ({empDTOs, whitelist}) => {
    const empDTOsWithoutEmails = empDTOs.filter(e => {
        const emails = e.emails;
        for(const email of emails){
            for(const domain of whitelist){
                if(email.endsWith(`@${domain}`)) return false;
            }
        }
        return true
    })
    empDTOsWithoutEmails.sort((a, b) => {
        const aName = `${a.first_name} ${a.last_name}`;
        const bName = `${b.first_name} ${b.last_name}`;
        return aName.localeCompare(bName)
    })
    return (
        <CollapseableContent
            title='Employees in your HRIS without a whitelisted email'
            isExpandedByDefault={false}
            >
            <Stack>
                { empDTOsWithoutEmails.map(e => <ExternalEmpDTOItem empDTO={e} key={e.external_id}/>) }
            </Stack>
        </CollapseableContent>
    )
}

const DomainWhitelistInstructions = () => {
    return (
        <Box sx={SX_BOX_SIMPLE} marginY={2}>
        <Typography>
            {BULLET_CHAR} Define the email address domains that users can use to log in to the system. Only emails with a whitelisted domain defined here will
            be associated with a user (allowing them to log in and recieve email notifications)
        </Typography>
        <Typography>
            {BULLET_CHAR} Each user may have at most 1 email address in AxoTrax.
        </Typography>
        <Typography>
            {BULLET_CHAR} Your HRIS may have muliple emails for each employee.
            Their login email for AxoTrax will be one that is in the whitelist you define here. If the user has multiple whitelisted emails, the email
            with a domain higher up in the list will be preferred. Note that the order of domains matters
        </Typography>

        <Typography>
            {BULLET_CHAR} For example, to allow "johndoe@yourcompany.com", add "yourcompany.com" as a domain.
        </Typography>
        <Typography>
            {BULLET_CHAR} You should explicitly add any necesary subdomains as well.
            For example, to allow "johndoe@somesubdomain.yourcompany.com", you should add "somesubdomain.yourcompany.com" as a domain.
        </Typography>
        <Typography>
            {BULLET_CHAR} Note that user's login emails may automatically update later on if their emails are changed in your HRIS.
        </Typography>
        <Typography>
            {BULLET_CHAR} You can edit this later, but add at least 1 domain now so that your will be able to login.
        </Typography>
    </Box>
    )
}

export const EmailDomainWhitelistWidget = ({whitelist, setWhitelist, suggestions}) => {

    const setSingleDomain = (idx, newDomain) => {
        whitelist[idx] = newDomain;
        setWhitelist([...whitelist])
    }

    const removeDomain = (idx) => {
        const newWhitelist = [...whitelist]
        newWhitelist.splice(idx, 1)
        setWhitelist(newWhitelist);
    }

    const addDomain = () => {
        setWhitelist([...whitelist, ""])
    }

    const moveDomain = (idx, isUp) => {
        const copy = [...whitelist];
        const N = copy.length;
        const offset = isUp ? -1 : 1;
        const targetIdx = (idx + offset + N) % N;
        swapItemsAtIndices(copy, idx, targetIdx);
        setWhitelist(copy);
    }
    return (
        <Box sx={SX_BOX_SIMPLE} marginY={2}>
            <Typography>Whitelisted User Email Domains:</Typography>
            <Stack margin={2}>
                {
                    whitelist.map((domain, idx) => {
                        return (
                            <Box key={idx}>
                                <Stack direction='row' spacing={1}>
                                    <UpDownArrows onClick={(isUp) => moveDomain(idx, isUp)}/>
                                    <SuggestedTextField
                                        label={"domain"}
                                        value={domain}
                                        setValue={(x) => setSingleDomain(idx, x)}
                                        suggestions={suggestions}
                                        />
                                    <IconButton onClick={() => removeDomain(idx)}>
                                        <DeleteForeverIcon color='error'/>
                                    </IconButton>
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
            <Box justifyContent='center' display='flex'>
                <Tooltip title='Add domain to whitelist'>
                    <IconButton onClick={addDomain}>
                        <AddCircleIcon color='primary'/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export const EmailDomainWhitelistPanel = ({externalCompanyDTO, whitelist, setWhitelist, advanceStep, goBackStep, currUserExternalId}) => {
    const {user, alertError} = useContext(Context);
    const suggestions = getAllEmailDomains(externalCompanyDTO.employee_dtos).filter(domain => !whitelist.includes(domain));


    const onNextClick = () => {
        //at least 1 domain
        if(whitelist.length === 0){
            alertError("Add at least 1 domain");
            return;
        }

        //no empties
        for(const domain of whitelist){
            if(domain.trim().length === 0){
                alertError("Remove empty domain fields");
                return;
            }
            if(!domain.includes(".")){
                alertError(`The domain ${domain} is invalid. it should include a period`);
                return;
            }
        }
        
        //no duplicates
        if(hasDuplicates(whitelist)){
            alertError("Remove duplicates from list");
            return;
        }
        if(!getCurrUsersNewLoginEmail(currUserExternalId, whitelist, externalCompanyDTO, currUserExternalId)){
            alertError("You do not have a whitelisted email. Either you mis-mapped yourself in previous step, or did not whitelist a domain for an email your have in your HRIS.")
            return;
        }
        advanceStep()
    }

    return (
        <Box>
            <Typography variant='h6'>User Email Domain Whitelist</Typography>
            <DomainWhitelistInstructions/>
            <CurrUserLoginNote currUserExternalId={currUserExternalId} whitelist={whitelist} externalCompanyDTO={externalCompanyDTO}/>
            <EmailDomainWhitelistWidget whitelist={whitelist} setWhitelist={setWhitelist} suggestions={suggestions}/>

            <ExternalEmpsWithoutWhiteListedDomain empDTOs={externalCompanyDTO.employee_dtos} whitelist={whitelist}/>

            <Stack marginTop={4} spacing={4} direction='row' justifyContent='center'>
                <Button onClick={goBackStep} variant='contained'>
                    Back
                </Button>
                <Button onClick={onNextClick} variant='contained'>
                    Next
                </Button>
            </Stack>
        </Box>
    )
}


