import { useContext, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "../../../../node_modules/@mui/material/index"
import { EXTENSIONS, post } from "../../../helpers/requests"
import Context from "../../../Store"
import LoadButton from "../../generic/LoadButton"
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx"
import { BULLET_CHAR } from "../../../helpers/Constants"
import LoadingMessage from "../../generic/LoadingMessage"



export const ExternalHRISVaultPanel = ({advanceStep, goBackStep}) => {
    const {alertError} = useContext(Context);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onContinueClick = () => {
        const onSuccess = (resp) => {
            setIsSubmitting(false);
            advanceStep()
        }

        const onFail = (err) => {
            setIsSubmitting(false);
            alertError(err.response.data);
        }
        post(EXTENSIONS.VALIDATE_VAULT_SETUP, {}, onSuccess, onFail);
        setIsSubmitting(true);
    }
    return (
        <Box>
            <Typography variant='h6'>Vault Setup Validation</Typography>
            <Box sx={SX_BOX_SIMPLE} marginBottom={2}>
                <Typography>
                    {BULLET_CHAR} When you are done integrating your HRIS in the vault, click continue to validate that everything is properly setup.
                </Typography>
                <Typography>
                    {BULLET_CHAR} NOTE: Do NOT edit anything in the vault after successfully advancing from this step. You may put your settings in an invalid state
                    and the system may not operate properly. 
                </Typography>
            </Box>

            {
                isSubmitting ?
                    <Box justifyContent='center'>
                        <LoadingMessage msg={'Validating. This may take a minute...'}/>
                    </Box> :
                    <Stack marginTop={4} spacing={4} direction='row' justifyContent='center'>
                        <Button onClick={goBackStep} variant='contained'>
                            Back
                        </Button>
                        <Button onClick={onContinueClick} variant='contained'>
                            Continue
                        </Button>
                    </Stack>

            }
            {/* <Button onClick={advanceStep}>
                Skip (dev only - remove after)
            </Button> */}
        </Box>
    )
}