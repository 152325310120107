import { useContext, useState } from "react";
import { Box, IconButton, Stack, Tooltip } from "../../../../../node_modules/@mui/material/index";
import { CHOICE_DESC_TYPES, FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../../../inputs/FieldProps";
import TicketSelectField from "../../../inputs/TicketSelectField";
import ConfirmActionDialog from "../../../generic/ConfirmActionDialog";

import Context from "../../../../Store";
import { getManagedTableSelectFieldChoices } from "../table_select_helper";
import { SX_BOX_ICON_BTN } from "../../../../helpers/common_sx";
import ListIcon from '@mui/icons-material/List';
import { ACCESS_STRUCTURE_IDS, MESSAGE_LIST_SEP } from "../../../../helpers/Constants";
import { getErrorMessagesForStaticChoices, StaticChoicePanelContent } from "./StaticChoicePanelContent";
import { CompanyFuncChoicePanelContent, getErrorMessagesForCompanyFunc } from "./CompanyFuncChoicePanelContent";
import { getErrorMessagesForTableChoices, TableChoicePanelContent } from "./TableChoicePanelContent";


const _BUTTON_TOOLTIP = `
Click to set/define the selectable choices for this field
`

//#region common
const getTypeChoices = (fieldStructure) => {
    if(fieldStructure.component !== FIELD_COMPONENT_TYPES.SELECT){
        return [
            {value: CHOICE_DESC_TYPES.STATIC, label: 'Static (manually define choices now)'}
        ]
    }
    else{
        return [
            {value: CHOICE_DESC_TYPES.STATIC, label: 'Static (manually define choices now)'},
            {value: CHOICE_DESC_TYPES.COMPANY, label: 'Company Function'},
            {value: CHOICE_DESC_TYPES.TABLE, label: 'Managed Table'},
        ]
    }
}

const getErrors = (fieldStructure, sectionStructure, configMeta, tables) => {
    const {choice_desc} = fieldStructure;
    const {__meta_type, dependencies, choices} = choice_desc;

    if(__meta_type === CHOICE_DESC_TYPES.STATIC){
        return getErrorMessagesForStaticChoices(fieldStructure);
    }
    if(__meta_type === CHOICE_DESC_TYPES.COMPANY){
        return getErrorMessagesForCompanyFunc(fieldStructure, sectionStructure, configMeta);
    }
    if(__meta_type === CHOICE_DESC_TYPES.TABLE){
        return getErrorMessagesForTableChoices(fieldStructure, sectionStructure, tables);
    }
    return [`Unexpected error: choice type = ${__meta_type}`]
}


export const ChoiceDescField = ({
    fieldStructure, sectionStructure, setChoiceDesc, configMeta, tables, refetchTables,
    isPanelOpen, onPanelOpenClick, save, cancel, contextFields
}) => {
    //TODO: choice desc on the the actual field should not be updated until "save" is clicked
    //TODO: disable save button unless the config is valid. they can always just cancel if no valid config exists.
    const {staticData, alertError} = useContext(Context);
    const {all_users, all_departments} = staticData;


    if(fieldStructure.id === ACCESS_STRUCTURE_IDS.ACCESS_LVL_FIELD_ID) return null;

    if(!isPanelOpen){
        return (
            <Box sx={SX_BOX_ICON_BTN}>
                <Tooltip title="Set what the selectable choices for this field will be" enterDelay={500}>
                    <IconButton onClick={onPanelOpenClick}>
                        <ListIcon color='primary'/>
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }
    
    const {choice_desc} = fieldStructure;
    const {__meta_type, dependencies, choices} = choice_desc;

    const trySave = () => {
        const errs = getErrors(fieldStructure, sectionStructure, configMeta, tables);
        if(errs.length){
            const errMsg = "Please fix the following errors:\n- " + errs.join("\n- ");
            alertError(errMsg);
            // return false;
        }
        else{
            if(__meta_type === CHOICE_DESC_TYPES.TABLE){
                choice_desc.choices = getManagedTableSelectFieldChoices(fieldStructure, tables, all_users, all_departments);
                choice_desc.dependencies = choice_desc.constraints.map(c => c.id_of_field_with_value)
                setChoiceDesc({...choice_desc});
            }

            save();
            // return true;
        }
    }

    let ComponentType = null;
    if(__meta_type === CHOICE_DESC_TYPES.STATIC) ComponentType = StaticChoicePanelContent;
    if(__meta_type === CHOICE_DESC_TYPES.COMPANY) ComponentType = CompanyFuncChoicePanelContent;
    if(__meta_type === CHOICE_DESC_TYPES.TABLE) ComponentType = TableChoicePanelContent;
    if(!ComponentType) return null;

    
    const setType = (x) => {
        const constraints = (x === CHOICE_DESC_TYPES.TABLE) ? [] : undefined;
        setChoiceDesc({...choice_desc, __meta_type: x, constraints, choices: []})
    }
    const dialogTitle = `Set selectable choices for "${fieldStructure.label}"`
    return (
        <CloseableDialog trySave={trySave} cancel={cancel} title={dialogTitle}>
            <Stack spacing={2}>
                <TicketSelectField
                    label={"Source for choices"}
                    value={__meta_type}
                    setValue={setType}
                    choices={getTypeChoices(fieldStructure)}
                    fullWidth={true}
                    />
                <ComponentType
                    fieldStructure={fieldStructure}
                    sectionStructure={sectionStructure}
                    setChoiceDesc={setChoiceDesc}
                    configMeta={configMeta}
                    tables={tables}
                    refetchTables={refetchTables}
                    contextFields={contextFields}
                    />
            </Stack>
        </CloseableDialog>
    )  
}

const CloseableDialog = ({children, title, trySave, cancel}) => {
    const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
    
    const onSaveClick = () => {
        trySave();
    }

    const onCancelClick = () => {
        cancel();
    }
    return (
        <ConfirmActionDialog
            title={title}
            body={
                <Box minWidth={500} minHeight={100}>
                    {children}
                </Box>
            }
            handleCancel={onCancelClick}
            handleConfirm={onSaveClick}
            cancelText={'Cancel'}
            confirmText={'Apply'}
            loading={false}
            disabled={false}
            />
    )
}
