import { Box, Button, ButtonGroup, Stack, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const CloseableMessage = ({message}) => {
    const [isClosed, setClosed] = useState(false);
    if(isClosed) return null;

    return (
        <Box bgcolor={'#fcc368'} paddingY={1} paddingX={2}>
            <Stack direction={'row'} justifyContent="space-between">
                <Typography alignContent="center">{message}</Typography>
                <IconButton onClick={() => setClosed(true)}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Box>
    )
}
export default CloseableMessage;