import React, { memo } from 'react';
import { Handle, NodeResizer, Position } from 'reactflow';
import { Box, Typography } from '@mui/material';
import './index.css'


const statusToColor = (status) => {
    return '#eeeeee'
    switch(status){
        case "approved": return "success.light";
        case "rejected": return "error.light";
        case "enabled": return "primary.light";
        case null: return "#cccccc";
        default: return "#000000";
    }
}

export default memo(({ data }) => {
    const {status, name, id, height, width} = data; //status in "enabled", "approved", "rejected", null
    const color = statusToColor(status);
    return (
        <Box bgcolor={color} borderRadius={2} border={1} width={width} height={height}>
            <Handle
                type="target"
                id="dst"
                position={Position.Left}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="src"
                isConnectable={false}
            />
            <Handle
                type="target"
                id="exit"
                position={Position.Right}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={false}
            />
            <Handle
                type="source"
                position={Position.Left}
                id="enter"
                isConnectable={false}
            />
        </Box>
    );
});