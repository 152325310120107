import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import { Badge, Button, Divider, Tooltip } from "../../../node_modules/@mui/material/index";
import CloseableMessage from "../generic/CloseableMessage";
import LabelValueDisplay from "../generic/LabelValueDisplay";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../inputs/FieldProps";
import { getAllUniquePossibleChoices } from "../inputs/TicketSelectField";
// import { Box, Stack, Typography } from "../../../node_modules/@mui/material/index";
import CircleIcon from '@mui/icons-material/Circle';

export const NON_STRUCTURE_SECTION = "";
export const NAME_FIELD = 'name';
export const PARENT_FIELD = 'parent';

const SELECTED_COLOR = '#ccccff';
const DEFAULT_COLOR = '#ffffff';

const GUESS_TOOLTIP_MSG = `
Allow system to try guessing the field to column mapping. You should still verify the mapping after.
`

const getInstructionsMessage = (instanceType) => {
    return `
        The column names of your CSV may not match the field names in the ${instanceType.name}'s form.
        Click a field and it's corresponding csv column name to let us know how to map them.
        You do not need to map all the fields or columns, but unmapped fields will be empty and you will need to fill them in manually.
    `
}
const MyDivider = () => {
    return <Divider flexItem sx={{ borderBottomWidth: 1, width:'100%', bgcolor: "#000000"}}/>
}

const SingleField = ({onFieldClick, selectedField, fieldDesc, mapping, label}) => {
    const {section, field} = fieldDesc;
    const color = (selectedField && (selectedField.field === field)) ? SELECTED_COLOR : DEFAULT_COLOR;
    // const fieldDesc = {section: section.id, field: field.id};
    const mappedColName = mapping[section] ? mapping[section][field] : null;
    return (
        <Stack direction='row' bgcolor={color} onClick={() => onFieldClick(fieldDesc)} spacing={1}>
            {
                !mappedColName ? null :
                <Tooltip title={`mapped from column: ${mappedColName}`}>
                    <Box alignItems='center' display='flex'>
                        <CheckCircleIcon color='primary' fontSize={'small'} />
                    </Box>
                </Tooltip>
            }
            <Typography>{label}</Typography>
        </Stack>
    )
 
}
const FieldsPanel = ({structure, mapping, selectedField, onFieldClick, nonStructureFields}) => {

    return (
        <Stack sx={SX_BOX_SIMPLE}>
            <Box justifyContent='center' display='flex'>
                <Typography variant='h6'>Form Fields</Typography>
            </Box>
            <Typography>Descriptor Fields</Typography>
            <Stack marginLeft={2}  spacing={1}>
            {
                nonStructureFields.map(field => {
                    const fieldDesc = {section: NON_STRUCTURE_SECTION, field: field};
                    return (
                        <SingleField
                            key={field}
                            onFieldClick={onFieldClick}
                            selectedField={selectedField}
                            fieldDesc={fieldDesc}
                            mapping={mapping}
                            label={field}
                            />
                    )
                })
            }
            </Stack>
            {
                structure.sections.map(section => {
                    return (
                        <Box key={section.id}  spacing={2}>
                            <Typography>{section.metadata.label}</Typography>
                            <Stack marginLeft={2}  spacing={1}>
                            {
                                section.fields.map(field => {
                                    const fieldDesc = {section: section.id, field: field.id}
                                    return (
                                        <SingleField
                                            key={field.id}
                                            onFieldClick={onFieldClick}
                                            selectedField={selectedField}
                                            fieldDesc={fieldDesc}
                                            mapping={mapping}
                                            label={field.short_label}
                                            />
                                    )
                                })
                            }
                            </Stack>
                        </Box>
                    )
                })
            }
        </Stack>
    )
}

const ColumnsPanel = ({columns, selectedColumn, onColumnClick, mapping}) => {
    const colMapCounts = {};
    columns.forEach(col => colMapCounts[col] = 0);
    for(const sectionId of Object.keys(mapping)){
        for(const fieldId of Object.keys(mapping[sectionId])){
            const col = mapping[sectionId][fieldId];
            colMapCounts[col]++;
        }
    }

    return (
        <Stack sx={SX_BOX_SIMPLE}>
            <Box justifyContent='center' display='flex'>
                <Typography variant='h6'>CSV Columns</Typography>
            </Box>
            {
                columns.map((colName, idx) => {
                    const color = (selectedColumn === colName) ? SELECTED_COLOR : DEFAULT_COLOR;
                    const mapCount = colMapCounts[colName];
                    const iconColor = (mapCount > 1) ? 'warning' : 'primary';
                    return (
                        <Stack key={`${colName}-${idx}`} onClick={() => onColumnClick(colName)} bgcolor={color} direction='row' spacing={1}>
                            {
                                (mapCount === 0) ? null :
                                <Tooltip title={`maps to ${mapCount} field(s)`}>
                                    <CheckCircleIcon color={iconColor} fontSize={'small'} />
                                </Tooltip>
                            }
                            <Typography>{colName}</Typography>
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}

const inferFieldValue = (fieldStructure, providedValue) => {
    const providedValueLower = providedValue.toLowerCase();
    const {component, value_type, choice_desc} = fieldStructure;

    // if select, then expect either the id/value or the label
    if(component === FIELD_COMPONENT_TYPES.SELECT){
        const allChoices = getAllUniquePossibleChoices(choice_desc.choices);
        for(const choice of allChoices){
            if(choice.label.toLowerCase() === providedValueLower) return choice.value;
            if(choice.value.toString().toLowerCase() === providedValueLower) return choice.value;
        }
    }
    
    // if multiselect/multicheck, then same as select, but list
    if([FIELD_COMPONENT_TYPES.SELECT, FIELD_COMPONENT_TYPES.MULTICHECK].includes(component)){
        const converted = [];
        
        const allChoices = getAllUniquePossibleChoices(choice_desc.choices);
        const values = providedValueLower.split(',').map(x => x.trim()).filter(x => x.length > 0);
        for(const singleProvidedValue of values){
            let correspondingValue = null;
            for(const choice of allChoices){
                const wasChoiceChosen = converted.includes(choice.value);
                const doesChoiceMatch = [choice.label.toLowerCase(), choice.value.toString().toLowerCase()].includes(singleProvidedValue);
                if(!wasChoiceChosen && doesChoiceMatch){
                    correspondingValue = choice.value;
                    break;
                }
            }
            if(correspondingValue === null) correspondingValue = singleProvidedValue;
            if(!converted.includes(correspondingValue)){
                converted.push(correspondingValue);
            }
        }
        return converted;
    }

    // if bool field, expect yes or no, true or false
    if(value_type === FIELD_VALUE_TYPES.BOOL){
        if(['yes', 'true'].includes(providedValueLower)) return true;
        if(['no', 'false'].includes(providedValueLower)) return false;
    }

    return providedValue;
}
export const csvObjsToEntitities = (structure, csvObjs, mappings, parentChoices) => {
    const output = [];
    let entryKey = 100000; //chose big number because the key used in the BulkImportPage will increment from zero
    for(const obj of csvObjs){
        // {name: "", parent: parent, values: values, key: nextKey}

        const formValues = {};
        for(const section of structure.sections){
            const sectionId = section.id;
            const sectionValues = {};
            formValues[sectionId] = sectionValues;

            for(const field of section.fields){
                const fieldId = field.id;
                if(mappings[sectionId] && mappings[sectionId][fieldId]){
                    const colName = mappings[sectionId][fieldId];
                    const providedValue = obj[colName];
                    const inferedValue = inferFieldValue(field, providedValue);
                    sectionValues[fieldId] = inferedValue;
                }
                else{
                    sectionValues[fieldId] = "";
                }
            }
        }
        const nameColName = mappings[NON_STRUCTURE_SECTION][NAME_FIELD];
        const name = nameColName ? obj[nameColName] : "";

        // try to infer the parent value
        const parentColName = mappings[NON_STRUCTURE_SECTION][PARENT_FIELD];
        const provideParentValue = parentColName ? obj[parentColName] : "";
        const provideParentValueLower = provideParentValue.toLowerCase();
        let inferedParentId = parentChoices.find(choice => (choice.value.toString().toLowerCase() === provideParentValueLower) || (choice.label.toLowerCase() === provideParentValueLower))
        if(!inferedParentId) inferedParentId = provideParentValue;

        const data = {
            name: name,
            parent: inferedParentId,
            values: formValues,
            key: entryKey
        }
        output.push(data)
        entryKey++;
    }
    return output
}

const guessMapping = (structure, columnNames) => {
    const output = {[NON_STRUCTURE_SECTION]: {}};
    // map descriptor fields
    if(columnNames.includes('name')) output[NON_STRUCTURE_SECTION][NAME_FIELD] = 'name';
    if(columnNames.includes('parent')) output[NON_STRUCTURE_SECTION][PARENT_FIELD] = 'parent';

    // map form fields
    for(const section of structure.sections){
        const sectionId = section.id;
        const sectionLabel = section.metadata.label;

        for(const field of section.fields){
            const fieldId = field.id;
            const fieldLabel = field.short_label;

            let guess = undefined;

            //try matching field name for a guess
            const colsMatchingField = columnNames.filter(colName => colName.includes(fieldLabel));
            if(colsMatchingField.length > 0){
                guess = colsMatchingField[0];
            }

            //try matching both field and section for better guess
            const colsMatchingFieldAndSection = colsMatchingField.filter(colName => colName.includes(sectionLabel));
            if(colsMatchingFieldAndSection.length > 0){
                guess = colsMatchingFieldAndSection[0];
            }
            
            //try also matching both field id for better guess
            const colsMatchingLabelsAndIds = colsMatchingFieldAndSection.filter(colName => colName.includes(fieldId));
            if(colsMatchingLabelsAndIds.length > 0){
                guess = colsMatchingLabelsAndIds[0];
            }

            
            if(guess){
                if(output[sectionId] === undefined){
                    output[sectionId] = {};
                }
                output[sectionId][fieldId] = guess;
            }
        }
    }

    return output;
}

const EntityFieldColumnMapper = ({entityType, isRoot, columnNames, onCompleteClick, onCancelClick}) => {
    const [selectedField, setSelectedField] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [mappings, setMappings] = useState({[NON_STRUCTURE_SECTION]: {}});

    const instanceType = isRoot ? entityType.root_instance_type : entityType.member_instance_type;
    const structure = instanceType.structure;

    const handleMappingSelected = (fieldDesc, colName) => {
        const {section, field} = fieldDesc;
        const newMappings = {...mappings};
        if(newMappings[section] === undefined){
            newMappings[section] = {}
        }

        newMappings[section][field] = colName
        setMappings(newMappings);
        setSelectedColumn(null);
        setSelectedField(null);
    }

    const onFieldClick = (fieldDesc) => {
        setSelectedField(fieldDesc);
        if(fieldDesc && selectedColumn){
            handleMappingSelected(fieldDesc, selectedColumn);
        }
    }

    const onColumnNameClick = (colName) => {
        setSelectedColumn(colName);
        if(selectedField && colName){
            handleMappingSelected(selectedField, colName)
        }
    }
    const nonStructureFields = isRoot ? [NAME_FIELD] : [NAME_FIELD, PARENT_FIELD];

    const onGuessMappingClick = () => {
        const guess = guessMapping(structure, columnNames);
        setMappings(guess);
    }
    return (
        <Box>
            <Typography variant='h5'>Field to Column Mappings</Typography>
            <CloseableMessage message={getInstructionsMessage(instanceType)}/>
            <Box marginY={1}>
                <Tooltip title={GUESS_TOOLTIP_MSG}>
                    <Button onClick={onGuessMappingClick} variant={'contained'}>
                        Guess Mapping
                    </Button>
                </Tooltip>
            </Box>
            <Stack direction='row' spacing={2}>
                <FieldsPanel
                    structure={structure}
                    mapping={mappings}
                    selectedField={selectedField}
                    onFieldClick={onFieldClick}
                    nonStructureFields={nonStructureFields}
                    />
                <ColumnsPanel
                    columns={columnNames}
                    selectedColumn={selectedColumn}
                    onColumnClick={onColumnNameClick}
                    mapping={mappings}
                    />
            </Stack>

            <Stack direction='row' spacing={2} marginTop={2}>
                <Button variant='contained' onClick={() => onCompleteClick(mappings)}>
                    Done
                </Button>
                <Button variant='contained' color='error' onClick={onCancelClick}>
                    Cancel
                </Button>
            </Stack>
        </Box>
    )
}

export default EntityFieldColumnMapper;