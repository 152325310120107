import { Box } from "@mui/material";
import {DenseStageFlow} from "./DenseStageFlow";



// const setStageStatusesAtomicStage = (stage, enabledStageIds) => {
//     if(enabledStageIds.includes(stage.id)) stage.status = 'enabled';
// }

// const setStageStatusesParallelStage = (stage, enabledStageIds) => {
//     stage.approval_processes.forEach(proc => setStageStatusesApprProc(proc, enabledStageIds));
// }

// const setStageStatusesStage = (stage, enabledStageIds) => {
//     const isAtomic = !stage.approval_processes;
//     if(isAtomic) setStageStatusesAtomicStage(stage, enabledStageIds);
//     else setStageStatusesParallelStage(stage, enabledStageIds);
// }

// const setStageStatusesApprProc = (approvalProcess, enabledStageIds) => {
//     approvalProcess.stages.forEach(stg => setStageStatusesStage(stg, enabledStageIds));
// }

const ProgressGraph = ({approvalProcess, enabledStageIds}) => {

    return(
        <Box height={50} justifyContent={'center'}>
            <DenseStageFlow
                approvalProcess={approvalProcess}
                enabledStageIds={enabledStageIds}
                />
        </Box>
    )
        
}

export default ProgressGraph;