//This is the generic response dialog
//TODO: refactor so that other response dialogs just use this one


/*
TODOS:
- rename deferable_field_structure to defer choices, and dont receive the whole field, just the choices
*/

import React, { useContext, useState } from "react";
import { Box, Button, IconButton, MenuItem, Stack, TextField, Toolbar, Typography } from "@mui/material";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import ConfirmActionDialog from "../generic/ConfirmActionDialog";
import TicketSelectField from "../inputs/TicketSelectField";
import Context from "../../Store";

import { ACTION_MSG_MAX_LEN, REQUEST_STATES } from "../../helpers/Constants";
import { useNavigate } from "react-router-dom";
import { SX_BOX_SIMPLE } from "../../helpers/common_sx";
import { get_review_action_system_note } from "./review_action_system_notes";



export const DEFER ="Defer"
export const APPROVE ="Approve"
export const REJECT ="Reject"

const getDialogTitle = (confirmTitle, action) => {
    let output = confirmTitle + " "
    if(action === APPROVE) output += "Approval"
    else if(action === REJECT) output += "Rejection"
    else if(action === DEFER) output += "Deferal"
    else output = `${output}${action}`

    return output
}


//TODO: make sure ApproverTicketReviewDirect (and others) pass a value for the 'stage' prop, or remove the prop
const ResponseDialog = ({confirm_msgs, deferChoices, onConfirmClick, msgPlaceholderText, confirmTitle, submitState, canReject, stage, instanceName}) => {
    // entity_type_id, entity_type_instance_id, member_id,
    // ticket, structure, deferable_field , deferable_field_structure,
    // confirm_msgs, stage, postUrlExt, disappearAfterSubmit, msgPlaceholderText, task_type}) => {
    
    const {alertSuccess, alertError} = useContext(Context)

    const [message, setMessage] = useState("")
    const [deferredValue, setDeferredValue] = useState("")
    const [action, setAction] = useState(null)
    const [isExpanded, setIsExpanded] = useState(true)

    const canDefer = !!deferChoices;
    const navigate = useNavigate()

    //confirm submission dialog
    if(action){
        const loading = (submitState === REQUEST_STATES.SENDING)
        const disabled = [REQUEST_STATES.SENDING, REQUEST_STATES.SUCCEEDED].includes(submitState)
        const dialogTitle = getDialogTitle(confirmTitle, action)

        let systemNote = get_review_action_system_note(stage, action, instanceName) //TODO: pass actual name
        if(systemNote) systemNote = "System note: " + systemNote + '\n\n';
        let bodyMessage = systemNote + confirm_msgs[action];

        return(
            <ConfirmActionDialog
                title={dialogTitle}
                body={bodyMessage}
                handleClose={() => {}}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
                handleConfirm={() => onConfirmClick(action, message, deferredValue)}
                handleCancel={() => setAction(null)}
                requestState={submitState}
                loading={loading}
                disabled={disabled}
                />
        )
    }

    //Action taking dialog (minimized)
    // if(disappearAfterSubmit && (submitState === REQUEST_STATES.SUCCEEDED)) return null
    if(!isExpanded){
        return(
            <Box m={0} width={"70%"}
                sx={{...SX_BOX_SIMPLE, position: 'fixed', bottom: '0%', backgroundColor: '#fafafa', borderColor:'#000000'}}
                onClick={e => setIsExpanded(true)}
                >
                    <Box display="flex" justifyContent="flex-end" alignItems="justify-start">
                        <IconButton> <ExpandLessRoundedIcon/> </IconButton>
                    </Box>
            </Box>
        )
    }

    const errMsg = (message && (message.length > ACTION_MSG_MAX_LEN)) ? `Message cannot exceed ${ACTION_MSG_MAX_LEN} characters. (currently ${message.length})` : null;
    //Action taking dialog
    return (
        <Box m={0} width={"70%"} sx = {{...SX_BOX_SIMPLE, position: 'fixed', bottom: '0%', backgroundColor: '#fafafa', borderColor:'#000000'}}>
            <Box marginTop={0} marginBottom={0} marginX={0}>
                <Stack direction={"row"} >
                    <Stack direction={"column"} sx={{ justifyContent: "center", margin:2}} width={"80%"} spacing={1}>
                        {canDefer &&
                            <TicketSelectField
                                value={deferredValue}
                                setValue={setDeferredValue}
                                idPrefix={'defer'}
                                label={'Defer to...'}
                                choices={deferChoices}
                                // dependencies={deferable_field_structure.dependencies}
                                // sectionValues={ticket[deferable_field.section]}
                                optional={true}
                                />
                        }
                        <TextField
                            id={"Response details"}
                            variant="outlined"
                            label={"Your comments, notes or message to submitter and future reviewers"}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={msgPlaceholderText}
                            multiline fullWidth minRows={2} maxRows={5}
                            error={!!errMsg}
                            helperText={errMsg}
                            />
                    </Stack>
                    <Stack direction={"column"} sx={{ justifyContent: "center", margin:2}} spacing={1}>
                        {canDefer &&
                            <Button variant="contained" onClick={e => setAction(DEFER)} color="error" disabled={!deferredValue}>
                                Defer
                            </Button>
                        }
                        <Button variant="contained" onClick={e => setAction(APPROVE)}>
                            Approve
                        </Button>
                        {
                            !canReject ? null :
                            <Button variant="contained" onClick={e => setAction(REJECT)} color="error">
                                Reject
                            </Button>
                        }
                    </Stack>
                    <Box justifyContent="flex-end">
                        <IconButton onClick={e => setIsExpanded(false)}><ExpandMoreRoundedIcon/></IconButton>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

export default ResponseDialog;