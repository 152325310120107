import { Button, ButtonGroup, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRefetchData } from "../../helpers/CustomHooks";
import { EXTENSIONS, post } from "../../helpers/requests";
import AbstractTable from "../tables/AbstractTable";
import { useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import Context from "../../Store";
import ManagedTableView from "./ManagedTableView";
import { sortFieldChoicesInSection } from "../../helpers/StaticDataHelper";
import { hasGobalPermission } from "../permissions/perm_helper";
import { ALL_PERMS } from "../permissions/perm_constants";
import { Stack } from "../../../node_modules/@mui/material/index";
import TicketTextField from "../inputs/TicketTextField";
import { CreateManagedTableWidget } from "./CreateManagedTableWidget";


const TablesTable = ({tables, refetchTables}) => {
    const { user } = React.useContext(Context);

    const [searchParams, setSearchParams] = useSearchParams();

    const canUserCreateTable = hasGobalPermission(user, ALL_PERMS.SYSTEM_ACTIONS.EDIT_CONFIG.key) && hasGobalPermission(user, ALL_PERMS.SYSTEM_ACTIONS.EDIT_TABLES.key);

    const onViewTableClick = (tableId) => {
        setSearchParams({view: 'table', table_id: tableId});
    }

    const onNewTableCreated = (newTableId) => {
        refetchTables();
    }

    const renderRowCells = (t) => {
        return [
            <Tooltip enterDelay={1000} title={"View table"}>
                <IconButton onClick={() => onViewTableClick(t.id)}>
                    <VisibilityIcon color="primary"/>
                </IconButton>
            </Tooltip>,
            t.name
        ]
    }
    
    return (
        <Stack spacing={2}>

            <AbstractTable
                rows={tables}
                rowToKey={t => t.id}
                columnNames={['', 'Table']}
                renderRowCells={renderRowCells}
                rowHeight={'small'}
                />
            {
                !canUserCreateTable ? null : <CreateManagedTableWidget onCreated={onNewTableCreated}/>
            }
        </Stack>
    )
}


const ManagedTablesPage = () => {
    const onTablesFetched = (resp) => {
        for(const table of resp.data.data){
            sortFieldChoicesInSection(table.structure);
        }
    }

    const [tablesData, refetchTables] = useRefetchData(EXTENSIONS.GET_ALL_MANAGED_TABLES, {}, onTablesFetched);
    const [searchParams, setSearchParams] = useSearchParams();

    if(tablesData === false) return <div>Failed to load</div>
    if(tablesData === null) return <div>Loading...</div>
    const tables = tablesData.data;

    switch (searchParams.get('view')){
        case 'all':
            return <TablesTable tables={tables} refetchTables={refetchTables}/>
        case 'table':
            return <ManagedTableView tables={tables} refetchTables={refetchTables}/>
                
        default:
            return <TablesTable tables={tables} refetchTables={refetchTables}/>
    }

}

export default ManagedTablesPage;