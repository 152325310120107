export const FIELD_VALUE_TYPES = {
    STRING: "string",
    FLOAT: "float",
    BOOL: "bool",
    DATE: "date",
    FILE: "file",
    EMPLOYEE: "employee",
    DEPARTMENT: "department",
    ACCESS_LEVEL: "access level"
}
export const FIELD_COMPONENT_TYPES = {
    SELECT: "select",
    MULTISELECT: "multiselect",
    MULTICHECK: "multicheck",
    YES_NO_SELECT:"yes no select",
    SHORT_TEXT: "short text",
    LONG_TEXT: "long text",
    MULTI_TEXT:"multitext",
    CHECKBOX: "checkbox",
    // FLOAT:"float",
    DATEPICKER: "datepicker",
    FILEPICKER: "filepicker",
}

export const COND_RELATION_TYPES = {
    EQUALS: "=",
    NOT_EQUALS: "!=",
    IN: "in",
    NOT_IN: "not_in"
}

export const CHOICE_DESC_TYPES = {
    STATIC: 'StaticSelectChoicesDescriptor',
    COMPANY: "CompanySelectChoicesDescriptor",
    TABLE: "TableSelectChoicesDescriptor",
    ACCESS_LEVEL: 'AccessLevelSelectChoicesDescriptor'
}