import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Stack} from "../../../../node_modules/@mui/material/index"
import { SX_BOX_SIMPLE } from "../../../helpers/common_sx";
import { FIELD_COMPONENT_TYPES, FIELD_VALUE_TYPES } from "../../inputs/FieldProps";
import { makeNestedDeepCopy } from "../../../helpers/TicketFormHelper";
import TicketTextField from "../../inputs/TicketTextField";
import Context from "../../../Store";
import TicketSelectField from "../../inputs/TicketSelectField";
import TicketParagraphField from "../../inputs/TicketParagraphField";
import TicketCheckbox from "../../inputs/TicketCheckbox";
import { CLIENT_FIELD_ID_PREFIX, COMPONENT_CHOICES_PER_VALUE_TYPE, MAX_LABEL_LEN, cloneFieldStructure, doesComponentUseChoices, getDependantFields, getValueTypeChoices, isPreExistingField, makeDefaultChoiceDesc } from "./field_builder_helper";
import HideConditionsField from "./HideConditionsField";
import { ChoiceDescField } from "./choice_descs/ChoiceDescField";
import { getNextId } from "../../../helpers/CommonUtil";
import { ConfigPanelHeader } from "./ConfigPanelHeader";
import { CheckBoxWithHelpTooltip } from "./CheckBoxWithHelpTooltip";

const IS_USER_ROLE_TOOLTIP = `
User role fields will have a special permission profile created for them where the user marked the field can be granted additional permissions
for actions regarding the entity that they are marked with the role. They will also appear in the user's summary / relevance in system, which is useful
when offboarding the user.
`

const IS_SECRET_TOOLTIP = `
If a field is secret, then users will not be able to view the value for the field unless they are granted permission or are reviewing the request.
This is NOT meant for sensative info such as SSN or credit card info, it is intended for fields like a vendor's pay rate which you may not want being public to the company.
`

const PANEL_TYPES = {
    NONE: null,
    CHOICES_DESC: 'choice',
    HIDE_CONDS: 'hide_conds'
}
export const makeDefaultFieldStructure = () => {
    const idNum = getNextId();
    const output = {
        id: `${CLIENT_FIELD_ID_PREFIX}${idNum}`,
        value_type: FIELD_VALUE_TYPES.STRING,
        component: FIELD_COMPONENT_TYPES.SHORT_TEXT,
        label: "New Field",
        short_label: "New Field",
        helper_text: "",
        is_optional: false,
        is_secret: false,
        notify_if_problematic: false,
        is_user_role: false,
        hide_conditions: null,
        choice_desc: null
    }
    // output.choices = [];
    return output;
}

const isValidComponentChange = (prevComponent, newComponent) => {
    if(prevComponent === newComponent) return true;

    if(prevComponent === FIELD_COMPONENT_TYPES.SELECT) return false;
    const multiChoiceTypes = [FIELD_COMPONENT_TYPES.MULTISELECT, FIELD_COMPONENT_TYPES.MULTISELECT]
    if(multiChoiceTypes.includes(multiChoiceTypes)) return multiChoiceTypes.includes(newComponent);
    return true;
}

const getErrMsgIfUserFieldOrHasDependencies = (fieldStructure, sectionStructure) => {
    const dependentFields = getDependantFields(fieldStructure, sectionStructure);
    
    let errMsg = "";
    if(fieldStructure.is_user_role) errMsg += "- It is a User Role field\n";
    if(dependentFields.length > 0){
        errMsg += "- The following field(s) directly depend on it:\n"
        for(const depField of dependentFields){
            errMsg += `--- ${depField.label}\n`
        }
    }

    if(errMsg){
        errMsg = "cannot set this field as optional, secret or hidden because\n" + errMsg;
    }
    return errMsg;
}

export const FieldStructureForm = ({
    fieldStructure, setFieldStructure, sectionStructure, configMeta, tables, refetchTables, addField, moveField, removeField, canHaveUserRoleFields, contextFields
}) => {
    const {alertError} = React.useContext(Context);
    const {
        id, value_type, component, label, short_label, helper_text,
        is_optional, is_secret, notify_if_problematic, is_user_role,
        hide_conditions, choice_desc
    } = fieldStructure;

    // const dependentFields = getDependantFields(fieldStructure, sectionStructure);
    const [fieldCopy, setFieldCopy] = useState({});
    useEffect(() => setFieldCopy(cloneFieldStructure(fieldStructure)), []);
    
    const [openPanel, setOpenPanel] = useState(PANEL_TYPES.NONE);

//#region fieldStructure state setters
    const set_value_type = (new_value_type) => {
        if(isPreExistingField(fieldStructure.id)){
            alertError('Cannot edit the value type of a pre-existing field');
            return;
        }
        //simple - set label and short_label together
        setFieldStructure({...fieldStructure, value_type: new_value_type});
    }
    const set_label = (new_label) => {
        //simple - set label and short_label together
        setFieldStructure({...fieldStructure, label: new_label, short_label: new_label});
    }
    const set_helper_text = (new_helper_text) => {
        setFieldStructure({...fieldStructure, helper_text: new_helper_text});
        //simple
    }
    
    const set_is_optional = (new_is_optional) => {
        //if setting to true, make sure no dependencies on field and not a role field
        //this effects other fields
        let errMsg = "";
        if(new_is_optional) errMsg = getErrMsgIfUserFieldOrHasDependencies(fieldStructure, sectionStructure);
        if(errMsg) alertError(errMsg);
        else setFieldStructure({...fieldStructure, is_optional: new_is_optional});
    }
    const set_is_secret = (new_is_secret) => {
        //if setting to true, make sure no dependencies on field and not a role field
        //this effects other fields
        let errMsg = "";
        if(new_is_secret) errMsg = getErrMsgIfUserFieldOrHasDependencies(fieldStructure, sectionStructure);
        if(errMsg) alertError(errMsg);
        else setFieldStructure({...fieldStructure, is_secret: new_is_secret});
    }
    const set_is_user_role = (new_is_user_role) => {
        //user fields cannot be optional or secret - reject
        //can only be true if value_type is 'user'
        //this effects other fields - if a company select or table field depends on a user type param
        let errMsg = "";
        if(new_is_user_role){
            if(is_optional || is_secret || hide_conditions){
                errMsg += "Cannot mark field as a User Role since it is either optional, secret, or has 'do not display' conditions.\n";
            }
            if(value_type !== FIELD_VALUE_TYPES.EMPLOYEE){
                errMsg += "Cannot mark field as a User Role since the value type is not 'user'\n";
            }
        }
        if(errMsg){
            alertError(errMsg);
        }
        else setFieldStructure({...fieldStructure, is_user_role: new_is_user_role});
    }

    const set_component = (new_component) => {
        if(isPreExistingField(fieldStructure.id) && !isValidComponentChange(component, new_component)){
            alertError('This type of component change is not yet supported for pre-existing fields');
            return;
        }
        // reevaluate all the values, possibly nullifying
        const needsChoices = doesComponentUseChoices(new_component);
        const alreadyHasChoices = !!choice_desc;
        let newChoiceDesc = choice_desc;
        if(needsChoices && !alreadyHasChoices){
            newChoiceDesc = makeDefaultChoiceDesc();
        }
        else if(!needsChoices && alreadyHasChoices){
            newChoiceDesc = null;//TODO: see if you can keep it around just so the data isnt erased
        }
        setFieldStructure({...fieldStructure, component: new_component, choice_desc: newChoiceDesc});
    }

//#endregion fieldStructure state setters

//#region panel handling
    const onPanelOpenClick = (panelType) => {
        if(panelType === PANEL_TYPES.HIDE_CONDS){
            const errMsg = getErrMsgIfUserFieldOrHasDependencies(fieldStructure, sectionStructure);
            if(errMsg){
                alertError(errMsg);
                return;
            }
        }
        setFieldCopy(cloneFieldStructure(fieldStructure));
        setOpenPanel(panelType);
    }

    const onCanelPanelGestured = () => {
        //have user confirm work will be lost?
        setFieldCopy(cloneFieldStructure(fieldStructure));//we want
        setOpenPanel(PANEL_TYPES.NONE);
    }

    const applyChanges = () => {
        //child is responsible for error validation and conditionally calling this
        //if has errors - notify
        //else - apply changes & close panel
        setFieldStructure(fieldCopy);
        setFieldCopy(makeNestedDeepCopy(fieldCopy))
        // setFieldCopy(makeNestedDeepCopy(fieldCopy));
        setOpenPanel(PANEL_TYPES.NONE)
    }
//#endregion panel handling

    return (
        <Box sx={SX_BOX_SIMPLE}>
            <ConfigPanelHeader
                title={'Field'}
                onAddClick={addField}
                onMoveClick={moveField}
                onDeleteClick={removeField}
                moveTooltip={'move field up or down in section'}
                />
            <Stack spacing={2}>
                <TicketTextField
                    label={'Field Label'}
                    value={label}
                    setValue={set_label}
                    maxLen={MAX_LABEL_LEN}
                    />
                <TicketParagraphField
                    label={'Helper Text'}
                    value={helper_text ? helper_text : ""}
                    setValue={set_helper_text}
                    />
                <TicketSelectField
                    label="Value Type"
                    value={value_type}
                    setValue={set_value_type}
                    choices={getValueTypeChoices(fieldStructure.id)}
                    />

                <Stack direction='row' spacing={1}>
                    <TicketSelectField
                        label="Field Type"
                        value={component}
                        setValue={set_component}
                        choices={COMPONENT_CHOICES_PER_VALUE_TYPE}
                        dependencies={['value_type']}
                        sectionValues={{value_type, component}}
                        />
                    {
                        !choice_desc ? null :
                        <ChoiceDescField
                            // fieldStructure={fieldStructure}
                            fieldStructure={fieldCopy}
                            sectionStructure={sectionStructure}
                            // setChoiceDesc={set_choice_desc}
                            setChoiceDesc={(x) => setFieldCopy({...fieldCopy, choice_desc: x})}
                            configMeta={configMeta}
                            tables={tables}
                            refetchTables={refetchTables}
                            save={applyChanges}
                            cancel={onCanelPanelGestured}
                            isPanelOpen={openPanel === PANEL_TYPES.CHOICES_DESC}
                            onPanelOpenClick={() => onPanelOpenClick(PANEL_TYPES.CHOICES_DESC)}
                            contextFields={contextFields}
                            />
                    }
                </Stack>
                <Stack direction='row' spacing={1}>
                    <Stack spacing={-2}>
                        {
                            !canHaveUserRoleFields ? null :
                            <CheckBoxWithHelpTooltip
                                label={'User role'}
                                value={is_user_role}
                                setValue={set_is_user_role}
                                tooltip={IS_USER_ROLE_TOOLTIP}
                                />
                        }
                        <TicketCheckbox
                            label={'Optional'}
                            value={is_optional}
                            setValue={set_is_optional}
                            />
                        <CheckBoxWithHelpTooltip
                            label={'Secret'}
                            value={is_secret}
                            setValue={set_is_secret}
                            tooltip={IS_SECRET_TOOLTIP}
                            />
                    </Stack>
                    <Divider flexItem  orientation="vertical" sx={{ borderBottomWidth: 1, bgcolor: "#000000"}}/>
                    <HideConditionsField
                        fieldStructure={fieldCopy}
                        sectionStructure={sectionStructure}
                        // fieldStructure={fieldStructure}
                        // setHideCondsValue={set_hide_conditions}
                        setHideCondsValue={(x) => setFieldCopy({...fieldCopy, hide_conditions: x})}
                        applyChanges={applyChanges}
                        cancel={onCanelPanelGestured}
                        isPanelOpen={openPanel === PANEL_TYPES.HIDE_CONDS}
                        onPanelOpenClick={() => onPanelOpenClick(PANEL_TYPES.HIDE_CONDS)}
                        />
                </Stack>


            </Stack>
        </Box>
    )
}

